import React from "react";

import { useTranslation } from "react-i18next";
import { Subscription, useGetSubscriptionsQuery, useGetUserDevicesQuery, useGetValidPaymentQuery } from "store/api";
import { Flex, ItemCart, TitleH1Os } from "ui";
import { PendingSubscriptionPrompt } from "views/MyDevices/PendingSubscriptionsPrompt";
import BuyDevicePrompt from "views/commons/BuyDevicePrompt";

import { SubscriptionList } from "./SubscriptionList";

const MySubscriptions: React.FC = () => {
  const { t } = useTranslation();
  const { data: subscriptions, isLoading: isLoadingSubscription } = useGetSubscriptionsQuery();
  const { data: validPaymentData, isLoading: isLoadingPayment } = useGetValidPaymentQuery();
  const { data: pairedDevices, isLoading: isLoadingDevices } = useGetUserDevicesQuery();
  const isLoadingData = isLoadingSubscription || isLoadingPayment || isLoadingDevices;

  const pendingSubscriptions = subscriptions?.filter((subscription: Subscription) => !subscription.deviceId);

  const isSubscriptionsExists = subscriptions && subscriptions.length > 0;

  if (isLoadingData) {
    return (
      <Flex w="full" flexDirection="column">
        <TitleH1Os>{t("label_noun_my_subscriptions")}</TitleH1Os>
        <ItemCart>
          <ItemCart.Header />
          <ItemCart.Description />
          <ItemCart.Content />
          <ItemCart.Footer />
        </ItemCart>
      </Flex>
    );
  }

  return (
    <Flex w="full" flexDirection="column">
      <TitleH1Os>{t("label_noun_my_subscriptions")}</TitleH1Os>
      {!isSubscriptionsExists && (
        <BuyDevicePrompt
          title={t("prompt_want_to_buy_subscription")}
          description={t("prompt_go_to_store_enjoy_advantages")}
          listItems={[t("label_noun_lower_initial_price"), t("label_action_request_pay_monthly_stop_subscription")]}
        />
      )}
      <PendingSubscriptionPrompt counter={pendingSubscriptions?.length} />
      <SubscriptionList subscriptions={subscriptions} mandate={validPaymentData} pairedDevices={pairedDevices} />
    </Flex>
  );
};

export default MySubscriptions;
