import { cssVar, defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const $startColor = cssVar("skeleton-start-color");
const $endColor = cssVar("skeleton-end-color");

const baseStyle = defineStyle({
  [$startColor.variable]: "colors.white",
  [$endColor.variable]: "colors.grayLight",
  background: $startColor.reference,
  borderColor: $endColor.reference,
  opacity: 0.7,
  borderRadius: "2px",
});

export default defineStyleConfig({
  baseStyle,
});
