import React from "react";

import { createIcon } from "@chakra-ui/react";

export const WalletLogo = createIcon({
  displayName: "WalletLogo",
  viewBox: "0 0 60 60",
  defaultProps: {
    width: "3.75rem",
    height: "3.75rem",
    fill: "transparent",
  },
  path: [
    <path
      d="M50.95 23.47H15.86C13.9159 23.47 12.34 25.046 12.34 26.99V48.6C12.34 50.544 13.9159 52.12 15.86 52.12H50.95C52.894 52.12 54.47 50.544 54.47 48.6V26.99C54.47 25.046 52.894 23.47 50.95 23.47Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M12.34 30.29H54.3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M54.3 37.27H12.34" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M8.61994 33.52L7.25994 32.73C6.86553 32.5032 6.51986 32.2007 6.24284 31.8398C5.96581 31.4789 5.76291 31.0668 5.6458 30.6272C5.52869 30.1875 5.49969 29.7291 5.56047 29.2782C5.62124 28.8273 5.7706 28.3929 5.99994 28L16.6199 9.61C17.0781 8.81644 17.8324 8.23705 18.7173 7.99895C19.6022 7.76086 20.5454 7.88351 21.3399 8.34L40.7199 19.53"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M15.5 12.71L27.31 19.53" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M14.76 20.2L11.71 18.45" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
  ],
});
