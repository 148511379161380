import React from "react";

import { TextProps as Props, SystemProps } from "@chakra-ui/react";

import { Box } from "../Box/Box";
import { Text } from "../Text/Text";

interface TitleH2Props extends Props {
  /**
   * The CSS `text-align` property
   * @type SystemProps["textAlign"]
   */
  align?: SystemProps["textAlign"];
  /**
   * The CSS `text-decoration` property
   * @type SystemProps["textDecoration"]
   */
  decoration?: SystemProps["textDecoration"];
  /**
   * The CSS `text-transform` property
   * @type SystemProps["textTransform"]
   */
  casing?: SystemProps["textTransform"];
  children: string;
  subtitle?: string;
}

export const TitleH1Os: React.FC<TitleH2Props> = ({ children, subtitle, ...props }) => {
  return (
    <Box pb="xl">
      <Text color="black" variant="header24" {...props}>
        {children}
      </Text>
      {subtitle && (
        <Text pt="m" color="black">
          {subtitle}
        </Text>
      )}
    </Box>
  );
};
