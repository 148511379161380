import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ArrowUp = createIcon({
  displayName: "ArrowUp",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 6,
      h: 6,
    },
  },
  path: [
    <path
      d="M11.3 6.8L2.3 15.8C1.9 16.2 1.9 16.8 2.3 17.2C2.7 17.6 3.3 17.6 3.7 17.2L12 8.9L20.3 17.2C20.7 17.6 21.3 17.6 21.7 17.2C22.1 16.8 22.1 16.2 21.7 15.8L12.7 6.8C12.3 6.4 11.7 6.4 11.3 6.8Z"
      fill="currentColor"
    />,
  ],
});
