import React from "react";

import { useUserDeviceSocket } from "hooks";
import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import {
  Device,
  Subscription,
  transformResponse,
  useConnectSubscriptionMutation,
  useCreatePairingTokenMutation,
  useGetSubscriptionsQuery,
} from "store/api";
import { err, errorToast } from "store/errors";
import {
  BodyText,
  Button,
  Flex,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  TitleH1Os,
} from "ui";

import { FinalizationState } from "./Finalization";

const EnteringCode: React.FC = () => {
  const { t } = useTranslation();
  const { data: subscriptions } = useGetSubscriptionsQuery();
  const [connectSubscription] = useConnectSubscriptionMutation();
  const [getPairToken, getPairTokenState] = useCreatePairingTokenMutation();
  const lastMessage = useUserDeviceSocket();
  const navigate = useNavigate();

  const goToMyDevices = React.useCallback(() => navigate(Paths.MyDevices), [navigate]);
  const pairingToken = React.useMemo(
    () => (getPairTokenState.isSuccess ? getPairTokenState.data.value : ""),
    [getPairTokenState],
  );

  const newlyPairedDevice = React.useRef<Device | null>(null);
  React.useEffect(() => {
    const onConnect = async () => {
      try {
        const message = JSON.parse(lastMessage.message);
        const identifier = JSON.parse(lastMessage.identifier);
        newlyPairedDevice.current = transformResponse(message);
        const subscriptionId = subscriptions?.find((subscription: Subscription) => !subscription.deviceId)?.id;
        if (identifier.channel === "Users::DeviceChannel" && subscriptionId) {
          await connectSubscription({ id: message.data.id, requestBody: { subscription_id: subscriptionId } }).unwrap();
        }
      } catch (error) {
        // if error occurs before we transform the message we stay without redirection to step 3 and show general message
        // otherwise we redirect to step 3 because we are able to provide device id and button to assign device manually
        if (newlyPairedDevice.current) {
          errorToast({
            title: t("prompt_no_subscription_pt1"),
            description: t("prompt_no_subscription_pt2"),
          });
        } else {
          err.handler(error);
        }
      } finally {
        if (newlyPairedDevice.current) {
          const finalStepState = { newDeviceId: newlyPairedDevice.current?.id } as FinalizationState;
          navigate(Paths.PairDeviceStep3, { state: finalStepState });
        }
      }
    };

    if (lastMessage && lastMessage.identifier && lastMessage.message) {
      onConnect();
    }
  }, [connectSubscription, lastMessage, navigate, subscriptions, t]);

  React.useEffect(() => {
    const getCode = async () => {
      try {
        await getPairToken().unwrap();
      } catch (e) {
        err.handler(e, [
          {
            code: "error_billing_details_not_exists",
            title: t("prompt_your_billing_details_dont_exists"),
            description: t("prompt_go_back_to_billing_address"),
          },
          {
            code: "error_credit_card_invalid",
            title: t("prompt_no_valid_credit_card_available"),
            description: t("prompt_go_back_to_payment"),
          },
          {
            code: "error_general",
            title: t("prompt_error_generic_toast_pt1"),
            description: t("label_action_request_error_generic_toast_pt2"),
          },
        ]);
      }
    };
    getCode();
  }, [getPairToken, t]);

  return (
    <>
      <TitleH1Os>{t("label_action_info_entering_code")}</TitleH1Os>
      <InfoPanel>
        <InfoPanelIllustration>
          {getPairTokenState.isSuccess && (
            <Flex flexDirection="column">
              <QRCode value={pairingToken} size={150} />
              <BodyText variant="header24" mt={4} align="center">
                {pairingToken}
              </BodyText>
            </Flex>
          )}
        </InfoPanelIllustration>
        <InfoPanelContent>
          <BodyText>{t("prompt_entering_code_instructions")}</BodyText>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button onClick={goToMyDevices} variant="secondary">
            {t("label_action_info_resignation")}
          </Button>
        </InfoPanelAction>
      </InfoPanel>
    </>
  );
};

export default EnteringCode;
