import React from "react";

import { createIcon } from "@chakra-ui/react";

export const BackArrow = createIcon({
  displayName: "BackArrow",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 5,
      h: 5,
    },
  },
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41421 12L14.7071 3.70706L13.2929 2.29285L4.29289 11.2928C3.90237 11.6834 3.90237 12.3165 4.29289 12.7071L13.2929 21.7071L14.7071 20.2928L6.41421 12Z"
      fill="#FF5151"
    />,
  ],
});
