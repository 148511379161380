import React from "react";

import useWebSocket, { ReadyState } from "react-use-websocket";
import { store } from "store";

export const useUserDeviceSocket = () => {
  const token = store.getState().auth.token;
  const url = `${process.env.REACT_APP_WEBSOCKET_URL}?token=${token?.replace("Bearer ", "")}`;
  const options = { protocols: ["actioncable-v1-json", "actioncable-unsupported"] };
  const { readyState, sendJsonMessage, lastJsonMessage } = useWebSocket(url, options);

  React.useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        command: "subscribe",
        identifier: JSON.stringify({ channel: "Users::DeviceChannel" }),
      });
    }
  }, [readyState, sendJsonMessage]);

  return lastJsonMessage;
};
