import React from "react";

import { Stack } from "@chakra-ui/react";
import { subMonths } from "date-fns";
import { useTranslation } from "react-i18next";
import { Device, Mandate, Subscription, useGetSubscriptionOfferQuery } from "store/api";
import { BodyText, DividedList } from "ui";
import { utils } from "utils";
import InfoListItem from "views/commons/InfoListItem";

type SubscriptionInfoProps = {
  subscription: Subscription;
  mandate?: Mandate;
  device?: Device;
  isActive?: boolean;
};

export const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({
  subscription,
  mandate,
  device,
  isActive = true,
}) => {
  const { t } = useTranslation();
  const { expiredAt, freePeriodExpiredAt, priceSubunit, priceCurrency, subscriptionOfferId } = subscription;
  const { data: offer, isLoading } = useGetSubscriptionOfferQuery({ id: subscriptionOfferId }, { skip: !isActive });

  const isLastPaymentAvailable = expiredAt && expiredAt !== freePeriodExpiredAt && offer?.durationInMonths;
  const isOfferDurationInMonths = offer?.durationInMonths !== undefined;

  return (
    <Stack spacing={4}>
      <BodyText color="violet.300">{t("label_noun_subscription_info").toUpperCase()}</BodyText>
      <DividedList dividerColor="grayLight">
        <InfoListItem title={`${t("label_noun_device")}:`} content={utils.devices.getName(device?.externalProductId)} />
        <InfoListItem title={`${t("label_noun_device_id")}:`} content={device?.externalDeviceId} />
        <InfoListItem
          title={`${t("label_noun_monthly_fee")}:`}
          content={utils.subscriptions.formatPrice(priceSubunit, priceCurrency)}
        />
        <InfoListItem
          title={`${t("label_noun_billing_period")}:`}
          content={
            isOfferDurationInMonths
              ? utils.helpers.formatDurationToString(
                  { months: offer.durationInMonths },
                  utils.helpers.fixDurationFormatForPolishMonth(),
                )
              : null
          }
          isLoading={isLoading}
        />
        <InfoListItem
          title={`${t("label_noun_last_payment")}:`}
          content={
            isLastPaymentAvailable
              ? utils.helpers.formatDateToString(subMonths(new Date(expiredAt), offer.durationInMonths))
              : null
          }
          isLoading={isLoading}
        />
        <InfoListItem
          title={`${t("label_noun_next_payment")}:`}
          content={expiredAt ? utils.helpers.formatDateToString(new Date(expiredAt)) : null}
        />
        <InfoListItem title={`${t("label_noun_type_of_card")}:`} content={mandate?.label} />
        <InfoListItem
          title={`${t("label_noun_credit_card")}:`}
          content={mandate?.number ? `**** **** **** ${mandate.number}` : null}
        />
      </DividedList>
    </Stack>
  );
};
