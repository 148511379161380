import React from "react";

import { createIcon } from "@chakra-ui/react";

export const VisaCardLogo = createIcon({
  displayName: "VisaCardLogo",
  viewBox: "0 0 48 32",
  defaultProps: {
    width: 12,
    height: 8,
    fill: "transparent",
  },
  path: [
    <rect x="0.5" y="0.5" width="47" height="31" rx="3.5" fill="white" />,
    <path
      d="M23.84 10.7325L21.64 21.295H18.9825L21.18 10.7325H23.84ZM35.0275 17.5525L36.4275 13.5875L37.2325 17.5525H35.0275ZM37.995 21.3025H40.455L38.3075 10.74H36.0375C35.7925 10.7393 35.553 10.813 35.3508 10.9514C35.1486 11.0898 34.9931 11.2863 34.905 11.515L30.905 21.3025H33.6975L34.2525 19.725H37.6625L37.995 21.3025ZM31.0525 17.8525C31.065 15.0675 27.3025 14.9125 27.3275 13.6675C27.3275 13.2875 27.6875 12.885 28.455 12.7825C29.3564 12.6927 30.2651 12.8545 31.08 13.25L31.545 11C30.7472 10.6928 29.8999 10.5343 29.045 10.5325C26.415 10.5325 24.5675 11.9675 24.545 14.0325C24.5275 15.555 25.865 16.4025 26.87 16.9075C27.875 17.4125 28.255 17.7575 28.25 18.22C28.25 18.9275 27.4225 19.2425 26.66 19.255C25.7139 19.2702 24.7801 19.0394 23.95 18.585L23.4675 20.9C24.4074 21.2747 25.4107 21.4648 26.4225 21.46C29.2175 21.46 31.045 20.0425 31.0525 17.845V17.8525ZM20.0525 10.74L15.7325 21.295H12.92L10.8 12.865C10.6725 12.3475 10.55 12.1575 10.1675 11.9375C9.33528 11.5276 8.45394 11.2259 7.54504 11.04L7.60754 10.7325H12.1325C12.4324 10.735 12.7213 10.8452 12.9466 11.043C13.1719 11.2408 13.3187 11.513 13.36 11.81L14.48 17.92L17.25 10.7325L20.0525 10.74Z"
      fill="#1A1F71"
    />,
    <rect x="0.5" y="0.5" width="47" height="31" rx="3.5" stroke="#8D95A5" />,
  ],
});
