import { useCallback, useEffect } from "react";

import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { store } from "store";
import { api, useSignOutMutation } from "store/api";
import {
  BodyText,
  Button,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  SpinnerWithLabel,
  Stack,
  TitleH1,
} from "ui";

const SignOut: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signOut] = useSignOutMutation();

  const [params] = useSearchParams();
  const isSessionExpired = !!params.get("session-expired");

  const goToSignIn = useCallback(() => navigate(Paths.SignIn, { replace: true }), [navigate]);

  useEffect(() => {
    const onSignOut = async () => {
      try {
        await signOut().unwrap();
      } finally {
        store.dispatch(api.util.resetApiState());
        if (!isSessionExpired) goToSignIn();
      }
    };

    onSignOut();
  }, [goToSignIn, isSessionExpired, signOut]);

  if (isSessionExpired)
    return (
      <InfoPanel isBorder>
        <InfoPanelIllustration illustrationName="SubscriptionEnd2Illustration" />
        <InfoPanelContent>
          <Stack spacing="m">
            <TitleH1 color="redAlert">{t("prompt_your_session_has_expired")}</TitleH1>
            <BodyText>{t("prompt_please_sign_in")}</BodyText>
          </Stack>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button type="submit" onClick={goToSignIn}>
            {t("label_action_request_go_to_sign_in")}
          </Button>
        </InfoPanelAction>
      </InfoPanel>
    );

  return <SpinnerWithLabel label={t("label_action_info_loading")} />;
};

export default SignOut;
