import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationPayment2Illustration = createIcon({
  displayName: "ConfirmationPayment2Illustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <g clipPath="url(#clip0_1880_85491)">
      <rect width="150" height="150" fill="white" />,
      <path
        d="M17.59 63.2299C13.6907 54.6866 12.8999 45.0491 15.3544 35.9844C17.8088 26.9197 23.3542 18.9979 31.0314 13.5891C38.7085 8.18038 48.0346 5.62485 57.3965 6.36455C66.7585 7.10424 75.5675 11.0927 82.3 17.6399"
        stroke="#999999"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M61.9199 51.28L62.1899 50.61C64.924 44.1424 69.3016 38.5021 74.8885 34.2487C80.4754 29.9953 87.0774 27.2766 94.0394 26.3625C101.001 25.4484 108.081 26.3705 114.577 29.0375C121.072 31.7044 126.758 36.0234 131.069 41.566C135.38 47.1086 138.167 53.6821 139.153 60.6342C140.139 67.5864 139.29 74.6755 136.691 81.1984C134.091 87.7212 129.831 93.4509 124.333 97.8191C118.836 102.187 112.291 105.042 105.35 106.1C104.58 106.21 103.81 106.31 103.02 106.38"
        stroke="#999999"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M50.7099 143.77C72.9504 143.77 90.9799 125.74 90.9799 103.5C90.9799 81.2595 72.9504 63.23 50.7099 63.23C28.4694 63.23 10.4399 81.2595 10.4399 103.5C10.4399 125.74 28.4694 143.77 50.7099 143.77Z"
        stroke="#8F8BEF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M55.13 113.12C55.2071 111.941 54.8516 110.775 54.13 109.84C53.1063 108.829 51.8694 108.06 50.51 107.59C48.78 106.89 47.18 106.17 45.71 105.45C44.3429 104.783 43.0715 103.935 41.93 102.93C40.8961 102.008 40.0652 100.88 39.49 99.62C38.8935 98.1792 38.6072 96.6288 38.65 95.07C38.6054 93.6984 38.8494 92.3327 39.3662 91.0614C39.883 89.7901 40.6611 88.6415 41.65 87.69C43.8648 85.6726 46.7085 84.4822 49.7 84.32V78.73H53.91V84.42C56.8606 84.7541 59.5791 86.1812 61.53 88.42C63.4268 90.76 64.4078 93.71 64.29 96.72H55.29C55.3845 95.231 55.0141 93.7493 54.23 92.48C53.8964 92.0527 53.4763 91.7006 52.9972 91.4469C52.5182 91.1932 51.9909 91.0435 51.45 91.0076C50.9091 90.9717 50.3667 91.0505 49.8583 91.2387C49.3499 91.427 48.8871 91.7205 48.5 92.1C47.8097 92.9434 47.4538 94.0111 47.5 95.1C47.4717 95.6667 47.5561 96.2333 47.7484 96.7672C47.9406 97.301 48.2369 97.7914 48.62 98.21C49.657 99.1667 50.8692 99.9137 52.19 100.41C53.83 101.077 55.38 101.8 56.84 102.58C58.1995 103.267 59.4663 104.124 60.61 105.13C61.6539 106.067 62.5004 107.202 63.1 108.47C63.7346 109.911 64.042 111.476 64 113.05C64.0506 114.412 63.8221 115.771 63.3286 117.042C62.835 118.312 62.0868 119.469 61.13 120.44C58.9963 122.443 56.2225 123.626 53.3 123.78V129.1H49.14V123.75C45.3 123.34 42.34 122.02 40.29 119.75C38.1763 117.337 37.0701 114.205 37.2 111H46.12C46.007 112.641 46.4821 114.268 47.46 115.59C47.9325 116.124 48.52 116.543 49.1783 116.817C49.8365 117.091 50.5483 117.211 51.26 117.17C51.7801 117.198 52.3004 117.117 52.7877 116.933C53.2749 116.749 53.7184 116.465 54.09 116.1C54.4521 115.697 54.7305 115.226 54.909 114.714C55.0875 114.203 55.1626 113.661 55.13 113.12Z"
        fill="#8F8BEF"
      />
      ,
      <path
        d="M109.25 67.94C108.106 66.9339 106.839 66.0771 105.48 65.39C104.02 64.6434 102.47 63.9167 100.83 63.21C99.5085 62.7108 98.2961 61.9603 97.26 61C96.8752 60.5806 96.578 60.0886 96.3857 59.5529C96.1934 59.0171 96.1098 58.4484 96.14 57.88C96.0976 56.7917 96.4531 55.7252 97.14 54.88C97.4827 54.5083 97.9034 54.2169 98.3719 54.0267C98.8404 53.8365 99.3451 53.7523 99.85 53.78C100.435 53.7559 101.017 53.8799 101.541 54.1403C102.065 54.4008 102.516 54.7893 102.85 55.27C103.633 56.536 104.004 58.0142 103.91 59.5H112.86C112.978 56.4884 111.993 53.5373 110.09 51.2C108.148 48.9548 105.43 47.5261 102.48 47.2V41.52H98.26V47.1C95.2672 47.265 92.4234 48.459 90.21 50.48C89.2214 51.4296 88.4434 52.5765 87.9266 53.8461C87.4097 55.1158 87.1656 56.4799 87.21 57.85C87.1515 59.4085 87.4206 60.962 88 62.41C88.5767 63.6696 89.4074 64.7965 90.44 65.72C91.5798 66.725 92.8515 67.5694 94.22 68.23C95.69 68.96 97.29 69.67 99.02 70.38C100.382 70.8452 101.62 71.6147 102.64 72.63C103.363 73.5642 103.719 74.7312 103.64 75.91C103.682 76.4515 103.615 76.9959 103.443 77.5111C103.272 78.0263 102.998 78.502 102.64 78.91C102.27 79.2767 101.826 79.5614 101.339 79.7457C100.851 79.9301 100.33 80.0099 99.81 79.98C99.0991 80.0226 98.3878 79.9035 97.7296 79.6316C97.0714 79.3596 96.4835 78.942 96.01 78.41C95.0321 77.0878 94.5571 75.4606 94.67 73.82H91.13C95.094 79.2266 97.9302 85.3752 99.47 91.9H101.94V86.58C104.863 86.4264 107.636 85.2432 109.77 83.24C110.726 82.2701 111.473 81.1152 111.967 79.8462C112.46 78.5772 112.689 77.2207 112.64 75.86C112.683 74.2855 112.376 72.7212 111.74 71.28C111.143 70.0106 110.296 68.8746 109.25 67.94Z"
        fill="#999999"
      />
      ,
      <path
        d="M52.19 41.23C51.8078 40.8113 51.5128 40.3206 51.3222 39.7866C51.1316 39.2527 51.0493 38.6861 51.08 38.12C51.0339 37.0312 51.3898 35.9634 52.08 35.12C52.4184 34.7497 52.8335 34.4576 53.2964 34.2642C53.7593 34.0708 54.2587 33.9806 54.76 34C55.344 33.9757 55.9247 34.0985 56.4489 34.3571C56.9731 34.6157 57.4239 35.0018 57.76 35.48C58.1038 35.9774 58.3545 36.5331 58.5 37.12C60.1882 34.763 62.0751 32.555 64.14 30.52C62.2719 28.8061 59.9089 27.7278 57.39 27.44V21.74H53.19V27.33C50.2147 27.5063 47.3904 28.6996 45.19 30.71C44.2014 31.6596 43.4234 32.8064 42.9066 34.0761C42.3898 35.3458 42.1456 36.7099 42.19 38.08C42.1392 39.6333 42.4152 41.1801 43 42.62C43.5714 43.8827 44.4029 45.0106 45.44 45.93C46.5813 46.9404 47.8567 47.7884 49.23 48.45C50.1 48.88 51.03 49.3 51.99 49.73C52.7824 47.4186 53.745 45.1691 54.87 43C53.8812 42.5692 52.9744 41.9703 52.19 41.23Z"
        fill="#999999"
      />
      ,
    </g>,
    <defs>
      <clipPath id="clip0_1880_85491">
        <rect width="150" height="150" fill="white" />,
      </clipPath>
    </defs>,
  ],
});
