import { defineStyle, defineStyleConfig } from "@chakra-ui/system";

import colors from "../../foundations/Colors/colors";

const sizes = {
  s: defineStyle({
    h: "1.75rem",
    w: "1.75rem",
    minW: "1.75rem",
    minH: "1.75rem",
  }),
  m: defineStyle({
    h: "2.25rem",
    w: "2.25rem",
    minW: "2.25rem",
    minH: "2.25rem",
  }),
  l: defineStyle({
    h: "2.5rem",
    w: "2.5rem",
    minW: "2.5rem",
    minH: "2.5rem",
  }),
  xl: defineStyle({
    h: "3rem",
    w: "3rem",
    minW: "3rem",
    minH: "3rem",
  }),
  xxl: defineStyle({
    h: "3.75rem",
    w: "3.75rem",
    minW: "3.75rem",
    minH: "3.75rem",
  }),
};

const baseStyle = defineStyle({
  display: "flex",
  borderRadius: "full",
  alignItems: "center",
  justifyContent: "center",
  transitionProperty: "background-color, color",
  transitionTimingFunction: "ease-in-out",
  transitionDuration: "normal",
  _focus: {
    boxShadow: "none",
  },
  _hover: {
    _disabled: {
      cursor: "not-allowed",
      boxShadow: "none",
    },
  },
  _active: {
    bgColor: colors.grayDark,
  },
  _focusVisible: {
    boxShadow: "outline",
    outline: "none",
  },
  _disabled: {
    opacity: 0.6,
  },
});

const variantPrimary = defineStyle({
  backgroundColor: "white",
  _hover: {
    backgroundColor: "grayLight",
  },
  _disabled: {
    backgroundColor: "white",
  },
});

const variantSecondary = defineStyle({
  backgroundColor: "grayLight",
  _hover: {
    backgroundColor: "grayMedium",
  },
  _disabled: {
    backgroundColor: "grayLight",
  },
});

export const IconButton = defineStyleConfig({
  baseStyle,
  sizes,
  variants: {
    primary: variantPrimary,
    secondary: variantSecondary,
  },
  defaultProps: {
    variant: "primary",
    size: "l",
  },
});
