import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationCompleted2Illustration = createIcon({
  displayName: "ConfirmationCompleted2Illustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <g clipPath="url(#clip0_1880_85538)">
      <rect width="150" height="150" fill="white" />
      <path
        d="M16.3999 75C16.4021 60.4643 21.457 46.3815 30.6992 35.1624C39.9413 23.9433 52.7959 16.2857 67.0622 13.5007C81.3285 10.7156 96.1194 12.9763 108.902 19.8956C121.686 26.815 131.666 37.9627 137.135 51.4302C142.604 64.8978 143.221 79.8476 138.882 93.7203C134.542 107.593 125.515 119.526 113.346 127.476C101.177 135.426 86.6229 138.898 72.1754 137.299C57.728 135.7 44.2857 129.129 34.1499 118.71"
        stroke="#999999"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.75 67.5601L16.9 79.7901L8.23999 67.5601"
        stroke="#999999"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.1901 93.6C64.4626 93.6 72.7901 85.2725 72.7901 75C72.7901 64.7275 64.4626 56.4 54.1901 56.4C43.9176 56.4 35.5901 64.7275 35.5901 75C35.5901 85.2725 43.9176 93.6 54.1901 93.6Z"
        fill="currentColor"
      />
      <path
        d="M49.0901 82.74C53.3648 82.74 56.8301 79.2747 56.8301 75C56.8301 70.7253 53.3648 67.26 49.0901 67.26C44.8154 67.26 41.3501 70.7253 41.3501 75C41.3501 79.2747 44.8154 82.74 49.0901 82.74Z"
        fill="white"
      />
      <path d="M122.57 71.15H62.1201V78.71H122.57V71.15Z" fill="currentColor" />
      <path d="M116.63 75.14H97.54V87.03H116.63V75.14Z" fill="currentColor" />
      <path d="M110.26 82.27H104.31V92.41H110.26V82.27Z" fill="currentColor" />
    </g>,
    <defs>
      <clipPath id="clip0_1880_85538">
        <rect width="150" height="150" fill="white" />
      </clipPath>
    </defs>,
  ],
});
