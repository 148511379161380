import { ChangeEvent } from "react";

import { useBreakpointValue } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack } from "ui";
import * as yup from "yup";

export type ChangePasswordFormState = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
};

type Props = {
  onSubmit: (formState: ChangePasswordFormState) => void;
  isSubmitting?: boolean;
};

const ChangePasswordForm: React.FC<Props> = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();

  const validationSchema = yup
    .object({
      currentPassword: yup
        .string()
        .required(t("prompt_error_password_required"))
        .min(12, t("prompt_error_length_min", { value: 12 })),
      password: yup
        .string()
        .required(t("prompt_error_mandatory_password"))
        .min(12, t("prompt_error_length_min", { value: 12 }))
        .matches(/[a-z]+/, t("prompt_error_lowercase_lack"))
        .matches(/[A-Z]+/, t("prompt_error_uppercase_lack"))
        .matches(/\d+/, t("prompt_error_number_lack")),
      passwordConfirmation: yup
        .string()
        .required(t("prompt_error_required_password_repeat"))
        .oneOf([yup.ref("password")], t(`prompt_error_passwords_dont_match`)),
    })
    .required();

  let navigate = useNavigate();
  const variant = useBreakpointValue({ sm: "vertical", md: "horizontal" });
  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<ChangePasswordFormState>({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
  });

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    register("password").onChange(event);
    if (touchedFields.passwordConfirmation) {
      trigger("passwordConfirmation");
    }
  };

  const goBack = () => navigate(-1);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("currentPassword")}>
        <FormLabel htmlFor="current-password">{t("label_noun_old_password")}</FormLabel>
        <Input
          id="current-password"
          type="password"
          placeholder={t("label_action_request_suggestion_password")}
          {...register("currentPassword")}
          onChange={onChangePassword}
        />
        <FormErrorMessage>{errors.currentPassword?.message}</FormErrorMessage>
      </FormControl>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("password")}>
        <FormLabel htmlFor="password">{t("label_noun_new_password")}</FormLabel>
        <Input
          id="password"
          type="password"
          placeholder={t("label_action_request_suggestion_password")}
          {...register("password")}
          onChange={onChangePassword}
        />
        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
      </FormControl>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("passwordConfirmation")}>
        <FormLabel htmlFor="password-confirmation">{t("label_action_request_repeat_password")}</FormLabel>
        <Input
          id="password-confirmation"
          type="password"
          placeholder={t("label_action_request_suggestion_password")}
          {...register("passwordConfirmation")}
        />
        <FormErrorMessage>{errors.passwordConfirmation?.message}</FormErrorMessage>
      </FormControl>
      <Stack
        direction={["column", "column", "row"]}
        spacing={["l", "l", "m"]}
        justifyContent="end"
        mt={["l", "l", "xl"]}
      >
        <Button variant="secondary" w={["full", "initial"]} onClick={goBack}>
          {t("label_action_request_cancel")}
        </Button>
        <Button type="submit" w={["full", "initial"]} isLoading={isSubmitting}>
          {t("label_action_request_set_password")}
        </Button>
      </Stack>
    </form>
  );
};

export default ChangePasswordForm;
