import { defineStyle, defineStyleConfig } from "@chakra-ui/system";

const sizes = {
  small: defineStyle({
    h: "1.625rem",
    w: "1.625rem",
    minW: "1.625rem",
    minH: "1.625rem",
    p: "1px",
    borderWidth: "0.081rem",
  }),
  large: defineStyle({
    h: "2.5rem",
    w: "2.5rem",
    minW: "2.5rem",
    minH: "2.5rem",
    p: "2px",
    borderWidth: "0.125rem",
  }),
};

const baseStyle = defineStyle({
  display: "flex",
  borderRadius: "full",
  alignItems: "center",
  justifyContent: "center",
  transitionProperty: "background-color, color",
  transitionTimingFunction: "ease-in-out",
  transitionDuration: "normal",
});

export const ColorSelector = defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: "small",
  },
});
