import React, { useMemo } from "react";

import { Button as Btn, ButtonProps as Props } from "@chakra-ui/react";

import { Spinner } from "../Spinner/Spinner";

export interface ButtonProps extends Props {
  /**
   * The content of the button
   */
  children: Props["children"];
  /**
   * If `true`, the button will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, the button will be styled in its loading state.
   */
  isLoading?: boolean;
  /**
   * If `true`, the button will be styled in its active state.
   */
  isActive?: boolean;
  size?: "s" | "m" | "l" | "xl" | "xxl";
  variant?: "primary" | "secondary" | "tertiary" | "quaternary" | "black" | "white" | "link";
}

/**
 * The basic component for interaction. By default it uses the button component, accepts all HTML <button> properties in React notations.
 */
export const Button = ({ children, size = "m", variant = "primary", onClick, ...buttonProps }: ButtonProps) => {
  const defaultLoadingText = useMemo(() => {
    if (typeof children === "string") {
      return children;
    }
  }, [children]);

  return (
    <Btn
      onClick={onClick}
      size={size}
      variant={variant}
      loadingText={defaultLoadingText}
      spinner={<Spinner w={6} h={6} />}
      spinnerPlacement="end"
      {...buttonProps}
    >
      {children}
    </Btn>
  );
};
