import { useEffect, useState } from "react";

import { Paths } from "paths";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetCurrentConsentsQuery } from "store/api";
import { err } from "store/errors";
import { selectCurrentUser } from "store/state";

import { useAppSelector } from "./useAppSelector";

export const useCheckConsents = () => {
  const user = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [getCurrentConsents] = useLazyGetCurrentConsentsQuery();
  const [consentsNamesToAccept, setConsentsNamesToAccept] = useState<string[]>([]);

  useEffect(() => {
    const getConsentsNames = async () => {
      try {
        if (user && !user.consentsAccepted) {
          const currentConsents = await getCurrentConsents().unwrap();
          let consentsNames: string[] = [];
          currentConsents?.forEach(consent => {
            if (!consent.latest) {
              consentsNames.push(consent.name);
            }
          });
          setConsentsNamesToAccept(consentsNames);
        }
      } catch (error) {
        err.handler(error);
      } finally {
        setIsLoading(false);
      }
    };

    getConsentsNames();
  }, [getCurrentConsents, user]);

  useEffect(() => {
    if (consentsNamesToAccept.length > 0 && location.pathname !== Paths.SendDeleteAccountInstruction) {
      navigate(Paths.TermsUpdate, {
        state: { consentsNamesToAccept: consentsNamesToAccept, redirectTo: location.pathname },
      });
    }
  }, [consentsNamesToAccept, location.pathname, navigate]);

  return isLoading;
};
