import React from "react";

import { createIcon } from "@chakra-ui/react";
import { Mandate } from "store/api";

import {
  AmexCardMediumLogo,
  CbCardMediumLogo,
  MasterCardMediumLogo,
  SiCardMediumLogo,
  VisaCardMediumLogo,
} from "../../atoms/Logos";

export type CardIllustrationProps = {
  numberLabel: string;
  number: string;
  holderLabel: string;
  holder: string;
  expDateLabel: string;
  expDate: string;
  logo?: Mandate["label"];
};

export const CreditCard = (props: CardIllustrationProps) => {
  const cardLogoRender = () => {
    switch (props.logo) {
      case "Visa":
        return <VisaCardMediumLogo />;
      case "Mastercard":
        return <MasterCardMediumLogo />;
      case "American Express":
        return <AmexCardMediumLogo />;
      case "Carte Bleue":
        return <CbCardMediumLogo />;
      case "Carta Si":
        return <SiCardMediumLogo />;
      default:
        return null;
    }
  };

  const CardComponent = createIcon({
    displayName: "CardIllustration",
    viewBox: `0 0 280 176`,
    defaultProps: {
      width: 280,
      height: 176,
      fill: "transparent",
    },
    path: [
      <rect width="280" height="176" rx="16" fill="url(#paint0_linear_1898_87824)" />,
      <defs>
        <linearGradient
          id="paint0_linear_1898_87824"
          x1="2.17724e-06"
          y1="182.408"
          x2="288.14"
          y2="-1.37761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C6880" />
          <stop offset="0.960338" stopColor="#8D95A5" />
        </linearGradient>
        <style>
          {` .text-svg {
              fill: white;
              fontFamily: Roboto;
              textRendering: optimizeLegibility;
            }`}
        </style>
      </defs>,
      <svg x="209.39" y="20.61" width="3.125rem" height="2.188rem">
        {cardLogoRender()}
      </svg>,
      <text x="20.61" y="105.39" fontSize="0.625rem" className="text-svg">
        {props.numberLabel}
      </text>,
      <text x="20.61" y="121.39" fontSize="1rem" fontWeight={500} className="text-svg">
        {props.number?.toUpperCase()}
      </text>,
      <text x="20.61" y="146.26" fontSize="0.625rem" className="text-svg">
        {props.holderLabel}
      </text>,
      <text x="20.61" y="162.26" fontSize="1rem" fontWeight={500} className="text-svg">
        {props.holder?.toUpperCase()}
      </text>,
      <text x="259.39" y="146.26" textAnchor="end" fontSize="0.625rem" className="text-svg">
        {props.expDateLabel}
      </text>,
      <text x="259.39" y="162.26" textAnchor="end" fontSize="1rem" fontWeight={500} className="text-svg">
        {props.expDate?.toUpperCase()}
      </text>,
    ],
  });

  return <CardComponent />;
};
