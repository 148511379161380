import { useBreakpointValue } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "hooks";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack } from "ui";
import * as yup from "yup";

export type ChangeEmailFormState = {
  email: string;
};

type Props = {
  onSubmit: (formState: ChangeEmailFormState) => void;
  isSubmitting?: boolean;
};

const ChangeEmailForm: React.FC<Props> = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();

  const validationSchema = yup
    .object({
      email: yup
        .string()
        .email(t("prompt_error_invalid_e-mail_2"))
        .max(255, t("prompt_error_length_max", { value: 255 }))
        .required(t("prompt_error_mandatory_e-mail")),
    })
    .required();

  let navigate = useNavigate();
  const user = useUser();
  const variant = useBreakpointValue({ sm: "vertical", md: "horizontal" });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeEmailFormState>({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
  });

  const goBack = () => navigate(-1);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl variant={variant}>
        <FormLabel htmlFor="current-email">{t("label_noun_previous_e-mail")}</FormLabel>
        <Input id="current-email" type="email" value={user.email} isDisabled isReadOnly />
        <FormErrorMessage></FormErrorMessage>
      </FormControl>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("email")}>
        <FormLabel htmlFor="email">{t("label_noun_new_e-mail")}</FormLabel>
        <Input
          id="email"
          type="email"
          placeholder={t("label_action_request_suggestion_new_e-mail")}
          {...register("email")}
        />
        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
      </FormControl>
      <Stack
        direction={["column", "column", "row"]}
        spacing={["l", "l", "m"]}
        justifyContent="end"
        mt={["l", "l", "xl"]}
      >
        <Button variant="secondary" w={["full", "initial"]} onClick={goBack}>
          {t("label_action_request_cancel")}
        </Button>
        <Button type="submit" w={["full", "initial"]} isLoading={isSubmitting}>
          {t("label_action_request_send_link")}
        </Button>
      </Stack>
    </form>
  );
};

export default ChangeEmailForm;
