import React from "react";

import { BodyText, Flex, Stack } from "../../atoms";

type Props = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
};
export const TitleWithIcons = ({ title, subtitle, children }: Props) => {
  return (
    <>
      <Flex flexDir={["column", "row"]} justifyContent="space-between" mb="m">
        <BodyText variant="header24" order={[2, 1]}>
          {title}
        </BodyText>
        <Stack direction="row" spacing="xs" order={[1, 2]} mb={["m", 0]}>
          {children}
        </Stack>
      </Flex>
      <BodyText variant="body16">{subtitle}</BodyText>
    </>
  );
};
