import { Currency, Method, ResBuilder, api, transformResponse } from "./api";

const subscriptionsApi = api.injectEndpoints({
  endpoints: builder => ({
    getSubscriptions: builder.query<Subscription[], void>({
      query: () => ({
        url: "users/subscriptions",
        method: Method.Get,
      }),
      transformResponse: (res: ResBuilder<SubscriptionRaw[]>) => transformResponse(res),
      providesTags: ["Subscriptions"],
    }),
    activateSubscription: builder.mutation<Subscription, ActivateSubscriptionReq>({
      query: ({ id }) => ({
        url: `/users/subscriptions/${id}/activate`,
        method: Method.Post,
      }),
      transformResponse: (res: ResBuilder<SubscriptionRaw>) => transformResponse(res),
      invalidatesTags: ["Subscriptions"],
    }),
    cancelSubscription: builder.mutation<Subscription, ActivateSubscriptionReq>({
      query: ({ id }) => ({
        url: `/users/subscriptions/${id}/cancel`,
        method: Method.Post,
      }),
      transformResponse: (res: ResBuilder<SubscriptionRaw>) => transformResponse(res),
      invalidatesTags: ["Subscriptions"],
    }),
    getSubscriptionOffer: builder.query<SubscriptionOffer, { id: number }>({
      query: ({ id }) => ({
        url: `/subscription_offers/${id}`,
        method: Method.Get,
      }),
      transformResponse: (res: ResBuilder<SubscriptionOfferRaw>) => transformResponse(res),
      providesTags: ["SubscriptionOffers"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSubscriptionsQuery,
  useActivateSubscriptionMutation,
  useCancelSubscriptionMutation,
  useGetSubscriptionOfferQuery,
} = subscriptionsApi;

export enum SubscriptionStatus {
  Active = "active",
  Inactive = "inactive",
  Expiring = "expiring",
  Initial = "initial",
}

export type Subscription = {
  id: number;
  type: "subscription";
  uid: string;
  status: SubscriptionStatus;
  expiredAt: string | null;
  freePeriodExpiredAt: string | null;
  priceSubunit: number;
  priceCurrency: Currency;
  deviceId?: number;
  subscriptionOfferId: number;
};

export type SubscriptionOffer = {
  id: number;
  type: "subscription_offer";
  startDate: string;
  endDate: string | null;
  freePeriodDurationInDays: number | null;
  durationInMonths: number;
  subscriptionPriceSubunit: number;
  subscriptionPriceCurrency: Currency;
};

export type ActivateSubscriptionReq = {
  id: number;
};

export type SubscriptionRaw = {
  id: string;
  type: "subscription";
  attributes: {
    status: SubscriptionStatus;
    expired_at: string;
    free_period_expired_at: string;
    price_subunit: number;
    price_currency: Currency;
    uid: string;
  };
  relationships: {
    subscription_offer: {
      data: {
        id: string;
        type: "subscription_offer";
      };
    };
    device: {
      data: {
        id: string;
        type: "users_device";
      } | null;
    };
  };
};

export type SubscriptionOfferRaw = {
  id: string;
  type: "subscription_offer";
  attributes: {
    start_date: string;
    end_date: string;
    free_period_duration_in_days: number;
    duration_in_months: number;
    subscription_price_subunit: number;
    subscription_price_currency: Currency;
  };
};
