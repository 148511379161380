import React from "react";

import { createIcon } from "@chakra-ui/react";

export const EndIcon = createIcon({
  displayName: "EndIcon",
  viewBox: "0 0 20 20",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 5,
      h: 5,
    },
  },
  path: [
    <rect
      width="16.6667"
      height="1.66667"
      transform="matrix(0.698685 -0.715429 0.698685 0.715429 3.33337 15.8333)"
      fill="#2D3139"
    />,
    <rect x="9.16675" y="10" width="1.66667" height="1.66667" fill="#2D3139" />,
    <rect
      width="16.6667"
      height="1.66667"
      transform="matrix(0.698685 0.715429 -0.698685 0.715429 5 4.16666)"
      fill="#2D3139"
    />,
  ],
});
