import { StyleFunctionProps } from "@chakra-ui/system";

const getTermsStyles = (props: StyleFunctionProps) => {
  const { space, colors, fontSizes, fontWeights } = props.theme;

  return {
    "#terms": {
      textAlign: "justify",
      fontSize: fontSizes.md,
    },
    "#terms h1": {
      fontSize: 24,
      padding: `0 0 ${space[5]} 0`,
      fontWeight: fontWeights.medium,
      fontFamily: "Old Standard TT,sans-serif !important",
    },
    "#terms h2": {
      padding: `${space[4]} 0`,
      fontWeight: fontWeights.medium,
    },
    "#terms h3": {
      padding: `${space[4]} 0`,
      fontWeight: fontWeights.medium,
    },
    "#terms ol": {
      paddingLeft: space[8],
    },
    "#terms p b": {
      fontWeight: fontWeights.medium,
    },
    "#terms a": {
      color: `${colors.blue[300]} !important`,
    },
  };
};

export default getTermsStyles;
