import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Subscription, useGetSubscriptionsQuery } from "store/api";
import { Box, Button, Divider, SideMenu, SideMenuItem, SideMenuItemMobile } from "ui";
import { useBreakpoints } from "ui/hooks";

type Props = {
  onClose?: () => void;
};

const Menu: React.FC<Props> = ({ onClose }) => {
  const { isMobile, isTablet } = useBreakpoints();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: subscriptions } = useGetSubscriptionsQuery();
  const pendingSubscriptionExists = subscriptions?.some((subscription: Subscription) => !subscription.deviceId);
  const MenuItemComponent = isMobile || isTablet ? SideMenuItemMobile : SideMenuItem;
  const goTo = (pathName: string) => {
    navigate(pathName);
    onClose?.();
  };

  return (
    <>
      <SideMenu spacing={[0, 0, "xxs"]}>
        <MenuItemComponent
          onClick={() => goTo(Paths.MyDevices)}
          isActive={location.pathname.startsWith(Paths.MyDevices)}
        >
          {t("label_noun_my_devices")}
        </MenuItemComponent>
        <Divider variant="light" display={["block", "block", "none"]} />
        <MenuItemComponent
          onClick={() => goTo(Paths.MySubscriptions)}
          isActive={location.pathname.startsWith(Paths.MySubscriptions)}
        >
          {t("label_noun_my_subscriptions")}
        </MenuItemComponent>
        <Divider variant="light" display={["block", "block", "none"]} />
        <MenuItemComponent onClick={() => goTo(Paths.Profile)} isActive={location.pathname.startsWith(Paths.Profile)}>
          {t("label_noun_personal_information")}
        </MenuItemComponent>
        <Divider variant="light" display={["block", "block", "none"]} />
        <Box w="full">
          <MenuItemComponent onClick={() => goTo(Paths.SignOut)}>
            {t("label_action_request_sign_out")}
          </MenuItemComponent>
        </Box>
      </SideMenu>
      <Divider variant="light" display={["block", "block", "none"]} />
      <Box w="full" py="m" pl="l" display={["none", "none", "block"]}>
        <Divider variant="dark" />
      </Box>
      <Button
        width="auto"
        variant="link"
        onClick={() => goTo(Paths.PairDevice)}
        isDisabled={!pendingSubscriptionExists}
        py={["m", "m", "xs"]}
        pl={["m", "m", "l"]}
      >
        {t("label_action_request_register_new_device")}
      </Button>
    </>
  );
};

export default Menu;
