import { SystemStyleObject, defineStyle, defineStyleConfig } from "@chakra-ui/system";

const baseStyle: SystemStyleObject = {
  borderStyle: "solid",
};

const sizes = {
  small: defineStyle({ borderWidth: 1 }),
  medium: defineStyle({ borderWidth: 2 }),
};

const variantBlack = defineStyle({
  borderColor: "black",
});
const variantDark = defineStyle({
  borderColor: "grayDark",
});

const variantLight = defineStyle({
  borderColor: "grayLight",
});

const variantWhite = defineStyle({
  borderColor: "white",
});

const variantBrand = defineStyle({
  borderColor: "violet.300",
});

export const Divider = defineStyleConfig({
  baseStyle,
  variants: {
    black: variantBlack,
    dark: variantDark,
    light: variantLight,
    white: variantWhite,
    brand: variantBrand,
  },
  sizes,
  defaultProps: {
    size: "small",
    colorScheme: "gray",
    variant: "black",
  },
});
