import React from "react";

import { useTranslation } from "react-i18next";
import {
  BodyText,
  Button,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  Stack,
  TitleH1,
} from "ui";

type PromptCancelSubscriptionProps = {
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
};

export const PromptCancelSubscription: React.FC<PromptCancelSubscriptionProps> = ({
  onClose,
  onSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  return (
    <InfoPanel isBorder borderColor="grayLight" border="2px solid">
      <InfoPanelIllustration illustrationName="ConfirmationEmailExpired2Illustration" />
      <InfoPanelContent>
        <Stack spacing="m">
          <TitleH1 color="redAlert">{t("label_other_sure_to_cancel_subscription")}</TitleH1>
          <BodyText>{t("prompt_you_wont_use_device_any_longer")}</BodyText>
        </Stack>
      </InfoPanelContent>

      <InfoPanelAction>
        <Button onClick={onClose} variant="secondary" mt={["m", 0]} order={[2, 1]}>
          {t("label_action_request_keep_my_subscription_active")}
        </Button>
        <Button onClick={onSubmit} isLoading={isSubmitting} order={[1, 2]}>
          {t("label_action_request_cancel_subscription")}
        </Button>
      </InfoPanelAction>
    </InfoPanel>
  );
};
