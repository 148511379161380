const colors = {
  // Basic
  black: "#2D3139",
  white: "#FFFFFF",
  grayLight: "#EFECE8",
  grayDark: "#999999",
  grayMedium: "#C2C2C2",
  // Complementary
  green: {
    100: "#E2F3DF",
    300: "#A7DAB8",
    500: "#5E976A",
  },
  blue: {
    100: "#D9ECEF",
    300: "#56B5DD",
    500: "#4A7E8E",
  },
  violet: {
    100: "#E0E2F2",
    300: "#8F8BEF",
    500: "#6F61A5",
  },
  orange: {
    100: "#FAEDE5",
    300: "#FD905F",
    500: "#9E4B38",
  },
  yellow: {
    100: "#FFF7DC",
    300: "#FFD260",
    500: "#8E6F57",
  },
  // Action
  primary: {
    100: "#B3BFFF",
    300: "#4966FF",
    500: "#001480",
  },
  secondary: {
    100: "#FFB3B3",
    300: "#FF5151",
    500: "#800000",
  },
  // Alerts
  keyboardBlueAlert: "#009CFF",
  greenAlert: "#008A1E",
  redAlert: "#E40000",
} as const;

export default colors;
