import React from "react";

import { createIcon } from "@chakra-ui/react";

export const PolishFlag = createIcon({
  displayName: "PolishFlag",
  viewBox: "0 0 40 40",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: "20px",
      h: "20px",
    },
  },
  path: [
    <g clipPath="url(#a)">
      <path fill="#F0F0F0" d="M0 20C0 8.953 8.953 0 20 0s20 8.953 20 20H0Z" />
      <path fill="#BE0026" d="M40 20c0 11-9 20-20 20S0 31 0 20h40Z" />
    </g>,
    <defs>
      <clipPath id="a">
        <rect width={40} height={40} fill="#fff" rx={20} />
      </clipPath>
    </defs>,
  ],
});
