import { StyleProps, chakra, useBreakpointValue } from "@chakra-ui/react";
import { useMollie } from "hooks/useMollie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAddPaymentMutation, useGetValidPaymentQuery } from "store/api";
import { err } from "store/errors";
import { BodyText, Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Stack } from "ui";
import { MollieLogo } from "ui/atoms/Logos/MollieLogo";
import { utils } from "utils";
import { getFullUrl } from "utils/helpers";

const inputStyleProps = (isFocus: boolean, isError: boolean): StyleProps => {
  const getBorderColor = () => {
    if (isFocus) {
      return "transparent";
    }
    if (isError) {
      return "redAlert";
    }
    return "grayDark";
  };

  return {
    border: "1px solid",
    borderColor: getBorderColor(),
    boxShadow: isFocus ? "outline" : "initial",
    transitionProperty: "common",
    transitionDuration: "normal",
    minH: 9,
    borderRadius: "md",
    px: "s",
    py: "xs",
    zIndex: 1,
  };
};

type PlaceholderProps = {
  isVisible: boolean;
  children: string;
};

const Placeholder: React.FC<PlaceholderProps> = ({ children, isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <BodyText pos="absolute" top="6px" left="s" color="grayDark" pointerEvents="none">
      {children}
    </BodyText>
  );
};

type ChangeCardFormProps = {
  redirectPath: string;
  goBackPath: string;
};

const ChangeCardForm: React.FC<ChangeCardFormProps> = ({ redirectPath, goBackPath }) => {
  const { t } = useTranslation();
  const [addPayment] = useAddPaymentMutation();
  const { data: validPaymentData } = useGetValidPaymentQuery();
  const navigate = useNavigate();
  const goBack = () => navigate(goBackPath);
  const variant = useBreakpointValue({ sm: "vertical", md: "horizontal" });

  const { state, isSubmitting, register, registerForm } = useMollie({
    onSubmit: async (token: string) => {
      const payment = await addPayment({
        cardToken: token,
        redirectUrl: getFullUrl(redirectPath),
      }).unwrap();
      utils.gtm.addCreditCardDataLayer();
      localStorage.setItem("mandate", JSON.stringify(payment));
      payment.checkoutUrl && window.location.replace(payment.checkoutUrl);
    },
    onError: error => {
      err.handler(error);
    },
  });

  return (
    <chakra.form {...registerForm()} w="full">
      <FormControl isInvalid={!!state.cardNumber.error} isRequired variant={variant}>
        <FormLabel htmlFor="cardNumber">{t("label_noun_card_number")}</FormLabel>
        <Box pos="relative">
          <Placeholder isVisible={!state.cardNumber.dirty && !state.cardNumber.focus}>
            {t("label_action_request_enter_card_number")}
          </Placeholder>
          <Box {...register("cardNumber")} {...inputStyleProps(state.cardNumber.focus, !!state.cardNumber.error)} />
        </Box>
        <FormErrorMessage>{state.cardNumber.error}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!state.cardHolder.error} isRequired variant={variant}>
        <FormLabel htmlFor="cardHolder">{t("label_noun_name_on_card")}</FormLabel>
        <Box pos="relative">
          <Placeholder isVisible={!state.cardHolder.dirty && !state.cardHolder.focus}>
            {t("label_action_request_enter_name")}
          </Placeholder>
          <Box {...register("cardHolder")} {...inputStyleProps(state.cardHolder.focus, !!state.cardHolder.error)} />
        </Box>
        <FormErrorMessage>{state.cardHolder.error}</FormErrorMessage>
      </FormControl>
      <Flex flexDirection={["row", "column"]} justifyContent={["space-between", "normal"]}>
        <FormControl isInvalid={!!state.expiryDate.error} isRequired variant={variant} flexBasis={["auto", "40%"]}>
          <FormLabel htmlFor="expiryDate">{t("label_noun_expiry_date")}</FormLabel>
          <Box pos="relative">
            <Box
              {...register("expiryDate")}
              {...inputStyleProps(state.expiryDate.focus, !!state.expiryDate.error)}
              w="88px"
            />
          </Box>
          <FormErrorMessage>{state.expiryDate.error}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!state.verificationCode.error} isRequired variant={variant}>
          <FormLabel htmlFor="verificationCode">{t("label_noun_security_code")}</FormLabel>
          <Box pos="relative">
            <Placeholder isVisible={!state.verificationCode.dirty && !state.verificationCode.focus}>
              CVV/CVC
            </Placeholder>
            <Box
              {...register("verificationCode")}
              {...inputStyleProps(state.verificationCode.focus, !!state.verificationCode.error)}
              w="93px"
            />
          </Box>
          <FormErrorMessage>{state.verificationCode.error}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Stack
        direction={["column", "column", "row"]}
        spacing={["l", "l", "m"]}
        justifyContent="end"
        mt={["56px", "60px"]}
      >
        {!!validPaymentData && (
          <Button variant="secondary" onClick={goBack}>
            {t("label_action_request_cancel")}
          </Button>
        )}
        <Button type="submit" isLoading={isSubmitting}>
          {t("label_action_request_verify_card")}
        </Button>
      </Stack>
      <Flex mt={["m", 5]} justifyContent={["center", "center", "flex-start"]} alignItems="center" alignContent="center">
        <BodyText variant="body12" mr="xxs">
          {t("prompt_payments_secured_and_powered_by")}
        </BodyText>
        <MollieLogo />
      </Flex>
    </chakra.form>
  );
};

export default ChangeCardForm;
