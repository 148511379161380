import { Paths } from "paths";
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import ConfirmEmail from "views/Api/ConfirmEmail";
import CreateOrder from "views/Api/CreateOrder";
import DeleteAccount from "views/Api/DeleteAccount";
import NewPassword from "views/Api/NewPassword";
import ResetPassword from "views/Auth/ResetPassword";
import SignIn from "views/Auth/SignIn/SignIn";
import SignUp from "views/Auth/SignUp/SignUp";
import MyDevices from "views/MyDevices/MyDevices";
import EnteringCode from "views/MyDevices/PairDevice/EnteringCode";
import Finalization from "views/MyDevices/PairDevice/Finalization";
import PairDevice from "views/MyDevices/PairDevice/PairDevice";
import PreparingDevice from "views/MyDevices/PairDevice/PreparingDevice";
import MySubscriptions from "views/MySubscriptions/MySubscriptions";
import PageNotFound from "views/PageNotFound";
import ChangeBillingAddress from "views/Profile/ChangeBillingAddress";
import ChangeCard from "views/Profile/ChangeCard";
import ChangeEmail from "views/Profile/ChangeEmail/ChangeEmail";
import ChangePassword from "views/Profile/ChangePassword/ChangePassword";
import ChangeShippingAddress from "views/Profile/ChangeShippingAddress";
import Profile from "views/Profile/Profile";
import SendDeleteAccountInstruction from "views/Profile/SendDeleteAccountInstruction";
import SignOut from "views/SignOut";
import Terms from "views/Terms";
import TermsUpdate from "views/TermsUpdate";
import AppLayout from "views/commons/Layouts/AppLayout";
import BackgroundLayout from "views/commons/Layouts/BackgroundLayout";

export const getRoutes = (isSignIn: boolean): RouteObject[] => [
  {
    element: isSignIn ? <AppLayout /> : <Navigate to={Paths.SignIn} replace />,
    children: [
      { path: Paths.Home, element: <Navigate to={Paths.MyDevices} /> },
      { path: Paths.Profile, element: <Profile /> },
      { path: Paths.ChangeEmail, element: <ChangeEmail /> },
      { path: Paths.ChangePassword, element: <ChangePassword /> },
      { path: Paths.ChangeCard, element: <ChangeCard /> },
      { path: Paths.EditBillingAddress, element: <ChangeBillingAddress /> },
      { path: Paths.AddBillingAddress, element: <ChangeBillingAddress /> },
      { path: Paths.EditShippingAddress, element: <ChangeShippingAddress /> },
      { path: Paths.MyDevices, element: <MyDevices /> },
      { path: Paths.MySubscriptions, element: <MySubscriptions /> },
      {
        path: Paths.PairDevice,
        element: <PairDevice />,
        children: [
          { path: Paths.PairDeviceStep1, element: <PreparingDevice /> },
          { path: Paths.PairDeviceStep2, element: <EnteringCode /> },
          { path: Paths.PairDeviceStep3, element: <Finalization /> },
        ],
      },
    ],
  },
  // {
  //   element: <CheckoutLayout />,
  //   children: [
  //     { path: Paths.Cart, element: <Cart /> },
  //     {
  //       path: Paths.Checkout,
  //       element: <Checkout />,
  //       children: [
  //         {
  //           path: Paths.CheckoutAuth,
  //           element: <Outlet />,
  //           children: [
  //             ...(isSignIn
  //               ? [
  //                   { path: Paths.CheckoutAuth, element: <Navigate to={Paths.CheckoutUserInfo} replace /> },
  //                   { path: Paths.CheckoutUserInfo, element: <CheckoutUserInfo /> },
  //                   { path: Paths.CheckoutSignIn, element: <CheckoutSignIn /> },
  //                   { path: Paths.CheckoutSignOutConfirmation, element: <CheckoutSignOutConfirmation /> },
  //                   { path: `${Paths.CheckoutAuth}/*`, element: <Navigate to={Paths.CheckoutUserInfo} replace /> },
  //                 ]
  //               : [
  //                   { path: Paths.CheckoutAuth, element: <Navigate to={Paths.CheckoutSignUp} replace /> },
  //                   { path: Paths.CheckoutSignUp, element: <CheckoutSignUp /> },
  //                   { path: Paths.CheckoutSignIn, element: <CheckoutSignIn /> },
  //                   { path: Paths.CheckoutSignUpResult, element: <CheckoutSignUpResult /> },
  //                   { path: `${Paths.CheckoutAuth}/*`, element: <Navigate to={Paths.CheckoutSignUp} replace /> },
  //                   { path: Paths.CheckoutEmailUsedLink, element: <CheckoutConfirmEmailAlreadyConfirmed /> },
  //                   { path: Paths.CheckoutEmailExpiredLink, element: <CheckoutConfirmEmailExpired /> },
  //                   { path: Paths.CheckoutEmailConfirmed, element: <CheckoutConfirmEmailSuccess /> },
  //                 ]),
  //           ],
  //         },
  //         { path: Paths.CheckoutAddress, element: <CheckoutAddress /> },
  //         { path: Paths.CheckoutEditBillingAddress, element: <CheckoutChangeBillingAddress /> },
  //         { path: Paths.CheckoutAddBillingAddress, element: <CheckoutChangeBillingAddress /> },
  //         { path: Paths.CheckoutEditShippingAddress, element: <CheckoutChangeShippingAddress /> },
  //         { path: Paths.CheckoutAddShippingAddress, element: <CheckoutChangeShippingAddress /> },
  //         { path: Paths.CheckoutPaymentMethod, element: <PaymentMethod /> },
  //         { path: Paths.CheckoutPaymentMethodAddCard, element: <PaymentMethodAddCard /> },
  //         { path: Paths.CheckoutPaymentMethodChangeCard, element: <PaymentMethodAddCard /> },
  //         { path: Paths.CheckoutPaymentMethodAddingCardFailed, element: <PaymentMethodAddingCardFailed /> },
  //         { path: Paths.CheckoutPaymentMethodVerifyCard, element: <PaymentMethodVerifyCard /> },
  //         { path: Paths.CheckoutShippingMethods, element: <CheckoutShippingMethods /> },
  //         { path: Paths.CheckoutSummary, element: <CheckoutSummary /> },
  //       ],
  //     },
  //     { path: Paths.CheckoutPaymentFailed, element: <CheckoutPaymentFailed /> },
  //     { path: Paths.CheckoutPaymentProcessing, element: <CheckoutPaymentProcessing /> },
  //     { path: Paths.CheckoutOrderCompleted, element: <CheckoutOrderCompleted /> },
  //   ],
  // },
  {
    element: <BackgroundLayout />,
    children: [
      {
        path: Paths.Auth,
        element: !isSignIn ? <Outlet /> : <Navigate to={Paths.Home} replace />,
        children: [
          { path: Paths.Auth, element: <Navigate to={Paths.SignIn} replace /> },
          { path: Paths.SignIn, element: <SignIn /> },
          { path: Paths.SignUp, element: <SignUp /> },
          { path: Paths.ResetPassword, element: <ResetPassword /> },
          { path: `${Paths.Auth}/*`, element: <Navigate to={Paths.SignIn} replace /> },
        ],
      },
      { path: Paths.Terms, element: <Terms /> },
      { path: Paths.TermsUpdate, element: <TermsUpdate /> },
      { path: Paths.ConfirmEmail, element: <ConfirmEmail /> },
      { path: Paths.NewPassword, element: <NewPassword /> },
      { path: Paths.SendDeleteAccountInstruction, element: <SendDeleteAccountInstruction /> },
      { path: Paths.DeleteAccount, element: <DeleteAccount /> },
      { path: Paths.SignOut, element: <SignOut /> },
      { path: "*", element: isSignIn ? <PageNotFound /> : <Navigate to={Paths.SignIn} replace /> },
    ],
  },
  { path: Paths.CreateOrder, element: <CreateOrder /> },
];
