import React from "react";

import { createIcon } from "@chakra-ui/react";

export const UPSLogo = createIcon({
  displayName: "UPSLogo",
  viewBox: "0 0 60 60",
  defaultProps: {
    width: "3.75rem",
    height: "3.75rem",
    fill: "transparent",
  },
  path: [
    <g clipPath="url(#clip0_1848_80331)">
      <path
        d="M30 57.7594C30.4974 57.511 43.4314 51.9218 47.5355 48.5684C51.7639 45.0907 54.0025 40.1225 54.0025 34.1608V6.21512L53.6294 6.09091C43.3071 0.501775 30.4974 0.874384 29.8756 0.874384C29.3781 0.874384 16.5685 0.501775 6.24617 6.09091L5.99744 6.21512V34.285C5.99744 40.2468 8.23601 45.2149 12.4644 48.6926C16.5685 52.046 29.5025 57.6352 30 57.7594Z"
        fill="#341B14"
      />
      <path
        d="M30 58.3804C30 58.3804 16.3198 52.4186 12.0914 49.0652C7.61421 45.3391 5.5 40.2468 5.5 34.4092V5.34569C16.3198 -0.491849 30 0.00496256 30 0.00496256C30 0.00496256 43.6802 -0.491849 54.5 5.34569V34.285C54.5 40.1225 52.3858 45.2149 47.9086 48.941C43.6802 52.4186 30 58.3804 30 58.3804ZM7.48985 34.4092C7.48985 39.8741 9.4797 44.3454 13.335 47.4505C16.8173 50.3072 27.1396 54.9027 30 56.1447C32.8604 54.9027 43.3071 50.183 46.665 47.4505C50.5203 44.3454 52.5102 39.7499 52.5102 34.4092V5.84251C38.0838 4.47627 20.9213 5.22149 7.48985 17.6418V34.4092Z"
        fill="#FFB406"
      />
      <path
        d="M43.8045 30.3105C45.67 31.4283 46.4162 32.1736 46.5406 33.5398C46.5406 35.0302 45.5456 35.8996 43.9289 35.8996C42.5609 35.8996 40.9441 35.1544 39.8248 34.1608V38.1353C41.1928 38.8805 42.8096 39.5015 44.5507 39.5015C48.7791 39.5015 50.769 36.5207 50.769 33.7882C50.8933 31.3041 50.1472 29.3169 46.5406 27.2054C44.9238 26.2118 43.6801 25.5908 43.6801 24.1004C43.6801 22.6099 45.0482 21.9889 46.1675 21.9889C47.6598 21.9889 49.1522 22.8583 50.0228 23.7277V20.0017C49.2766 19.3806 47.6598 18.5112 45.2969 18.6354C42.4365 18.7596 39.4517 20.7469 39.4517 24.2246C39.5761 26.5844 40.3223 28.3233 43.8045 30.3105ZM29.1294 39.2531C29.5025 39.3773 30.1243 39.5015 31.1192 39.5015C35.9695 39.5015 38.7055 35.1544 38.7055 28.9443C38.7055 22.6099 35.8451 18.7596 30.7461 18.7596C28.3832 18.7596 26.5177 19.2564 24.901 20.2501V48.32H29.1294V39.2531ZM29.1294 22.3615C29.5025 22.2373 30.1243 21.9889 30.6218 21.9889C33.1091 21.9889 34.104 23.9762 34.104 28.8201C34.104 33.5398 32.8603 35.7754 30.373 35.7754C29.7512 35.7754 29.2537 35.6512 29.005 35.527V22.3615H29.1294ZM16.071 39.5015C18.6827 39.5015 20.9213 38.8805 22.538 37.7627V19.008H18.1852V35.2786C17.6878 35.6512 17.0659 35.7754 16.1954 35.7754C14.2055 35.7754 13.9568 33.9124 13.9568 32.7946V19.008H9.604V32.5462C9.604 37.1417 11.8426 39.5015 16.071 39.5015ZM45.0482 56.2689V59.1256H45.5456V58.0078H45.67L46.4162 59.1256H47.038C47.038 59.1256 46.2918 58.0078 46.1675 57.8836C46.5406 57.7594 46.7893 57.511 46.7893 57.1384C46.7893 56.7658 46.5406 56.2689 45.7944 56.2689H45.0482ZM45.7944 56.6416C46.1675 56.6416 46.2918 56.89 46.2918 57.0142C46.2918 57.2626 46.1675 57.511 45.67 57.511H45.5456V56.6416H45.7944Z"
        fill="#FFB406"
      />
      <path
        d="M47.7842 57.6352C47.7842 58.6288 46.9137 59.4982 45.9187 59.4982C44.9238 59.4982 44.0532 58.6288 44.0532 57.6352C44.0532 56.6416 44.9238 55.7721 45.9187 55.7721C47.038 55.7721 47.7842 56.6416 47.7842 57.6352ZM45.9187 55.2753C44.6751 55.2753 43.5558 56.2689 43.5558 57.6352C43.5558 58.8772 44.5507 59.995 45.9187 59.995C47.1624 59.995 48.2817 59.0014 48.2817 57.6352C48.2817 56.3932 47.1624 55.2753 45.9187 55.2753Z"
        fill="#FFB406"
      />
    </g>,
    <defs>
      <clipPath id="clip0_1848_80331">
        <rect width="49" height="60" fill="white" transform="translate(5.5)" />
      </clipPath>
    </defs>,
  ],
});
