import React from "react";

import { createIcon } from "@chakra-ui/react";

export const VisaCardMediumLogo = createIcon({
  displayName: "VisaCardMediumLogo",
  viewBox: "0 0 60 40",
  defaultProps: {
    width: "3.75rem",
    height: "2.5rem",
    fill: "transparent",
  },
  path: [
    <path
      d="M35.7666 0.292836L32.5579 15.7583H28.6819L31.887 0.292836H35.7666ZM52.0839 10.2786L54.1258 4.47308L55.2999 10.2786H52.0839ZM56.412 15.7693H60L56.8678 0.303817H53.557C53.1996 0.302811 52.8503 0.410762 52.5553 0.613386C52.2604 0.816011 52.0337 1.10376 51.9052 1.43856L46.0711 15.7693H50.144L50.9535 13.4595H55.9271L56.412 15.7693ZM46.2862 10.7178C46.3045 6.64007 40.8168 6.41312 40.8532 4.59022C40.8532 4.03383 41.3783 3.44449 42.4977 3.29441C43.8124 3.16293 45.1377 3.39987 46.3263 3.97892L47.0046 0.684506C45.841 0.23473 44.6051 0.00273333 43.3582 0C39.5223 0 36.8277 2.1011 36.7949 5.12464C36.7694 7.35386 38.7201 8.59476 40.186 9.33417C41.6518 10.0736 42.206 10.5787 42.1987 11.2559C42.1987 12.2918 40.9918 12.753 39.8797 12.7713C38.4997 12.7937 37.1377 12.4556 35.9271 11.7903L35.2233 15.1799C36.5942 15.7286 38.0575 16.007 39.5333 15.9999C43.6098 15.9999 46.2753 13.9244 46.2862 10.7068V10.7178ZM30.2425 0.303817L23.9417 15.7583H19.8396L16.7475 3.41521C16.5615 2.65749 16.3829 2.3793 15.825 2.05718C14.6111 1.457 13.3257 1.01538 12 0.743073L12.0912 0.292836H18.691C19.1283 0.29654 19.5497 0.457912 19.8783 0.747523C20.207 1.03713 20.421 1.43569 20.4813 1.8705L22.1149 10.8167L26.155 0.292836L30.2425 0.303817Z"
      fill="white"
    />,
  ],
});
