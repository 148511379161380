import { SystemStyleFunction, defineStyle, defineStyleConfig } from "@chakra-ui/system";

import spacing from "../../foundations/Spacing/spacing";

const sizes = {
  s: defineStyle({
    h: "1.75rem",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    px: spacing.m,
  }),
  m: defineStyle({
    h: "2.25rem",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    px: spacing.m,
  }),
  l: defineStyle({
    h: "2.5rem",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    px: spacing.m,
  }),
  xl: defineStyle({
    h: "3rem",
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    px: spacing.m,
  }),
  xxl: defineStyle({
    h: "3.75rem",
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    px: spacing.xl,
  }),
};

const baseStyle = defineStyle({
  borderRadius: "2rem",
  transitionProperty: "common",
  transitionDuration: "normal",
  textTransform: "uppercase",
  _focus: {
    boxShadow: "outline",
  },
  _disabled: {
    cursor: "not-allowed",
    boxShadow: "none",
  },
  _hover: {
    _disabled: {
      bg: "initial",
    },
  },
});

const variantPrimary = defineStyle(() => {
  const _disabled = { bg: "grayDark", borderColor: "grayDark", color: "white" };

  return {
    border: "1px",
    bg: "primary.300",
    color: "white",
    borderColor: "primary.300",
    _disabled,
    _hover: {
      bg: "primary.500",
      borderColor: "primary.500",
      _disabled,
    },
    _active: {
      bg: "primary.300",
      borderColor: "primary.500",
      boxShadow: "none",
    },
  };
});

const variantSecondary = defineStyle(() => {
  const _disabled = { borderColor: "grayDark", color: "grayDark" };
  return {
    border: "1px",
    borderColor: "grayDark",
    color: "primary.300",
    backgroundColor: "white",
    _disabled,
    _hover: {
      borderColor: "primary.300",
      _disabled,
    },
    _active: {
      borderColor: "primary.500",
      boxShadow: "none",
    },
  };
});

const variantTertiary: SystemStyleFunction = () => {
  const _disabled = { bg: "grayDark", borderColor: "grayDark", color: "white" };

  return {
    border: "1px",
    bg: "secondary.300",
    color: "white",
    borderColor: "secondary.300",
    _disabled,
    _hover: {
      bg: "secondary.500",
      borderColor: "secondary.500",
      _disabled,
    },
    _active: {
      bg: "secondary.300",
      borderColor: "secondary.500",
      boxShadow: "none",
    },
  };
};

const variantQuaternary = defineStyle(() => {
  const _disabled = { borderColor: "grayDark", color: "grayDark" };
  return {
    border: "1px",
    borderColor: "grayDark",
    color: "secondary.300",
    _disabled,
    _hover: {
      borderColor: "secondary.300",
      _disabled,
    },
    _active: {
      borderColor: "secondary.500",
      boxShadow: "none",
    },
  };
});

const variantBlack = defineStyle(() => {
  const _disabled = { borderColor: "grayDark", color: "grayDark" };
  return {
    border: "1px",
    borderColor: "black",
    color: "black",
    _disabled,
    _hover: {
      backgroundColor: "black",
      color: "white",
      borderColor: "black",
      _disabled,
    },
    _active: {
      borderColor: "greyDark",
      boxShadow: "none",
    },
  };
});

const variantWhite = defineStyle(() => {
  const _disabled = { borderColor: "grayDark", color: "grayDark" };
  return {
    border: "1px",
    borderColor: "white",
    color: "white",
    _disabled,
    _hover: {
      backgroundColor: "white",
      color: "black",
      borderColor: "white",
      _disabled,
    },
    _active: {
      borderColor: "greyDark",
      boxShadow: "none",
    },
  };
});

const variantLink = defineStyle({
  height: "auto",
  verticalAlign: "baseline",
  textTransform: "math-auto",
  color: "primary.300",
  py: spacing.xs,
  _hover: {
    color: "primary.500",
    _disabled: {
      color: "grayDark",
      textDecoration: "none",
    },
  },
  _active: {
    color: "primary.500",
  },
  _disabled: {
    color: "grayDark",
  },
});

const variants = {
  primary: variantPrimary,
  secondary: variantSecondary,
  tertiary: variantTertiary,
  quaternary: variantQuaternary,
  black: variantBlack,
  white: variantWhite,
  link: variantLink,
};

export const Button = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: "primary",
    size: "m",
    colorScheme: "gray",
  },
});
