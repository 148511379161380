import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  field: {
    width: "100%",
    minWidth: 0,
    outline: 0,
    position: "relative",
    appearance: "none",
    transitionProperty: "common",
    transitionDuration: "normal",
    fontSize: "1rem",
    px: "m",
    h: "2.5rem",
    borderRadius: "s",
  },
});

const variantPrimary = definePartsStyle({
  field: {
    border: "1px solid",
    borderColor: "grayDark",
    bg: "white",
    color: "black",
    _placeholder: {
      color: "grayDark",
    },
    _hover: {
      borderColor: "primary.300",
      _placeholder: {
        color: "grayDark",
      },
    },
    _readOnly: {
      boxShadow: "none !important",
      userSelect: "all",
      _focus: {
        border: "1px solid",
        borderColor: "grayDark",
        _placeholder: {
          color: "grayDark",
        },
      },
    },
    _disabled: {
      cursor: "not-allowed",
      color: "grayDark",
      bg: "grayLight",
      border: "1px solid",
      borderColor: "grayLight",
    },
    _invalid: {
      borderColor: "redAlert",
    },
    _focus: {
      borderColor: "primary.500",
      boxShadow: "outline",
      _placeholder: {
        color: "transparent",
      },
    },
  },
  addon: {
    border: "1px solid",
    borderColor: "inherit",
    bg: "greyLight",
  },
});

export const Input = defineMultiStyleConfig({
  baseStyle,
  variants: {
    primary: variantPrimary,
  },
  defaultProps: {
    variant: "primary",
  },
});
