import { PayloadAction, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { SalesProduct, authApi } from "store/api";
import { v4 as uuid } from "uuid";

import { OrderPayload, resetOrder } from "./checkout.slice";

export type CartItem = SalesProduct & { cartItemId: string };

export type CartItemWithQuantity = CartItem & { quantity: number };

const salesProductsAdapter = createEntityAdapter<CartItem>({
  selectId: salesProduct => salesProduct.cartItemId,
});

export const initialCartState = salesProductsAdapter.getInitialState();

export const cartSlice = createSlice({
  name: "cart",
  initialState: initialCartState,
  reducers: {
    initCart: (state, { payload }: PayloadAction<SalesProduct[]>) => {
      salesProductsAdapter.setAll(
        state,
        payload.map(salesProduct => ({ cartItemId: uuid(), ...salesProduct })),
      );
    },
    addProduct: (state, { payload }: PayloadAction<SalesProduct>) => {
      salesProductsAdapter.addOne(state, { cartItemId: uuid(), ...payload });
    },
    updateProduct: salesProductsAdapter.updateOne,
    removeProduct: salesProductsAdapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, () => initialCartState);
    builder.addMatcher(authApi.endpoints.signOut.matchFulfilled, () => initialCartState);
    builder.addMatcher(authApi.endpoints.signOut.matchRejected, () => initialCartState);
  },
});

export const { initCart, addProduct, updateProduct, removeProduct } = cartSlice.actions;

export const cartSelectors = salesProductsAdapter.getSelectors<RootState>(state => state.cart);

export const selectSalesProductsToOrder = createSelector([cartSelectors.selectAll], cartItems => {
  let salesProducts: OrderPayload["salesProducts"] = [];

  cartItems.forEach(cartItem => {
    const productExistedInOrder = salesProducts.find(p => p.id === cartItem.id);

    if (productExistedInOrder) {
      productExistedInOrder.quantity++;
    } else {
      salesProducts = [...salesProducts, { id: cartItem.id, quantity: 1 }];
    }
  });

  return salesProducts;
});

export const selectCartItemsWithQty = createSelector([cartSelectors.selectAll], cartItems => {
  const salesProducts: CartItemWithQuantity[] = [
    ...cartItems
      .reduce((previousValue, currentValue) => {
        const productExisted = previousValue.find(p => p.id === currentValue.id);
        if (productExisted) {
          productExisted.quantity++;
        } else {
          previousValue = [...previousValue, { ...currentValue, quantity: 1 }];
        }
        return previousValue;
      }, [] as CartItemWithQuantity[])
      .values(),
  ];
  return salesProducts;
});
