import { useEffect } from "react";

import { store } from "store";
import { selectToken } from "store/state";
import { setLocaleInCookie } from "utils/helpers";

import { useAppSelector } from "./useAppSelector";

export const useInitLocale = () => {
  const token = useAppSelector(selectToken);

  useEffect(() => {
    const user = store.getState().auth?.user;
    if (token && user) {
      setLocaleInCookie(user.locale);
    }
  }, [token]);
};
