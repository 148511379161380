import React from "react";

import { chakra } from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

export const HamburgerButton = ({ isOpen, onClick }: Props) => {
  const styleProps = isOpen
    ? {
        span: {
          backgroundColor: "transparent",
        },
        before: {
          transform: "translateY(0.525rem) rotate(45deg)",
          top: 0,
          marginTop: "-0.5rem",
        },
        after: {
          transform: "translateY(0.525rem) rotate(-45deg)",
          top: 0,
          marginTop: "-0.5rem",
        },
      }
    : {};

  const baseStyle = {
    display: "block",
    w: "m",
    bgColor: "black",
    transitionDuration: "slow",
    h: "2px",
    opacity: 1,
    content: `""`,
  };

  return (
    <chakra.button
      onClick={onClick}
      width="xxl"
      h="xxl"
      _focusVisible={{
        boxShadow: "outline",
        outline: "none",
      }}
    >
      <chakra.span
        ml="s"
        transitionProperty="all"
        position="absolute"
        {...baseStyle}
        {...styleProps.span}
        _before={{
          transitionProperty: "transform, opacity",
          top: "-6px",
          pos: "absolute",

          ...baseStyle,
          ...styleProps.before,
        }}
        _after={{
          transitionProperty: "all",
          top: "6px",
          pos: "absolute",
          ...baseStyle,
          ...styleProps.after,
        }}
      ></chakra.span>
    </chakra.button>
  );
};
