import { useEffect } from "react";

import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateBillingAddressMutation,
  useGetBillingAddressesQuery,
  useUpdateBillingAddressMutation,
} from "store/api";
import { err } from "store/errors";
import { Flex, SpinnerWithLabel, TitleH1Os, toast } from "ui";
import { camelKeysToSnake } from "utils/helpers";
import BillingAddressForm, { BillingDetailsFormState } from "views/commons/BillingAddressForm";

const ChangeBillingAddress: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAddBillingAddressPage = useLocation().pathname === Paths.AddBillingAddress;
  const [createBillingAddress, createBillingAddressState] = useCreateBillingAddressMutation();
  const [updateBillingAddress, updateBillingAddressState] = useUpdateBillingAddressMutation();
  const { data: billingAddress, isSuccess, isLoading, error } = useGetBillingAddressesQuery();

  useEffect(() => {
    if (err.hasSpecificStatus(error, 404) && !isAddBillingAddressPage) {
      navigate(Paths.AddBillingAddress, { replace: true });
    }
    if (isSuccess && isAddBillingAddressPage) {
      navigate(Paths.EditBillingAddress, { replace: true });
    }
  }, [error, isSuccess, navigate, isAddBillingAddressPage]);

  const onUpdateOrCreateBillingAddress = async (formState: BillingDetailsFormState) => {
    try {
      const address = { ...formState };
      if (!address.phone) {
        address.dialCode = null;
      }

      const payload = camelKeysToSnake(address);

      if (billingAddress) {
        await updateBillingAddress(payload).unwrap();
      } else {
        await createBillingAddress(payload).unwrap();
      }
      toast({
        status: "success",
        title: t("label_noun_success"),
        description: t("label_action_info_success_billing_address"),
      });
      navigate(Paths.Profile);
    } catch (error) {
      err.handler(error, [
        {
          code: "error_firstname_invalid_format",
          title: t("label_noun_first_name"),
          description: t("prompt_error_special_characters"),
        },
        {
          code: "error_lastname_invalid_format",
          title: t("label_noun_family_name"),
          description: t("prompt_error_special_characters"),
        },
        {
          code: "error_primary_address_line_invalid_format",
          title: t("label_noun_address"),
          description: t("prompt_error_wrong_format"),
        },
        {
          code: "error_optional_address_line_invalid_format",
          title: t("label_noun_address"),
          description: t("prompt_error_wrong_format"),
        },
        {
          code: "error_zip_code_line_invalid_format",
          title: t("label_noun_address_complement"),
          description: t("prompt_error_wrong_format"),
        },
        {
          code: "error_city_invalid_format",
          title: t("label_noun_city"),
          description: t("prompt_error_wrong_format"),
        },
        {
          code: "error_general",
          title: t("prompt_error_generic_toast_pt1"),
          description: t("label_action_request_error_generic_toast_pt2"),
        },
      ]);
    }
  };

  const onCancel = () => navigate(Paths.Profile);

  const isSubmitting = createBillingAddressState.isLoading || updateBillingAddressState.isLoading;

  if (!billingAddress && isLoading) {
    return <SpinnerWithLabel label={t("label_action_info_loading")} />;
  }

  return (
    <Flex w="full" flexDirection="column">
      <TitleH1Os subtitle={t("prompt_monthly_billing_info")}>{t("label_noun_billing_address")}</TitleH1Os>
      <BillingAddressForm
        defaultValues={billingAddress}
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        onSubmit={onUpdateOrCreateBillingAddress}
      />
    </Flex>
  );
};

export default ChangeBillingAddress;
