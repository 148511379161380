import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSignInMutation } from "store/api";
import { err } from "store/errors";
import {
  BackArrow,
  BodyText,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  List,
  ListItem,
  Stack,
  Text,
  TextDivider,
  TitleH1,
} from "ui";
import { useBreakpoints } from "ui/hooks";

import SignInForm, { SignInFormState } from "./SignInForm";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const [signIn, signInResult] = useSignInMutation();
  const goBack = () => {
    navigate(-1);
  };

  const onSignIn = async (formState: SignInFormState) => {
    try {
      await signIn(formState).unwrap();
    } catch (error) {
      err.handler(error, [
        {
          code: "error_login_data_invalid_data",
          title: t("prompt_error_incorrect_data_pt1"),
          description: t("label_action_request_error_incorrect_data_pt2"),
        },
        {
          code: "error_user_unconfirmed",
          title: t("prompt_login_error"),
          description: t("prompt_confirm_your_email_to_sign_in"),
        },
      ]);
    }
  };

  const goToSignUp = () => {
    navigate(Paths.SignUp);
  };

  return (
    <>
      <Card direction={["column", "row"]} py={[0, "xl"]} px={[0, "l", 16]} pos="relative">
        <Stack direction="column" flex={1} spacing="l">
          <BodyText variant="header24" mt={[0, "m"]}>
            {t("label_action_request_sign_in")}
          </BodyText>
          <SignInForm onSubmit={onSignIn} isSubmitting={signInResult.isLoading} />
        </Stack>
        <Box mt={["xl", 0]} mb={[12, 0]}>
          {isMobile ? (
            <TextDivider label={t("label_other_divider").toUpperCase()} />
          ) : (
            <Divider variant="light" orientation="vertical" mx={[0, "l", 16]} />
          )}
        </Box>
        <Flex flex={1} flexDirection="column" justifyContent={["flex-start", "space-between"]}>
          {!isMobile && (
            <Box>
              <TitleH1 color="violet.300" mt="xxl" mb="xl">
                {t("prompt_why_sign_up_title")}
              </TitleH1>
              <List>
                <ListItem>{t("label_action_request_registration_first_advantage")}</ListItem>
                <ListItem>{t("label_action_request_registration_second_advantage")}</ListItem>
                <ListItem>{t("label_action_request_registration_third_advantage")}</ListItem>
              </List>
            </Box>
          )}
          <Button variant="tertiary" onClick={goToSignUp}>
            {t("label_action_request_create_account")}
          </Button>
        </Flex>
        {isMobile && (
          <Button onClick={goBack} variant="quaternary" mt="xl" w="min-content">
            <BackArrow />
            <Text pl="xs">{t("label_action_request_back")}</Text>
          </Button>
        )}
      </Card>
      {!isMobile && (
        <Button onClick={goBack} variant="quaternary" mt={12} w="min-content" alignSelf="start">
          <BackArrow />
          <Text pl="xs">{t("label_action_request_back")}</Text>
        </Button>
      )}
    </>
  );
};

export default SignIn;
