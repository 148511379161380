import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ErrorIcon = createIcon({
  displayName: "ErrorIcon",
  viewBox: "0 0 16 16",
  defaultProps: {
    fill: "transparent",
  },
  path: [
    <path
      d="M8.5125 3.9L8.3625 10.3187H7.075L6.91875 3.9H8.5125ZM6.875 12.2875C6.875 12.0583 6.95 11.8667 7.1 11.7125C7.25417 11.5542 7.46667 11.475 7.7375 11.475C8.00417 11.475 8.21458 11.5542 8.36875 11.7125C8.52292 11.8667 8.6 12.0583 8.6 12.2875C8.6 12.5083 8.52292 12.6979 8.36875 12.8562C8.21458 13.0104 8.00417 13.0875 7.7375 13.0875C7.46667 13.0875 7.25417 13.0104 7.1 12.8562C6.95 12.6979 6.875 12.5083 6.875 12.2875Z"
      fill="currentColor"
    />,
  ],
});
