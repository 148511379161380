import React from "react";

import { useNavigate } from "react-router-dom";

import { BodyText, Divider, Flex, StatusButton } from "../../atoms";
import { useBreakpoints } from "../../hooks";

type Step = {
  index: number;
  title: string;
  path: string;
};

type Steps = Step[];

type Props = {
  steps: Steps;
  lastReachableStep: Step;
  children: JSX.Element;
  activeStep: Step;
};

export const StatusHeader: React.FC<Props> = ({ children, steps, lastReachableStep, activeStep }) => {
  const { isMobile } = useBreakpoints();
  const navigate = useNavigate();

  return (
    <>
      <Flex w="full" mb="xl">
        {steps.map((step, index) => {
          const isStepReachable = lastReachableStep.index >= step.index;
          return (
            <React.Fragment key={index}>
              {index !== 0 && <Divider alignSelf="center" size="medium" variant={isStepReachable ? "brand" : "dark"} />}
              <StatusButton
                onClick={() => {
                  navigate(step.path);
                }}
                disabled={!isStepReachable}
              >
                {index + 1}
              </StatusButton>
              {!isMobile && activeStep.index === index && (
                <Flex mx="m">
                  <BodyText
                    variant="body18"
                    color="violet.300"
                    fontWeight="bold"
                    alignSelf="center"
                    whiteSpace="nowrap"
                  >
                    {activeStep.title.toUpperCase()}
                  </BodyText>
                </Flex>
              )}
            </React.Fragment>
          );
        })}
      </Flex>
      {activeStep && children}
    </>
  );
};
