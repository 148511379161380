import { Text as ChakraText, ComponentWithAs, TextProps as Props, SystemProps } from "@chakra-ui/react";

export interface TextProps extends Props {
  /**
   * The CSS `text-align` property
   * @type SystemProps["textAlign"]
   */
  align?: SystemProps["textAlign"];
  /**
   * The CSS `text-decoration` property
   * @type SystemProps["textDecoration"]
   */
  decoration?: SystemProps["textDecoration"];
  /**
   * The CSS `text-transform` property
   * @type SystemProps["textTransform"]
   */
  casing?: SystemProps["textTransform"];
}

/**
 * Text component is the used to render text and paragraphs within an interface. It renders a \<p\> tag by default.
 */
export const Text = ChakraText as ComponentWithAs<"div", TextProps>;
