import React from "react";

import { IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from "@chakra-ui/react";

import { EndIcon, KebabIcon } from "../../atoms";

type Props = {
  children?: React.ReactNode;
};

export const OverflowMenu: React.FC<Props> = ({ children }) => {
  return (
    <Menu gutter={0} placement="bottom-end">
      {({ isOpen }) => (
        <>
          <MenuButton
            as={IconButton}
            variant="outline"
            borderBottomRadius={isOpen ? 0 : "full"}
            icon={isOpen ? <EndIcon /> : <KebabIcon />}
            {...styles.button}
          />
          <MenuList {...styles.list}>
            {React.Children.map(children, (child, index) => (
              <>
                {index !== 0 && <MenuDivider {...styles.divider} />}
                {child}
              </>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

type ItemProps = {
  onClick: () => void;
  children: string;
};

export const OverflowMenuItem: React.FC<ItemProps> = ({ children, onClick }) => (
  <MenuItem onClick={onClick} {...styles.item}>
    {children}
  </MenuItem>
);

const buttonStyles = {
  h: "1.75rem",
  w: "1.75rem",
  borderTopRadius: "full",
  transitionProperty: "border-radius",
  backgroundColor: "grayLight",
  _focus: {
    boxShadow: "none",
  },
  _focusVisible: {
    outlineOffset: 0,
    outlineWidth: 2,
    outlineStyle: "solid",
  },
  transitionDuration: "fast",
  transitionTimingFunction: "ease-in-out",
};

const listStyles = {
  backgroundColor: "grayLight",
  borderTopLeftRadius: "s",
  borderBottomRadius: "s",
};

const dividerStyles = {
  borderBottom: "1px solid",
  borderColor: "grayDark",
};

const itemStyles = {
  px: "m",
  py: "xs",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  outlineOffset: 0,
  _hover: { color: "primary.300" },
  _active: { color: "primary.500" },
  _focusVisible: { color: "black", borderColor: "keyboardBlueAlert", borderWidth: 2, borderStyle: "solid" },
};

const styles = {
  button: buttonStyles,
  list: listStyles,
  divider: dividerStyles,
  item: itemStyles,
};
