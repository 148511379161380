import { LocaleAPI } from "../../locales";
import { Method, ResBuilder, api, transformResponse } from "./api";
import { User, UserRaw } from "./auth.api";

export const consentsApi = api.injectEndpoints({
  endpoints: builder => ({
    getCurrentConsents: builder.query<Consent[], void>({
      query: () => ({
        url: "users/consents/current",
        method: Method.Get,
      }),
      transformResponse: (response: ResBuilder<ConsentRaw[]>) => transformResponse(response),
    }),
    getLatestConsent: builder.query<ConsentDetails, { language: string; name: string }>({
      query: payload => ({
        url: "users/consents/details/latest",
        method: Method.Get,
        params: payload,
      }),
      transformResponse: (response: ResBuilder<ConsentDetailsRaw>) => transformResponse(response),
    }),
    acceptConsent: builder.mutation<User, { id: number; language: string }>({
      query: ({ id, ...payload }) => ({
        url: `users/consents/${id}/accept`,
        method: Method.Put,
        body: payload,
      }),
      transformResponse: (response: ResBuilder<UserRaw>) => transformResponse(response),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetCurrentConsentsQuery,
  useLazyGetLatestConsentQuery,
  useGetCurrentConsentsQuery,
  useGetLatestConsentQuery,
  useAcceptConsentMutation,
} = consentsApi;

export type ConsentName = "platform_regulations" | "platform_privacy_policy" | "store_policy";

export type Consent = {
  id: number;
  type: "consent";
  name: ConsentName;
  version: number;
  latest: boolean;
  detailsIds: number[];
};

export type ConsentDetails = {
  id: number;
  type: "consents_detail";
  text: string;
  language: LocaleAPI;
  consentId: number;
};

export type ConsentRaw = {
  id: string;
  type: "consent";
  attributes: {
    name: string;
    version: number;
    latest: boolean;
  };
  relationships: {
    details: {
      data: {
        id: string;
        type: "consents_detail";
      }[];
    };
  };
};

export type ConsentDetailsRaw = {
  id: string;
  type: "consents_detail";
  attributes: {
    created_at: string;
    updated_at: string;
    language: LocaleAPI;
    text: string;
  };
  relationships: {
    consent: {
      data: {
        id: string;
        type: "consent";
      }[];
    };
  };
};
