import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationDeliveryIllustration = createIcon({
  displayName: "ConfirmationDeliveryIllustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <rect width="150" height="150" fill="white" />,
    <path
      d="M11.9299 84.81V35.35H104.43V110.94H41.9199"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M23.9199 110.94H11.9299V98.12"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M125.22 61.14L134.17 79.39"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M32.9199 119.92C37.8905 119.92 41.9199 115.891 41.9199 110.92C41.9199 105.949 37.8905 101.92 32.9199 101.92C27.9494 101.92 23.9199 105.949 23.9199 110.92C23.9199 115.891 27.9494 119.92 32.9199 119.92Z"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M32.9199 113.92C34.5768 113.92 35.9199 112.577 35.9199 110.92C35.9199 109.263 34.5768 107.92 32.9199 107.92C31.2631 107.92 29.9199 109.263 29.9199 110.92C29.9199 112.577 31.2631 113.92 32.9199 113.92Z"
      fill="#999999"
    />,
    <path
      d="M123.07 119.92C128.041 119.92 132.07 115.891 132.07 110.92C132.07 105.949 128.041 101.92 123.07 101.92C118.1 101.92 114.07 105.949 114.07 110.92C114.07 115.891 118.1 119.92 123.07 119.92Z"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M123.07 113.92C124.727 113.92 126.07 112.577 126.07 110.92C126.07 109.263 124.727 107.92 123.07 107.92C121.413 107.92 120.07 109.263 120.07 110.92C120.07 112.577 121.413 113.92 123.07 113.92Z"
      fill="#999999"
    />,
    <path
      d="M132.131 111H141.31C142.026 110.974 142.703 110.666 143.194 110.144C143.684 109.621 143.949 108.926 143.93 108.21V85.44C143.93 83.9 132.75 79.65 131.31 79.65H104.51"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M103.08 110.95L114 111" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M105 60.31H126.88V56.8C126.902 56.0835 126.64 55.3875 126.151 54.8632C125.662 54.3389 124.986 54.0287 124.27 54H105"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M143.24 89.25H136.36V92.76C136.339 93.4781 136.602 94.1756 137.093 94.7002C137.584 95.2248 138.262 95.5339 138.98 95.56H143.24"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M24.0999 97.5H5.88989" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M34.3499 91.76H16.1399" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M95.0601 30.08H76.8501" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M23 55.4844H30.3826L39.8995 77.5065H39.9836L49.4574 55.4844H56.881L44.5457 82.5725C43.7277 84.335 41.8926 85.4966 39.9232 85.4983C37.9524 85.4955 36.1168 84.3311 35.3007 82.5661L23 55.4844Z"
      fill="#8F8BEF"
    />,
    <path
      d="M76.038 54.8749C78.0055 54.8746 79.8404 56.0326 80.6604 57.7922L93 84.8782H85.5807L76.107 62.8583H76.0207L66.4995 84.8782H59.1147L71.4155 57.7965C72.2347 56.0356 74.0696 54.8759 76.038 54.8749Z"
      fill="#8F8BEF"
    />,
  ],
});
