import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { User, authApi, consentsApi } from "store/api";

type AuthStore = {
  user?: User;
  token?: string | null;
};

export const initialAuthState = {} as AuthStore;

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
    setCredentials: (state, { payload: { user, token } }: PayloadAction<AuthStore>) => {
      state.user = user;
      state.token = token;
    },
    clearToken: state => {
      state.token = null;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(authApi.endpoints.signOut.matchFulfilled, () => initialAuthState);
    builder.addMatcher(authApi.endpoints.signOut.matchRejected, () => initialAuthState);
    builder.addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, { payload }) => {
      state.token = payload.token;
      state.user = payload.data;
    });
    builder.addMatcher(authApi.endpoints.changePassword.matchFulfilled, (state, { payload }) => {
      state.token = payload.token;
      state.user = payload.data;
    });
    builder.addMatcher(consentsApi.endpoints.acceptConsent.matchFulfilled, (state, { payload }) => {
      state.user = payload;
    });
  },
});

export const { setUser, setCredentials, clearToken } = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;
