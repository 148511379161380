import React from "react";

import { useBoolean } from "@chakra-ui/react";
import { useCheckConsents } from "hooks";
import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { Outlet, Link as RouterLink } from "react-router-dom";
import { BodyText, Box, Flex, FlexProps, Footer, Link, MenuMobile, SpinnerWithLabel, Stack, TopBar, theme } from "ui";
import { useBreakpoints } from "ui/hooks";
import ChangeLanguageWidget from "views/commons/ChangeLanguageWidget";

import Menu from "../Menu";

const AppLayout: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, { toggle, off }] = useBoolean();
  const isLoading = useCheckConsents();
  const { isDesktop } = useBreakpoints();

  const styleProps: Record<string, FlexProps> = {
    container: {
      flex: 1,
      px: [0, 0, "xxl", 120],
      mt: "112px",
      mb: "xxl",
      flexDir: "column",
      w: "full",
      maxW: 1440,
      alignSelf: "center",
    },
  };

  if (isLoading) {
    return <SpinnerWithLabel label={t("label_action_info_loading")} />;
  }

  return (
    <>
      <TopBar logoLink={{ to: "/", as: RouterLink }} isOpenMenu={isOpen} onToggleMenu={toggle} maxW={1440}>
        <ChangeLanguageWidget />
      </TopBar>

      <MenuMobile isOpen={isOpen} onClose={off} offsetY={theme.sizes.menu}>
        <Box w="full">
          <Menu onClose={off} />
        </Box>
      </MenuMobile>
      <Flex {...styleProps.container}>
        <Stack direction="row" spacing={[0, 0, 68, 76]}>
          <Flex flex={1} mx={["m", "xl", 0]}>
            <Outlet />
          </Flex>
          {isDesktop && (
            <Flex w={280} flexDirection="column" pt={4}>
              <Menu />
            </Flex>
          )}
        </Stack>
      </Flex>
      <Footer maxW={1440}>
        <Link href={Paths.PlatformRegulations} color="white" target="_blank">
          {t("label_noun_terms_of_use")}
        </Link>
        <BodyText color="white">|</BodyText>
        <Link href={Paths.PlatformPrivacyPolicy} color="white" target="_blank">
          {t("label_noun_privacy_policy")}
        </Link>
      </Footer>
    </>
  );
};

export default AppLayout;
