import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { Subscription, useGetSubscriptionsQuery, useGetUserUniqueDevicesQuery } from "store/api";
import { selectCurrentUser } from "store/state";
import { Flex, ItemCart, SkeletonGroup, TitleH1Os } from "ui";
import BuyDevicePrompt from "views/commons/BuyDevicePrompt";

import { DevicesList } from "./DevicesList";
import { PendingSubscriptionPrompt } from "./PendingSubscriptionsPrompt";

const MyDevices: React.FC = () => {
  const currentUser = useAppSelector(selectCurrentUser);
  const { t } = useTranslation();
  const { data: pairedDevices, isLoading: isLoadingDevices } = useGetUserUniqueDevicesQuery(undefined, {
    skip: !currentUser?.consentsAccepted,
  });

  const { data: subscriptions, isLoading: isLoadingSubscriptions } = useGetSubscriptionsQuery(undefined, {
    skip: !currentUser?.consentsAccepted,
  });

  const isLoadingData = isLoadingDevices || isLoadingSubscriptions;
  const isDevicesExists = pairedDevices && pairedDevices.length > 0;
  const isSubscriptionsExists = subscriptions && subscriptions.length > 0;
  const pendingSubscriptions = subscriptions?.filter((subscription: Subscription) => !subscription.deviceId);

  if (isLoadingData) {
    return (
      <Flex w="full" flexDirection="column">
        <TitleH1Os>{t("label_noun_my_devices")}</TitleH1Os>
        <SkeletonGroup repeatTimes={1}>
          <ItemCart>
            <ItemCart.Header />
            <ItemCart.Description />
            <ItemCart.Content />
            <ItemCart.Footer />
          </ItemCart>
        </SkeletonGroup>
      </Flex>
    );
  }

  return (
    <Flex w="full" flexDirection="column">
      <TitleH1Os>{t("label_noun_my_devices")}</TitleH1Os>
      {!isDevicesExists && !isSubscriptionsExists && (
        <BuyDevicePrompt
          title={t("prompt_buying_device_instruction_pt1")}
          description={t("prompt_buying_device_instruction_pt2")}
          listItems={[t("label_noun_subscription_device_lower_price"), t("label_noun_standalone_device")]}
        />
      )}
      <PendingSubscriptionPrompt counter={pendingSubscriptions?.length} />

      <DevicesList devices={pairedDevices} subscriptions={subscriptions} />
    </Flex>
  );
};

export default MyDevices;
