const typography = {
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },

  fonts: {
    heading: `'Old Standard TT', serif`,
    body: `'Noto Sans', sans-serif`,
    mono: `'Noto Sans', sans-serif`,
  },
};

export default typography;
