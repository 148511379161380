import { useMediaQuery } from "@chakra-ui/react";

import { theme } from "../theme";

export const useBreakpoints = () => {
  const mobileWidth = theme.breakpoints.sm;
  const mobileWidthAsNumber = parseInt(mobileWidth.replace("px", ""));

  const tabletWidth = theme.breakpoints.md;
  const tabletWidthAsNumber = parseInt(tabletWidth.replace("px", ""));

  const [isMobile, isTablet] = useMediaQuery([
    `(max-width: ${mobileWidthAsNumber - 1}px)`,
    `(max-width: ${tabletWidthAsNumber - 1}px)`,
  ]);

  return {
    isMobile,
    isTablet: !isMobile && isTablet,
    isDesktop: !isMobile && !isTablet,
  };
};
