import React from "react";

import { createIcon } from "@chakra-ui/react";

export const SuccessIcon = createIcon({
  displayName: "SuccessIcon",
  viewBox: "0 0 16 16",
  defaultProps: {
    fill: "transparent",
  },
  path: [
    <circle cx="8" cy="8" r="8" fill="white" />,
    <path d="M4.16006 8L6.87478 10.7147L12.0105 5.579" stroke="#008A1E" strokeWidth="1.6" />,
  ],
});
