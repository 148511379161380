import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";
import { keyframes } from "@chakra-ui/system";

const spin = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
});

const baseStyle = defineStyle({
  color: "violet.300",
  borderBottomColor: "white",
  borderLeftColor: "white",
  borderStyle: "solid",
  borderRadius: "99999px",
  borderWidth: 2,
  animation: `${spin} 700ms linear infinite`,
  display: "inline-block",
  m: "xs",
});

const sizes = {
  sm: defineStyle({
    width: "1.5rem",
    height: "1.5rem",
  }),
};

export const Spinner = defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: "sm",
  },
});
