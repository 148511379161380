import React from "react";

import { createIcon } from "@chakra-ui/react";

export const FrenchFlag = createIcon({
  displayName: "FrenchFlag",
  viewBox: "0 0 40 40",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: "20px",
      h: "20px",
    },
  },
  path: [
    <g clipPath="url(#a)">
      <path
        fill="#F0F0F0"
        d="M20 40c2.461 0 4.82-.445 7-1.258V1.258A20.005 20.005 0 0 0 20 0c-2.46 0-4.82.445-7 1.258v37.476c2.18.82 4.54 1.266 7 1.266Z"
      />
      <path
        fill="#D80027"
        d="M39.93 21.547v-.024c.007-.14.023-.289.03-.43 0-.038.009-.085.009-.124.008-.117.008-.235.015-.352 0-.047 0-.086.008-.133 0-.156.008-.312.008-.468 0-.157 0-.313-.008-.47 0-.046 0-.085-.008-.132a5.3 5.3 0 0 0-.015-.352c0-.039-.008-.085-.008-.125a7.415 7.415 0 0 0-.031-.43v-.023C39.328 10.547 34.125 3.93 27 1.258v37.476c7.125-2.664 12.328-9.28 12.93-17.187Z"
      />
      <path
        fill="#0052B4"
        d="M.016 20.602c0 .117.007.234.015.351 0 .04.008.086.008.125.008.149.016.29.031.43v.023C.672 29.438 5.875 36.063 13 38.727V1.257C5.875 3.93.672 10.548.07 18.454v.024c-.008.14-.023.289-.03.43 0 .038-.009.085-.009.124-.008.117-.008.235-.015.352 0 .047 0 .086-.008.133 0 .156-.008.312-.008.468 0 .157 0 .313.008.47.008.062.008.108.008.148Z"
      />
    </g>,
    <defs>
      <clipPath id="a">
        <rect width={40} height={40} fill="#fff" rx={20} />
      </clipPath>
    </defs>,
  ],
});
