import React from "react";

import { Button, Flex } from "../../atoms";
import * as Illustrations from "../../atoms/Illustrations";

type Props = {
  illustrationName: "Error404Illustration" | "ErrorGenericIllustration" | "Error503Illustration";
  action: {
    onClick: () => void;
    label: string;
  };
  children: React.ReactNode;
};

export const ErrorCart: React.FC<Props> = ({ children, illustrationName, action }) => {
  const IllustrationComponent = illustrationName ? Illustrations[illustrationName] : null;

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      pt="xxxl"
      maxW={713}
      flex={[1, "none"]}
      w="full"
      alignSelf="center"
    >
      <Flex justifyContent="center" flexDirection="row" mb="m">
        {children}
      </Flex>
      <Flex alignItems="center" flexDirection="column" mb={66}>
        {IllustrationComponent && <IllustrationComponent color="grayDark" width={320} height={280} />}
      </Flex>
      <Flex alignItems="flex-end" flexDirection="column">
        <Button variant="secondary" onClick={action.onClick} w={["full", "initial"]}>
          {action.label}
        </Button>
      </Flex>
    </Flex>
  );
};
