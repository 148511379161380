import React from "react";

import { useTranslation } from "react-i18next";
import { Subscription, SubscriptionStatus as SubscriptionStatusType } from "store/api";
import { BodyText, Stack } from "ui";
import { utils } from "utils";

type DeviceInfoProps = {
  subscription?: Pick<Subscription, "expiredAt" | "status">;
};

export const SubscriptionStatus: React.FC<DeviceInfoProps> = ({ subscription }) => {
  const { t } = useTranslation();
  const isActive = subscription?.status === SubscriptionStatusType.Active;
  const isExpiring = subscription?.status === SubscriptionStatusType.Expiring;

  const subscriptionStatusLabel = {
    [SubscriptionStatusType.Active]: t("label_other_active"),
    [SubscriptionStatusType.Expiring]: t("label_other_expiring"),
    [SubscriptionStatusType.Inactive]: t("label_other_inactive"),
    [SubscriptionStatusType.Initial]: t("label_other_not_assigned"),
  };

  return (
    <Stack spacing={1} marginBottom={4}>
      <BodyText>{t("label_noun_subscription").toUpperCase()}</BodyText>
      <BodyText color={isActive ? "greenAlert" : "redAlert"}>
        {subscription?.status ? subscriptionStatusLabel[subscription.status].toUpperCase() : ""}
      </BodyText>
      {subscription && subscription.expiredAt && (isExpiring || isActive) && (
        <BodyText>
          {isExpiring
            ? `${t("label_other_will_expire_date").toLowerCase()} ${utils.helpers.formatDateToString(
                new Date(subscription.expiredAt),
              )}`
            : `${t("label_noun_next_payment").toLowerCase()} ${utils.helpers.formatDateToString(
                new Date(subscription.expiredAt),
              )}`}
        </BodyText>
      )}
      {!subscription?.status && <BodyText color="redAlert">{t("label_other_not_assigned").toUpperCase()}</BodyText>}
    </Stack>
  );
};
