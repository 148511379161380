import { Device, Mandate, Subscription } from "store/api";
import { Stack } from "ui";

import { SubscriptionListItem } from "./SubscriptionListItem";

type SubscriptionListProps = {
  subscriptions?: Subscription[];
  pairedDevices?: Device[];
  mandate?: Mandate;
};

export const SubscriptionList = ({ subscriptions, mandate, pairedDevices }: SubscriptionListProps) => {
  if (!subscriptions || subscriptions.length === 0) return null;

  return (
    <Stack spacing={4} mt={4}>
      {subscriptions.map((subscription, index) => (
        <SubscriptionListItem
          key={`${index}${subscription.id}`}
          subscription={subscription}
          device={pairedDevices?.find(device => device.id === subscription.deviceId)}
          mandate={mandate}
        />
      ))}
    </Stack>
  );
};
