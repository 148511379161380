import React from "react";

import { HTMLChakraProps, ThemingProps, chakra, forwardRef, omitThemingProps, useStyleConfig } from "@chakra-ui/system";

export interface ContainerProps extends HTMLChakraProps<"div">, ThemingProps<"Container"> {
  /**
   * If `true`, container will center its children
   * regardless of their width.
   *
   * @default false
   */
  centerContent?: boolean;
}

/**
 * Layout component used to wrap app or website content
 *
 * It sets `margin-left` and `margin-right` to `auto`,
 * to keep its content centered.
 *
 * It also sets a default max-width of `60ch` (60 characters).
 *
 * @see Docs https://chakra-ui.com/docs/components/container
 */
export const Container = forwardRef<ContainerProps, "div">(function Container(props, ref) {
  const { centerContent, ...rest } = omitThemingProps(props);

  const styles = useStyleConfig("Container", props);

  return (
    <chakra.div
      ref={ref}
      {...rest}
      __css={{
        ...styles,
        ...(centerContent && {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }),
      }}
    />
  );
});
