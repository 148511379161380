import React from "react";

import { createIcon } from "@chakra-ui/react";

export const VerticalDotsIcon = createIcon({
  displayName: "VerticalDotsIcon",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 6,
      h: 6,
    },
  },
  path: [
    <rect x="10" y="3" width="4" height="4" rx="2" fill="#2D3139" />,
    <rect x="10" y="10" width="4" height="4" rx="2" fill="#2D3139" />,
    <rect x="10" y="17" width="4" height="4" rx="2" fill="#2D3139" />,
  ],
});
