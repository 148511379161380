import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { Market } from "store/api";

export type AppState = {
  market: Market | null;
  prestaCartId?: string;
};

export const initialAppState: AppState = {
  market: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialAppState,
  reducers: {
    setMarket: (state, { payload }: PayloadAction<AppState["market"]>) => {
      state.market = payload;
    },
    setPrestaCartId: (state, { payload }: PayloadAction<AppState["prestaCartId"]>) => {
      state.prestaCartId = payload;
    },
  },
});

export const selectPrestCardId = (state: RootState) => state.app.prestaCartId;
export const selectMarket = (state: RootState) => state.app.market;

export const { setMarket, setPrestaCartId } = appSlice.actions;
