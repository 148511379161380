import React from "react";

import { FormControlOptions, useFormControl } from "@chakra-ui/form-control";
import {
  HTMLChakraProps,
  ThemingProps,
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig,
} from "@chakra-ui/system";

type Omitted = "disabled" | "required" | "readOnly" | "size";

export interface InputProps
  extends Omit<HTMLChakraProps<"input">, Omitted>,
    ThemingProps<"Input">,
    FormControlOptions {}

/**
 * Input
 *
 * Element that allows users enter single valued data.
 *
 * @see Docs https://chakra-ui.com/docs/components/input
 */
export const Input = forwardRef<InputProps, "input">(function Input(props, ref) {
  const styles = useMultiStyleConfig("Input", props);
  const ownProps = omitThemingProps(props);
  const input = useFormControl<HTMLInputElement>(ownProps);

  return <chakra.input {...input} __css={styles.field} ref={ref} />;
});
