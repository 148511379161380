import { PreloadedState, combineReducers, configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { api } from "./api/api";
import { appSlice } from "./state/app.slice";
import { authSlice } from "./state/auth.slice";
import { cartSlice } from "./state/cart.slice";
import { checkoutSlice } from "./state/checkout.slice";

export * from "./errors";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authSlice.reducer,
  app: appSlice.reducer,
  cart: cartSlice.reducer,
  checkout: checkoutSlice.reducer,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    whitelist: ["auth", "app", "cart", "checkout"],
  },
  rootReducer,
);

export const setupStore = (preloadedState?: PreloadedState<RootReducerState>) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([api.middleware]),
  });
};

export const store = setupStore();
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export type RootReducerState = ReturnType<typeof persistedReducer>;
export type AppDispatch = typeof store.dispatch;
