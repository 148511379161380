import { useAppDispatch, useAppSelector } from "hooks";
import { LOCALES, LocaleKey } from "locales";
import { useTranslation } from "react-i18next";
import { transformResponse, useUpdateUserMutation } from "store/api";
import { err } from "store/errors";
import { selectCurrentUser, setUser } from "store/state";
import { Box, TopbarDropdown, TopbarDropdownItem } from "ui";
import { parseLocaleToApi, setLocaleInCookie } from "utils/helpers";

const ChangeLanguageWidget: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [updateUser] = useUpdateUserMutation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);

  const languageShort = i18n.language as LocaleKey;
  const localeKeys = Object.keys(LOCALES) as unknown as LocaleKey[];
  const onChangeLanguage = async (locale: LocaleKey) => {
    try {
      const apiLocale = parseLocaleToApi(locale);
      setLocaleInCookie(apiLocale);

      if (user?.consentsAccepted) {
        const user = await updateUser({ locale: apiLocale }).unwrap();
        dispatch(setUser(transformResponse(user)));
      }
    } catch (error) {
      err.handler(error, [], true);
    }
  };

  return (
    <Box zIndex="dropdown">
      <TopbarDropdown label={LOCALES[languageShort]?.short || LOCALES["en-US"].short}>
        {localeKeys.map(locale => (
          <TopbarDropdownItem key={locale} onClick={() => onChangeLanguage(locale)}>
            {t(LOCALES[locale].translationKey)}
          </TopbarDropdownItem>
        ))}
      </TopbarDropdown>
    </Box>
  );
};

export default ChangeLanguageWidget;
