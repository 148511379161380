import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import Rollbar, { Configuration } from "rollbar";

import { ToastProps, toast } from "../ui/atoms";

export type ApiErrorRaw = {
  status: number;
  data: {
    errors: {
      [key: string]: {
        code: string;
        text: string;
      }[];
    };
  };
};

const isKeysExistGuard = <T extends string | number | symbol>(
  input: object,
  keyName: T | readonly T[],
): input is { [key in T]: unknown } => {
  const keyNameArray = Array.isArray(keyName) ? keyName : [keyName];
  let doesAllKeysExist = true;

  keyNameArray.forEach(aKeyName => {
    if (!(aKeyName in input)) {
      doesAllKeysExist = false;
    }
  });

  return doesAllKeysExist;
};

export const errorToast = (options?: Partial<ToastProps>) => {
  return toast({
    status: "error",
    title: options?.title || t("prompt_error_generic_toast_pt1"),
    description: options?.description || t("label_action_request_error_generic_toast_pt2"),
  });
};

export const infoToast = (options?: Partial<ToastProps>) => {
  return toast({
    status: "info",
    title: options?.title || "",
    description: options?.description || "",
  });
};

const ERROR_CODES = [
  "error_email_not_unique",
  "error_confirmation_token_already_confirmed",
  "error_password_invalid",
  "error_destroy_token_invalid",
  "error_login_data_invalid_data",
  "error_payment_recurring_payment_failed",
  "error_user_accepted_consent_required",
  "error_order_create_invalid_data",
  "error_user_unconfirmed",
  "error_billing_details_not_exists",
  "error_credit_card_invalid",
  "error_city_invalid_format",
  "error_zip_code_line_invalid_format",
  "error_optional_address_line_invalid_format",
  "error_primary_address_line_invalid_format",
  "error_lastname_invalid_format",
  "error_firstname_invalid_format",
] as const;

const rollbarConfig: Configuration = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV === "development" ? "development" : process.env.REACT_APP_ENV,
};

export const rollbar = new Rollbar(rollbarConfig);

export type ErrorCode = (typeof ERROR_CODES)[number] | "error_general";
export type Err = { codes: ErrorCode[]; status: number | null; orginalError: any };

type FetchQueryError = FetchBaseQueryError & { data: ApiErrorRaw["data"] };

const getErrorStatus = (e: Err | SerializedError | undefined) => (e && "status" in e ? e.status : null);

const isFetchErrorGuard = (error: unknown): error is FetchQueryError => {
  if (
    typeof error === "object" &&
    error !== null &&
    isKeysExistGuard(error, ["data", "status"]) &&
    typeof error.data === "object" &&
    error.data !== null &&
    isKeysExistGuard(error.data, ["errors"]) &&
    typeof error.data.errors === "object" &&
    error.data.errors !== null
  ) {
    return true;
  }
  return false;
};

const isErrorCode = (code: string): code is ErrorCode => {
  return ERROR_CODES.includes(code as any);
};

const isError = (error: unknown): error is Err => {
  return typeof error === "object" && error !== null && isKeysExistGuard(error, ["codes", "status", "orginalError"]);
};

const mapErrorDataToErrorCodes = (errorData: FetchQueryError["data"]): ErrorCode[] => {
  const { errors } = errorData;

  const errorsKeys = Object.keys(errors).map(itemKey => {
    const errorKey = `error_${itemKey}_${errors[itemKey][0].code}`;

    return isErrorCode(errorKey) ? errorKey : "error_general";
  });

  return errorsKeys;
};

const parse = (error: unknown | FetchBaseQueryError) => {
  let ecosystemError: Err = { codes: ["error_general"], status: null, orginalError: error };

  if (isFetchErrorGuard(error)) {
    ecosystemError.status = typeof error.status === "number" ? error.status : null;
    switch (error.status) {
      case 422:
      case 401:
        ecosystemError.codes = [...mapErrorDataToErrorCodes(error.data)];
    }
  }

  return ecosystemError;
};

type SpecificError = {
  code: ErrorCode; // one of error EcosystemErrorCode
  title: string; // title which should be displayed in toast
  description: string; // description which should be displayed in toast
};

const handler = (error: unknown, specificErrors: SpecificError[] = [], skipNotification: boolean = false) => {
  if (isError(error)) {
    if (error.codes.includes("error_general")) {
      rollbar.warn(`Unknown error - status: ${error.status}`, error);
    } else {
      rollbar.info(error.codes[0], error);
    }
  } else {
    rollbar.error(typeof error === "object" ? (error as any).message : "Unknown error", error as any);
  }

  if (!skipNotification) {
    if (isError(error) && specificErrors.length > 0) {
      specificErrors.forEach(specificError => {
        if (error.codes.includes(specificError.code)) {
          errorToast({ title: specificError.title, description: specificError.description });
        }
      });
    } else {
      errorToast();
    }
  }
};

const hasSpecificCode = (error: unknown, specificErrorCode: ErrorCode): error is Err => {
  return isError(error) && error.codes.includes(specificErrorCode);
};

const hasSpecificStatus = (e: Err | SerializedError | undefined, status: number) => {
  return getErrorStatus(e) === status;
};

export const err = {
  parse,
  handler,
  hasSpecificCode,
  hasSpecificStatus,
};
