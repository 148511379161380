import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { Box, TitleWithIcons } from "ui";
import { AmexCardLogo, CbCardLogo, MasterCardLogo, SiCardLogo, VisaCardLogo } from "ui/atoms/Logos";
import ChangeCardForm from "views/commons/ChangeCardForm";

const ChangeCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box w="full">
      <TitleWithIcons title={t("label_noun_your_card")} subtitle={t("prompt_payment_method_used_for_all")}>
        <VisaCardLogo />
        <MasterCardLogo />
        <AmexCardLogo />
        <CbCardLogo />
        <SiCardLogo />
      </TitleWithIcons>
      <Box w="full" pt={["xxs", "xs"]}>
        <ChangeCardForm redirectPath={Paths.ProfileVerifyCard} goBackPath={Paths.Profile} />
      </Box>
    </Box>
  );
};

export default ChangeCard;
