import React from "react";

import { useTranslation } from "react-i18next";
import { BodyText, ErrorCart, Stack } from "ui";
import BackgroundLayout from "views/commons/Layouts/BackgroundLayout";

type Props = {
  resetError: () => void;
};
const ErrorPage: React.FC<Props> = ({ resetError }) => {
  const { t } = useTranslation();
  const action = {
    label: t("label_action_request_go_to_homepage"),
    onClick: resetError,
  };

  return (
    <BackgroundLayout>
      <ErrorCart illustrationName="ErrorGenericIllustration" action={action}>
        <Stack direction="row" spacing="xs">
          <BodyText variant="header24" color="violet.300">
            {t("label_other_error_generic_pt1")}
          </BodyText>
          <BodyText variant="header24">{t("prompt_error_generic_pt2")}</BodyText>
        </Stack>
      </ErrorCart>
    </BackgroundLayout>
  );
};

export default ErrorPage;
