import React from "react";

import { createIcon } from "@chakra-ui/react";

export const InPostPaczkomatLogo = createIcon({
  displayName: "InPostPaczkomatLogo",
  viewBox: "0 0 60 60",
  defaultProps: {
    width: "3.75rem",
    height: "3.75rem",
    fill: "transparent",
  },
  path: [
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.896 40.3352C25.6529 40.1353 25.5332 39.8666 25.5332 39.5284C25.5332 39.1864 25.6529 38.9174 25.896 38.7175C26.1393 38.5177 26.4674 38.4179 26.8804 38.4179C27.2934 38.4179 27.6178 38.5177 27.8611 38.7175C28.1041 38.9174 28.2277 39.1864 28.2277 39.5284C28.2277 39.8666 28.1041 40.1353 27.8611 40.3352C27.6178 40.5351 27.2934 40.635 26.8804 40.635C26.4674 40.635 26.1393 40.5351 25.896 40.3352ZM27.5945 39.5284C27.5945 39.1557 27.3165 38.9174 26.8804 38.9174C26.4443 38.9174 26.1663 39.1557 26.1663 39.5284C26.1663 39.9009 26.4443 40.1353 26.8804 40.1353C27.3165 40.1353 27.5945 39.9009 27.5945 39.5284Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.5608 38.4715H31.1783V40.581H30.5763V40.2545C30.4334 40.4889 30.1477 40.6351 29.7771 40.6351C29.4567 40.6351 29.2214 40.5543 29.0399 40.3812C28.8507 40.1971 28.7734 39.9511 28.7734 39.5012V38.4715H29.395V39.517C29.395 39.7781 29.4529 39.9319 29.5802 40.0204C29.6882 40.0857 29.7811 40.1124 29.9315 40.1124C30.2095 40.1124 30.4257 39.9703 30.5183 39.7359C30.5454 39.6707 30.5608 39.5783 30.5608 39.467V38.4715Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.471 40.0817V40.5812H32.6952C32.2667 40.5812 32.0891 40.4121 32.0891 40.0123V38.9634H31.6953V38.4715H32.0891V37.9069H32.7106V38.4715H33.471V38.9634H32.7106V39.8742C32.7106 40.0316 32.7647 40.0817 32.9537 40.0817H33.471Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.4878 40.3352C35.2448 40.1353 35.125 39.8666 35.125 39.5284C35.125 39.1864 35.2448 38.9174 35.4878 38.7175C35.7311 38.5177 36.0592 38.4179 36.4722 38.4179C36.8852 38.4179 37.2096 38.5177 37.4527 38.7175C37.696 38.9174 37.8195 39.1864 37.8195 39.5284C37.8195 39.8666 37.696 40.1353 37.4527 40.3352C37.2096 40.5351 36.8852 40.635 36.4722 40.635C36.0592 40.635 35.7311 40.5351 35.4878 40.3352ZM37.1864 39.5284C37.1864 39.1557 36.9083 38.9174 36.4722 38.9174C36.0361 38.9174 35.7582 39.1557 35.7582 39.5284C35.7582 39.9009 36.0361 40.1353 36.4722 40.1353C36.9083 40.1353 37.1864 39.9009 37.1864 39.5284Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.774 38.1837H39.3843C39.2259 38.1837 39.1641 38.2257 39.1641 38.337V38.4716H39.774V38.9634H39.1641V40.5813H38.5429V38.9634H38.1953V38.4716H38.5429V38.3103C38.5429 37.8992 38.8092 37.7071 39.3534 37.7071H39.774V38.1837Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.1292 40.0817V40.5812H42.3534C41.9249 40.5812 41.7473 40.4121 41.7473 40.0123V38.9634H41.3535V38.4715H41.7473V37.9069H42.3687V38.4715H43.1292V38.9634H42.3687V39.8742C42.3687 40.0316 42.4227 40.0817 42.6119 40.0817H43.1292Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.7441 40.5811V37.7069H44.3657V38.7716C44.5237 38.5526 44.8171 38.4179 45.1762 38.4179C45.485 38.4179 45.7281 38.5024 45.9134 38.6715C46.1025 38.8521 46.1835 39.1056 46.1835 39.5514V40.5811H45.5623V39.5362C45.5623 39.294 45.5081 39.1479 45.3884 39.0559C45.2919 38.9791 45.1605 38.9406 45.0178 38.9406C44.7245 38.9406 44.4929 39.0864 44.4042 39.3207C44.3771 39.3941 44.3657 39.4821 44.3657 39.5899V40.5811H43.7441Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.0932 40.3428C46.854 40.1469 46.7344 39.8781 46.7344 39.5322C46.7344 39.2056 46.8577 38.9366 47.1086 38.729C47.3596 38.5216 47.6801 38.4178 48.0774 38.4178C48.4675 38.4178 48.7801 38.51 49.0195 38.6947C49.2626 38.879 49.3823 39.1173 49.3823 39.4055C49.3823 39.4324 49.3823 39.4552 49.3783 39.4822C49.3783 39.5093 49.3746 39.5665 49.3707 39.6626H47.3712C47.4137 39.9586 47.7032 40.1317 48.1549 40.1317C48.4792 40.1317 48.7993 40.0279 48.9963 39.859L49.3282 40.2315C49.1005 40.4814 48.6334 40.6351 48.0968 40.6351C47.6684 40.6351 47.3365 40.5391 47.0932 40.3428ZM47.3906 39.2746H48.7262C48.6837 39.0327 48.4444 38.8829 48.0851 38.8829C47.7186 38.8829 47.4562 39.0286 47.3906 39.2746Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.168 40.5811V37.7069H51.7894V38.7216C51.994 38.5217 52.2874 38.4179 52.6269 38.4179C52.9858 38.4179 53.2754 38.5217 53.5031 38.7251C53.7307 38.9291 53.8428 39.1978 53.8428 39.5287C53.8428 39.8549 53.7307 40.1242 53.5031 40.3275C53.2754 40.5315 52.9858 40.6353 52.6269 40.6353C52.2757 40.6353 51.9748 40.5235 51.7738 40.3236V40.5811H51.168ZM53.2097 39.5287C53.2097 39.1634 52.9281 38.9215 52.5113 38.9215C52.0789 38.9215 51.7894 39.1634 51.7894 39.5287C51.7894 39.8935 52.0789 40.1318 52.5113 40.1318C52.9319 40.1318 53.2097 39.8935 53.2097 39.5287Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M54.6714 40.3352C54.4283 40.1353 54.3086 39.8666 54.3086 39.5284C54.3086 39.1864 54.4283 38.9174 54.6714 38.7175C54.9147 38.5177 55.2428 38.4179 55.6557 38.4179C56.0688 38.4179 56.3931 38.5177 56.6363 38.7175C56.8795 38.9174 57.003 39.1864 57.003 39.5284C57.003 39.8666 56.8795 40.1353 56.6363 40.3352C56.3931 40.5351 56.0688 40.635 55.6557 40.635C55.2428 40.635 54.9147 40.5351 54.6714 40.3352ZM56.37 39.5284C56.37 39.1557 56.0919 38.9174 55.6557 38.9174C55.2197 38.9174 54.9418 39.1557 54.9418 39.5284C54.9418 39.9009 55.2197 40.1353 55.6557 40.1353C56.0919 40.1353 56.37 39.9009 56.37 39.5284Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.9998 40.5811H59.1738L58.5755 39.8975L57.9732 40.5811H57.2012L58.1971 39.517L57.2204 38.4717H58.0348L58.6101 39.1131L59.1815 38.4717H59.9536L58.9885 39.4937L59.9998 40.5811Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.9714 34.976H30.312V30.2291C30.312 29.2295 29.8693 28.6914 29.0652 28.6294C28.564 28.5905 27.7697 28.7083 27.1933 28.837V34.976H25.5332V27.6613C25.5332 27.6613 27.5517 27.2135 29.3748 27.2149C30.1633 27.2157 30.7935 27.4947 31.2642 27.9647C31.7354 28.4346 31.9714 29.0893 31.9714 29.9287V34.976Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.9658 35.0265C59.693 35.0956 59.4096 35.1301 59.1165 35.1301C58.1812 35.1301 57.5632 34.923 57.2623 34.5088C57.022 34.1832 56.9023 33.5662 56.9023 32.6579V25.0453H58.5404V27.1979H59.9948V28.5299H58.5404V32.5543C58.5404 32.9888 58.5959 33.2754 58.7071 33.4137C58.8384 33.5908 59.1165 33.6796 59.5416 33.6796C59.6629 33.6796 59.8042 33.67 59.9658 33.6501V35.0265Z"
      fill="#424143"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.6815 30.0212C8.6815 30.0212 6.73834 30.769 4.34075 30.769C1.94343 30.769 0 30.0212 0 30.0212C0 30.0212 1.94343 29.2736 4.34075 29.2736C6.73834 29.2736 8.6815 30.0212 8.6815 30.0212Z"
      fill="#FFCB04"
    />,
    <mask id="mask0_1848_80334" x="9" y="12" width="6" height="8">
      <path d="M9.82812 12H14.6827V19.0235H9.82812V12Z" fill="white" />
    </mask>,
    <g mask="url(#mask0_1848_80334)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6827 19.0234C14.6827 19.0234 12.9609 17.8692 11.6201 15.9297C10.2798 13.9902 9.82812 11.9998 9.82812 11.9998C9.82812 11.9998 11.5498 13.1541 12.8907 15.0934C14.2312 17.0332 14.6827 19.0234 14.6827 19.0234Z"
        fill="#FFCB04"
      />
      ,
    </g>,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2881 23.769C10.2881 23.769 8.21266 23.5389 6.09592 22.4403C3.97919 21.3422 2.62305 19.7917 2.62305 19.7917C2.62305 19.7917 4.69852 20.0218 6.81525 21.1202C8.93199 22.2185 10.2881 23.769 10.2881 23.769Z"
      fill="#FFCB04"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4762 33.6218C15.7083 33.6101 17.1943 32.6444 17.1943 32.6444C17.1943 32.6444 13.7486 31.6063 13.9655 28.5299C14.645 23.4811 18.7515 19.5173 23.8923 18.8794C23.3471 18.8045 22.7903 18.7654 22.2234 18.7677C15.865 18.7958 10.7333 23.8494 10.7618 30.0548C10.7904 36.26 15.9685 41.2678 22.3271 41.24C22.8575 41.2377 23.3783 41.1973 23.8893 41.1272C19.3774 40.566 15.7051 37.4614 14.3878 33.3585"
      fill="#FFCB04"
    />,
    <mask id="mask1_1848_80334" maskUnits="userSpaceOnUse" x="9" y="40" width="6" height="8">
      <path d="M9.82812 40.976H14.6827V48H9.82812V40.976Z" fill="white" />
    </mask>,
    <g mask="url(#mask1_1848_80334)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6827 40.9761C14.6827 40.9761 12.9609 42.1305 11.6201 44.0702C10.2798 46.0094 9.82812 48.0001 9.82812 48.0001C9.82812 48.0001 11.5498 46.8457 12.8907 44.9062C14.2312 42.9667 14.6827 40.9761 14.6827 40.9761Z"
        fill="#FFCB04"
      />
    </g>,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2881 36.2308C10.2881 36.2308 8.21266 36.461 6.09592 37.5595C3.97919 38.6576 2.62305 40.2081 2.62305 40.2081C2.62305 40.2081 4.69852 39.9779 6.81525 38.8796C8.93199 37.7812 10.2881 36.2308 10.2881 36.2308Z"
      fill="#FFCB04"
    />,
    <mask id="mask2_1848_80334" maskUnits="userSpaceOnUse" x="0" y="12" width="60" height="36">
      <path d="M0 48H60V12H0V48Z" fill="white" />,
    </mask>,
    <g mask="url(#mask2_1848_80334)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.1992 34.9679H23.8915V25.0372H22.1992V34.9679Z"
        fill="#424143"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.1646 28.4309C39.1646 27.2269 38.5728 26.6252 37.3904 26.6252H35.3036V30.2074H37.3593C37.9258 30.2074 38.3684 30.0522 38.6868 29.7411C39.0051 29.4303 39.1646 28.9936 39.1646 28.4309ZM40.8747 28.4309C40.8747 29.4473 40.558 30.2641 39.9273 30.881C39.2947 31.4977 38.4375 31.8059 37.3554 31.8059H35.3036V34.944H33.6113V25.0114H37.2341C38.4069 25.0114 39.3073 25.3154 39.9343 25.9223C40.5609 26.5291 40.8747 27.3653 40.8747 28.4309Z"
        fill="#424143"
      />
      ,
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.9952 31.0401C46.9952 30.2922 46.8204 29.6997 46.4724 29.2618C46.1227 28.8243 45.6604 28.6049 45.0836 28.6049C44.5078 28.6049 44.0447 28.8267 43.6957 29.2691C43.3477 29.7121 43.1733 30.3019 43.1733 31.0401C43.1733 31.7875 43.3477 32.3802 43.6957 32.8181C44.0447 33.2562 44.5078 33.475 45.0836 33.475C45.6604 33.475 46.1227 33.2562 46.4724 32.8181C46.8204 32.3802 46.9952 31.7875 46.9952 31.0401ZM48.7088 31.0325C48.7088 32.2266 48.3728 33.189 47.7001 33.9191C47.027 34.6495 46.156 35.0142 45.0836 35.0142C44.0127 35.0142 43.1397 34.6495 42.4677 33.9191C41.7947 33.189 41.459 32.2266 41.459 31.0325C41.459 29.839 41.7947 28.8789 42.4677 28.1534C43.1397 27.4285 44.0127 27.0656 45.0836 27.0656C46.1657 27.0656 47.0405 27.4285 47.708 28.1534C48.375 28.8789 48.7088 29.839 48.7088 31.0325Z"
        fill="#424143"
      />
      ,
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M55.9181 32.409C55.9181 33.1592 55.6249 33.7757 55.0379 34.2596C54.4519 34.7434 53.6779 34.9842 52.7181 34.9842C51.2209 34.9842 50.0635 34.5404 49.2441 33.6521L50.5029 32.6015C51.1301 33.214 51.8633 33.5193 52.7023 33.5193C53.1681 33.5193 53.537 33.428 53.8102 33.2455C54.0831 33.0628 54.2196 32.8339 54.2196 32.5572C54.2196 32.2417 54.0626 32.0242 53.7494 31.906C53.5673 31.837 53.082 31.7433 52.2931 31.6245C51.3426 31.4867 50.6652 31.2793 50.26 31.0029C49.7849 30.6677 49.5481 30.1642 49.5481 29.4936C49.5481 28.8123 49.8358 28.2427 50.4121 27.7832C50.9883 27.3247 51.757 27.0949 52.7181 27.0949C53.8801 27.0949 54.8713 27.4753 55.6908 28.235L54.7046 29.2711C54.1081 28.7487 53.4304 28.4865 52.6719 28.4865C51.7011 28.4865 51.2163 28.7584 51.2163 29.301C51.2163 29.6164 51.4383 29.8338 51.8838 29.9524C52.5103 30.0504 53.1321 30.1543 53.7494 30.2626C55.1949 30.5293 55.9181 31.2447 55.9181 32.409Z"
        fill="#424143"
      />
      ,
    </g>,
  ],
});
