import { radioAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/system";

import { Checkbox } from "../Checkbox/checkboxStyles";

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);
const baseStyleControl = defineStyle({
  borderRadius: "full",
  borderColor: "primary.300",
  borderWidth: "1px",
  _checked: {
    _before: {
      content: `""`,
      w: "s",
      h: "s",
      borderRadius: "full",
      bg: "primary.300",
    },
    _disabled: {
      _before: {
        bg: "grayDark",
      },
    },
  },
  _hover: {
    borderColor: "primary.500",
    _before: {
      bg: "primary.500",
    },
  },
  _focus: {
    boxShadow: "outline",
    borderColor: "primary.500",
    zIndex: 1,
  },
  _disabled: {
    cursor: "not-allowed",
    borderColor: "grayDark",
  },
});

const baseStyle = definePartsStyle({
  label: Checkbox.baseStyle?.().label,
  container: {
    ...Checkbox.baseStyle?.().container,
    _disabled: { cursor: "default" },
  },
  control: baseStyleControl,
});

const sizes = {
  md: definePartsStyle({
    control: { w: "l", h: "l" },
    label: { fontSize: "md", _disabled: { cursor: "not-allowed" } },
  }),
};

export const Radio = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: "md",
  },
});
