import {
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
  ComponentWithAs,
  RadioProps as Props,
  RadioGroupProps,
  SystemProps,
} from "@chakra-ui/react";

export const RadioGroup = ChakraRadioGroup as ComponentWithAs<"div", RadioGroupProps>;

export interface RadioProps extends Props {
  /**
   * The label content of the radio
   */
  children?: Props["children"];

  /**
   * The spacing between the checkbox and its label text
   */
  spacing?: SystemProps["marginLeft"];

  /**
   *  Refers to the id of the element that labels the radio element.
   */
  "aria-describedby"?: string;

  /**
   * If true, the radio will be checked. You'll need to pass onChange to update its value (since it is now controlled)
   */
  isChecked?: boolean;

  /**
   * If true, the radio will be disabled
   */
  isDisabled?: boolean;

  /**
   *   If true and isDisabled is true, the radio will remain focusable but not interactive.
   */
  isFocusable?: boolean;

  /**
   * If true, the radio will be read-only
   */
  isReadOnly?: boolean;

  /**
   * If true, the radio button will be invalid. This also sets `aria-invalid` to true.
   */
  isInvalid?: boolean;

  /**
   * If true, the radio button will be required. This also sets `aria-required` to true.
   */
  isRequired?: boolean;

  /**
   * The value to be used in the radio button. This is the value that will be returned on form submission.
   */
  value?: string;

  /**
   * The name of the input field in a radio (Useful for form submission).
   */
  name?: string;
}

export const Radio = ChakraRadio as ComponentWithAs<"input", RadioProps>;
