import React from "react";

import { BodyText, Flex, FlexProps, Spinner } from "../../atoms";

type Props = FlexProps & {
  label: string;
};

export const SpinnerWithLabel = ({ label, ...props }: Props) => {
  return (
    <Flex alignItems="center" flexDir="column" position="absolute" left="50%" top="50%" {...props}>
      <Spinner />
      {label && <BodyText>{label}</BodyText>}
    </Flex>
  );
};
