import React from "react";

import { createIcon } from "@chakra-ui/react";

export const PayPal2CardLogo = createIcon({
  displayName: "PayPal2CardLogo",
  viewBox: "0 0 48 32",
  defaultProps: {
    width: 12,
    height: 8,
    fill: "transparent",
  },
  path: [
    <path
      d="M20.7068 24.39L21.0187 22.4085L20.3238 22.3924H17.0056L19.3116 7.77082C19.3188 7.72668 19.342 7.68552 19.376 7.65629C19.41 7.62707 19.4536 7.61096 19.4989 7.61096H25.0939C26.9514 7.61096 28.2332 7.99748 28.9025 8.76038C29.2162 9.11827 29.416 9.49227 29.5127 9.90384C29.6141 10.3357 29.6158 10.8516 29.5168 11.4809L29.5097 11.5269V11.9301L29.8234 12.1078C30.0877 12.248 30.2976 12.4085 30.4587 12.5922C30.7271 12.8982 30.9007 13.2871 30.974 13.7482C31.0498 14.2224 31.0247 14.7866 30.9007 15.4255C30.7575 16.1603 30.5261 16.8004 30.2135 17.3241C29.926 17.8066 29.5598 18.2069 29.1249 18.517C28.7098 18.8117 28.2165 19.0354 27.6588 19.1785C27.1184 19.3193 26.5022 19.3903 25.8264 19.3903H25.391C25.0796 19.3903 24.7772 19.5024 24.5398 19.7034C24.3018 19.9086 24.1443 20.189 24.096 20.4956L24.0632 20.6739L23.512 24.1663L23.487 24.2946C23.4804 24.3351 23.4691 24.3554 23.4524 24.3691C23.4375 24.3817 23.416 24.39 23.3951 24.39H20.7068Z"
      fill="#253B80"
    />,
    <path
      d="M30.1197 11.5732C30.103 11.68 30.0839 11.7891 30.0625 11.9012C29.3246 15.6895 26.8003 16.9982 23.5763 16.9982H21.9348C21.5405 16.9982 21.2083 17.2845 21.1468 17.6734L20.3064 23.0036L20.0684 24.5145C20.0284 24.7698 20.2253 25 20.483 25H23.3944C23.7392 25 24.032 24.7495 24.0863 24.4095L24.1149 24.2615L24.6631 20.7829L24.6983 20.592C24.752 20.2508 25.0454 20.0003 25.3902 20.0003H25.8256C28.6464 20.0003 30.8546 18.855 31.5 15.541C31.7696 14.1565 31.63 13.0006 30.9166 12.1876C30.7007 11.9424 30.4329 11.739 30.1197 11.5732Z"
      fill="#179BD7"
    />,
    <path
      d="M29.3478 11.2653C29.235 11.2325 29.1187 11.2027 28.9994 11.1759C28.8795 11.1496 28.7566 11.1263 28.6302 11.1061C28.1876 11.0345 27.7027 11.0005 27.1831 11.0005H22.7978C22.6898 11.0005 22.5872 11.0249 22.4954 11.0691C22.2932 11.1663 22.1429 11.3578 22.1065 11.5922L21.1736 17.501L21.1467 17.6733C21.2082 17.2844 21.5404 16.9981 21.9347 16.9981H23.5762C26.8002 16.9981 29.3245 15.6888 30.0623 11.9012C30.0844 11.789 30.1029 11.6799 30.1196 11.5731C29.9329 11.4741 29.7307 11.3894 29.513 11.3172C29.4593 11.2993 29.4038 11.282 29.3478 11.2653Z"
      fill="#222D65"
    />,
    <path
      d="M22.1068 11.5923C22.1432 11.3579 22.2935 11.1664 22.4957 11.0698C22.5881 11.0257 22.6901 11.0012 22.7981 11.0012H27.1834C27.703 11.0012 28.1879 11.0352 28.6305 11.1068C28.7569 11.1271 28.8798 11.1503 28.9997 11.1766C29.119 11.2034 29.2353 11.2332 29.3481 11.266C29.4041 11.2827 29.4596 11.3 29.5139 11.3173C29.7316 11.3895 29.9338 11.4748 30.1205 11.5732C30.34 10.1733 30.1187 9.22011 29.3618 8.357C28.5273 7.4068 27.0212 7 25.0939 7H19.4989C19.1053 7 18.7694 7.28631 18.7086 7.67581L16.3782 22.4477C16.3322 22.74 16.5577 23.0036 16.8524 23.0036H20.3066L21.1739 17.5011L22.1068 11.5923Z"
      fill="#253B80"
    />,
    <rect x="0.5" y="0.5" width="47" height="31" rx="3.5" stroke="#8D95A5" />,
  ],
});
