import React from "react";

import { HTMLChakraProps, SystemProps, ThemingProps, chakra, forwardRef, omitThemingProps } from "@chakra-ui/system";

interface ListOptions {
  /**
   * Shorthand prop for `listStylePosition`
   * @type SystemProps["listStylePosition"]
   */
  stylePosition?: SystemProps["listStylePosition"];
  /**
   * The space between each list item
   * @type SystemProps["margin"]
   */
  spacing?: SystemProps["margin"];
}

export interface ListProps extends HTMLChakraProps<"ul">, ThemingProps<"List">, ListOptions {}

/**
 * List is used to display list items, it renders a `<ul>` by default.
 *
 * @see Docs https://chakra-ui.com/list
 */
export const List = forwardRef<ListProps, "ul">(function List(props, ref) {
  const { children, stylePosition, spacing, ...rest } = omitThemingProps(props);

  return (
    <chakra.ul ref={ref} listStyleType="none" listStylePosition={stylePosition} role="list" {...rest}>
      {children}
    </chakra.ul>
  );
});
