import React from "react";

import { BodyText, BoxProps, Divider, Flex } from "../../atoms";

type Props = BoxProps & {
  label: string;
};

export const TextDivider = ({ label }: Props) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Divider flex={1} variant="dark" />
      <BodyText px="l">{label}</BodyText>
      <Divider flex={1} variant="dark" />
    </Flex>
  );
};
