import React, { useMemo } from "react";

import { BoxProps, chakra } from "@chakra-ui/react";

import { Box, Text } from "..";

type MenuItemProps = BoxProps & {
  onClick?: () => void;
  /**
   * If `true`, the menu item will be styled in its active state.
   * @default false
   */
  isActive?: boolean;
  /**
   * The content of the menu item
   */
  children: string;
};

export const SideMenuItemMobile: React.FC<MenuItemProps> = ({ children, isActive = false, onClick, ...props }) => {
  const styleProps = useMemo(
    () => ({
      button: { backgroundColor: isActive ? "grayLight" : "white", _hover: { backgroundColor: "grayMedium" } },
    }),
    [isActive],
  );

  return (
    <Box display="flex" {...props}>
      <chakra.button display="flex" flex={1} p="m" alignItems="center" onClick={onClick} {...styleProps.button}>
        <Text>{children}</Text>
      </chakra.button>
    </Box>
  );
};
