import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationEmailExpired2Illustration = createIcon({
  displayName: "ConfirmationEmailExpired2Illustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <path
      d="M138.15 15.45H11.8504C7.98988 15.45 4.86035 18.5795 4.86035 22.44V101.56C4.86035 105.42 7.98988 108.55 11.8504 108.55H138.15C142.011 108.55 145.14 105.42 145.14 101.56V22.44C145.14 18.5795 142.011 15.45 138.15 15.45Z"
      stroke="#8D95A5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M90.2605 108.55H59.7305V134.54H90.2605V108.55Z"
      stroke="#8D95A5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M39.8203 134.55H110.18" stroke="#8D95A5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M75.0003 92.84C92.3586 92.84 106.43 78.7683 106.43 61.41C106.43 44.0517 92.3586 29.98 75.0003 29.98C57.642 29.98 43.5703 44.0517 43.5703 61.41C43.5703 78.7683 57.642 92.84 75.0003 92.84Z"
      stroke="#E40000"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M84.6701 51.74L65.3301 71.09"
      stroke="#E40000"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M65.3301 51.74L84.6701 71.09"
      stroke="#E40000"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
