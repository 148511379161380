import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ProfileIcon = createIcon({
  displayName: "ProfileIcon",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 6,
      h: 6,
    },
  },
  path: [
    <path
      d="M20 21V19C20 16.8 18.2 15 16 15H8C5.8 15 4 16.8 4 19V21"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />,
    <path
      d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
