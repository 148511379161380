import { useEffect, useState } from "react";

import { LocaleKey } from "locales";
import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { ConsentName, useAcceptConsentMutation, useLazyGetLatestConsentQuery } from "store/api";
import { err } from "store/errors";
import { BodyText, Box, Button, Card, Stack } from "ui";
import { parseLocaleToApi } from "utils/helpers";

const consentRequiredForRegistration = ["platform_privacy_policy", "platform_regulations"];
const TermsUpdate: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location as { state: { consentsNamesToAccept: ConsentName[]; redirectTo: string } | undefined };
  const [acceptConsent, acceptConsentState] = useAcceptConsentMutation();
  const [getLatestConsent, getLatestConsentState] = useLazyGetLatestConsentQuery();
  const [consentNameToAccept, setConsentNameToAccept] = useState<ConsentName>();

  const language = parseLocaleToApi(i18n.resolvedLanguage as LocaleKey);
  const consentsNamesToAccept =
    state?.consentsNamesToAccept.filter(name => consentRequiredForRegistration.includes(name)) || [];

  const onAcceptConsent = async (consentName: ConsentName) => {
    try {
      setConsentNameToAccept(consentName);
      const consentDetails = await getLatestConsent({ language, name: consentName }).unwrap();
      if (consentDetails) {
        await acceptConsent({ id: consentDetails.consentId, language }).unwrap();
        navigate(Paths.TermsUpdate, {
          state: {
            redirectTo: state?.redirectTo,
            consentsNamesToAccept: consentsNamesToAccept.filter((name: ConsentName) => name !== consentName),
          },
        });
      }
    } catch (error) {
      err.handler(error);
    }
  };

  const onRead = (consentName: ConsentName) => {
    const path = generatePath(Paths.Terms, { name: consentName });
    window.open(path, "_blank")?.focus();
  };

  const onDeleteAccount = () => {
    navigate(Paths.SendDeleteAccountInstruction, { replace: true });
  };

  useEffect(() => {
    if (!state || !state.consentsNamesToAccept || consentsNamesToAccept.length === 0) {
      navigate(state?.redirectTo || Paths.Home, { replace: true });
    }
  }, [consentsNamesToAccept.length, navigate, state]);

  const isLoading = getLatestConsentState.isLoading || acceptConsentState.isLoading;

  return (
    <Card py={[0, "xl"]} px={[0, "l", "xl"]} flexDirection="column">
      {consentsNamesToAccept.map((consentName: ConsentName, index: number) => (
        <Box mt={index !== 0 ? 20 : 0} key={consentName}>
          <BodyText variant="header24" mb="m">
            {t(translationKeys[consentName].heading)}
          </BodyText>

          <BodyText mb={[0, "xl"]}>{t(translationKeys[consentName].description)}</BodyText>
          <Stack mt={["l", "xl"]} justifyContent="flex-end" direction={["column", "row"]} spacing={["l", "m"]}>
            <Button variant="secondary" onClick={() => onRead(consentName)}>
              {t("label_action_request_read_document")}
            </Button>
            <Button
              onClick={() => onAcceptConsent(consentName)}
              isLoading={consentNameToAccept === consentName && isLoading}
            >
              {t("label_action_request_accept")}
            </Button>
          </Stack>
        </Box>
      ))}
      <Box justifyContent={["center", "flex-start"]} mt="l">
        <Button variant="link" onClick={onDeleteAccount}>
          {t("label_action_request_delete_account")}
        </Button>
      </Box>
    </Card>
  );
};

export default TermsUpdate;

const translationKeys = {
  platform_regulations: {
    heading: "label_noun_updated_tos_for_subscription",
    description: "label_noun_accept_tos_for_subscription",
  },
  platform_privacy_policy: {
    heading: "label_noun_updated_privacy_policy_for_subscription",
    description: "label_noun_accept_privacy_policy_for_subscription",
  },
  store_policy: {
    heading: "label_noun_updated_tos_for_store",
    description: "label_noun_accept_tos_for_store",
  },
} as const;
