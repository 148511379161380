import React from "react";

import {
  FormControl as ChakraFormControl,
  FormControlProps as Props,
  chakra,
  useMultiStyleConfig,
} from "@chakra-ui/react";

export interface FormControlProps extends Props {
  /**
   * If `true`, the form control will be required. This has 2 side effects:
   * - The `FormLabel` will show a required indicator
   * - The form element (e.g, Input) will have `aria-required` set to `true`
   */
  isRequired?: boolean;
  /**
   * If `true`, the form control will be disabled. This has 2 side effects:
   * - The `FormLabel` will have `data-disabled` attribute
   * - The form element (e.g, Input) will be disabled
   */
  isDisabled?: boolean;
  /**
   * If `true`, the form control will be invalid. This has 2 side effects:
   * - The `FormLabel` and `FormErrorIcon` will have `data-invalid` set to `true`
   * - The form element (e.g, Input) will have `aria-invalid` set to `true`
   */
  isInvalid?: boolean;
  /**
   * If `true`, the form control will be readonly
   */
  isReadOnly?: boolean;
  /**
   * The label text used to inform users as to what information is
   * requested for a text field.
   */
  label?: string;
  /**
   * The custom `id` to use for the form control. This is passed directly to the form element (e.g, Input).
   * - The form element (e.g Input) gets the `id`
   * - The form label id: `form-label-${id}`
   * - The form error text id: `form-error-text-${id}`
   * - The form helper text id: `form-helper-text-${id}`
   */
  id?: string;
  variant?: string;
}

/**
 * FormControl provides context such as isInvalid, isDisabled, and isRequired to form elements.
 */
export const FormControl = ({ children, variant, ...restProps }: FormControlProps) => {
  const styles = useMultiStyleConfig("FormControl", { variant });

  return (
    <ChakraFormControl {...restProps}>
      <chakra.div __css={styles.container}>{children}</chakra.div>
    </ChakraFormControl>
  );
};
