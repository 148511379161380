import React from "react";

import { createIcon } from "@chakra-ui/react";

export const CbCardMediumLogo = createIcon({
  displayName: "CbCardMediumLogo",
  viewBox: "0 0 60 40",
  defaultProps: {
    width: "3.75rem",
    height: "2.5rem",
    fill: "transparent",
  },
  path: [
    <g clipPath="url(#clip0_3009_91974)">
      <path d="M60 0H12V32.8604H60V0Z" fill="#1BA559" />,
      <path d="M60 0H12V32.8604H60V0Z" fill="url(#paint0_linear_3009_91974)" />,
      <path d="M60 0H12V32.8604H60V0Z" fill="url(#paint1_linear_3009_91974)" />,
      <path
        d="M36.9491 16.2714C36.6206 16.2933 36.2738 16.3371 35.9269 16.3371H28.3626C28.1382 16.3076 27.9102 16.3212 27.6909 16.3773C27.6029 16.4239 27.5262 16.4892 27.4662 16.5687C27.4063 16.6482 27.3646 16.7399 27.344 16.8373C27.344 16.9286 27.574 17.1622 27.7091 17.1732C28.3115 17.2097 28.9175 17.1732 29.5345 17.1732H36.2591C36.4818 17.1732 36.7045 17.1951 36.9893 17.2133C37.0693 18.3714 36.8624 19.5313 36.3869 20.5903C35.0727 23.8759 32.4478 25.5042 29.0854 26.0664C26.8469 26.4608 24.5445 26.271 22.4009 25.5151C20.7401 24.9286 19.2973 23.85 18.2649 22.423C17.2324 20.996 16.6593 19.2882 16.6218 17.5273C16.5415 15.1981 16.9066 13.0113 18.3084 11.0874C19.7103 9.16343 21.7255 8.0682 24.0511 7.62646C26.6686 7.11536 29.2643 7.22853 31.7468 8.29455C34.9595 9.67818 36.6096 12.2154 37.0295 15.6216C37.0654 15.8438 37.0375 16.0717 36.9491 16.2787V16.2714Z"
        fill="white"
      />
      <path
        d="M44.8495 17.2133C46.6748 17.2133 48.5002 17.195 50.2964 17.2133C50.9998 17.2104 51.7018 17.2777 52.3919 17.4141C54.3377 17.8449 55.725 19.9075 55.4877 21.93C55.4022 22.9319 54.9485 23.8665 54.2143 24.5536C53.4801 25.2406 52.5174 25.6312 51.5121 25.6502C47.3283 25.6976 43.1446 25.7013 38.9608 25.7487C38.2526 25.7487 37.9313 25.599 37.9569 24.7813C38.0189 22.5397 38.0153 20.2945 37.9569 18.053C37.9569 17.3228 38.1942 17.1731 38.8622 17.1841C40.8519 17.2242 42.8452 17.1841 44.8349 17.1841L44.8495 17.2133Z"
        fill="white"
      />
      <path
        d="M38.0042 7.96231C38.3985 7.9331 38.7344 7.89294 39.041 7.89294C42.794 7.89294 46.5506 7.89295 50.3072 7.92215C51.0339 7.91174 51.7592 7.98648 52.4685 8.14485C53.4124 8.39705 54.2339 8.98096 54.7823 9.78957C55.3308 10.5982 55.5695 11.5773 55.4548 12.5476C55.1737 14.6979 53.56 16.2203 51.4609 16.2422C47.2808 16.2897 43.097 16.2933 38.9132 16.3481C38.1831 16.3481 37.9568 16.1582 37.975 15.4171C38.0261 13.2011 38.0006 10.9815 38.0042 8.76547V7.96231Z"
        fill="white"
      />
    </g>,
    <defs>
      <linearGradient id="paint0_linear_3009_91974" x1="36" y1="0" x2="36" y2="32.8567" gradientUnits="userSpaceOnUse">
        <stop stopColor="#098EC3" />,
        <stop offset="1" stopColor="#098EC3" stopOpacity="0" />,
      </linearGradient>
      <linearGradient
        id="paint1_linear_3009_91974"
        x1="12"
        y1="16.4284"
        x2="60"
        y2="16.4284"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#002A63" stopOpacity="0" />,
        <stop offset="1" stopColor="#002962" />,
      </linearGradient>
      <clipPath id="clip0_3009_91974">
        <rect width="48" height="32" fill="white" transform="translate(12)" />,
      </clipPath>
    </defs>,
  ],
});
