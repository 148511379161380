import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, TitleH1Os } from "ui";

type PendingSubscriptionPromptProps = {
  counter?: number;
};

export const PendingSubscriptionPrompt: React.FC<PendingSubscriptionPromptProps> = ({ counter }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToPairingDevice = () => navigate(Paths.PairDevice);

  if (!counter) {
    return null;
  }

  return (
    <Box w="full" borderWidth={2} borderColor="grayLight" borderRadius="2xl" px={[4, 12]} py={[6, 12]}>
      <TitleH1Os subtitle={t("label_action_request_register_your_device_to_activate_subscription")}>
        {t("label_action_info_subscription_waiting", { number: counter })}
      </TitleH1Os>
      <Button onClick={goToPairingDevice} w={["full", "auto"]}>
        {t("label_action_request_register_device").toUpperCase()}
      </Button>
    </Box>
  );
};
