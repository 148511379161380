import { OrderReq } from "store/api";
import { OrderPayload } from "store/state";

export const checkCreatePayload = (
  orderPayload: OrderPayload,
): orderPayload is Exclude<OrderReq, "storePolicyAttributes"> => {
  const { billingDetailId, salesProducts, shippingAddressId, shippingMethodId, useBillingAsShippingAddress } =
    orderPayload;

  if (
    billingDetailId &&
    salesProducts.length > 0 &&
    shippingMethodId &&
    (useBillingAsShippingAddress || shippingAddressId)
  ) {
    return true;
  }

  throw Error("error_order_create_invalid_data");
};

export const order = {
  checkCreatePayload,
};
