import React from "react";

import { BoxProps, Image, ImageProps, Skeleton } from "@chakra-ui/react";

import { BodyText, Box, Flex, FlexProps, Stack, TitleH1 } from "../../atoms";
import * as Illustrations from "../../atoms/Illustrations";
import { useBreakpoints } from "../../hooks";
import { CartFooter, OverflowMenu, OverflowMenuItem } from "../../molecules";
import { getChildrenByName } from "../../utils";

type ItemCartProps = React.FunctionComponent<
  BoxProps & {
    imageOnTop?: boolean;
    wrapperStyleProps?: BoxProps;
  }
> & {
  Image: React.FunctionComponent<ItemCartImageProps>;
  Description: React.FunctionComponent<ItemCartDescriptionProps>;
  Header: React.FunctionComponent<ItemCartHeaderProps>;
  Content: React.FunctionComponent<ItemCartContentProps>;
  Footer: React.FunctionComponent<ItemCartFooterProps>;
};

export const ItemCart: ItemCartProps = ({ children, ...styleProps }) => {
  const { isMobile } = useBreakpoints();

  const Image = getChildrenByName(children, "ItemCartImage");
  const Header = getChildrenByName(children, "ItemCartHeader");
  const Footer = getChildrenByName(children, "ItemCartFooter");
  const Description = getChildrenByName(children, "ItemCartDescription");
  const Content = getChildrenByName(children, "ItemCartContent");

  return (
    <Box w="full" borderWidth={2} borderColor="grayLight" borderRadius="l" {...styleProps}>
      <Box my={["l", "xxxl"]} mx={["m", "xxxl"]}>
        {isMobile && Header}
        <Flex flex={1} direction={["column", "row"]}>
          {Image}
          <Flex flex={1} direction="column">
            {!isMobile && Header}
            {Description}
          </Flex>
        </Flex>
      </Box>
      {Content}
      {Footer}
    </Box>
  );
};

type ItemCartImageProps = ImageProps & { illustrationName?: keyof typeof Illustrations; wrapperStyleProps?: BoxProps };

const ItemCartImage: React.FC<ItemCartImageProps> = ({
  src,
  color,
  illustrationName,
  wrapperStyleProps,
  ...styleProps
}) => {
  const IllustrationComponent = illustrationName ? Illustrations[illustrationName] : null;

  return (
    <Box
      textAlign={["center", "left"]}
      alignSelf={["center", "left"]}
      mr={[0, "xxxl"]}
      mb={["l", 0]}
      {...wrapperStyleProps}
    >
      {!src && !IllustrationComponent && <Skeleton mb={["l", 0]} w={180} h={180} borderRadius="md" />}
      {src && <Image w={150} h={150} fit="contain" src={src} {...styleProps} />}
      {IllustrationComponent && <IllustrationComponent w={150} h={150} color={color} />}
    </Box>
  );
};

ItemCartImage.displayName = "ItemCartImage";
ItemCart.Image = ItemCartImage;

type ItemCartHeaderProps = FlexProps & {
  title?: string;
  subtitle?: string;
  menuItems?: {
    label: string;
    onClick: () => void;
  }[];
  color?: "black" | "grayDark" | "violet.300" | "greenAlert" | "redAlert";
  extra?: React.ReactNode;
  spacing?: number;
};

const ItemCartHeader: React.FC<ItemCartHeaderProps> = ({ title, subtitle, menuItems, extra, color, spacing = 0 }) => {
  if (!title) {
    return <Skeleton w="full" mb={"l"} height={8} borderRadius="md" />;
  }

  return (
    <Flex direction="row" mb="l" w="full" justifyContent="space-between" alignItems="center">
      <Stack spacing={spacing}>
        <TitleH1 color={color}>{title}</TitleH1>
        {subtitle && <BodyText>{subtitle}</BodyText>}
      </Stack>
      {menuItems && (
        <Flex justifyContent="center" alignItems="center">
          <OverflowMenu>
            {menuItems.map(({ label, onClick }) => (
              <OverflowMenuItem key={label} onClick={onClick}>
                {label}
              </OverflowMenuItem>
            ))}
          </OverflowMenu>
        </Flex>
      )}
      {extra}
    </Flex>
  );
};

ItemCartHeader.displayName = "ItemCartHeader";
ItemCart.Header = ItemCartHeader;

type ItemCartFooterProps = {
  children?: React.ReactNode;
  action?: {
    label: string;
    onClick: () => void;
  };
};

const ItemCartFooter: React.FC<ItemCartFooterProps> = ({ children, action, ...styleProps }) => {
  return (
    <Box backgroundColor="grayLight" borderRadius={14} {...styleProps}>
      <CartFooter action={action ? { label: action?.label, onClick: action?.onClick } : undefined}>
        {children}
      </CartFooter>
    </Box>
  );
};

ItemCartFooter.displayName = "ItemCartFooter";
ItemCart.Footer = ItemCartFooter;

type ItemCartDescriptionProps = BoxProps;

const ItemCartDescription: React.FC<ItemCartDescriptionProps> = ({ children, ...styleProps }) => {
  if (!children) {
    return (
      <Stack spacing={1} pt="l">
        <Skeleton w="full" height={6} />
        <Skeleton w="full" height={6} />
        <Skeleton w="full" height={6} />
      </Stack>
    );
  }

  return (
    <Stack spacing={1} w="full" {...styleProps}>
      {children}
    </Stack>
  );
};

ItemCartDescription.displayName = "ItemCartDescription";
ItemCart.Description = ItemCartDescription;

type ItemCartContentProps = BoxProps & { hasContent?: boolean };

const ItemCartContent: React.FC<ItemCartContentProps> = ({ children, hasContent, ...styleProps }) => {
  return (
    <Box w="full" py={hasContent ? ["l", "xxxl"] : 0} px={["m", "xxxl"]} {...styleProps}>
      {children}
    </Box>
  );
};

ItemCartContent.displayName = "ItemCartContent";
ItemCart.Content = ItemCartContent;
