import { defineStyle, defineStyleConfig } from "@chakra-ui/system";

const baseStyle = defineStyle({
  marginEnd: 3,
  my: "xs",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: "bold",
  transitionProperty: "common",
  transitionDuration: "normal",
  span: {
    marginInlineStart: 0,
    color: "violet.300",
  },
});

export const FormLabel = defineStyleConfig({
  baseStyle,
});
