import React from "react";

import { useTranslation } from "react-i18next";
import {
  Device,
  Subscription,
  SubscriptionStatus,
  useActivateSubscriptionMutation,
  useConnectSubscriptionMutation,
} from "store/api";
import { err } from "store/errors";
import { Button, Dropdown, DropdownItem, Stack } from "ui";

import { DeviceListItem } from "../commons/DeviceListItem";

type DeviceListProps = {
  devices?: Device[];
  subscriptions?: Subscription[];
};

export const DevicesList: React.FC<DeviceListProps> = ({ devices, subscriptions }) => {
  const { t } = useTranslation();
  const [activateSubscription, activateSubscriptionState] = useActivateSubscriptionMutation();
  const [connectSubscription, connectSubscriptionState] = useConnectSubscriptionMutation();
  const subscriptionsNotAssigned = React.useMemo(
    () => subscriptions?.filter((sub: Subscription) => sub.status !== "inactive" && !sub.deviceId) || [],
    [subscriptions],
  );

  if (!devices) {
    return null;
  }

  return (
    <Stack spacing={4} mt={4}>
      {devices.map(device => {
        const subscription = subscriptions?.find(sub => device.subscriptionId === sub.id);

        const onActivate = async () => {
          try {
            if (subscription?.id) await activateSubscription({ id: subscription.id }).unwrap();
          } catch (error) {
            err.handler(error);
          }
        };

        const onConnect = async (subscriptionId: number) => {
          try {
            await connectSubscription({ id: device.id, requestBody: { subscription_id: subscriptionId } }).unwrap();
          } catch (error) {
            err.handler(error);
          }
        };

        const subscriptionCanBeActivated =
          subscription && [SubscriptionStatus.Expiring, SubscriptionStatus.Inactive].includes(subscription.status);

        return (
          <React.Fragment key={device.id}>
            <DeviceListItem device={device} subscription={subscription}>
              {subscriptionCanBeActivated && (
                <Button
                  onClick={onActivate}
                  isLoading={activateSubscriptionState.isLoading}
                  mt={[6, 7]}
                  w="fit-content"
                >
                  {t("label_action_request_activate")}
                </Button>
              )}
              {!subscription && subscriptionsNotAssigned.length > 0 && (
                <Dropdown label={t("label_action_request_assign_with")} isDisabled={connectSubscriptionState.isLoading}>
                  {subscriptionsNotAssigned.map(subscription => (
                    <DropdownItem
                      key={subscription.id}
                      onClick={() => onConnect(subscription.id)}
                      isDisabled={connectSubscriptionState.isLoading}
                    >
                      {t("label_noun_subscription_no", { number: subscription.uid })}
                    </DropdownItem>
                  ))}
                </Dropdown>
              )}
            </DeviceListItem>
          </React.Fragment>
        );
      })}
    </Stack>
  );
};
