import { ThemeConfig, ThemeDirection, extendTheme } from "@chakra-ui/react";

import foundations from "../foundations";
import * as components from "./components";
import styles from "./styles";

const direction: ThemeDirection = "ltr";

const config: ThemeConfig = {
  cssVarPrefix: "vasco",
};

export const theme = extendTheme({
  direction,
  ...foundations,
  components,
  styles,
  config,
});

export type Theme = typeof theme;

export default theme;
