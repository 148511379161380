import React from "react";

import { createIcon } from "@chakra-ui/react";

export const SiCardMediumLogo = createIcon({
  displayName: "SiCardMediumLogo",
  viewBox: "0 0 60 40",
  defaultProps: {
    width: "3.75rem",
    height: "2.5rem",
    fill: "transparent",
  },
  path: [
    <g clipPath="url(#clip0_3009_91963)">
      <path
        d="M39.87 9.59C38.81 9.54 38.33 9.91 38.33 11.35V14.11C38.33 18.42 28.6 17.04 26.53 14.48C26.13 14 25.53 14.17 25.78 14.75C27.52 18.58 33.28 19.22 35.67 19.22C39.04 19.22 45.67 17.95 45.67 14.22C45.67 10.49 41.18 9.66 39.87 9.59Z"
        fill="white"
      />
      <path
        d="M33.24 2.22001C33.24 3.14001 34.45 3.89001 35.95 3.89001C37.45 3.89001 38.66 3.14001 38.66 2.22001C38.66 1.30001 37.45 0.540009 36 0.540009C34.45 0.540009 33.24 1.29001 33.24 2.22001Z"
        fill="white"
      />
      <path
        d="M36.09 5.70999C32.72 5.70999 26.09 6.96999 26.09 10.71C26.09 14.45 30.58 15.28 31.89 15.34C32.95 15.39 33.43 15.02 33.43 13.59V10.81C33.43 6.49999 43.16 7.88999 45.23 10.44C45.62 10.92 46.23 10.76 45.98 10.17C44.23 6.33999 38.48 5.70999 36.09 5.70999Z"
        fill="white"
      />
      <path
        d="M20 21.72C20.7097 21.7005 21.4145 21.8442 22.06 22.14C22.1021 22.1545 22.1479 22.1545 22.19 22.14C22.2095 22.1297 22.2267 22.1156 22.2404 22.0983C22.2542 22.0811 22.2643 22.0613 22.27 22.04L22.62 20.93C22.6306 20.8954 22.6284 20.8581 22.6137 20.825C22.599 20.7919 22.5728 20.7653 22.54 20.75C21.739 20.4057 20.8711 20.2451 20 20.28C19.2375 20.2329 18.4737 20.3488 17.7595 20.6199C17.0452 20.891 16.3969 21.3111 15.8577 21.8522C15.3184 22.3933 14.9006 23.0431 14.632 23.7583C14.3635 24.4735 14.2503 25.2377 14.3 26C14.2052 27.2098 14.5492 28.4132 15.269 29.3902C15.9889 30.3671 17.0364 31.0522 18.22 31.32C18.6978 31.4376 19.1879 31.498 19.68 31.5C20.2911 31.5003 20.9008 31.44 21.5 31.32C21.8632 31.2523 22.2161 31.1381 22.55 30.98C22.5807 30.965 22.6054 30.94 22.6199 30.9091C22.6344 30.8782 22.638 30.8432 22.63 30.81L22.36 29.72C22.3556 29.6982 22.346 29.6777 22.3321 29.6603C22.3182 29.6429 22.3003 29.6291 22.28 29.62C22.2597 29.6102 22.2375 29.6052 22.215 29.6052C22.1925 29.6052 22.1703 29.6102 22.15 29.62C21.4664 29.8907 20.735 30.0199 20 30C17.55 30 16.09 28.47 16.09 25.91C16.09 23.35 17.58 21.72 20 21.72Z"
        fill="white"
      />
      <path
        d="M29.65 29.32V26.49C29.65 25.02 29.12 23.26 26.65 23.26C25.7385 23.2526 24.8413 23.4872 24.05 23.94C24.0204 23.9583 23.9975 23.9858 23.9848 24.0182C23.9722 24.0507 23.9705 24.0865 23.98 24.12L24.3 25.04C24.3062 25.0625 24.3176 25.0833 24.3332 25.1007C24.3488 25.118 24.3683 25.1315 24.39 25.14C24.4355 25.1552 24.4846 25.1552 24.53 25.14C25.1015 24.78 25.7647 24.5925 26.44 24.6C26.6515 24.5721 26.8666 24.5932 27.0686 24.6617C27.2706 24.7302 27.4541 24.8443 27.6049 24.9952C27.7557 25.146 27.8698 25.3295 27.9383 25.5315C28.0069 25.7335 28.028 25.9485 28 26.16C25 26.16 23.42 27.23 23.42 29.16C23.419 29.6251 23.5603 30.0793 23.8248 30.4618C24.0894 30.8444 24.4645 31.1368 24.9 31.3C25.2196 31.4207 25.5585 31.4817 25.9 31.48C26.2408 31.4731 26.5783 31.4124 26.9 31.3C27.3687 31.1517 27.7919 30.8868 28.13 30.53L28.21 31.17C28.2142 31.203 28.2293 31.2337 28.2528 31.2572C28.2763 31.2808 28.307 31.2958 28.34 31.3H29.62C29.6564 31.2976 29.6907 31.2821 29.7164 31.2564C29.7422 31.2306 29.7577 31.1964 29.76 31.16C29.761 31.1433 29.761 31.1267 29.76 31.11C29.6797 30.5168 29.643 29.9185 29.65 29.32ZM28 28.59C28.0177 28.7261 28.0177 28.8639 28 29C27.8688 29.3497 27.6314 29.6495 27.3211 29.8575C27.0109 30.0656 26.6434 30.1713 26.27 30.16C26.1119 30.1808 25.9511 30.1652 25.7999 30.1146C25.6487 30.064 25.5109 29.9795 25.3972 29.8677C25.2835 29.7559 25.1967 29.6197 25.1435 29.4693C25.0903 29.319 25.072 29.1585 25.09 29C25.09 28 26.09 27.44 27.94 27.44H28V28.59Z"
        fill="white"
      />
      <path
        d="M35.08 23.3C34.96 23.3 34.85 23.3 34.71 23.3C34.2953 23.3156 33.8921 23.4403 33.5409 23.6615C33.1898 23.8828 32.9032 24.1927 32.71 24.56V23.56C32.7075 23.522 32.6906 23.4863 32.6627 23.4603C32.6349 23.4343 32.5982 23.4199 32.56 23.42H31.24C31.2228 23.4119 31.2041 23.4078 31.185 23.4078C31.166 23.4078 31.1473 23.4119 31.13 23.42C31.1213 23.437 31.1167 23.4559 31.1167 23.475C31.1167 23.4941 31.1213 23.513 31.13 23.53C31.13 24.17 31.19 24.9 31.19 25.88V31.09C31.1921 31.1248 31.2061 31.1578 31.2299 31.1834C31.2536 31.2089 31.2855 31.2254 31.32 31.23H32.7C32.7364 31.2276 32.7707 31.2121 32.7964 31.1864C32.8222 31.1606 32.8377 31.1263 32.84 31.09V27.09C32.8234 26.887 32.8234 26.683 32.84 26.48C32.8743 26.0454 33.0678 25.6387 33.3833 25.338C33.6989 25.0372 34.1143 24.8634 34.55 24.85C34.6962 24.8333 34.8439 24.8333 34.99 24.85C35.0105 24.8591 35.0327 24.8638 35.055 24.8638C35.0774 24.8638 35.0996 24.8591 35.12 24.85C35.152 24.8182 35.1699 24.775 35.17 24.73V23.43C35.1714 23.4014 35.1634 23.3732 35.1471 23.3497C35.1308 23.3262 35.1073 23.3087 35.08 23.3Z"
        fill="white"
      />
      <path
        d="M40.3 23.42H38.47V21.76C38.4719 21.7364 38.4673 21.7128 38.4567 21.6917C38.4461 21.6705 38.43 21.6526 38.41 21.64C38.3896 21.6309 38.3674 21.6262 38.345 21.6262C38.3226 21.6262 38.3005 21.6309 38.28 21.64L36.92 22C36.891 22.0102 36.8659 22.0292 36.848 22.0542C36.8301 22.0793 36.8203 22.1092 36.82 22.14V23.4H35.82C35.7793 23.3999 35.74 23.4154 35.7103 23.4433C35.6806 23.4711 35.6626 23.5093 35.66 23.55V24.6C35.6626 24.6407 35.6806 24.6788 35.7103 24.7067C35.74 24.7346 35.7793 24.7501 35.82 24.75H36.82V28.75C36.7431 29.4933 36.9464 30.2386 37.39 30.84C37.5762 31.0344 37.8011 31.1877 38.05 31.29C38.3512 31.4138 38.6744 31.4751 39 31.47C39.4136 31.4734 39.8251 31.4127 40.22 31.29H40.28C40.3107 31.2792 40.337 31.2587 40.3551 31.2317C40.3731 31.2046 40.3819 31.1725 40.38 31.14L40.31 30.09C40.3093 30.0683 40.3034 30.047 40.293 30.0279C40.2826 30.0088 40.2679 29.9924 40.25 29.98C40.2298 29.9702 40.2075 29.9652 40.185 29.9652C40.1625 29.9652 40.1403 29.9702 40.12 29.98C39.8729 30.049 39.6165 30.0793 39.36 30.07C38.73 30.07 38.47 29.68 38.47 28.75V24.77H40.3C40.3398 24.77 40.378 24.7542 40.4061 24.7261C40.4342 24.6979 40.45 24.6598 40.45 24.62V23.57C40.45 23.5302 40.4342 23.4921 40.4061 23.4639C40.378 23.4358 40.3398 23.42 40.3 23.42Z"
        fill="white"
      />
      <path
        d="M47.51 29.32V26.49C47.51 25.02 46.98 23.26 44.51 23.26C43.6018 23.2539 42.7081 23.4885 41.92 23.94C41.8903 23.9583 41.8674 23.9858 41.8548 24.0183C41.8422 24.0507 41.8405 24.0865 41.85 24.12L42.17 25.04C42.1762 25.0625 42.1876 25.0833 42.2032 25.1007C42.2188 25.118 42.2383 25.1315 42.26 25.14C42.3019 25.1562 42.3482 25.1562 42.39 25.14C42.9657 24.7813 43.6317 24.5939 44.31 24.6C44.5226 24.5731 44.7386 24.5957 44.941 24.666C45.1434 24.7364 45.3268 24.8526 45.4769 25.0056C45.627 25.1586 45.7396 25.3442 45.8061 25.5479C45.8726 25.7516 45.891 25.968 45.86 26.18C42.86 26.18 41.28 27.25 41.28 29.18C41.279 29.6451 41.4203 30.0993 41.6848 30.4818C41.9493 30.8644 42.3245 31.1568 42.76 31.32C43.0795 31.4407 43.4185 31.5017 43.76 31.5C44.1008 31.4931 44.4383 31.4324 44.76 31.32C45.2365 31.1619 45.6638 30.8828 46 30.51L46.08 31.15C46.0842 31.183 46.0992 31.2137 46.1228 31.2373C46.1463 31.2608 46.177 31.2758 46.21 31.28H47.49C47.5246 31.2754 47.5565 31.2589 47.5802 31.2334C47.6039 31.2078 47.618 31.1748 47.62 31.14V31.09C47.5407 30.5034 47.5039 29.9119 47.51 29.32ZM45.87 28.59C45.886 28.7195 45.886 28.8505 45.87 28.98C45.7387 29.3297 45.5014 29.6295 45.1911 29.8375C44.8809 30.0456 44.5134 30.1513 44.14 30.14C43.9869 30.1538 43.8325 30.1338 43.688 30.0814C43.5434 30.029 43.4121 29.9454 43.3033 29.8367C43.1946 29.728 43.1111 29.5967 43.0587 29.4521C43.0062 29.3075 42.9862 29.1532 43 29C43 28 44 27.44 45.85 27.44H45.92L45.87 28.59Z"
        fill="white"
      />
      <path
        d="M52.39 25C50.86 24.42 50.39 24 50.39 23.11C50.39 22.22 50.96 21.68 52.24 21.68C52.9115 21.6652 53.5746 21.831 54.16 22.16C54.1803 22.1698 54.2025 22.1748 54.225 22.1748C54.2475 22.1748 54.2698 22.1698 54.29 22.16C54.3127 22.1531 54.3331 22.1402 54.349 22.1226C54.3648 22.1049 54.3756 22.0833 54.38 22.06L54.74 21C54.7514 20.9666 54.7507 20.9302 54.7379 20.8972C54.7251 20.8643 54.701 20.837 54.67 20.82C53.9296 20.438 53.1025 20.2554 52.27 20.29C50.16 20.29 48.69 21.53 48.69 23.29C48.69 24.7 49.69 25.73 51.59 26.43C52.97 26.95 53.49 27.43 53.49 28.37C53.49 29.31 52.7 30 51.42 30C50.5866 29.999 49.7697 29.7671 49.06 29.33C49.0398 29.3203 49.0175 29.3152 48.995 29.3152C48.9725 29.3152 48.9503 29.3203 48.93 29.33C48.9081 29.3351 48.888 29.3462 48.8721 29.3621C48.8562 29.378 48.8451 29.3981 48.84 29.42L48.47 30.56C48.461 30.5926 48.4619 30.6271 48.4726 30.6592C48.4833 30.6912 48.5033 30.7194 48.53 30.74C48.9591 30.9887 49.4249 31.1676 49.91 31.27C50.3717 31.3833 50.8447 31.4437 51.32 31.45C51.7857 31.4473 52.2493 31.3868 52.7 31.27C53.3946 31.1316 54.02 30.7573 54.4701 30.2105C54.9203 29.6638 55.1675 28.9782 55.17 28.27C55.17 26.74 54.34 25.77 52.39 25Z"
        fill="white"
      />
      <path
        d="M57.55 23.42H56.16C56.1202 23.42 56.0821 23.4358 56.0539 23.4639C56.0258 23.4921 56.01 23.5302 56.01 23.57V31.14C56.012 31.1748 56.0261 31.2078 56.0498 31.2334C56.0736 31.2589 56.1054 31.2754 56.14 31.28H57.56C57.5964 31.2777 57.6306 31.2622 57.6564 31.2364C57.6822 31.2106 57.6977 31.1764 57.7 31.14V23.57C57.7 23.5302 57.6842 23.4921 57.6561 23.4639C57.6279 23.4358 57.5898 23.42 57.55 23.42Z"
        fill="white"
      />
      <path
        d="M57.55 20.28H56.16C56.0772 20.28 56.01 20.3472 56.01 20.43V21.78C56.01 21.8628 56.0772 21.93 56.16 21.93H57.55C57.6329 21.93 57.7 21.8628 57.7 21.78V20.43C57.7 20.3472 57.6329 20.28 57.55 20.28Z"
        fill="white"
      />
    </g>,
    <defs>
      <clipPath id="clip0_3009_91963">
        <rect width="48" height="32" fill="white" transform="translate(12)" />
      </clipPath>
    </defs>,
  ],
});
