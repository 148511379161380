import { useEffect, useMemo, useRef, useState } from "react";

import { err } from "store/errors";

import { useUser } from "./useUser";

type MollieComponentStyles = {
  backgroundColor?: string;
  color?: string;
  fontSize?: number;
  fontWeight?: string | number;
  letterSpacing?: number;
  lineHeight?: number;
  textAlign?: string;
  textDecoration?: string;
  textTransform?: string;
};

type MollieComponentType = "cardHolder" | "cardNumber" | "verificationCode" | "expiryDate";
type MollieComponentOptions = {
  styles: {
    base?: MollieComponentStyles & {
      "::placeholder"?: MollieComponentStyles;
    };
    valid?: MollieComponentStyles & {
      "::placeholder"?: MollieComponentStyles;
    };
    invalid?: MollieComponentStyles & {
      "::placeholder"?: MollieComponentStyles;
    };
  };
};

type MollieInputState = {
  dirty: boolean;
  error: string;
  touched: boolean;
  valid: boolean;
  focus: boolean;
};

type MollieComponentListenerCallback = (args: MollieInputState) => void;

type MollieComponentListener = (event: "blur" | "focus" | "change", callback: MollieComponentListenerCallback) => void;

type MollieComponentInstant = {
  addEventListener: MollieComponentListener;
  removeEventListener: MollieComponentListener;
  mount: (targetElement: string) => void;
  unmount: () => void;
};

type MollieInstant = {
  createComponent: (type: MollieComponentType, options?: MollieComponentOptions) => MollieComponentInstant;
  createToken: () => Promise<{ token: string; error: Error }>;
};

type MollieOptions = {
  locale: string;
  testmode: boolean;
};

type Mollie = (idProfile: string, options: MollieOptions) => MollieInstant;

type UseMollieState = {
  cardNumber: MollieInputState;
  cardHolder: MollieInputState;
  expiryDate: MollieInputState;
  verificationCode: MollieInputState;
};

const initState = {
  dirty: false,
  error: "",
  touched: false,
  valid: false,
  focus: false,
};

type UseMollieProps = {
  onSubmit: (token: string) => void;
  onError: (error: Error) => void;
};

const allowedMollieLocales = [
  "en_US",
  "nl_NL",
  "nl_BE",
  "fr_FR",
  "fr_BE",
  "de_DE",
  "de_AT",
  "de_CH",
  "es_ES",
  "ca_ES",
  "pt_PT",
  "it_IT",
  "nb_NO",
  "sv_SE",
  "fi_FI",
  "da_DK",
  "is_IS",
  "hu_HU",
  "pl_PL",
  "lv_LV",
  "lt_LT",
] as const;

const getMollieLocale = (locale: string) => {
  const mollieLocale = locale.replace("-", "_");
  if (allowedMollieLocales.includes(mollieLocale as any)) {
    return mollieLocale;
  } else {
    const countryCode = mollieLocale.split("_")[0];
    const alternativeLocale = allowedMollieLocales.find(item => item.split("_")[0] === countryCode);
    return alternativeLocale || allowedMollieLocales[0];
  }
};

export const useMollie = ({ onSubmit, onError }: UseMollieProps) => {
  const [mollie, setMollie] = useState<MollieInstant>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState<UseMollieState>({
    cardNumber: initState,
    cardHolder: initState,
    expiryDate: initState,
    verificationCode: initState,
  });
  const user = useUser();

  useEffect(() => {
    let mollieScript: HTMLScriptElement | null = null;
    let molliePromise: Promise<MollieInstant> | null = null;

    const initMollie = async () => {
      if (!molliePromise) {
        setIsLoading(true);
        molliePromise = new Promise(resolve => {
          mollieScript = document.createElement("script");
          mollieScript.src = "https://js.mollie.com/v1/mollie.js";
          document.body.appendChild(mollieScript);

          mollieScript.addEventListener("load", () => {
            const Mollie = (window as any).Mollie as Mollie;
            const MollieInstant = Mollie(process.env.REACT_APP_MOLLIE_SECRET || "", {
              locale: getMollieLocale(user.locale),
              testmode: process.env.REACT_APP_ENV !== "production",
            });
            resolve(MollieInstant);
          });
        });
        const Mollie = await molliePromise;
        setMollie(Mollie);
        setIsLoading(false);
      }
    };

    initMollie();

    return () => {
      if (mollieScript) {
        mollieScript.remove();
      }

      const mollieIframe = document.getElementsByClassName("mollie-components-controller")[0];
      if (mollieIframe) {
        mollieIframe.remove();
      }
    };
  }, [user.locale]);

  const formRef = useRef<HTMLFormElement>(null);

  const cardNumberRef = useRef<HTMLInputElement>(null);
  const cardHolderRef = useRef<HTMLInputElement>(null);
  const expiryDateRef = useRef<HTMLInputElement>(null);
  const verificationCodeRef = useRef<HTMLInputElement>(null);

  const refs = useMemo(
    () => ({
      cardNumber: cardNumberRef,
      cardHolder: cardHolderRef,
      expiryDate: expiryDateRef,
      verificationCode: verificationCodeRef,
    }),
    [],
  );

  const register = (inputType: MollieComponentType) => {
    return { ref: refs[inputType], id: inputType };
  };

  const registerForm = () => {
    return { ref: formRef };
  };

  useEffect(() => {
    if (mollie) {
      let cardNumber: MollieComponentInstant | null = null;
      let cardHolder: MollieComponentInstant | null = null;
      let expiryDate: MollieComponentInstant | null = null;
      let verificationCode: MollieComponentInstant | null = null;

      const onBlurCardNumber = (e: MollieInputState) =>
        setState(prev => ({ ...prev, cardNumber: { ...prev.cardNumber, ...e, focus: false } }));
      const onBlurCardHolder = (e: MollieInputState) =>
        setState(prev => ({ ...prev, cardHolder: { ...prev.cardHolder, ...e, focus: false } }));
      const onBlurExpiryDate = (e: MollieInputState) =>
        setState(prev => ({ ...prev, expiryDate: { ...prev.expiryDate, ...e, focus: false } }));
      const onBlurVerificationCode = (e: MollieInputState) =>
        setState(prev => ({ ...prev, verificationCode: { ...prev.verificationCode, ...e, focus: false } }));

      const onFocus = (componentType: MollieComponentType) => {
        setState(prev => ({ ...prev, [componentType]: { ...prev[componentType], focus: true } }));
      };

      const onFocusCardNumber = () => onFocus("cardNumber");
      const onFocusCardHolder = () => onFocus("cardHolder");
      const onFocusExpiryDate = () => onFocus("expiryDate");
      const onFocusVerificationCode = () => onFocus("verificationCode");

      const options: MollieComponentOptions = {
        styles: {
          base: {
            color: "#333",
            "::placeholder": {
              color: "transparent",
            },
          },
        },
      };

      if (cardNumberRef.current) {
        cardNumber = mollie.createComponent("cardNumber", options);
        cardNumber.mount("#cardNumber");
        cardNumber.addEventListener("focus", onFocusCardNumber);
        cardNumber.addEventListener("blur", onBlurCardNumber);
      }

      if (cardHolderRef.current) {
        cardHolder = mollie.createComponent("cardHolder", options);
        cardHolder.mount("#cardHolder");
        cardHolder.addEventListener("focus", onFocusCardHolder);
        cardHolder.addEventListener("blur", onBlurCardHolder);
      }

      if (expiryDateRef.current) {
        expiryDate = mollie.createComponent("expiryDate", options);
        expiryDate.mount("#expiryDate");
        expiryDate.addEventListener("focus", onFocusExpiryDate);
        expiryDate.addEventListener("blur", onBlurExpiryDate);
      }

      if (verificationCodeRef.current) {
        verificationCode = mollie.createComponent("verificationCode", options);
        verificationCode.mount("#verificationCode");
        verificationCode.addEventListener("focus", onFocusVerificationCode);
        verificationCode.addEventListener("blur", onBlurVerificationCode);
      }

      return () => {
        if (cardNumber) {
          cardNumber.removeEventListener("focus", onFocusCardNumber);
          cardNumber.removeEventListener("blur", onBlurCardNumber);
          cardNumber.unmount();
        }
        if (cardHolder) {
          cardHolder.removeEventListener("focus", onFocusCardHolder);
          cardHolder.removeEventListener("blur", onBlurCardHolder);
          cardHolder.unmount();
        }
        if (expiryDate) {
          expiryDate.removeEventListener("focus", onFocusExpiryDate);
          expiryDate.removeEventListener("blur", onBlurExpiryDate);
          expiryDate.unmount();
        }
        if (verificationCode) {
          verificationCode.removeEventListener("focus", onFocusVerificationCode);
          verificationCode.removeEventListener("blur", onBlurVerificationCode);
          verificationCode.unmount();
        }
      };
    }
  }, [mollie]);

  useEffect(() => {
    const formRefCurrent = formRef.current;
    const onSubmitForm = async (event: SubmitEvent) => {
      try {
        setIsSubmitting(true);
        if (mollie) {
          event.preventDefault();
          const { token, error } = await mollie.createToken();
          if (!error) {
            await onSubmit(token);
          } else {
            onError(error);
          }
        }
      } catch (error) {
        err.handler(error);
      } finally {
        setIsSubmitting(false);
      }
    };

    if (formRefCurrent) {
      formRefCurrent.addEventListener("submit", onSubmitForm);
    }

    return () => {
      if (formRefCurrent) {
        formRefCurrent.removeEventListener("submit", onSubmitForm);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mollie]);

  return { state, isLoading, isSubmitting, register, registerForm };
};
