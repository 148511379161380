import React, { useMemo } from "react";

import { Collapse, useBreakpointValue } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "hooks";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BillingAddress, COUNTRY_OPTIONS } from "store/api";
import { selectMarket } from "store/state";
import {
  Asterisk,
  BodyText,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from "ui";
import { utils } from "utils";
import { PHONE_NUMBER_REGEX, VALIDATE_NAME_AND_SURNAME_REGEX, VALIDATE_PHONE_NUMBER_REGEX } from "utils/addresses";
import { removeWhiteSpace } from "utils/helpers";
import * as yup from "yup";

type Props = {
  onSubmit: (formState: BillingDetailsFormState) => void;
  onCancel?: () => void;
  isSubmitting?: boolean;
  defaultValues: BillingDetailsFormState | undefined;
  buttonLabel?: string;
};

export type BillingDetailsFormState = Omit<BillingAddress, "id">;

export const BillingAddressForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  isSubmitting,
  defaultValues,
  buttonLabel,
}) => {
  const { t, i18n } = useTranslation();
  const market = useAppSelector(selectMarket);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    trigger,
    control,
    getValues,
    formState: { errors },
  } = useForm<BillingDetailsFormState>({
    mode: "onTouched",
    resolver: yupResolver(
      yup
        .object({
          accountType: yup.string().required(t("prompt_error_required_field")),
          companyName: yup
            .string()
            .nullable()
            .max(255, t("prompt_error_length_max", { value: 255 }))
            .when("accountType", {
              is: "company",
              then: yup.string().required(t("prompt_error_required_field")).nullable(),
            }),
          vatIdentifier: yup
            .string()
            .nullable()
            .when("accountType", {
              is: "company",
              then: yup.string().required(t("prompt_error_required_field")).nullable(),
            }),
          firstname: yup
            .string()
            .max(255, t("prompt_error_length_max", { value: 255 }))
            .required(t("prompt_error_required_field"))
            .matches(VALIDATE_NAME_AND_SURNAME_REGEX, t("prompt_error_special_characters")),
          lastname: yup
            .string()
            .max(255, t("prompt_error_length_max", { value: 255 }))
            .required(t("prompt_error_required_field"))
            .matches(VALIDATE_NAME_AND_SURNAME_REGEX, t("prompt_error_special_characters")),
          primaryAddressLine: yup
            .string()
            .max(128, t("prompt_error_length_max", { value: 128 }))
            .required(t("prompt_error_required_field"))
            .matches(/^[^!<>?=+@{}_$%]*$/, t("prompt_error_wrong_format")),
          optionalAddressLine: yup
            .string()
            .nullable()
            .max(128, t("prompt_error_length_max", { value: 128 }))
            .matches(/^[^!<>?=+@{}_$%]*$/, t("prompt_error_wrong_format")),
          city: yup
            .string()
            .max(64, t("prompt_error_length_max", { value: 64 }))
            .required(t("prompt_error_required_field"))
            .matches(/^[^!<>;?=+@#"°{}_$%]*$/, t("prompt_error_wrong_format")),
          zipCode: yup
            .string()
            .max(12, t("prompt_error_length_max", { value: 12 }))
            .required(t("prompt_error_required_field"))
            .matches(/^[a-zA-Z 0-9-]+$/, t("prompt_error_wrong_format"))
            .test("zipCode", t("prompt_sorry_subscriptions_arent_avalaible"), val =>
              utils.addresses.checkIsTenerifePostalCode(getValues().country, val),
            ),
          country: yup.string().required(t("prompt_error_required_field")),
          dialCode: yup
            .string()
            .nullable()
            .test(
              "dialCode",
              t("prompt_error_wrong_prefix"),
              (val, context) =>
                utils.addresses.getAllDialCodes().some(dialCode => dialCode === val) || !context.parent?.phone,
            ),
          phone: yup
            .string()
            .nullable()
            .test("phone", t("prompt_error_length_min", { value: 4 }), val =>
              val && removeWhiteSpace(val).length < 4 && removeWhiteSpace(val).length > 0 ? false : true,
            )
            .test("phone", t("prompt_error_length_max", { value: 13 }), val =>
              val && removeWhiteSpace(val).length > 13 ? false : true,
            )
            .matches(VALIDATE_PHONE_NUMBER_REGEX, {
              excludeEmptyString: true,
              message: t("prompt_error_wrong_format"),
            }),
        })
        .required(),
    ),
    defaultValues: {
      ...defaultValues,
      dialCode:
        defaultValues?.dialCode ??
        (market?.name ? utils.addresses.DIAL_CODES.find(dialCode => dialCode.marketName === market?.name)?.code : "+"),
    },
  });

  const countries = useMemo(
    () =>
      (Object.keys(COUNTRY_OPTIONS) as unknown as Array<keyof typeof COUNTRY_OPTIONS>)
        .map(code => ({ code, name: t(COUNTRY_OPTIONS[code]) }))
        .sort((countryA, countryB) => countryA.name.localeCompare(countryB.name)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.resolvedLanguage, t],
  );

  const variant = useBreakpointValue({ sm: "vertical", md: "horizontal" });
  const accountType = watch("accountType");

  const onChangeDialCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    let dialCode = e.target.value;

    if (!dialCode.startsWith("+")) {
      dialCode = `+${dialCode}`.substring(dialCode.indexOf("+") + 1);
    }

    setValue("dialCode", dialCode);
  };

  const onChangePhone = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target.value) {
      if (PHONE_NUMBER_REGEX.test(e.target.value)) {
        setValue("phone", e.target.value);
      }
    } else {
      setValue("phone", "");
    }
  };

  const onBlurPhone = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    register("phone").onBlur(e);
    trigger("dialCode");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="billing">
      <FormControl isInvalid={errors.hasOwnProperty("accountType")}>
        <RadioGroup defaultValue={accountType || "private"} name="accountType">
          <Stack spacing={[4, 8]} direction={["column", "row"]}>
            <Radio value="private" {...register("accountType")}>
              {t("label_noun_individual_customer")}
            </Radio>
            <Radio value="company" {...register("accountType")}>
              {t("label_noun_company")}
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("firstname")} isRequired>
        <FormLabel htmlFor="firstname">{t("label_noun_first_name")}</FormLabel>
        <Input
          id="firstname"
          placeholder={t("label_action_request_suggestion_name")}
          {...register("firstname")}
          autoComplete="billing first-name"
        />
        <FormErrorMessage>{errors.firstname?.message}</FormErrorMessage>
      </FormControl>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("lastname")} isRequired>
        <FormLabel htmlFor="lastname">{t("label_noun_family_name")}</FormLabel>
        <Input
          id="lastname"
          placeholder={t("label_action_request_suggestion_family_name")}
          {...register("lastname")}
          autoComplete="billing family-name"
        />
        <FormErrorMessage>{errors.lastname?.message}</FormErrorMessage>
      </FormControl>

      <Collapse in={accountType === "company"} animateOpacity transitionEnd={{ enter: { overflow: "inherit" } }}>
        <FormControl variant={variant} isInvalid={errors.hasOwnProperty("companyName")}>
          <FormLabel htmlFor="companyName">
            {t("label_noun_company_name")}
            <Asterisk />
          </FormLabel>
          <Input
            id="companyName"
            placeholder={t("label_action_request_suggestion_company_name")}
            {...register("companyName")}
            autoComplete="billing organization"
          />
          <FormErrorMessage>{errors.companyName?.message}</FormErrorMessage>
        </FormControl>
        <FormControl variant={variant} isInvalid={errors.hasOwnProperty("vatIdentifier")}>
          <FormLabel htmlFor="vatIdentifier">
            {t("label_noun_vat_number")}
            <Asterisk />
          </FormLabel>
          <Input
            id="vatIdentifier"
            placeholder={t("label_action_request_suggestion_vat_number")}
            {...register("vatIdentifier")}
            autoComplete="billing vat-number"
          />
          <FormErrorMessage>{errors.vatIdentifier?.message}</FormErrorMessage>
        </FormControl>
      </Collapse>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("country")} isRequired>
        <FormLabel htmlFor="country">{t("label_noun_country")}</FormLabel>
        <Select id="country" {...register("country")}>
          {countries.map(country => (
            <option key={country.name} value={country.code}>
              {country.name}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
      </FormControl>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("primaryAddressLine")} isRequired>
        <FormLabel htmlFor="primaryAddressLine">{t("label_noun_address")}</FormLabel>
        <Input
          id="primaryAddressLine"
          placeholder={t("label_action_request_suggestion_address")}
          {...register("primaryAddressLine")}
          autoComplete="street-address"
        />
        <FormErrorMessage>{errors.primaryAddressLine?.message}</FormErrorMessage>
      </FormControl>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("optionalAddressLine")}>
        <FormLabel htmlFor="optionalAddressLine">{t("label_noun_address_complement")}</FormLabel>
        <Input
          id="optionalAddressLine"
          placeholder={t("label_action_request_suggestion_address")}
          {...register("optionalAddressLine")}
          autoComplete="billing address-line2"
        />
        <FormErrorMessage>{errors.optionalAddressLine?.message}</FormErrorMessage>
      </FormControl>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("zipCode")} isRequired>
        <FormLabel htmlFor="zipCode">{t("label_noun_postal_code")}</FormLabel>
        <Input
          id="zipCode"
          placeholder={t("label_action_request_suggestion_postal_code")}
          maxW={["100%", "100%", 200]}
          {...register("zipCode")}
          autoComplete="billing postal-code"
        />
        <FormErrorMessage>{errors.zipCode?.message}</FormErrorMessage>
      </FormControl>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("city")} isRequired>
        <FormLabel htmlFor="city">{t("label_noun_city")}</FormLabel>
        <Input
          id="city"
          placeholder={t("label_action_request_suggestion_city")}
          {...register("city")}
          autoComplete="billing address-level2"
        />
        <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
      </FormControl>

      <FormControl variant={variant} isInvalid={errors.hasOwnProperty("dialCode") || errors.hasOwnProperty("phone")}>
        <FormLabel htmlFor="phone">{t("label_noun_phone")}</FormLabel>
        <Stack spacing={4} direction="row">
          <Input
            inputMode="numeric"
            id="dialCode"
            {...register("dialCode")}
            onChange={onChangeDialCode}
            maxWidth="72px"
            maxLength={5}
          />
          <Controller
            name="phone"
            render={({ field: { value, ref, ...field } }) => (
              <Input
                {...field}
                id="phone"
                placeholder={t("label_action_request_suggestion_phone")}
                onChange={onChangePhone}
                onBlur={onBlurPhone}
                value={value || ""}
              />
            )}
            control={control}
          />
        </Stack>
        <FormErrorMessage>{errors.dialCode?.message || errors.phone?.message}</FormErrorMessage>
      </FormControl>
      <Flex direction="row">
        <Asterisk />
        <BodyText alignSelf="center" variant="body12">{` ${t("label_noun_mandatory_fields")}`}</BodyText>
      </Flex>

      <Stack mt={[9, "xl"]} flex={1} direction={["column", "row"]} spacing={[6, 2]} justifyContent="flex-end">
        {!!onCancel && (
          <Button variant="secondary" onClick={onCancel}>
            {t("label_action_request_cancel")}
          </Button>
        )}
        <Button type="submit" isLoading={isSubmitting} ml={["0", "auto"]}>
          {buttonLabel
            ? buttonLabel
            : !!defaultValues
            ? t("label_action_request_save_changes")
            : t("label_action_request_save_address")}
        </Button>
      </Stack>
    </form>
  );
};

export default BillingAddressForm;
