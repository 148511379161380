import React from "react";

import { HStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from "@chakra-ui/react";

import { BodyText, Box, TriangleDownIcon, TriangleUpIcon } from "../../atoms";
import colors from "../../foundations/Colors/colors";

type Props = {
  label: string;
  children: React.ReactNode;
  isDisabled?: boolean;
  isSecondary?: boolean;
  isError?: boolean;
  flag?: React.ReactNode;
};

export const Dropdown: React.FC<Props> = ({
  children,
  label,
  isDisabled = false,
  isSecondary = false,
  isError = false,
  flag,
}) => {
  return (
    <Box zIndex="dropdown">
      <Menu gutter={0} placement="bottom-end">
        {({ isOpen }) => {
          const styles = getStyles(isOpen, isDisabled, isSecondary, isError);
          return (
            <>
              <MenuButton
                borderRadius="base"
                pl={isSecondary ? 4 : 2}
                pr={2}
                py={isSecondary ? "7px" : 1}
                role="group"
                disabled={isDisabled}
                {...styles.button}
              >
                <HStack spacing={isSecondary ? 2 : 1}>
                  {flag}
                  <BodyText
                    textTransform="uppercase"
                    variant={isSecondary ? "body16" : "highlights16"}
                    {...styles.text}
                  >
                    {label}
                  </BodyText>
                  {isOpen ? (
                    <TriangleUpIcon color={isSecondary ? "primary.300" : "white"} />
                  ) : (
                    <TriangleDownIcon color="primary.300" />
                  )}
                </HStack>
              </MenuButton>
              <MenuList bgColor="grayLight" mt={1}>
                {React.Children.map(children, (item, index) => (
                  <>
                    {index !== 0 && <MenuDivider borderColor="grayDark" />}
                    {item}
                  </>
                ))}
              </MenuList>
            </>
          );
        }}
      </Menu>
    </Box>
  );
};

type DropdownItemProps = {
  onClick?: () => void;
  children: string;
  isDisabled?: boolean;
};

export const DropdownItem: React.FC<DropdownItemProps> = ({ children, onClick, isDisabled = false }) => (
  <MenuItem
    onClick={onClick}
    paddingX={2}
    paddingY={1}
    isDisabled={isDisabled}
    _focusVisible={{
      boxShadow: isDisabled ? "none" : `0 0 0 2px ${colors.keyboardBlueAlert}`,
    }}
  >
    <BodyText
      variant="highlights16"
      casing="uppercase"
      _hover={{ color: isDisabled ? "grayDark" : "primary.300" }}
      _active={{ color: isDisabled ? "grayDark" : "primary.500" }}
    >
      {children}
    </BodyText>
  </MenuItem>
);

const getStyles = (isOpen: boolean, isDisabled: boolean, isSecondary: boolean, isError: boolean) => {
  if (isSecondary) {
    return {
      button: {
        backgroundColor: isDisabled ? "grayLight" : "white",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: isError ? "redAlert" : isDisabled ? "grayLight" : "grayDark",
        _hover: {
          borderColor: isError ? "redAlert" : isDisabled ? "grayLight" : "primary.500",
        },
        _active: {
          borderColor: isError ? "redAlert" : isDisabled ? "grayLight" : "primary.300",
        },
        _focusVisible: {
          boxShadow: isDisabled ? "none" : `0 0 0 2px ${colors.keyboardBlueAlert}`,
          borderColor: isError ? "redAlert" : "white",
        },
      },
      text: {
        color: isDisabled ? "grayDark" : "black",
      },
    };
  }

  return {
    button: {
      backgroundColor: isOpen ? "primary.300" : "grayLight",
      _focusVisible: {
        boxShadow: isDisabled ? "none" : `0 0 0 2px ${colors.keyboardBlueAlert}`,
        borderColor: "white",
      },
    },
    text: {
      color: isDisabled ? "grayDark" : isOpen ? "white" : "black",
      _groupHover: { color: isDisabled ? "grayDark" : "primary.300" },
      _groupActive: { color: isDisabled ? "grayDark" : isOpen ? "white" : "primary.500" },
    },
  };
};
