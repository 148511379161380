import React from "react";

import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BodyText, Button, InfoPanel, InfoPanelAction, InfoPanelContent, InfoPanelIllustration, TitleH1Os } from "ui";

const PreparingDevices: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToMyDevices = React.useCallback(() => navigate(Paths.MyDevices), [navigate]);

  return (
    <>
      <TitleH1Os>{t("label_action_info_preparing_device")}</TitleH1Os>
      <InfoPanel>
        <InfoPanelIllustration illustrationName="ConfirmationPaired2Illustration" />
        <InfoPanelContent>
          <BodyText>{t("prompt_preparing_device_instructions")}</BodyText>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button onClick={goToMyDevices} variant="secondary">
            {t("label_action_info_resignation")}
          </Button>
          <Button onClick={() => navigate(Paths.PairDeviceStep2)}>{t("label_action_request_show_code")}</Button>
        </InfoPanelAction>
      </InfoPanel>
    </>
  );
};

export default PreparingDevices;
