import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationPaired2Illustration = createIcon({
  displayName: "ConfirmationPaired2Illustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <g clipPath="url(#clip0_1880_85501)">
      <rect width="150" height="150" fill="white" />
      <path
        d="M31.1499 20.23L20.6699 27.49"
        stroke="#8F8BEF"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6699 27.49L31.1399 34.86"
        stroke="#8F8BEF"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M79.78 79.2L87.05 89.68" stroke="#8F8BEF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M87.05 89.68L94.42 79.21"
        stroke="#8F8BEF"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.2001 50.58H135.57C137.427 50.58 139.207 51.3175 140.52 52.6302C141.833 53.943 142.57 55.7234 142.57 57.58V116.42C142.57 118.276 141.833 120.057 140.52 121.37C139.207 122.682 137.427 123.42 135.57 123.42H39.8301C37.9736 123.42 36.1931 122.682 34.8803 121.37C33.5676 120.057 32.8301 118.276 32.8301 116.42V92.12"
        stroke="#999999"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M56.0801 50.58H65.4601" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M99.64 123.4H75.76V143.73H99.64V123.4Z"
        stroke="#999999"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M65.48 143.73H109.92" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M36.0799 6.27002H14.4299C10.5695 6.27002 7.43994 9.39955 7.43994 13.26V72.1C7.43994 75.9605 10.5695 79.09 14.4299 79.09H36.0799C39.9404 79.09 43.0699 75.9605 43.0699 72.1V13.26C43.0699 9.39955 39.9404 6.27002 36.0799 6.27002Z"
        stroke="#999999"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6499 27.54C39.8012 27.54 46.8825 28.9488 53.4893 31.6858C60.0962 34.4227 66.0991 38.4344 71.1554 43.4916C76.2117 48.5488 80.2222 54.5525 82.958 61.1599C85.6938 67.7672 87.1012 74.8487 87.0999 82"
        stroke="#8F8BEF"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>,
    <defs>
      <clipPath id="clip0_1880_85501">
        <rect width="150" height="150" fill="white" />
      </clipPath>
    </defs>,
  ],
});
