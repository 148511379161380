import { Checkbox as ChakraCheckbox, ComponentWithAs, CheckboxProps as Props } from "@chakra-ui/react";

export interface CheckboxProps extends Props {
  /**
   * The label content of the checkbox
   */
  children?: Props["children"];
  /**
   * If `true`, the checkbox will be checked.
   * You'll need to pass `onChange` to update its value (since it is now controlled)
   * @default false
   */
  isChecked?: boolean;
  /**
   * If `true`, the checkbox will be indeterminate.
   * This only affects the icon shown inside checkbox
   * and does not modify the isChecked property.
   * @default false
   */
  isIndeterminate?: boolean;
  /**
   * If `true`, the checkbox will be disabled
   * @default false
   */
  isDisabled?: boolean;
  /**
   * If `true` and `isDisabled` is passed, the checkbox will
   * remain tabbable but not interactive
   * @default false
   */
  isFocusable?: boolean;
  /**
   * If `true`, the checkbox will be readonly
   * @default false
   */
  isReadOnly?: boolean;
  /**
   * If `true`, the checkbox is marked as invalid.
   * Changes style of unchecked state.
   * @default false
   */
  isInvalid?: boolean;
  /**
   * If `true`, the checkbox input is marked as required,
   * and `required` attribute will be added
   * @default false
   */
  isRequired?: boolean;
  /**
   * If `true`, the checkbox will be initially checked.
   * @default false
   */
  defaultChecked?: boolean;
}

/**
 * The Checkbox component is used in forms when a user needs to select multiple values from several options.
 */
export const Checkbox = ChakraCheckbox as ComponentWithAs<"input", CheckboxProps>;
