import React from "react";

import { createIcon } from "@chakra-ui/react";

export const RightIcon = createIcon({
  displayName: "RightIcon",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 6,
      h: 6,
    },
  },
  path: <path d="M8 20L16 12L8 4" stroke="#2D3139" strokeWidth="2" strokeLinecap="square" />,
});
