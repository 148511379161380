import React from "react";

import { createIcon } from "@chakra-ui/react";

export const SpainFlag = createIcon({
  displayName: "SpainFlag",
  viewBox: "0 0 40 40",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: "20px",
      h: "20px",
    },
  },
  path: [
    <g clipPath="url(#a)">
      <path
        fill="#D80228"
        d="M20 0C13.187 0 7.18 3.406 3.563 8.602H36.43C32.82 3.406 26.813 0 20 0ZM20 40c6.89 0 12.977-3.492 16.57-8.797H3.43C7.023 36.508 13.11 40 20 40Z"
      />
      <path
        fill="#FEDB45"
        d="M0 20a19.92 19.92 0 0 0 3.43 11.203h33.14A19.92 19.92 0 0 0 40 20c0-4.235-1.32-8.164-3.563-11.399H3.563A19.947 19.947 0 0 0 0 20Z"
      />
      <path
        fill="#D42D40"
        d="M24.203 16.797c.602 0 1.203-.399 1.203-.797 0-.399-.601-.797-1.203-.797-.797 0-1.398.399-1.398.797-.203.398.398.797 1.398.797Z"
      />
      <path fill="#E3E5EA" d="M25 17.398h-2v11h2v-11Z" />
      <path
        fill="#FAB446"
        d="M25.602 16.398h-3.204v1h.204v.203h2.796v-.203h.204v-1ZM22.398 29.398h3.204v-1h-.204v-.195h-2.796v.195h-.204v1Z"
      />
      <path
        fill="#D42D40"
        d="M6.398 16.797c.602 0 1.204-.399 1.204-.797 0-.399-.602-.797-1.204-.797-.796 0-1.398.399-1.398.797-.203.398.398.797 1.398.797Z"
      />
      <path fill="#E3E5EA" d="M7.203 17.398h-2v11h2v-11Z" />
      <path
        fill="#FAB446"
        d="M7.797 16.398H4.602v1h.195v.203h2.805v-.203h.195v-1ZM4.602 29.398h3.195v-1h-.195v-.195H4.797v.195h-.195v1ZM19 14.203h-8.203v1.399H19v-1.399Z"
      />
      <path
        fill="#D42D40"
        d="M19.398 14.203c1.399-.601 2.204-1.797 1.399-3.203C20 9.797 18.594 10.4 18 10.797c-.797-.797-1.602-1-3-1-1.203 0-2 .203-3.203.797-.203-.399-1.797-.797-2.602.398-.797 1.399 0 2.602 1.399 3.203h8.804"
      />
      <path
        fill="#FAB446"
        d="M15 12.797c.332 0 .602-.896.602-2 0-1.105-.27-2-.602-2-.332 0-.601.895-.601 2 0 1.104.269 2 .601 2Z"
      />
      <path
        fill="#F0F0F0"
        d="M15 29c-1.398 0-2.602-.398-3.797-.797C10.406 27.805 9.805 27 9.805 26v-3.398h10.398V26c0 1-.601 1.797-1.398 2.203-1.203.594-2.407.797-3.805.797ZM15 22.602h5.203v-5.805H15v5.805Z"
      />
      <path
        fill="#D42D40"
        d="M9.797 22.602H15v-5.805H9.797v5.805ZM20.203 22.398v3.399c0 1.398-1.203 2.398-2.601 2.398C16.203 28.195 15 27 15 25.797v-3.399h5.203Z"
      />
      <path
        fill="#FAB446"
        d="M10.805 22.398v5.242c.18.141.383.258.593.344v-5.586h-.593ZM12 22.398v5.766c.133.023.266.031.398.031.133 0 .266-.008.399-.031v-5.766H12ZM13.398 22.398v5.617c.22-.078.422-.187.602-.32v-5.297h-.602ZM9.797 22.398v3.399c0 .422.148.843.406 1.218v-4.617h-.406ZM14.601 27.094c.25-.368.399-.805.399-1.297v-3.399h-.399v4.696Z"
      />
      <path
        fill="#D42D40"
        d="M10.203 22.398v4.617c.164.235.367.446.602.625v-5.242h-.602ZM11.398 22.398v5.586c.188.078.391.14.602.172v-5.765h-.602v.007ZM14 22.398v5.297c.234-.164.438-.367.601-.61V22.39H14v.008ZM12.797 22.398v5.766c.21-.031.414-.078.601-.149v-5.617h-.601Z"
      />
      <path
        fill="#4AB6E6"
        d="M13.398 23c0-1 .602-1.797 1.602-1.797S16.602 22 16.602 23c0 1-.602 1.797-1.602 1.797-1-.195-1.602-1-1.602-1.797Z"
      />
    </g>,
    <defs>
      <clipPath id="a">
        <rect width={40} height={40} fill="#fff" rx={20} />
      </clipPath>
    </defs>,
  ],
});
