import React from "react";

import { Select as ChakraSelect, SelectProps as Props } from "@chakra-ui/react";

export interface SelectProps extends Props {}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(({ children, ...props }, ref) => {
  return (
    <ChakraSelect ref={ref} icon={<></>} {...props}>
      {children}
    </ChakraSelect>
  );
});
