import React from "react";

import { ListItemProps as Props } from "@chakra-ui/react";
import { chakra, forwardRef } from "@chakra-ui/system";

import { Box } from "../Box/Box";
import { Flex } from "../Flex/Flex";

interface ListItemProps extends Props {}

/**
 * ListItem composes Box so you can pass all style and pseudo style props.
 */

export const ListItem = forwardRef<ListItemProps, "li">(function ListItem(props, ref) {
  return (
    <Flex>
      <Box w={1} h={1} minW={1} minH={1} backgroundColor="violet.300" m="0.625rem" />
      <chakra.li ref={ref} listStyleType="none" {...props} />
    </Flex>
  );
});
