import React from "react";

import { createIcon } from "@chakra-ui/react";

export const EnglishUSAFlag = createIcon({
  displayName: "EnglishUSAFlag",
  viewBox: "0 0 40 40",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: "20px",
      h: "20px",
    },
  },
  path: [
    <g clipPath="url(#a)">
      <path
        fill="#F0F0F0"
        d="M19.96 39.96c11.047 0 20-8.953 20-20 0-11.045-8.953-20-20-20-11.045 0-20 8.955-20 20 0 11.047 8.955 20 20 20Z"
      />
      <path
        fill="#D80027"
        d="M19.164 19.96h20.797c0-1.796-.203-3.6-.602-5.202H19.164v5.203ZM19.164 9.563h18a19.214 19.214 0 0 0-4.601-5.204H19.164v5.204ZM19.961 39.96c4.797 0 9-1.6 12.398-4.398H7.563c3.398 2.797 7.601 4.399 12.398 4.399ZM2.961 30.36h34.203c1-1.602 1.797-3.4 2.203-5.204H.563c.601 1.805 1.398 3.602 2.398 5.203Z"
      />
      <path
        fill="#0052B4"
        d="M9.164 3.164h1.797L9.359 4.367l.602 2-1.602-1.203L6.563 6.36l.601-1.796A19.781 19.781 0 0 0 3.36 8.96h.602l-1.203.797c-.203.203-.203.398-.399.797l.602 1.601-1-.797c-.399.602-.602 1-.797 1.602l.602 1.797h2L2.164 15.96l.602 2-1.602-1.203-1 .805c-.203.796-.203 1.601-.203 2.398h20v-20c-4 0-7.602 1.203-10.797 3.203Zm.797 14.797-1.602-1.203-1.796 1.203.601-2-1.601-1.203h2l.601-2 .602 2h2L9.367 15.96l.594 2Zm-.602-7.797.602 2-1.602-1.203-1.796 1.203.601-2-1.601-1.203h2l.601-2 .602 2h2l-1.407 1.203Zm7.805 7.797-1.602-1.203-1.796 1.203.601-2-1.601-1.203h2l.601-2 .602 2h2l-1.407 1.203.602 2Zm-.602-7.797.602 2-1.602-1.203-1.796 1.203.601-2-1.601-1.203h2l.601-2 .602 2h2l-1.407 1.203Zm0-5.805.602 2-1.602-1.203-1.796 1.203.601-2-1.601-1.203h2l.601-2 .602 2h2L16.562 4.36Z"
      />
    </g>,
    <defs>
      <clipPath id="a">
        <rect width={40} height={40} fill="#fff" rx={20} />
      </clipPath>
    </defs>,
  ],
});
