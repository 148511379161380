import { Method, ResBuilder, api, transformResponse } from "./api";

export const transformUniqueUserDevices = (data: ResBuilder<DeviceRaw[]>) => {
  const devices = transformResponse<Device[]>(data);
  const groupedDevices: { [key: string]: Device } = {};
  devices.forEach(device => {
    const { id, externalDeviceId, active } = device;
    const previous = groupedDevices[externalDeviceId];
    if (active) {
      groupedDevices[externalDeviceId] = previous && previous.active && previous.id > id ? previous : device;
    } else {
      groupedDevices[externalDeviceId] = !previous || id > previous.id ? device : previous;
    }
  });

  return [...Object.values(groupedDevices)];
};

const devicesApi = api.injectEndpoints({
  endpoints: builder => ({
    getUserDevices: builder.query<Device[], void>({
      query: () => ({
        url: "users/devices",
        method: Method.Get,
      }),
      transformResponse: (res: ResBuilder<DeviceRaw[]>) => transformResponse(res),
      providesTags: ["UserDevices"],
    }),
    getUserUniqueDevices: builder.query<Device[], void>({
      query: () => ({
        url: "users/devices",
        method: Method.Get,
      }),
      transformResponse: transformUniqueUserDevices,
      providesTags: ["UserDevices"],
    }),
    createPairingToken: builder.mutation<PairingToken, void>({
      query: () => ({
        url: "users/pairing_tokens",
        method: Method.Post,
      }),
      transformResponse: (res: ResBuilder<PairingTokenRaw>) => transformResponse(res),
      invalidatesTags: ["PairingToken"],
    }),
    connectSubscription: builder.mutation<Device, ConnectSubscriptionReq>({
      query: ({ id, requestBody }) => ({
        url: `/users/devices/${id}/connect_subscription`,
        method: Method.Post,
        body: requestBody,
      }),
      transformResponse: (res: ResBuilder<DeviceRaw>) => transformResponse(res),
      invalidatesTags: ["UserDevices", "Subscriptions"],
    }),
    disconnectSubscription: builder.mutation<Device, DisconnectSubscriptionReq>({
      query: ({ id }) => ({
        url: `/users/devices/${id}/disconnect_subscription`,
        method: Method.Post,
      }),
      transformResponse: (res: ResBuilder<DeviceRaw>) => transformResponse(res),
      invalidatesTags: ["UserDevices", "Subscriptions"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserDevicesQuery,
  useGetUserUniqueDevicesQuery,
  useCreatePairingTokenMutation,
  useConnectSubscriptionMutation,
  useDisconnectSubscriptionMutation,
} = devicesApi;

export type Device = {
  id: number;
  type: "users_device";
  active: boolean;
  externalProductId: string;
  externalDeviceId: string;
  subscriptionId?: number;
};

export type PairingToken = {
  id: number;
  createdAt: string;
  updatedAt: string;
  value: string;
  expiredAt: string;
};

export type ConnectSubscriptionReq = {
  id: number;
  requestBody: {
    subscription_id: number;
  };
};

export type DisconnectSubscriptionReq = {
  id: number;
};

export type DeviceRaw = {
  id: string;
  type: "users_device";
  attributes: {
    active: boolean;
    external_product_id: string;
    external_device_id: string;
  };
  relationships: {
    subscription: {
      data: {
        id: string;
        type: "subscription";
      } | null;
    };
  };
};

export type PairingTokenRaw = {
  id: string;
  attributes: {
    created_at: string;
    updated_at: string;
    value: string;
    expired_at: string;
  };
  relationships: {
    user: {
      data: {
        id: string;
        type: "user";
      };
    };
  };
  type: "pairing_token";
};
