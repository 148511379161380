import React from "react";

import { createIcon } from "@chakra-ui/react";

export const SubscriptionEnd2Illustration = createIcon({
  displayName: "SubscriptionEnd2Illustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <rect width="150" height="150" fill="white" />,
    <path
      d="M85.6198 105.57C85.5978 104.79 85.7488 104.016 86.0617 103.302C86.3747 102.588 86.8419 101.952 87.4298 101.44C88.7115 100.398 90.313 99.8292 91.9648 99.8292C93.6166 99.8292 95.218 100.398 96.4998 101.44C97.0697 101.965 97.5246 102.603 97.8358 103.313C98.147 104.023 98.3076 104.79 98.3076 105.565C98.3076 106.34 98.147 107.107 97.8358 107.817C97.5246 108.527 97.0697 109.164 96.4998 109.69C95.2204 110.737 93.618 111.309 91.9648 111.309C90.3115 111.309 88.7091 110.737 87.4298 109.69C86.8421 109.18 86.3749 108.546 86.0619 107.833C85.7489 107.121 85.5979 106.348 85.6198 105.57ZM96.6798 94.9998H87.4098L85.9998 61.2598H98.1398L96.6798 94.9998Z"
      fill="#E40000"
    />,
    <path
      d="M145.1 113.51C149.41 120.51 146.19 126.3 137.94 126.3H45.53C37.28 126.3 34.06 120.54 38.37 113.51L83.89 39.2803C88.21 32.2803 95.26 32.2803 99.58 39.2803L145.1 113.51Z"
      stroke="#E40000"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M38.0702 95.7999C31.8509 95.4399 25.8307 93.4757 20.5958 90.0985C15.361 86.7213 11.0897 82.0462 8.19791 76.5283C5.30611 71.0104 3.89231 64.8377 4.09414 58.6113C4.29597 52.3848 6.10655 46.3167 9.34962 40.9977C12.5927 35.6787 17.1577 31.29 22.6003 28.2589C28.043 25.2278 34.1777 23.6576 40.4072 23.7011C46.6368 23.7446 52.749 25.4004 58.1487 28.5072C63.5484 31.614 68.0517 36.0661 71.2202 41.4299"
      stroke="#8D95A5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M43.2206 67.6754C43.2861 66.6926 42.9844 65.7201 42.3722 64.9404C41.5037 64.0977 40.4543 63.4566 39.3009 63.0643C37.8332 62.4806 36.4758 61.8803 35.2286 61.2799C34.0688 60.7237 32.9902 60.017 32.0217 59.1787C31.1445 58.4096 30.4396 57.4697 29.9516 56.4187C29.4455 55.2173 29.2026 53.9246 29.2389 52.6248C29.2011 51.4811 29.4081 50.3423 29.8466 49.2823C30.285 48.2222 30.9451 47.2645 31.7841 46.4711C33.6632 44.789 36.0758 43.7964 38.6137 43.6611V39H42.1855V43.7445C44.6889 44.0231 46.9953 45.213 48.6503 47.0798C50.2596 49.031 51.0919 51.4908 50.9919 54.0006H43.3563C43.4365 52.759 43.1223 51.5236 42.457 50.4652C42.174 50.1089 41.8176 49.8153 41.4112 49.6037C41.0047 49.3922 40.5574 49.2673 40.0985 49.2374C39.6396 49.2075 39.1794 49.2732 38.7481 49.4302C38.3168 49.5871 37.9241 49.8318 37.5957 50.1483C37.0101 50.8515 36.7081 51.7419 36.7473 52.6498C36.7233 53.1223 36.7949 53.5948 36.958 54.04C37.1211 54.4851 37.3725 54.894 37.6975 55.243C38.5773 56.0407 39.6058 56.6636 40.7263 57.0774C42.1176 57.6333 43.4327 58.2364 44.6713 58.8868C45.8247 59.4597 46.8995 60.1742 47.8698 61.0131C48.7555 61.794 49.4737 62.7409 49.9823 63.7981C50.5208 65 50.7816 66.3042 50.7459 67.617C50.7889 68.753 50.595 69.8856 50.1763 70.9453C49.7576 72.005 49.1228 72.9693 48.311 73.779C46.5008 75.4493 44.1475 76.4359 41.668 76.564V81H38.1386V76.539C34.8808 76.1971 32.3695 75.0965 30.6303 73.2037C28.837 71.1919 27.8985 68.5801 28.0087 65.9077H35.5765C35.4807 67.2757 35.8837 68.6325 36.7133 69.735C37.1142 70.18 37.6127 70.5298 38.1711 70.7581C38.7296 70.9863 39.3335 71.0869 39.9372 71.0524C40.3786 71.0757 40.82 71.0085 41.2334 70.8549C41.6467 70.7012 42.023 70.4647 42.3382 70.1602C42.6454 69.8241 42.8816 69.4314 43.0331 69.0048C43.1846 68.5783 43.2483 68.1264 43.2206 67.6754Z"
      fill="#8D95A5"
    />,
  ],
});
