import { useBreakpointValue } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Paths } from "paths";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetPasswordEmailMutation } from "store/api";
import { err } from "store/errors";
import {
  BodyText,
  Box,
  Button,
  Card,
  Form,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  Input,
  Stack,
  TitleH1,
} from "ui";
import * as yup from "yup";

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();

  const validationSchema = yup
    .object({
      email: yup
        .string()
        .email(t("prompt_error_invalid_e-mail"))
        .max(255, t("prompt_error_length_max", { value: 255 }))
        .required(t("prompt_error_mandatory_e-mail")),
    })
    .required();

  let navigate = useNavigate();
  const [sendResetPasswordEmail, sendResetPasswordResult] = useResetPasswordEmailMutation();
  const [params, setParams] = useSearchParams();
  const variant = useBreakpointValue({ sm: "vertical", md: "horizontal" });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<{ email: string }>({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
  });

  const onResetPassword = async () => {
    try {
      await sendResetPasswordEmail(getValues()).unwrap();
      setParams({ confirm: "true" });
    } catch (error) {
      err.handler(error);
    }
  };

  const goToSignIn = () => {
    navigate(Paths.SignIn);
  };

  if (params.get("confirm") && sendResetPasswordResult.isSuccess) {
    return (
      <InfoPanel isBorder>
        <InfoPanelIllustration illustrationName="EmailConfirmationIllustration" />
        <InfoPanelContent>
          <TitleH1 color="violet.300">{t("label_action_request_check_e-mail")}</TitleH1>
          <BodyText>{t("prompt_reset_e-mail_sent_information")}</BodyText>
          <Box>
            <BodyText>{t("label_action_request_spam_alert_title")}</BodyText>
            <BodyText>{t("label_action_request_check_spam_and_other_folder")}</BodyText>
          </Box>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button variant="secondary" w={["full", "initial"]} onClick={goToSignIn}>
            {t("label_action_request_go_to_sign_in")}
          </Button>
        </InfoPanelAction>
      </InfoPanel>
    );
  }

  return (
    <Card py={[0, "xl"]} px={[0, "l", "xl"]}>
      <Form onSubmit={handleSubmit(onResetPassword)}>
        <Stack spacing="m">
          <BodyText variant="header24">{t("label_action_request_reset_password")}</BodyText>
          <BodyText>{t("prompt_reset_password_instructions")}</BodyText>
          <FormControl variant={variant} isInvalid={errors.hasOwnProperty("email")}>
            <FormLabel htmlFor="email">{t("label_noun_e-mail")}</FormLabel>
            <Input
              id="email"
              placeholder={t("label_action_request_suggestion_e-mail")}
              type="email"
              {...register("email")}
              isRequired
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Box mt={[0, 0, 16]} textAlign="right">
          <Button type="submit" isLoading={isSubmitting} w={["full", "auto"]}>
            {t("label_action_request_send_e-mail")}
          </Button>
        </Box>
      </Form>
    </Card>
  );
};

export default ResetPassword;
