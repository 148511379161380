import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";

import { LocaleAPI } from "../../locales";
import { Method, ResBuilder, api, transformResponse } from "./api";

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    signUp: builder.mutation<{ data: User; token?: string }, SignUpReq>({
      query: payload => ({
        url: "users/sign_up",
        method: Method.Post,
        body: payload,
      }),
      transformResponse: (response: ResBuilder<UserRaw>, meta: FetchBaseQueryMeta | undefined) => ({
        data: transformResponse(response),
        token: meta?.response?.headers.get("authorization") || "",
      }),
    }),
    confirmEmail: builder.mutation<ConfirmEmailRaw, ConfirmEmailReq>({
      query: payload => ({
        url: "users/confirmation",
        method: Method.Get,
        params: payload,
      }),
      transformResponse: (response: ResBuilder<ConfirmEmailRaw>) => response.data,
    }),
    signIn: builder.mutation<{ data: User; token?: string }, SignInReq>({
      query: credentials => ({
        url: "users/sign_in",
        method: Method.Post,
        body: credentials,
      }),
      transformResponse: (response: ResBuilder<UserRaw>, meta: FetchBaseQueryMeta | undefined) => ({
        data: transformResponse(response),
        token: meta?.response?.headers.get("authorization") || "",
      }),
    }),
    resetPasswordEmail: builder.mutation<void, SendInstructionReq>({
      query: payload => ({
        url: "users/password/send_instructions",
        method: Method.Post,
        body: payload,
        responseHandler: "text",
      }),
    }),
    resetPassword: builder.mutation<null, ResetPasswordReq>({
      query: payload => ({
        url: "users/password/reset",
        method: Method.Put,
        body: payload,
        responseHandler: "text",
      }),
    }),
    changePassword: builder.mutation<UserWithToken, UpdatePasswordReq>({
      query: payload => ({
        url: "users/password",
        method: Method.Put,
        body: payload,
      }),
      transformResponse: (response: ResBuilder<UserRaw>, meta: FetchBaseQueryMeta | undefined) => ({
        data: transformResponse(response),
        token: meta?.response?.headers.get("authorization") || "",
      }),
    }),
    changeEmail: builder.mutation<UserWithToken, UpdateEmailReq>({
      query: payload => ({
        url: "users/update_email",
        method: Method.Put,
        body: payload,
      }),
    }),
    sendDeleteAccountInstruction: builder.mutation<void, void>({
      query: () => ({
        url: "users/send_destroy_instructions",
        method: Method.Post,
      }),
    }),
    deleteAccount: builder.mutation<void, DeleteAccountReq>({
      query: payload => ({
        url: "users",
        method: Method.Delete,
        body: payload,
      }),
    }),
    signOut: builder.mutation<void, void>({
      query: () => ({
        url: "users/sign_out",
        method: Method.Delete,
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignOutMutation,
  useResetPasswordMutation,
  useConfirmEmailMutation,
  useSignUpMutation,
  useResetPasswordEmailMutation,
  useChangePasswordMutation,
  useChangeEmailMutation,
  useSendDeleteAccountInstructionMutation,
  useDeleteAccountMutation,
} = authApi;

type UserWithToken = { data: User; token?: string };

export type User = {
  id: number;
  type: "user";
  email: string;
  emailConfirmed: boolean;
  locale: LocaleAPI;
  consentsIds: number[];
  consentsAccepted: boolean;
  shippingAddressesIds: number[];
  billingDetailId?: number;
};

export type ConfirmEmailReq = {
  confirmation_token: string;
};

export type DeleteAccountReq = {
  destroy_token: string;
  password: string;
};

export type ResetPasswordReq = {
  password: string;
  password_confirmation: string;
  reset_password_token: string;
};

export type SendInstructionReq = {
  email: string;
};

export type SignInReq = {
  email: string;
  password: string;
};

export type SignUpReq = {
  email: string;
  password: string;
  password_confirmation: string;
  accepted_platform_privacy_policy: boolean;
  accepted_platform_regulations: boolean;
  language: LocaleAPI;
};

export type UpdateEmailReq = {
  email: string;
};

export type UpdatePasswordReq = {
  current_password: string;
  password: string;
  password_confirmation: string;
};

export type ConfirmEmailRaw = {
  reconfirmation?: boolean;
};

export type UserRaw = {
  id: string;
  type: "user";
  attributes: {
    email: string;
    email_confirmed: boolean;
    consents_accepted: boolean;
    created_at: string;
    locale: LocaleAPI;
    updated_at: string;
  };
  relationships: {
    billing_detail: {
      data: null | { id: string; type: "billing_detail" };
    };
    shipping_addresses: {
      data: {
        id: string;
        type: "shipping_addresses";
      }[];
    };
    consents: {
      data: {
        id: string;
        type: "consent";
      }[];
    };
  };
};
