import React from "react";

import useWebSocket, { ReadyState } from "react-use-websocket";

export const useUserConfirmationSocket = (token: string) => {
  const url = `${process.env.REACT_APP_WEBSOCKET_URL}?token=${token?.replace("Bearer ", "")}`;
  const options = { protocols: ["actioncable-v1-json", "actioncable-unsupported"] };
  const { readyState, sendJsonMessage, lastJsonMessage } = useWebSocket(url, options);

  React.useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        command: "subscribe",
        identifier: JSON.stringify({ channel: "Users::ConfirmationChannel" }),
      });
    }
  }, [readyState, sendJsonMessage]);

  return lastJsonMessage;
};
