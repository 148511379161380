import React from "react";

import { createIcon } from "@chakra-ui/react";

export const VascoSpainLogo = createIcon({
  displayName: "VascoSpainLogo",
  defaultProps: {
    width: "73px",
    height: "32px",
  },
  viewBox: "0 0 73 32",
  path: [
    <path
      d="M54.5882 0C56.6274 0 58.5098 1.17647 59.3725 3.05882L72.0784 31.3725H64.4705L56.1568 11.7647C55.5294 10.1961 54.9804 8.70587 54.7451 7.37254H54.5882C54.3529 8.70587 53.8039 10.1961 53.1764 11.7647L44.8627 31.3725H37.2549L49.8823 3.05882C50.6666 1.17647 52.549 0 54.5882 0Z"
      fill="#010101"
    />,
    <path
      d="M17.4117 31.9999C15.3725 31.9999 13.4902 30.8235 12.6274 28.9411L0 0.627441H7.60783L15.9216 20.2353C16.549 21.8039 17.098 23.2941 17.3333 24.6274H17.4902C17.7255 23.2941 18.2745 21.8039 18.9019 20.2353L27.2157 0.627441H34.8235L22.1961 28.9411C21.3333 30.7451 19.451 31.9999 17.4117 31.9999Z"
      fill="#010101"
    />,
  ],
});
