import React from "react";

import { HTMLChakraProps, ThemingProps, chakra, forwardRef, useStyleConfig } from "@chakra-ui/system";

interface SpinnerOptions {
  label?: string;
}

export interface SpinnerProps
  extends Omit<HTMLChakraProps<"div">, keyof SpinnerOptions>,
    SpinnerOptions,
    ThemingProps<"Spinner"> {}

/**
 * Spinner is used to indicate the loading state of a page or a component,
 * It renders a `div` by default.
 *
 * @see Docs https://chakra-ui.com/spinner
 */
export const Spinner = forwardRef<SpinnerProps, "div">((props, ref) => {
  const styles = useStyleConfig("Spinner");

  const spinnerStyles = {
    borderColor: "currentColor",
    ...styles,
  };

  return <chakra.div ref={ref} __css={spinnerStyles} {...props} />;
});
