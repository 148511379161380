import React from "react";

import {
  Alert,
  AlertDescription,
  AlertProps,
  AlertTitle,
  UseToastOptions,
  chakra,
  createStandaloneToast,
} from "@chakra-ui/react";

import theme from "../../theme";
import { Flex } from "../Flex/Flex";
import { ErrorWhiteIcon, InfoIcon, SuccessIcon } from "../Icons";

export interface ToastProps extends UseToastOptions, Omit<AlertProps, keyof UseToastOptions> {
  onClose?: () => void;
  title?: string;
  description?: string;
}

export const Toast: React.FC<ToastProps> = props => {
  const { status, id, title, description } = props;

  const ids = id
    ? {
        root: `toast-${id}`,
        title: `toast-${id}-title`,
        description: `toast-${id}-description`,
      }
    : undefined;

  return (
    <Alert addRole={false} status={status} id={ids?.root}>
      <Flex width="m" height="m" alignSelf="center" marginEnd="m">
        {(status === "error" || status === "warning") && <ErrorWhiteIcon />}
        {status === "info" && <InfoIcon />}
        {status === "success" && <SuccessIcon />}
      </Flex>
      <chakra.div flex="1" maxWidth="100%">
        {title && <AlertTitle id={ids?.title}>{title}</AlertTitle>}
        {description && (
          <AlertDescription id={ids?.description} display="block">
            {description}
          </AlertDescription>
        )}
      </chakra.div>
    </Alert>
  );
};

/**
 * The toast is used to show alerts on top of an overlay. The toast will close itself when the close button is clicked, or after a timeout — the default is 5 seconds. The toast component is used to give feedback to users after an action has taken place.
 */

export const toast = (options: ToastProps) => {
  const { toast } = createStandaloneToast({ theme });

  return toast({
    render: props => <Toast {...options} id={props.id} />,
  });
};
