import i18n, { LocaleAPI, LocaleKey } from "../locales";
import { Currency, Hardware } from "../store/api";

export const getCurrentLocale = () => {
  return parseLocaleToApi(i18n.resolvedLanguage as LocaleKey);
};

export const parseLocaleToApi = (locale: LocaleKey) => {
  return locale.replace("-", "_") as LocaleAPI;
};

export const parseLocale = (locale: LocaleAPI) => {
  return locale.replace("_", "-") as LocaleKey;
};

export const getDeviceImageByColor = (color: Hardware["color"] | boolean) => {
  switch (color) {
    case "stone_gray":
      return "https://vasco-translator-files.com/show/b5479601553825dda4f644ba/original/v4-stone-gray.png";
    case "cobalt_blue":
      return "https://vasco-translator-files.com/show/7cf26387a63d35d76b844207/original/v4-cobalt-blue.png";
    case "ruby_red":
      return "https://vasco-translator-files.com/show/b80305772ba8da17af361d36/original/v4-ruby-red.png";
    case "pearl_white":
      return "https://vasco-translator-files.com/show/0e443db108de12c80b20b118/original/v4-pearl-white.png";
    case "black_onyx":
      return "https://vasco-translator-files.com/show/06d5018b8cd29ffbb5f50eb1/original/v4-black-onyx.png";
    default:
      return "https://vasco-translator-files.com/show/0cdd25e1488951ce52df4795/original/v4-disconnected.png";
  }
};

export const formatPrice = (subunitPrice: number, currency: Currency) => {
  const locale = parseLocale(getCurrentLocale());
  const options = subunitPrice % 100 === 0 ? { maximumFractionDigits: 0 } : {};
  return Intl.NumberFormat(locale, { style: "currency", currency, ...options }).format(subunitPrice / 100);
};

export const transformSubunitToPrice = (subunitPrice: number) => {
  return subunitPrice / 100;
};
