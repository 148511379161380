import React from "react";

import { createIcon } from "@chakra-ui/react";

export const CloseIcon = createIcon({
  displayName: "CloseIcon",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 6,
      h: 6,
    },
  },
  path: [
    <rect width="20" height="2" transform="matrix(0.698685 -0.715429 0.698685 0.715429 4 19)" fill="#333333" />,
    <rect x="11" y="12" width="2" height="2" fill="#333333" />,
    <rect width="20" height="2" transform="matrix(0.698685 0.715429 -0.698685 0.715429 6 5)" fill="#333333" />,
  ],
});
