import { Method, ResBuilder, api, transformResponse } from "./api";

export const transformShippingAddressesRes = ({ data }: ResBuilder<ShippingAddressRaw[]>) => {
  return data.map(dataItem => transformShippingAddressRes({ data: dataItem }));
};

export const transformShippingAddressRes = ({ data }: ResBuilder<ShippingAddressRaw>) => {
  const dataCountry = data.attributes.country;
  const dataPhone = data.attributes.phone;

  data.attributes.phone = dataPhone ? dataPhone.replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null;
  data.attributes.country = Object.keys(SHIPPING_COUNTRY_OPTIONS).includes(dataCountry) ? dataCountry : "PL";

  return transformResponse<ShippingAddress>({ data });
};

export const transformBillingAddressRes = ({ data }: ResBuilder<BillingAddressRaw>) => {
  const dataCountry = data.attributes.country;
  const dataPhone = data.attributes.phone;

  data.attributes.phone = dataPhone ? dataPhone?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null;
  data.attributes.country = Object.keys(COUNTRY_OPTIONS).includes(dataCountry) ? dataCountry : "PL";

  return transformResponse<BillingAddress>({ data });
};

export const transformIsBillingAddressRes = ({ data }: ResBuilder<BillingAddressRaw>) => {
  return !!data.id;
};

const removeWhiteSpace = (value?: string | null) => {
  if (value) {
    return value.replace(/\s/g, "");
  }
  return null;
};

const addressesApi = api.injectEndpoints({
  endpoints: builder => ({
    getBillingAddresses: builder.query<BillingAddress, void>({
      query: () => ({
        url: "users/billing_details",
        method: Method.Get,
      }),
      transformResponse: transformBillingAddressRes,
      providesTags: ["BillingAddress"],
    }),
    getIsBillingAddress: builder.query<boolean, void>({
      query: () => ({
        url: "users/billing_details",
        method: Method.Get,
      }),
      transformResponse: transformIsBillingAddressRes,
      providesTags: ["BillingAddress"],
    }),
    createBillingAddress: builder.mutation<BillingAddress, BillingAddressReq>({
      query: payload => ({
        url: "users/billing_details",
        method: Method.Post,
        body: { ...payload, phone: removeWhiteSpace(payload.phone) },
      }),
      transformResponse: transformBillingAddressRes,
      invalidatesTags: ["BillingAddress"],
    }),
    updateBillingAddress: builder.mutation<BillingAddress, BillingAddressReq>({
      query: payload => ({
        url: "users/billing_details",
        method: Method.Patch,
        body: { ...payload, phone: removeWhiteSpace(payload.phone) },
      }),
      transformResponse: transformBillingAddressRes,
      invalidatesTags: ["BillingAddress"],
    }),
    getShippingAddresses: builder.query<ShippingAddress[], void>({
      query: () => ({
        url: "users/shipping_addresses",
        method: Method.Get,
      }),
      transformResponse: transformShippingAddressesRes,
      providesTags: ["ShippingAddress"],
    }),
    getShippingAddress: builder.query<ShippingAddress, { id: number }>({
      query: ({ id }) => ({
        url: `users/shipping_addresses/${id}`,
        method: Method.Get,
      }),
      transformResponse: transformShippingAddressRes,
      providesTags: ["ShippingAddress"],
    }),
    createShippingAddress: builder.mutation<ShippingAddress, ShippingAddressReq>({
      query: payload => ({
        url: "users/shipping_addresses",
        method: Method.Post,
        body: { ...payload, phone: removeWhiteSpace(payload.phone) },
      }),
      transformResponse: transformShippingAddressRes,
      invalidatesTags: ["ShippingAddress"],
    }),
    updateShippingAddress: builder.mutation<ShippingAddress, { id: number; payload: ShippingAddressReq }>({
      query: ({ id, payload }) => ({
        url: `users/shipping_addresses/${id}`,
        method: Method.Patch,
        body: { ...payload, phone: removeWhiteSpace(payload.phone) },
      }),
      transformResponse: transformShippingAddressRes,
      invalidatesTags: ["ShippingAddress"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBillingAddressesQuery,
  useUpdateBillingAddressMutation,
  useCreateBillingAddressMutation,
  useGetShippingAddressesQuery,
  useGetShippingAddressQuery,
  useCreateShippingAddressMutation,
  useUpdateShippingAddressMutation,
  useGetIsBillingAddressQuery,
} = addressesApi;

export type AddressCountry = keyof typeof COUNTRY_OPTIONS;

export type BillingAddress = {
  id: number;
  accountType: "company" | "private";
  firstname: string;
  lastname: string;
  companyName: string | null;
  vatIdentifier: string | null;
  primaryAddressLine: string;
  optionalAddressLine: string | null;
  city: string;
  zipCode: string;
  country: AddressCountry;
  dialCode: string | null;
  phone: string | null;
};

export type ShippingAddress = {
  id: number;
  firstname: string;
  lastname: string;
  country: Extract<AddressCountry, "PL" | "ES" | "FR">;
  primaryAddressLine: string;
  optionalAddressLine: string | null;
  dialCode: string | null;
  phone: string | null;
  city: string;
  zipCode: string;
};

export type BillingAddressReq = {
  account_type: "company" | "private";
  company_name: string | null;
  vat_identifier: string | null;
  firstname: string;
  lastname: string;
  primary_address_line: string;
  optional_address_line: string | null;
  city: string;
  zip_code: string;
  country: string;
  dial_code: string | null;
  phone: string | null;
};

export type ShippingAddressReq = {
  firstname: string;
  lastname: string;
  primary_address_line: string;
  optional_address_line: string | null;
  city: string;
  zip_code: string;
  country: string;
  dial_code: string | null;
  phone: string | null;
};

export type BillingAddressRaw = {
  id: string;
  type: "billing_detail";
  attributes: {
    account_type: "company" | "private";
    company_name: string | null;
    vat_identifier: string | null;
    firstname: string;
    lastname: string;
    primary_address_line: string;
    optional_address_line: string | null;
    city: string;
    zip_code: string;
    country: string;
    phone: string | null;
    dial_code: string | null;
  };
};

export type ShippingAddressRaw = {
  id: string;
  type: "shipping_address";
  attributes: {
    firstname: string;
    lastname: string;
    primary_address_line: string;
    optional_address_line?: string;
    city: string;
    zip_code: string;
    country: string;
    dial_code?: string;
    phone: string | null;
  };
};

export const COUNTRY_OPTIONS = {
  AD: "country_andorra",
  AE: "country_united_arab_emirates",
  AF: "country_afghanistan",
  AG: "country_antigua_and_barbuda",
  AI: "country_anguilla",
  AL: "country_albania",
  AM: "country_armenia",
  AN: "country_netherlands_antilles",
  AO: "country_angola",
  AR: "country_argentina",
  AS: "country_american_samoa",
  AT: "country_austria",
  AU: "country_australia",
  AW: "country_aruba",
  AX: "country_aland_islands",
  AZ: "country_azerbaijan",
  BA: "country_bosnia_and_herzegovina",
  BB: "country_barbados",
  BD: "country_bangladesh",
  BE: "country_belgium",
  BF: "country_burkina_faso",
  BG: "country_bulgaria",
  BH: "country_bahrain",
  BI: "country_burundi",
  BJ: "country_benin",
  BL: "country_saint_barthelemy",
  BM: "country_bermuda",
  BN: "country_brunei",
  BO: "country_bolivia",
  BR: "country_brazil",
  BS: "country_bahamas",
  BT: "country_bhutan",
  BW: "country_republic_of_botswana",
  BY: "country_belarus",
  BZ: "country_belize",
  CA: "country_canada",
  CC: "country_cocos_islands",
  CD: "country_congo_dr",
  CF: "country_central_african_republic",
  CG: "country_republic_of_the_congo",
  CH: "country_switzerland",
  CI: "country_ivory_coast",
  CK: "country_cook_islands",
  CL: "country_chile",
  CM: "country_cameroon",
  CN: "country_china",
  CO: "country_colombia",
  CR: "country_costa_rica",
  CU: "country_cuba",
  CV: "country_cape_verde",
  CX: "country_christmas_island",
  CY: "country_cyprus",
  CZ: "country_czech_republic",
  DE: "country_germany",
  DJ: "country_djibouti",
  DK: "country_denmark",
  DM: "country_dominica",
  DO: "country_dominican_republic",
  DZ: "country_algeria",
  EC: "country_ecuador",
  EE: "country_estonia",
  EG: "country_egypt",
  EH: "country_western_sahara",
  ER: "country_eritrea",
  ES: "country_spain",
  ET: "country_ethiopia",
  FI: "country_finland",
  FJ: "country_fiji",
  FK: "country_falkland_islands",
  FM: "country_micronesia",
  FO: "country_faroe_islands",
  FR: "country_france",
  GA: "country_gabon",
  GB: "country_united_kingdom",
  GD: "country_grenada",
  GE: "country_georgia",
  GF: "country_french_guyana",
  GG: "country_guernsey",
  GH: "country_ghana",
  GI: "country_gibraltar",
  GL: "country_greenland",
  GM: "country_gambia",
  GN: "country_guinea",
  GP: "country_guadeloupe",
  GQ: "country_equatorial_guinea",
  GR: "country_greece",
  GT: "country_guatemala",
  GU: "country_guam",
  GW: "country_guinea_bissau",
  GY: "country_guyana",
  HK: "country_hongkong",
  HN: "country_honduras",
  HR: "country_croatia",
  HT: "country_haiti",
  HU: "country_hungary",
  ID: "country_indonesia",
  IE: "country_ireland",
  IL: "country_israel",
  IM: "country_isle_of_man",
  IN: "country_india",
  IO: "country_british_indian_ocean_territory",
  IQ: "country_iraq",
  IR: "country_iran",
  IS: "country_iceland",
  IT: "country_italy",
  JE: "country_jersey",
  JM: "country_jamaica",
  JO: "country_jordan",
  JP: "country_japan",
  KE: "country_kenya",
  KG: "country_kyrgyzstan",
  KH: "country_cambodia",
  KI: "country_kiribati",
  KM: "country_comoros",
  KN: "country_saint_kitts_and_nevis",
  KP: "country_north_korea",
  KR: "country_south_korea",
  KW: "country_kuwait",
  KY: "country_cayman_islands",
  KZ: "country_kazakhstan",
  LA: "country_laos",
  LB: "country_lebanon",
  LC: "country_saint_lucia",
  LI: "country_liechtenstein",
  LK: "country_sri_lanka",
  LR: "country_liberia",
  LS: "country_lesotho",
  LT: "country_lithuania",
  LU: "country_luxembourg",
  LV: "country_latvia",
  LY: "country_libya",
  MA: "country_morocco",
  MC: "country_monaco",
  MD: "country_moldova",
  ME: "country_montenegro",
  MF: "country_saint_martin",
  MG: "country_madagascar",
  MH: "country_marshall_islands",
  MK: "country_macedonia",
  ML: "country_mali",
  MM: "country_myanmar",
  MN: "country_mongolia",
  MO: "country_macau",
  MP: "country_northern_mariana_islands",
  MQ: "country_martinique",
  MR: "country_mauritania",
  MS: "country_montserrat",
  MT: "country_malta",
  MU: "country_mauritius",
  MV: "country_maldives",
  MW: "country_malawi",
  MX: "country_mexico",
  MY: "country_malaysia",
  MZ: "country_mozambique",
  NA: "country_namibia",
  NC: "country_new_caledonia",
  NE: "country_niger",
  NF: "country_norfolk",
  NG: "country_nigeria",
  NI: "country_nicaragua",
  NL: "country_netherlands",
  NO: "country_norway",
  NP: "country_nepal",
  NR: "country_nauru",
  NU: "country_niue",
  NZ: "country_new_zealand",
  OM: "country_oman",
  PA: "country_panama",
  PE: "country_peru",
  PF: "country_french_polynesia",
  PG: "country_papua_new_guinea",
  PH: "country_philippines",
  PK: "country_pakistan",
  PL: "country_poland",
  PM: "country_saint_pierre_and_miquelon",
  PN: "country_pitcairn_islands",
  PR: "country_puerto_rico",
  PS: "country_palestine",
  PT: "country_portugal",
  PW: "country_palau",
  PY: "country_paraguay",
  QA: "country_qatar",
  RE: "country_reunion",
  RO: "country_romania",
  RS: "country_serbia",
  RU: "country_russia",
  RW: "country_rwanda",
  SA: "country_saudi_arabia",
  SB: "country_solomon_islands",
  SC: "country_seychelles",
  SD: "country_sudan",
  SE: "country_sweden",
  SG: "country_singapore",
  SI: "country_slovenia",
  SJ: "country_svalbard",
  SK: "country_slovakia",
  SL: "country_sierra_leone",
  SM: "country_san_marino",
  SN: "country_senegal",
  SO: "country_somalia",
  SR: "country_suriname",
  ST: "country_sao_tome_and_principe",
  SV: "country_el_salvador",
  SY: "country_syria",
  SZ: "country_swaziland",
  TC: "country_turks_and_caicos",
  TD: "country_chad",
  TF: "country_french_southern_and_antarctic_lands",
  TG: "country_togo",
  TH: "country_thailand",
  TJ: "country_tajikistan",
  TK: "country_tokelau",
  TL: "country_timor_leste",
  TM: "country_turkmenistan",
  TN: "country_tunisia",
  TO: "country_tonga",
  TR: "country_turkey",
  TT: "country_trinidad_and_tobago",
  TV: "country_tuvalu",
  TW: "country_taiwan",
  TZ: "country_tanzania",
  UA: "country_ukraine",
  UG: "country_uganda",
  US: "country_usa",
  UY: "country_uruguay",
  UZ: "country_uzbekistan",
  VA: "country_vatican_city",
  VC: "country_saint_vincent_and_the_grenadines",
  VE: "country_venezuela",
  VG: "country_british_virgin_islands",
  VI: "country_united_states_virgin_islands",
  VN: "country_vietnam",
  VU: "country_vanuatu",
  WF: "country_wallis_and_futuna",
  WS: "country_samoa",
  YE: "country_yemen",
  YT: "country_mayotte",
  ZA: "country_south_africa",
  ZM: "country_zambia",
  ZW: "country_zimbabwe",
} as const;

export const SHIPPING_COUNTRY_OPTIONS = {
  PL: "country_poland",
  ES: "country_spain",
  FR: "country_france",
} as const;
