import React, { ReactNode } from "react";

import { Locale, addYears, format, formatDuration } from "date-fns";
import { enUS as localeEnUS, es as localeEs, fr as localeFr, pl as localePl } from "date-fns/locale";
import { useSearchParams } from "react-router-dom";

import i18n, { LocaleAPI, LocaleKey } from "../locales";
import { CamelToSnakeCaseNested, SnakeToCamelCase, camelToSnakeCase } from "../store/api";

export const snakeToCamelCase = <T extends string>(str: T) => {
  return str.replace(/([-_][a-z])/gi, res => res.toUpperCase().replace("-", "").replace("_", ""));
};

export const parseLocale = (locale: LocaleAPI) => {
  return locale.replace("_", "-") as LocaleKey;
};

export const parseLocaleToApi = (locale: LocaleKey) => {
  return locale.replace("-", "_") as LocaleAPI;
};

export const getCurrentLocaleFromDateFns = () => {
  return {
    en_US: localeEnUS,
    pl_PL: localePl,
    es_ES: localeEs,
    fr_FR: localeFr,
  }[getCurrentLocale()];
};

const fixDurationFormatForPolishMonth = (): Locale => ({
  ...getCurrentLocaleFromDateFns(),
  formatDistance: (format, count) => {
    if (getCurrentLocaleFromDateFns().formatDistance?.(format, count) === "miesiąc") {
      return "1 miesiąc";
    }
    return getCurrentLocaleFromDateFns().formatDistance?.(format, count);
  },
});

const formatDurationToString = (duration: Duration, locale?: Locale) => {
  return formatDuration(duration, { locale: locale || getCurrentLocaleFromDateFns() });
};

const formatDateToString = (date: Date, dateFormat: string = "dd MMM y") => {
  return format(date, dateFormat, { locale: getCurrentLocaleFromDateFns() });
};

export const getCurrentLocale = () => {
  return parseLocaleToApi(i18n.resolvedLanguage as LocaleKey);
};

export const setLocaleInCookie = (locale: LocaleAPI) => {
  const localeIso = parseLocale(locale);
  if (i18n.languages[0] !== localeIso) {
    i18n.changeLanguage(localeIso);

    document.cookie = `i18next=${localeIso}; expires=${addYears(new Date(), 1).toUTCString()}; path=/`;
  }
};

export const removeCookieI18next = (locale: LocaleKey) => {
  document.cookie = `i18next=${parseLocaleToApi(locale)}; expires=${new Date().toUTCString()}; path=/`;
};

export const camelKeysToSnake = <T extends unknown>(data: T): CamelToSnakeCaseNested<T> => {
  if (!isObject(data) || isDate(data)) {
    return data as CamelToSnakeCaseNested<T>;
  }

  if (Array.isArray(data)) {
    return data.map(datum => {
      return camelKeysToSnake(datum);
    }) as CamelToSnakeCaseNested<T>;
  }

  return Object.entries(data).reduce((result, [k, v]) => {
    let value;

    if (!isObject(v) || isDate(v)) {
      value = v;
    } else {
      value = camelKeysToSnake(v);
    }

    Object.assign(result, {
      [camelToSnakeCase(k)]: value,
    });

    return result;
  }, {}) as CamelToSnakeCaseNested<T>;
};

export const isDate = (value: any): value is Date => {
  return value instanceof Date;
};

export const isObject = (data: any): data is object => {
  return typeof data === "object" && data !== null;
};

export const getFullUrl = (path: string) => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ""}${path || "/"}`;
};

const checkStringContainOnlyDigits = (str: string | null): str is string => {
  if (!str) {
    return false;
  }

  return /^[0-9]+$/.test(str);
};

export const PRODUCTS_HELP = {
  manualUrl: "https://manuals.vasco-electronics.com/",
  faq: {
    pl_PL: "https://vasco-electronics.pl/subskrypcja#faq",
    en_US: "https://vasco-electronics.pl/subskrypcja#faq", // FIXME when ready
    es_ES: "https://traductor-de-voz.es/suscripcion#faq",
    fr_FR: "https://vasco-electronics.fr/abonnement#faq",
  },
} as const;

export const LINK_STORE_PAGE = {
  "pl-PL": "https://vasco-electronics.pl/translator/",
  "es-ES": "https://traductor-de-voz.es/traductor/",
  "fr-FR": "https://vasco-electronics.fr/traducteur-vocal/",
  contact: {
    "pl-PL": "kontakt@vasco-electronics.pl",
    "es-ES": "info@vasco-electronics.es",
    "en-US": "contact@vasco-electronics.co.uk",
    "fr-FR": "contact@vasco-electronics.fr",
  },
  phone: {
    "pl-PL": "+48 122 51 80 66",
    "es-ES": "+34 931 81 63 77",
    "en-US": "+44 2035147163",
    "fr-FR": "33 975 18 07 05",
  },
  manualUrl: "https://manuals.vasco-electronics.com/",
  faq: {
    pl_PL: "https://vasco-electronics.pl/subskrypcja#faq",
    en_US: "https://vasco-electronics.pl/subskrypcja#faq",
    es_ES: "https://traductor-de-voz.es/suscripcion#faq",
    fr_FR: "https://vasco-electronics.fr/abonnement#faq",
  },
} as const;

export const LINK_LANDING_PAGE = {
  "pl-PL": "https://vasco-electronics.pl/subskrypcja",
  "es-ES": "https://traductor-de-voz.es/suscripcion",
  "fr-FR": "https://vasco-electronics.fr/traducteur-vocal/",
};

export const getChildrenByName = (children: ReactNode, displayName: string) => {
  if (!children) return null;

  // @ts-ignore
  const components = React.Children.map(children, child => (child?.type?.displayName === displayName ? child : null));
  return (components || []).length > 0 ? components : null;
};

const useChildOfTypeOnly = (
  type: React.JSXElementConstructor<any>,
  children: React.ReactNode,
  errorMessage?: string,
) => {
  const [isValid, setIsValid] = React.useState(true);

  React.useEffect(() => {
    React.Children.forEach(children, child => {
      if (!React.isValidElement(child) || (React.isValidElement(child) && child.type !== type)) {
        setIsValid(false);
        console.error(errorMessage || "Component only accepts children of specific type");
      }
    });
  }, [children, type, errorMessage]);

  return isValid;
};

const useGetSearchParams = <T extends string[]>() => {
  const [params] = useSearchParams();
  const paramsParsed = {} as Record<SnakeToCamelCase<T[number]>, string | null>;
  const keys = params.keys();
  let isNextKeyExists = true;

  while (isNextKeyExists) {
    const key = keys.next().value as T[number];
    if (key) {
      const camelizedKey = snakeToCamelCase(key);
      paramsParsed[camelizedKey as SnakeToCamelCase<T[number]>] = params.get(key);
    } else {
      isNextKeyExists = false;
    }
  }

  return paramsParsed;
};

export const removeWhiteSpace = (value?: string) => {
  if (value) {
    return value.replace(/\s/g, "");
  }
  return "";
};

export const helpers = {
  useChildOfTypeOnly,
  getChildrenByName,
  useGetSearchParams,
  checkStringContainOnlyDigits,
  formatDateToString,
  formatDurationToString,
  fixDurationFormatForPolishMonth,
  camelKeysToSnake,
  removeWhiteSpace,
};
