import React from "react";

import { LocaleAPI } from "locales";
import { useTranslation } from "react-i18next";
import { Button, Flex, HelpIcon, ManualIcon } from "ui";
import { PRODUCTS_HELP, getCurrentLocale } from "utils/helpers";

export const ProductHelpToolbar: React.FC = () => {
  const { t } = useTranslation();
  const localeApi = getCurrentLocale();
  const goToManual = () => {
    window.open(PRODUCTS_HELP.manualUrl, "_blank");
  };

  const goToFaq = (locale: LocaleAPI) => {
    window.open(PRODUCTS_HELP.faq[locale], "_blank");
  };

  return (
    <Flex direction={["column", "row"]} alignItems={["flex-start", "center"]} gap={4}>
      <Button variant="link" onClick={goToManual} p={0}>
        <Flex gap={2} alignItems="center">
          <ManualIcon w={6} h={6} />
          {t("label_action_request_go_to_manual")}
        </Flex>
      </Button>
      {localeApi && (
        <Button variant="link" onClick={() => goToFaq(localeApi)} p={0}>
          <Flex gap={2} alignItems="center">
            <HelpIcon w={6} h={6} />
            {t("label_noun_faq").toUpperCase()}
          </Flex>
        </Button>
      )}
    </Flex>
  );
};
