import { useEffect } from "react";

import { useIdSearchParams } from "hooks";
import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ShippingAddress, useGetShippingAddressQuery, useUpdateShippingAddressMutation } from "store/api";
import { err } from "store/errors";
import { Flex, PageLoader, Stack, Text } from "ui";
import { camelKeysToSnake } from "utils/helpers";
import ShippingAddressForm from "views/commons/ShippingAddressForm";

const ChangeShippingAddress: React.FC = () => {
  const navigate = useNavigate();
  const id = useIdSearchParams() || 0;
  const { t } = useTranslation();
  const [updateShippingAddress, updateShippingAddressState] = useUpdateShippingAddressMutation();
  const { data: shippingAddress, isLoading, error } = useGetShippingAddressQuery({ id });

  useEffect(() => {
    if (err.hasSpecificStatus(error, 404)) {
      navigate(Paths.PageNotFound, { replace: true });
    }
  }, [error, navigate]);

  const onUpdateOrCreateShippingAddress = async (formState: ShippingAddress) => {
    try {
      const address = { ...formState };
      if (!address.phone) {
        address.dialCode = null;
      }

      const payload = camelKeysToSnake(address);
      await updateShippingAddress({ id, payload }).unwrap();
      goToProfile();
    } catch (error) {
      err.handler(error, [
        {
          code: "error_firstname_invalid_format",
          title: t("label_noun_first_name"),
          description: t("prompt_error_special_characters"),
        },
        {
          code: "error_lastname_invalid_format",
          title: t("label_noun_family_name"),
          description: t("prompt_error_special_characters"),
        },
        {
          code: "error_primary_address_line_invalid_format",
          title: t("label_noun_address"),
          description: t("prompt_error_wrong_format"),
        },
        {
          code: "error_optional_address_line_invalid_format",
          title: t("label_noun_address"),
          description: t("prompt_error_wrong_format"),
        },
        {
          code: "error_zip_code_line_invalid_format",
          title: t("label_noun_address_complement"),
          description: t("prompt_error_wrong_format"),
        },
        {
          code: "error_city_invalid_format",
          title: t("label_noun_city"),
          description: t("prompt_error_wrong_format"),
        },
        {
          code: "error_general",
          title: t("prompt_error_generic_toast_pt1"),
          description: t("label_action_request_error_generic_toast_pt2"),
        },
      ]);
    }
  };

  const goToProfile = () => {
    navigate(Paths.Profile);
  };

  if (isLoading) return <PageLoader>{t("label_action_info_loading")}</PageLoader>;

  const isSubmitting = updateShippingAddressState.isLoading;

  return (
    <Flex w="full" flexDirection="column">
      <Stack spacing={4} pt={6} pb={8}>
        <Text as="h1" variant="v18b">
          {t("label_action_request_add_shipping_address")}
        </Text>
        <Text variant="v16b">{t("prompt_set_new_shipping_address")}</Text>
      </Stack>
      <ShippingAddressForm
        onSubmit={onUpdateOrCreateShippingAddress}
        onCancel={goToProfile}
        isSubmitting={isSubmitting}
        defaultValues={shippingAddress}
      />
    </Flex>
  );
};

export default ChangeShippingAddress;
