import { addresses } from "./addresses";
import { devices } from "./devices";
import { gtm } from "./gtm";
import { helpers } from "./helpers";
import { markets } from "./markets";
import { order } from "./order";
import { salesProducts } from "./salesProducts";
import { subscriptions } from "./subscriptions";

export const utils = {
  addresses,
  helpers,
  salesProducts,
  devices,
  subscriptions,
  order,
  gtm,
  markets,
};
