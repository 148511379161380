import { LocaleAPI } from "../../locales";
import { Method, ResBuilder, api } from "./api";
import { UserRaw } from "./auth.api";

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    updateUser: builder.mutation<ResBuilder<UserRaw>, UserUpdateReq>({
      query: payload => ({
        url: "users",
        method: Method.Patch,
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useUpdateUserMutation } = userApi;

export type UserUpdateReq = {
  locale: LocaleAPI;
};
