import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import countries_en from "./countries/en_US.json";
import countries_es from "./countries/es_ES.json";
import countries_fr from "./countries/fr_FR.json";
import countries_pl from "./countries/pl_PL.json";
import en from "./translations/resources/vasco-ecosystem-client/en_US.json";
import es from "./translations/resources/vasco-ecosystem-client/es_ES.json";
import fr from "./translations/resources/vasco-ecosystem-client/fr_FR.json";
import pl from "./translations/resources/vasco-ecosystem-client/pl_PL.json";

export const LOCALES = {
  "en-US": {
    short: "EN",
    translationKey: "str_language_english",
  },
  "es-ES": {
    short: "ESP",
    translationKey: "str_language_spanish",
  },
  "pl-PL": {
    short: "PL",
    translationKey: "str_language_polish",
  },
  "fr-FR": {
    short: "FR",
    translationKey: "str_language_french",
  },
} as const;

export type LocaleKey = keyof typeof LOCALES;
export type ParseLocaleAPI<S extends LocaleKey> = S extends `${infer T}-${infer U}` ? `${T}_${U}` : S;
export type LocaleAPI = ParseLocaleAPI<LocaleKey>;

export const resources = {
  "en-US": {
    translation: { ...en, ...countries_en },
  },
  "pl-PL": {
    translation: { ...pl, ...countries_pl },
  },
  "es-ES": {
    translation: { ...es, ...countries_es },
  },
  "fr-FR": {
    translation: { ...fr, ...countries_fr },
  },
};

const localeKeys = Object.keys(resources);

const fallbackShortLng: Record<string, keyof typeof resources> = {
  en: "en-US",
  pl: "pl-PL",
  es: "es-ES",
  fr: "fr-FR",
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "test",
    fallbackLng: code => {
      const langCode = code.replace("_", "-");
      if (localeKeys.includes(langCode)) {
        return langCode;
      }

      const shortCode = langCode.split("-");
      return fallbackShortLng[shortCode[0]] || "en-US";
    },
    resources,
    detection: {
      order: ["querystring", "cookie", "navigator"],
      caches: ["cookie"],
      cookieMinutes: 525948, // 1 year in minutes
    },
  });

export default i18n;
