import { useTranslation } from "react-i18next";
import {
  BodyText,
  Button,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  Stack,
  TitleH1,
} from "ui";

type CardVerificationFailedProps = {
  onRetry: () => void;
  onCancel?: () => void;
};

const CardVerificationFailed: React.FC<CardVerificationFailedProps> = ({ onRetry, onCancel }) => {
  const { t } = useTranslation();
  return (
    <InfoPanel>
      <InfoPanelIllustration illustrationName="ConfirmationCardIllustration" />
      <InfoPanelContent>
        <Stack spacing="m">
          <TitleH1 color="redAlert">{t("label_action_info_card_verification_failed")}</TitleH1>
          <BodyText variant="body16">{t("prompt_card_couldnt_be_verified")}</BodyText>
        </Stack>
      </InfoPanelContent>
      <InfoPanelAction>
        {onCancel && (
          <Button variant="secondary" onClick={onCancel}>
            {t("label_action_request_cancel")}
          </Button>
        )}
        <Button onClick={onRetry}>{t("label_other_try_again")}</Button>
      </InfoPanelAction>
    </InfoPanel>
  );
};

export default CardVerificationFailed;
