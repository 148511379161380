import React from "react";

import { createIcon } from "@chakra-ui/react";

export const RectangleIcon = createIcon({
  displayName: "RectangleIcon",
  viewBox: "0 0 16 16",
  defaultProps: {
    fill: "transparent",
  },
  path: <rect width={16} height={16} fill="currentColor" />,
});
