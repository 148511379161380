import React from "react";

import { ButtonProps, chakra, useStyleConfig } from "@chakra-ui/react";

export interface IconButtonProps extends ButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  size?: "s" | "m" | "l" | "xl" | "xxl";
  variant?: "primary" | "secondary";
}

export const IconButton: React.FC<IconButtonProps> = ({ children, size, variant, onClick, ...buttonProps }) => {
  const styles = useStyleConfig("IconButton", { variant, size });

  return (
    <chakra.button __css={styles} onClick={onClick} {...buttonProps}>
      {children}
    </chakra.button>
  );
};
