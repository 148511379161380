import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Link } from "ui";
import * as yup from "yup";

type Props = { onSubmit: (formState: SignInFormState) => Promise<void>; isSubmitting?: boolean };

export type SignInFormState = {
  email: string;
  password: string;
};

const SignInForm: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<SignInFormState>({
    mode: "onSubmit",
    resolver: yupResolver(
      yup
        .object({
          email: yup.string().required(t("prompt_error_mandatory_e-mail")),
          password: yup.string().required(t("prompt_error_mandatory_password")),
        })
        .required(),
    ),
  });

  const onSubmit = () => {
    const formState = getValues();
    props.onSubmit(formState);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column">
        <FormControl isInvalid={errors.hasOwnProperty("email")}>
          <FormLabel htmlFor="email">{t("label_noun_e-mail")}</FormLabel>
          <Input
            id="email"
            placeholder={t("label_action_request_suggestion_e-mail")}
            type="email"
            {...register("email")}
            autoComplete="user"
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.hasOwnProperty("password")}>
          <FormLabel htmlFor="password">{t("label_noun_password")}</FormLabel>
          <Input
            id="password"
            placeholder={t("label_action_request_suggestion_password")}
            type="password"
            {...register("password")}
            autoComplete="current-password"
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        <Link as={RouterLink} to="/auth/reset-password" mb={["xxl", "xl"]}>
          {t("label_action_info_forgot_password")}
        </Link>
        <Button type="submit" isLoading={props.isSubmitting}>
          {t("label_action_request_sign_in")}
        </Button>
      </Flex>
    </form>
  );
};

export default SignInForm;
