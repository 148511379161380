import React from "react";

import { BodyText, Box, Divider, Flex, Stack } from "../../atoms";

export const Footer = ({ children, maxW = 1200 }: { children: React.ReactNode; maxW?: number }) => {
  return (
    <Flex backgroundColor="black" width="full" justifyContent="center">
      <Box maxWidth={maxW} w="full" pb="l" pt="m" px={["m", "xl", "xxl", 120]}>
        <Divider variant="white" width="full" mb={["xs", "m", "m"]} />
        <Flex flexDirection={["column", "column", "row"]} justifyContent="space-between" pb={[0, 0, "xxs"]}>
          <Stack direction={["column", "column", "row"]} spacing={[0, "xxs"]}>
            <BodyText color="white">{`Copyright © ${new Date().getFullYear()} Vasco Electronics.`}</BodyText>
            <BodyText color="white">All rights reserved</BodyText>
          </Stack>
          <Stack direction="row">{children}</Stack>
        </Flex>
      </Box>
    </Flex>
  );
};
