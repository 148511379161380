import React from "react";

import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useMatch, useNavigate } from "react-router-dom";
import { Flex, StatusHeader } from "ui";

const PairDevice: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch(location.pathname);
  const { t } = useTranslation();

  React.useEffect(() => {
    // Move to the first step if path is /pair-device or /pair-device/
    if (match && match.pathnameBase === Paths.PairDevice) {
      navigate(Paths.PairDeviceStep1);
    }
  }, [navigate, match]);

  const steps = [
    { index: 0, title: t("label_noun_pairing_new_device"), path: Paths.PairDeviceStep1 },
    { index: 1, title: t("label_action_info_entering_code"), path: Paths.PairDeviceStep2 },
    { index: 2, title: t("label_action_info_finalization"), path: Paths.PairDeviceStep3 },
  ];

  const activeStep = match ? steps.find(step => step.path === match.pathnameBase) || steps[0] : steps[0];
  const lastReachableStep = steps.find(step => step.index === activeStep.index) || steps[0];

  return (
    <Flex direction="column" flex={1}>
      <StatusHeader steps={steps} activeStep={activeStep} lastReachableStep={lastReachableStep}>
        <Outlet />
      </StatusHeader>
    </Flex>
  );
};

export default PairDevice;
