import React from "react";

import { Fade, Slide, chakra } from "@chakra-ui/react";

import { Box, BoxProps } from "../../atoms";
import theme from "../../foundations";

type Props = BoxProps & {
  isOpen: boolean;
  onClose: () => void;
  offsetY?: BoxProps["top"];
};

const MenuOverlay = chakra("div", {
  baseStyle: {
    position: "fixed",
    cursor: "pointer",
    display: "flex",
    top: 0,
    left: 0,
    w: "full",
    h: "100vh",
    bgColor: "rgba(44,44,44,0.4)",
  },
});

const ANIM_DURATION = 0.4;
const ANIM_EASY = [0.01, 0.9, 0.99, 1];

export const MenuMobile: React.FC<Props> = ({ isOpen, onClose, children, offsetY = theme.sizes.menu, ...props }) => {
  return (
    <Box display={["flex", "flex", "none"]} position="fixed" left={0} top={offsetY} zIndex={1099} {...props}>
      <Fade
        in={isOpen}
        unmountOnExit
        transition={{
          enter: { duration: ANIM_DURATION },
        }}
      >
        <MenuOverlay onClick={onClose} />
      </Fade>
      <Slide
        in={isOpen}
        direction="top"
        unselectable="off"
        unmountOnExit
        transition={{
          enter: {
            duration: ANIM_DURATION,
            ease: ANIM_EASY,
          },
        }}
      >
        <Box bgColor="white" mt={offsetY} maxWidth="menuMobileWidth" borderBottomRadius="l">
          <Box w="full">{children}</Box>
        </Box>
      </Slide>
    </Box>
  );
};
