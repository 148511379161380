import React from "react";

import { chakra } from "@chakra-ui/react";

export const Asterisk = () => {
  return (
    <chakra.span as="span" role="presentation" color="violet.300" fontWeight={700}>
      *
    </chakra.span>
  );
};
