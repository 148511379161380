import { Market, SHIPPING_COUNTRY_OPTIONS } from "../store/api";

const MARKETS = [
  { code: "PL", name: "poland" },
  { code: "ES", name: "spain" },
  { code: "FR", name: "france" },
] as const;

export const DIAL_CODES = [
  { code: "+972", countryCode: "IL" },
  { code: "+93", countryCode: "AF" },
  { code: "+355", countryCode: "AL" },
  { code: "+213", countryCode: "DZ" },
  { code: "+1684", countryCode: "AS" },
  { code: "+376", countryCode: "AD" },
  { code: "+244", countryCode: "AO" },
  { code: "+1264", countryCode: "AI" },
  { code: "+1268", countryCode: "AG" },
  { code: "+54", countryCode: "AR" },
  { code: "+374", countryCode: "AM" },
  { code: "+297", countryCode: "AW" },
  { code: "+61", countryCode: "AU" },
  { code: "+43", countryCode: "AT" },
  { code: "+994", countryCode: "AZ" },
  { code: "+1242", countryCode: "BS" },
  { code: "+973", countryCode: "BH" },
  { code: "+880", countryCode: "BD" },
  { code: "+1246", countryCode: "BB" },
  { code: "+375", countryCode: "BY" },
  { code: "+32", countryCode: "BE" },
  { code: "+501", countryCode: "BZ" },
  { code: "+229", countryCode: "BJ" },
  { code: "+1441", countryCode: "BM" },
  { code: "+975", countryCode: "BT" },
  { code: "+387", countryCode: "BA" },
  { code: "+267", countryCode: "BW" },
  { code: "+55", countryCode: "BR" },
  { code: "+246", countryCode: "IO" },
  { code: "+359", countryCode: "BG" },
  { code: "+226", countryCode: "BF" },
  { code: "+257", countryCode: "BI" },
  { code: "+855", countryCode: "KH" },
  { code: "+237", countryCode: "CM" },
  { code: "+1", countryCode: "CA" },
  { code: "+238", countryCode: "CV" },
  { code: "+345", countryCode: "KY" },
  { code: "+236", countryCode: "CF" },
  { code: "+235", countryCode: "TD" },
  { code: "+56", countryCode: "CL" },
  { code: "+86", countryCode: "CN" },
  { code: "+61", countryCode: "CX" },
  { code: "+57", countryCode: "CO" },
  { code: "+269", countryCode: "KM" },
  { code: "+242", countryCode: "CG" },
  { code: "+682", countryCode: "CK" },
  { code: "+506", countryCode: "CR" },
  { code: "+385", countryCode: "HR" },
  { code: "+53", countryCode: "CU" },
  { code: "+537", countryCode: "CY" },
  { code: "+420", countryCode: "CZ" },
  { code: "+45", countryCode: "DK" },
  { code: "+253", countryCode: "DJ" },
  { code: "+1767", countryCode: "DM" },
  { code: "+1849", countryCode: "DO" },
  { code: "+593", countryCode: "EC" },
  { code: "+20", countryCode: "EG" },
  { code: "+503", countryCode: "SV" },
  { code: "+240", countryCode: "GQ" },
  { code: "+291", countryCode: "ER" },
  { code: "+372", countryCode: "EE" },
  { code: "+251", countryCode: "ET" },
  { code: "+298", countryCode: "FO" },
  { code: "+679", countryCode: "FJ" },
  { code: "+358", countryCode: "FI" },
  { marketName: "france", code: "+33", countryCode: "FR" },
  { code: "+33", countryCode: "FR" },
  { code: "+594", countryCode: "GF" },
  { code: "+689", countryCode: "PF" },
  { code: "+241", countryCode: "GA" },
  { code: "+220", countryCode: "GM" },
  { code: "+995", countryCode: "GE" },
  { code: "+49", countryCode: "DE" },
  { code: "+233", countryCode: "GH" },
  { code: "+350", countryCode: "GI" },
  { code: "+30", countryCode: "GR" },
  { code: "+299", countryCode: "GL" },
  { code: "+1473", countryCode: "GD" },
  { code: "+590", countryCode: "GP" },
  { code: "+1671", countryCode: "GU" },
  { code: "+502", countryCode: "GT" },
  { code: "+224", countryCode: "GN" },
  { code: "+245", countryCode: "GW" },
  { code: "+595", countryCode: "GY" },
  { code: "+509", countryCode: "HT" },
  { code: "+504", countryCode: "HN" },
  { code: "+36", countryCode: "HU" },
  { code: "+354", countryCode: "IS" },
  { code: "+91", countryCode: "IN" },
  { code: "+62", countryCode: "ID" },
  { code: "+964", countryCode: "IQ" },
  { code: "+353", countryCode: "IE" },
  { code: "+972", countryCode: "IL" },
  { code: "+39", countryCode: "IT" },
  { code: "+1876", countryCode: "JM" },
  { code: "+81", countryCode: "JP" },
  { code: "+962", countryCode: "JO" },
  { code: "+77", countryCode: "KZ" },
  { code: "+254", countryCode: "KE" },
  { code: "+686", countryCode: "KI" },
  { code: "+965", countryCode: "KW" },
  { code: "+996", countryCode: "KG" },
  { code: "+371", countryCode: "LV" },
  { code: "+961", countryCode: "LB" },
  { code: "+266", countryCode: "LS" },
  { code: "+231", countryCode: "LR" },
  { code: "+423", countryCode: "LI" },
  { code: "+370", countryCode: "LT" },
  { code: "+352", countryCode: "LU" },
  { code: "+261", countryCode: "MG" },
  { code: "+265", countryCode: "MW" },
  { code: "+60", countryCode: "MY" },
  { code: "+960", countryCode: "MV" },
  { code: "+223", countryCode: "ML" },
  { code: "+356", countryCode: "MT" },
  { code: "+692", countryCode: "MH" },
  { code: "+596", countryCode: "MQ" },
  { code: "+222", countryCode: "MR" },
  { code: "+230", countryCode: "MU" },
  { code: "+262", countryCode: "YT" },
  { code: "+52", countryCode: "MX" },
  { code: "+377", countryCode: "MC" },
  { code: "+976", countryCode: "MN" },
  { code: "+382", countryCode: "ME" },
  { code: "+1664", countryCode: "MS" },
  { code: "+212", countryCode: "MA" },
  { code: "+95", countryCode: "MM" },
  { code: "+264", countryCode: "NA" },
  { code: "+674", countryCode: "NR" },
  { code: "+977", countryCode: "NP" },
  { code: "+31", countryCode: "NL" },
  { code: "+599", countryCode: "AN" },
  { code: "+687", countryCode: "NC" },
  { code: "+64", countryCode: "NZ" },
  { code: "+505", countryCode: "NI" },
  { code: "+227", countryCode: "NE" },
  { code: "+234", countryCode: "NG" },
  { code: "+683", countryCode: "NU" },
  { code: "+672", countryCode: "NF" },
  { code: "+1670", countryCode: "MP" },
  { code: "+47", countryCode: "NO" },
  { code: "+968", countryCode: "OM" },
  { code: "+92", countryCode: "PK" },
  { code: "+680", countryCode: "PW" },
  { code: "+507", countryCode: "PA" },
  { code: "+675", countryCode: "PG" },
  { code: "+595", countryCode: "PY" },
  { code: "+51", countryCode: "PE" },
  { code: "+63", countryCode: "PH" },
  { marketName: "poland", code: "+48", countryCode: "PL" },
  { code: "+351", countryCode: "PT" },
  { code: "+1 939", countryCode: "PR" },
  { code: "+974", countryCode: "QA" },
  { code: "+40", countryCode: "RO" },
  { code: "+250", countryCode: "RW" },
  { code: "+685", countryCode: "WS" },
  { code: "+378", countryCode: "SM" },
  { code: "+966", countryCode: "SA" },
  { code: "+221", countryCode: "SN" },
  { code: "+381", countryCode: "RS" },
  { code: "+248", countryCode: "SC" },
  { code: "+232", countryCode: "SL" },
  { code: "+65", countryCode: "SG" },
  { code: "+421", countryCode: "SK" },
  { code: "+386", countryCode: "SI" },
  { code: "+677", countryCode: "SB" },
  { code: "+27", countryCode: "ZA" },
  { code: "+500", countryCode: "GS" },
  { marketName: "spain", code: "+34", countryCode: "ES" },
  { code: "+94", countryCode: "LK" },
  { code: "+249", countryCode: "SD" },
  { code: "+597", countryCode: "SR" },
  { code: "+268", countryCode: "SZ" },
  { code: "+46", countryCode: "SE" },
  { code: "+41", countryCode: "CH" },
  { code: "+992", countryCode: "TJ" },
  { code: "+66", countryCode: "TH" },
  { code: "+228", countryCode: "TG" },
  { code: "+690", countryCode: "TK" },
  { code: "+676", countryCode: "TO" },
  { code: "+1868", countryCode: "TT" },
  { code: "+216", countryCode: "TN" },
  { code: "+90", countryCode: "TR" },
  { code: "+993", countryCode: "TM" },
  { code: "+1649", countryCode: "TC" },
  { code: "+688", countryCode: "TV" },
  { code: "+256", countryCode: "UG" },
  { code: "+380", countryCode: "UA" },
  { code: "+971", countryCode: "AE" },
  { code: "+44", countryCode: "GB" },
  { code: "+1", countryCode: "US" },
  { code: "+598", countryCode: "UY" },
  { code: "+998", countryCode: "UZ" },
  { code: "+678", countryCode: "VU" },
  { code: "+681", countryCode: "WF" },
  { code: "+967", countryCode: "YE" },
  { code: "+260", countryCode: "ZM" },
  { code: "+263", countryCode: "ZW" },
  { code: "", countryCode: "AX" },
  { code: null, countryCode: "AQ" },
  { code: "+591", countryCode: "BO" },
  { code: "+673", countryCode: "BN" },
  { code: "+61", countryCode: "CC" },
  { code: "+243", countryCode: "CD" },
  { code: "+225", countryCode: "CI" },
  { code: "+500", countryCode: "FK" },
  { code: "+44", countryCode: "GG" },
  { code: "+379", countryCode: "VA" },
  { code: "+852", countryCode: "HK" },
  { code: "+98", countryCode: "IR" },
  { code: "+44", countryCode: "IM" },
  { code: "+44", countryCode: "JE" },
  { code: "+850", countryCode: "KP" },
  { code: "+82", countryCode: "KR" },
  { code: "+856", countryCode: "LA" },
  { code: "+218", countryCode: "LY" },
  { code: "+853", countryCode: "MO" },
  { code: "+389", countryCode: "MK" },
  { code: "+691", countryCode: "FM" },
  { code: "+373", countryCode: "MD" },
  { code: "+258", countryCode: "MZ" },
  { code: "+970", countryCode: "PS" },
  { code: "+872", countryCode: "PN" },
  { code: "+262", countryCode: "RE" },
  { code: "+7", countryCode: "RU" },
  { code: "+590", countryCode: "BL" },
  { code: "+290", countryCode: "SH" },
  { code: "+1869", countryCode: "KN" },
  { code: "+1758", countryCode: "LC" },
  { code: "+590", countryCode: "MF" },
  { code: "+508", countryCode: "PM" },
  { code: "+1784", countryCode: "VC" },
  { code: "+239", countryCode: "ST" },
  { code: "+252", countryCode: "SO" },
  { code: "+47", countryCode: "SJ" },
  { code: "+963", countryCode: "SY" },
  { code: "+886", countryCode: "TW" },
  { code: "+255", countryCode: "TZ" },
  { code: "+670", countryCode: "TL" },
  { code: "+58", countryCode: "VE" },
  { code: "+84", countryCode: "VN" },
  { code: "+1284", countryCode: "VG" },
  { code: "+1340", countryCode: "VI" },
];

export const PHONE_NUMBER_REGEX = /^[0-9 ]*$/;
export const VALIDATE_PHONE_NUMBER_REGEX = /^(?=.*\d)[\d ]+$/;
export const VALIDATE_NAME_AND_SURNAME_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(?!\s*$)(?:[^0-9!<>,;?=+()\/\\\\@#"°*`{}_^$%:¤\[\]|\.。]|[。\.](?:\s|$))*$/u;

export const getAllDialCodes = () => {
  return DIAL_CODES.map(dialCode => (dialCode.code || "").replace(/\s/g, ""));
};

export const checkIsCountryInCurrentMarket = (currentMarket: Market | null, countryCode: string) => {
  const market = MARKETS.find(availableMarket => availableMarket.name === currentMarket?.name);
  return market?.code === countryCode;
};

export const checkIsCountryInShipmentOptions = (countryCode: string) => {
  return Object.keys(SHIPPING_COUNTRY_OPTIONS).includes(countryCode);
};

const TENERIFE_POSTAL_CODES = [
  "38000",
  "38001",
  "38002",
  "38003",
  "38004",
  "38005",
  "38006",
  "38007",
  "38008",
  "38009",
  "38010",
  "38071",
  "38080",
  "38108",
  "38109",
  "38111",
  "38120",
  "38170",
  "38180",
  "38190",
  "38200",
  "38201",
  "38202",
  "38203",
  "38204",
  "38205",
  "38206",
  "38207",
  "38208",
  "38240",
  "38670",
  "38677",
  "38678",
  "38679",
  "38830",
  "38812",
  "38550",
  "38589",
  "38640",
  "38649",
  "38726",
  "38",
  "38480",
  "38914",
  "38917",
  "38570",
  "38911",
  "38740",
  "38450",
  "38787",
  "38588",
  "38594",
  "38600",
  "38616",
  "38617",
  "38440",
  "38680",
  "38688",
  "38820",
  "38829",
  "38869",
  "38890",
  "38430",
  "38760",
  "38370",
  "38300",
  "38750",
  "38400",
  "38789",
  "38715",
  "38410",
  "38720",
  "38420",
  "38620",
  "38800",
  "38879",
  "38700",
  "38390",
  "38398",
  "38399",
  "38684",
  "38690",
  "38360",
  "38350",
  "38355",
  "38356",
  "38358",
  "38379",
  "38435",
  "38770",
  "38280",
  "38292",
  "38780",
  "38870",
  "38840",
  "38915",
  "38380",
  "38613",
  "35000",
  "35001",
  "35002",
  "35003",
  "35004",
  "35005",
  "35006",
  "35007",
  "35008",
  "35009",
  "35010",
  "35011",
  "35012",
  "35013",
  "35014",
  "35015",
  "35016",
  "35017",
  "35018",
  "35019",
  "35020",
  "35080",
  "35100",
  "35109",
  "35110",
  "35118",
  "35119",
  "35120",
  "35129",
  "35130",
  "35138",
  "35200",
  "35212",
  "35214",
  "35215",
  "35219",
  "35220",
  "35240",
  "35259",
  "35310",
  "35339",
  "35412",
  "35500",
  "35468",
  "35480",
  "35488",
  "35489",
  "35260",
  "35",
  "35610",
  "35630",
  "35350",
  "35400",
  "35411",
  "35413",
  "35414",
  "35415",
  "35637",
  "35430",
  "35431",
  "35432",
  "35460",
  "35469",
  "35520",
  "35250",
  "35140",
  "35420",
  "35421",
  "35422",
  "35423",
  "35640",
  "35628",
  "35600",
  "35612",
  "35613",
  "35107",
  "35108",
  "35280",
  "35290",
  "35300",
  "35307",
  "35308",
  "35309",
  "35450",
  "35539",
  "35368",
  "35369",
  "35210",
  "35216",
  "35330",
  "35338",
  "35518",
  "35560",
  "35561",
  "35629",
  "35340",
  "35217",
  "35218",
  "35320",
  "35328",
  "35329",
  "35570",
  "35571",
  "35580",
  "38110",
  "38530",
  "35638",
];

const checkIsTenerifePostalCode = (country: string, val?: string) => {
  if (val && country === "ES") {
    if (TENERIFE_POSTAL_CODES.includes(val)) {
      return false;
    }
  }
  return true;
};

export const addresses = {
  checkIsCountryInCurrentMarket,
  getAllDialCodes,
  checkIsCountryInShipmentOptions,
  checkIsTenerifePostalCode,
  MARKETS,
  DIAL_CODES,
  TENERIFE_POSTAL_CODES,
};
