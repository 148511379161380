import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ErrorWhiteIcon = createIcon({
  displayName: "ErrorWhiteIcon",
  viewBox: "0 0 16 16",
  defaultProps: {
    fill: "transparent",
  },
  path: [
    <circle cx="8" cy="8" r="8" fill="white" />,
    <path
      d="M8.91249 3.7L8.76249 10.1187H7.47499L7.31874 3.7H8.91249ZM7.27499 12.0875C7.27499 11.8583 7.34999 11.6667 7.49999 11.5125C7.65416 11.3542 7.86666 11.275 8.13749 11.275C8.40416 11.275 8.61458 11.3542 8.76874 11.5125C8.92291 11.6667 8.99999 11.8583 8.99999 12.0875C8.99999 12.3083 8.92291 12.4979 8.76874 12.6562C8.61458 12.8104 8.40416 12.8875 8.13749 12.8875C7.86666 12.8875 7.65416 12.8104 7.49999 12.6562C7.34999 12.4979 7.27499 12.3083 7.27499 12.0875Z"
      fill="red"
    />,
  ],
});
