import { defineStyle, defineStyleConfig } from "@chakra-ui/system";

const baseStyle = defineStyle({
  display: "flex",
  borderRadius: "full",
  color: "violet.300",
  borderColor: "violet.300",
  borderWidth: 2,
  alignItems: "center",
  fontWeight: "medium",
  fontSize: "1rem",
  justifyContent: "center",
  transitionProperty: "background-color, color",
  transitionTimingFunction: "ease-in-out",
  transitionDuration: "normal",
  h: "2.25rem",
  w: "2.25rem",
  minW: "2.25rem",
  minH: "2.25rem",
  _focus: {
    boxShadow: "none",
  },
  _hover: {
    borderWidth: 1,
    color: "violet.500",
    borderColor: "violet.500",
    _disabled: {
      cursor: "default",
      boxShadow: "none",
      color: "grayDark",
      borderColor: "grayDark",
    },
  },
  _active: { color: "violet.300", borderColor: "violet.500" },
  _focusVisible: {
    boxShadow: "outline",
    outline: "none",
  },
  _disabled: {
    borderWidth: 1,
    color: "grayDark",
    borderColor: "grayDark",
  },
});

export const StatusButton = defineStyleConfig({ baseStyle });
