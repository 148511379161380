import { formAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleRequiredIndicator = defineStyle({
  marginStart: 1,
  color: "violet.300",
});

const baseStyle = definePartsStyle({
  container: { width: "100%", position: "relative", pt: 0, pb: 9 },
  requiredIndicator: baseStyleRequiredIndicator,
});

export const FormControl = defineMultiStyleConfig({
  baseStyle,
  variants: {
    horizontal: {
      container: {
        pt: "m",
        pb: "l",
        pl: "10.75rem",
        maxWidth: "36rem",
        label: {
          position: "absolute",
          left: 0,
          mr: 0,
          minW: "172px",
          maxW: "172px",
        },
      },
    },
  },
});
