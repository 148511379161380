import { alertAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  container: {
    color: "white",
    py: "xxs",
    px: 5,
    alignItems: "start",
    borderRadius: "m",
    textAlign: "start",
    width: "auto",
  },
  title: {
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  description: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
});

const variantPrimary = definePartsStyle(props => {
  const { status } = props;

  return {
    error: {
      container: {
        bg: "redAlert",
      },
    },
    info: {
      container: {
        bg: "black",
      },
    },
    success: {
      container: {
        bg: "greenAlert",
      },
    },
    warning: {
      container: {
        bg: "redAlert",
      },
    },
  }[status as "error" | "info" | "success" | "warning"];
});

export const Alert = defineMultiStyleConfig({
  baseStyle,
  variants: { primary: variantPrimary },
  defaultProps: {
    variant: "primary",
  },
});
