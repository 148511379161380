import { Flex as ChakraFlex, ComponentWithAs, FlexProps as Props, SystemProps } from "@chakra-ui/react";
import * as CSS from "csstype";

export interface FlexProps extends Props {
  /**
   * Shorthand for `alignItems` style prop
   * @type SystemProps["alignItems"]
   */
  align?: CSS.Properties["alignItems"];

  /**
   * Shorthand for `justifyContent` style prop
   * @type SystemProps["justifyContent"]
   */
  justify?: SystemProps["justifyContent"];

  /**
   * Shorthand for `flexWrap` style prop
   * @type SystemProps["flexWrap"]
   */
  wrap?: SystemProps["flexWrap"];

  /**
   * Shorthand for `flexDirection` style prop
   * @type SystemProps["flexDirection"]
   */
  direction?: SystemProps["flexDirection"];

  /**
   * Shorthand for `flexBasis` style prop
   * @type SystemProps["flexBasis"]
   */
  basis?: SystemProps["flexBasis"];

  /**
   * Shorthand for `flexGrow` style prop
   * @type SystemProps["flexGrow"]
   */
  grow?: SystemProps["flexGrow"];

  /**
   * Shorthand for `flexShrink` style prop
   * @type SystemProps["flexShrink"]
   */
  shrink?: SystemProps["flexShrink"];
}

/**
 * Flex is Box with display: flex and comes with helpful style shorthand. It renders a div element.
 */
export const Flex = ChakraFlex as ComponentWithAs<"div", FlexProps>;
