import { StyleFunctionProps } from "@chakra-ui/system";

const getCookiebotStyles = (props: StyleFunctionProps) => {
  const { space, colors, fontSizes, fontWeights } = props.theme;

  return {
    "#CybotCookiebotDialog": {
      fontFamily: "Noto Sans, sans-serif !important",
      padding: [`${space["xxxl"]} 0 ${space["xl"]} 0`, space["xxxl"]],
      borderRadius: [`${space[0]} !important`, `${space[2]} !important`],
      boxShadow: "unset !important",
      maxWidth: ["auto !important", "636px !important", "868px !important"],
      border: ["unset !important", `2px solid ${colors.grayLight} !important`],

      "a, button, input+span": {
        outlineColor: `${colors.blue[100]} !important`,
      },

      a: {
        color: `${colors.primary[300]} !important`,
        lineHeight: `${space["l"]} !important`,
      },

      h2: {
        fontFamily: "Old Standard TT, serif !important",
        fontSize: `24px !important`,
        marginBottom: `${space["m"]} !important`,
        color: `${colors.black} !important`,
        fontWeight: "500 !important",
        lineHeight: `${space["l"]} !important`,
      },

      ".CybotCookiebotScrollContainer": {
        padding: `${space[0]} !important`,
        border: "none !important",
      },

      // Header
      "#CybotCookiebotDialogHeader": {
        display: "none !important",
      },

      // Navigation
      "#CybotCookiebotDialogNav": {
        borderBottom: "none  !important",
        width: "auto !important",
        paddingBottom: [`${space[0]} !important`, `${space[6]} !important`],

        "#CybotCookiebotDialogNavList .CybotCookiebotDialogNavItem:not(:last-child)": {
          paddingRight: [space[0], space[1]],
          paddingBottom: [space[1], space[0]],
          margin: [space[0], space[0], `${space[0]} auto`],
        },
        "#CybotCookiebotDialogNavList .CybotCookiebotDialogNavItem": {
          margin: [space[0], space[0], `${space[0]} auto`],
        },

        ".CybotCookiebotDialogNavItems": {
          flexDirection: ["column !important", "row !important"],
        },

        ".CybotCookiebotDialogNavItemLink": {
          padding: `6px ${space[6]}`,
          fontSize: space[4],
          fontWeight: "400",
          borderBottom: ["unset", `${space[2]} solid ${colors.grayLight}`],
          borderLeft: [`${space[2]} solid ${colors.grayLight}`, "unset"],
          color: `${colors.black} !important`,
          textAlign: "left",
          lineHeight: space[5],

          "&:hover": {
            borderColor: colors.primary[300],
          },

          "&.CybotCookiebotDialogActive": {
            borderColor: colors.primary[300],
            borderTop: "unset",
            borderRight: "unset",
            color: `${colors.primary[300]} !important`,
          },
        },
      },

      // Content
      "#CybotCookiebotDialogTabContent": {
        "label, .CybotCookiebotDialogDetailBodyContentCookieContainerButton": {
          fontWeight: fontWeights.medium,
        },

        "#CybotCookiebotDialogBodyContentText": {
          fontSize: fontSizes.md,
          color: colors.black,
        },

        // Slider button
        ".CybotCookiebotDialogBodyLevelButtonSliderWrapper": {
          height: space[7],
          width: space[11],
          ".CybotCookiebotDialogBodyLevelButtonSlider": {
            backgroundColor: colors.grayLight,
            "&:before": {
              width: space[5],
              height: space[5],
              left: "2px",
            },
          },
          "input:checked+.CybotCookiebotDialogBodyLevelButtonSlider": {
            backgroundColor: colors.primary[300],
            "&:before": {
              width: space[5],
              transform: "translateX(30px)",
            },
          },
          "&:hover": {
            "input+.CybotCookiebotDialogBodyLevelButtonSlider": {
              opacity: "1 !important",
            },
            ".CybotCookiebotDialogBodyLevelButtonSlider": {
              backgroundColor: colors.grayDark,
            },
            "input:checked+.CybotCookiebotDialogBodyLevelButtonSlider": {
              backgroundColor: colors.primary[500],
            },
          },
        },

        ".CybotCookiebotDialogDetailBodyContentCookieContainerButton": {
          paddingLeft: space[3],
          paddingRight: space[3],
          "&:hover": {
            color: `${colors.primary[300]} !important`,
          },
        },

        ".CybotCookiebotDialogDetailBulkConsentCount": {
          backgroundColor: colors.grayLight,
          fontWeights: fontWeights.medium,
        },
      },

      // Footer
      "#CybotCookiebotDialogFooter": {
        "#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection": {
          color: `${colors.primary[300]} !important`,
        },
        ".CybotCookiebotDialogBodyButton": {
          borderRadius: space[8],
          width: "auto !important",
          padding: `${space[2]} ${space[4]}`,
          textTransform: "uppercase",
          border: `${space["px"]} solid ${colors.grayDark}`,
          fontWeight: "600",
          fontSize: space[4],
          lineHeight: space[5],

          "&:hover": {
            border: `${space["px"]} solid ${colors.primary[300]}`,
            opacity: "1",
          },

          "&#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll": {
            border: `${space["px"]} solid ${colors.primary[300]}`,
            backgroundColor: `${colors.primary[300]}`,
            "&:hover": {
              border: `${space["px"]} solid ${colors.primary[500]}`,
              backgroundColor: `${colors.primary[500]}`,
            },
          },
        },

        "#CybotCookiebotDialogPoweredByText": {
          display: "none",
        },

        ".CybotCookiebotDialogArrow": {
          display: "none !important",
        },
      },
    },

    // Other
    "#CybotCookiebotDialogBodyButtonDecline": {
      color: `${colors.primary[300]} !important`,
    },
    "#CybotCookiebotDialogBodyLevelButtonCustomize": {
      color: `${colors.primary[300]} !important`,
    },
    "#CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type)": {
      marginBottom: [`${space[4]} !important`, `${space[4]} !important`, `${space[0]} !important`],
      marginRight: [`${space[0]} !important`, `${space[0]} !important`, `${space[4]} !important`],
    },
    "#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper": {
      flexDirection: [`column !important`, `column !important`, "row !important"],
    },
    "#CybotCookiebotDialogFooter": {
      padding: [`${space[8]} ${space[4]} ${space[0]} ${space[4]} !important`, `${space[0]} !important`],
      marginTop: [`${space[0]} !important`, `${space[8]} !important`],
    },
    "#CybotCookiebotDialogBodyContent": {
      maxWidth: "649px",
      padding: [`${space[6]} ${space[4]}  ${space[8]} !important`, `${space[6]} 0 ${space[8]} 0 !important`],
    },
    "#CybotCookiebotDialogDetailBodyContentTextAbout": {
      maxWidth: "649px",
    },

    "#CybotCookiebotDialog.CybotCookiebotDialogActive+#CybotCookiebotDialogBodyUnderlay": {
      opacity: ".25 !important",
    },
  };
};

export default getCookiebotStyles;
