import React from "react";

import { Stack } from "../../atoms";

type SkeletonGroupProps = {
  repeatTimes: number;
  spacing?: number;
  children: JSX.Element;
};

export const SkeletonGroup: React.FC<SkeletonGroupProps> = ({ repeatTimes, spacing = 4, children }) => {
  return (
    <Stack spacing={spacing}>
      {[...Array(repeatTimes).keys()].map((_, index) => {
        return <React.Fragment key={index}>{children}</React.Fragment>;
      })}
    </Stack>
  );
};
