import { useCalcWindowHeight, useCookiebot, useInitLocale } from "hooks";
import { useAppSelector } from "hooks/useAppSelector";
import { useRoutes } from "react-router-dom";
import { selectToken } from "store/state";

import { getRoutes } from "../routes";

const App: React.FC = () => {
  const token = useAppSelector(selectToken);
  const renderRoutes = useRoutes(getRoutes(!!token));

  useCookiebot();
  useInitLocale();
  useCalcWindowHeight();

  return renderRoutes;
};

export default App;
