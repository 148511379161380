export enum Paths {
  Home = "/",
  Profile = "/profile",
  ProfileVerifyCard = "/profile?verify-card=1",
  ChangeEmail = "/profile/change-email",
  ChangePassword = "/profile/change-password",
  ChangeCard = "/profile/change-card",
  AddBillingAddress = "/profile/address/billing/add",
  EditBillingAddress = "/profile/address/billing/edit",
  EditShippingAddress = "/profile/address/shipping/:id/edit",
  SendDeleteAccountInstruction = "/profile/send-delete-account-instruction",
  Cart = "/cart",
  Checkout = "/checkout",
  CheckoutAuth = "/checkout/auth",
  CheckoutSignUp = "/checkout/auth/sign-up",
  CheckoutSignUpResult = "/checkout/auth/sign-up/check-your-inbox",
  CheckoutEmailConfirmed = "/checkout/auth/sign-up/email-confirmed",
  CheckoutEmailUsedLink = "/checkout/auth/sign-up/used-link",
  CheckoutEmailExpiredLink = "/checkout/auth/sign-up/expired-link",
  CheckoutSignIn = "/checkout/auth/sign-in",
  CheckoutSignOutConfirmation = "/checkout/auth/sign-out/confirmation",
  CheckoutUserInfo = "/checkout/auth/signed-in",
  CheckoutAddress = "/checkout/address",
  CheckoutEditBillingAddress = "/checkout/address/billing/edit",
  CheckoutAddBillingAddress = "/checkout/address/billing/add",
  CheckoutEditShippingAddress = "/checkout/address/shipping/:id/edit",
  CheckoutAddShippingAddress = "/checkout/address/shipping/add",
  CheckoutShippingMethods = "/checkout/shipping-method",
  CheckoutPaymentMethod = "/checkout/payment-method",
  CheckoutPaymentMethodAddCard = "/checkout/payment-method/add-card",
  CheckoutPaymentMethodChangeCard = "/checkout/payment-method/change-card",
  CheckoutPaymentMethodAddingCardFailed = "/checkout/payment-method/adding-card-failed",
  CheckoutPaymentMethodVerifyCard = "/checkout/payment-method/verify-card",
  CheckoutSummary = "/checkout/summary",
  CheckoutOrderCompleted = "/checkout/order-completed",
  CheckoutPaymentProcessing = "/checkout/payment/processing",
  CheckoutPaymentFailed = "/checkout/payment/failed",
  Auth = "/auth",
  SignIn = "/auth/sign-in",
  SignUp = "/auth/sign-up",
  ResetPassword = "/auth/reset-password",
  SignOut = "/sign-out",
  Terms = "/terms/:name",
  TermsUpdate = "/terms/update",
  ConfirmEmail = "/api/v1/users/confirmation",
  NewPassword = "/api/v1/users/password/reset",
  DeleteAccount = "/api/v1/users",
  CreateOrder = "/api/order/create",
  MyDevices = "/my-devices",
  PairDevice = "/my-devices/pairing",
  // RegisterDeviceBillingAddressAdd = "/my-devices/register/billing/add",
  // RegisterDeviceBillingAddressEdit = "/my-devices/register/billing/edit",
  // RegisterDeviceAddCard = "/my-devices/register/card/add",
  // RegisterDeviceEditCard = "/my-devices/register/card/edit",
  // RegisterDeviceVerifyCard = "/my-devices/register/card/checking",
  // RegisterDeviceAddingCardFailed = "/my-devices/register/card/fail",
  // RegisterDeviceAddingCardSuccess = "/my-devices/register/card/success",
  PairDeviceStep1 = "/my-devices/pairing/prepare",
  PairDeviceStep2 = "/my-devices/pairing/code",
  PairDeviceStep3 = "/my-devices/pairing/finish",
  MySubscriptions = "/my-subscriptions",
  PageNotFound = "/page-not-found",
  PlatformPrivacyPolicy = "/terms/platform_privacy_policy",
  PlatformRegulations = "/terms/platform_regulations",
  PlatformStorePolicy = "/terms/store_policy",
}
