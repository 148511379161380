import React from "react";

import { TextProps as Props, SystemProps } from "@chakra-ui/react";

import { Text } from "../Text/Text";

export interface TitleH1tProps extends Props {
  /**
   * The CSS `text-align` property
   * @type SystemProps["textAlign"]
   */
  align?: SystemProps["textAlign"];
  /**
   * The CSS `text-decoration` property
   * @type SystemProps["textDecoration"]
   */
  decoration?: SystemProps["textDecoration"];
  /**
   * The CSS `text-transform` property
   * @type SystemProps["textTransform"]
   */
  casing?: SystemProps["textTransform"];
  children: string;
  color?: "black" | "grayDark" | "violet.300" | "greenAlert" | "redAlert";
}

export const TitleH1: React.FC<TitleH1tProps> = ({ color = "black", children, ...props }) => {
  return (
    <Text color={color} variant="highlights24" {...props}>
      {children}
    </Text>
  );
};
