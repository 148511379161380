import React from "react";

import { Link as ChakraLink, ComponentWithAs, LinkProps as Props } from "@chakra-ui/react";

interface LinkProps extends Props {
  children: Props["children"];
  /**
   * The href attribute specifies the URL of the page the link goes to.
   * @type string
   */
  href?: Props["href"];
  /**
   * If true, the link will open in new tab
   * @default false
   */
  isExternal?: Props["isExternal"];
}

/**
 * Links are accessible elements used primarily for navigation. This component is styled to resemble a hyperlink and semantically renders an <a>.
 */
export const Link: ComponentWithAs<"a", LinkProps> = ({ children, ...restProps }: LinkProps) => {
  return <ChakraLink {...restProps}>{children}</ChakraLink>;
};
