import React from "react";

import { Stack, StackProps } from "../../atoms";

export const SideMenu: React.FC<StackProps> = ({ children, ...stackProps }) => {
  return (
    <Stack as="ul" role="list" spacing="xxs" display="flex" flexDirection="column" listStyleType="none" {...stackProps}>
      {children}
    </Stack>
  );
};
