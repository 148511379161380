import { useEffect, useState } from "react";

import i18next from "i18next";
import { LocaleKey } from "locales";
import { useTranslation } from "react-i18next";
import { removeCookieI18next } from "utils/helpers";

export type Cookiebot = { consent: { preferences?: boolean } };

export const useCookiebot = () => {
  const { i18n } = useTranslation();
  const [cookiebot, setCookiebot] = useState<Cookiebot | null>(null);
  const lang = i18n.languages[0] as LocaleKey;

  useEffect(() => {
    const initCookiebot = () => {
      setCookiebot((window as any).Cookiebot);
    };

    window.addEventListener("CookiebotOnLoad", initCookiebot);

    return () => {
      window.removeEventListener("CookiebotOnLoad", initCookiebot);
    };
  }, []);

  useEffect(() => {
    const cookiebotScript = document.getElementById("Cookiebot");

    cookiebotScript?.setAttribute("data-culture", i18n.languages[1]);

    const changeLanguage = () => {
      if (cookiebot && cookiebot.consent.preferences) {
        i18next.changeLanguage(lang);
      }
    };

    window.addEventListener("CookiebotOnAccept", changeLanguage);

    if (cookiebot?.consent.preferences === false) {
      removeCookieI18next(lang);
    }

    return () => {
      window.removeEventListener("CookiebotOnAccept", changeLanguage);
    };
  }, [cookiebot, i18n, lang]);
};
