import React from "react";

import { ThemeTypings } from "@chakra-ui/react";

import { Divider } from "../../atoms";

type DividedListProps = {
  dividerColor?: ThemeTypings["colors"];
  children: React.ReactNode;
};

export const DividedList: React.FC<DividedListProps> = ({ dividerColor = "grayDark", children }) => {
  return (
    <>
      <Divider variant="light" />
      {React.Children.map(
        children,
        (child, index) =>
          child && (
            <React.Fragment key={index}>
              {child}
              <Divider variant="light" />
            </React.Fragment>
          ),
      )}
    </>
  );
};
