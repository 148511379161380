import { Currency, Market } from "store/api";

const CURRENCY: Record<Market["name"], Currency> = {
  poland: "PLN",
  spain: "EUR",
  france: "EUR",
};

const getCurrency = (marketName: Market["name"]) => {
  return CURRENCY[marketName];
};

export const markets = {
  getCurrency,
};
