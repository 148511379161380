import { useEffect } from "react";

import { useAppDispatch } from "hooks";
import { Paths } from "paths";
import { useNavigate } from "react-router-dom";
import { Market, useLazyGetMarketsQuery, useLazyGetSalesProductsQuery } from "store/api";
import { err } from "store/errors";
import { initCart, setMarket, setPrestaCartId } from "store/state";
import { PageLoader } from "ui";
import { utils } from "utils";

type CartSearchParams = ["presta_cart_id", "external_id", "market_name", "ecomm_pagetype"];

// http://local.dev.vasc.ooo:3000/api/order/create?market_name=poland&external_id=5903111339845&presta_cart_id=602&ecomm_pagetype=
const CreateOrder: React.FC = () => {
  const navigate = useNavigate();
  const { prestaCartId, marketName, externalId, ecommPagetype } = utils.helpers.useGetSearchParams<CartSearchParams>();
  const dispatch = useAppDispatch();
  const [getMarkets] = useLazyGetMarketsQuery();
  const [getSalesProducts] = useLazyGetSalesProductsQuery();

  useEffect(() => {
    const initMarket = async () => {
      const markets = await getMarkets().unwrap();
      const market = markets.find(m => m.name === marketName);

      if (market) {
        dispatch(setMarket(market));
      }

      if (prestaCartId) {
        dispatch(setPrestaCartId(prestaCartId));
      }

      return market;
    };

    const addProductToCart = async (market: Market) => {
      if (market.id) {
        const salesProducts = await getSalesProducts({ marketIds: [market.id] }).unwrap();
        const salesProduct = salesProducts.find(p => p.externalId === externalId);
        if (salesProduct) {
          dispatch(initCart([salesProduct]));
        }
      }
    };

    const createOrder = async () => {
      try {
        const market = await initMarket();

        if (market && marketName && externalId) {
          await addProductToCart(market);
          utils.gtm.addToCartDataLayer({ ecommPagetype });
          navigate(Paths.Cart, { replace: true });
        } else {
          navigate(Paths.Home, { replace: true });
        }
      } catch (error) {
        err.handler(error, [], true);
        navigate(Paths.Home, { replace: true });
      }
    };

    createOrder();
  }, [prestaCartId, externalId, marketName, getMarkets, dispatch, getSalesProducts, navigate, ecommPagetype]);

  return <PageLoader />;
};

export default CreateOrder;
