import React from "react";

import { Center, Spinner, Text } from "../../atoms";

type Props = {
  children?: string;
};

export const PageLoader: React.FC<Props> = ({ children }) => {
  return (
    <Center flexDir="column" flex={1} position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)">
      <Spinner color="pink.300" />
      {children && children.length > 0 && <Text mt={4}>{children}</Text>}
    </Center>
  );
};
