import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationM3Illustration = createIcon({
  displayName: "ConfirmationM3Illustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <rect width="150" height="150" fill="white" />,
    <path
      d="M97.8001 4.81H52.2001C49.7977 4.81 47.8501 6.75756 47.8501 9.16V142.17C47.8501 144.572 49.7977 146.52 52.2001 146.52H97.8001C100.203 146.52 102.15 144.572 102.15 142.17V9.16C102.15 6.75756 100.203 4.81 97.8001 4.81Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M96.9702 8.02002H53.0302C52.1355 8.02002 51.4102 8.74532 51.4102 9.64002V75.35C51.4102 76.2447 52.1355 76.97 53.0302 76.97H96.9702C97.8649 76.97 98.5902 76.2447 98.5902 75.35V9.64002C98.5902 8.74532 97.8649 8.02002 96.9702 8.02002Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M102.1 79.71C102.1 80.42 100.39 81.71 98.2801 81.71H51.6801C49.5701 81.71 47.9001 80.46 47.9001 79.71"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M75 120.49C79.4128 120.49 82.99 116.913 82.99 112.5C82.99 108.087 79.4128 104.51 75 104.51C70.5873 104.51 67.01 108.087 67.01 112.5C67.01 116.913 70.5873 120.49 75 120.49Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M75 100.32C79.4128 100.32 82.99 96.7427 82.99 92.33C82.99 87.9172 79.4128 84.34 75 84.34C70.5873 84.34 67.01 87.9172 67.01 92.33C67.01 96.7427 70.5873 100.32 75 100.32Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M74.77 98.06C75.4604 98.06 76.02 97.5004 76.02 96.81C76.02 96.1196 75.4604 95.56 74.77 95.56C74.0797 95.56 73.52 96.1196 73.52 96.81C73.52 97.5004 74.0797 98.06 74.77 98.06Z"
      fill="#999999"
    />,
    <path d="M98.4302 85.79V142.47" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M51.5701 142.47V85.79" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M80.9601 33.55L75.5601 42.96L64.1101 23.17L58.5801 33.29L75.1701 62.07L91.7701 33.55H80.9601Z"
      stroke="#8F8BEF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
