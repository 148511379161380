import { formErrorAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleText = defineStyle({
  color: "redAlert",
  fontSize: "0.75rem",
  lineHeight: "1rem",
  position: "absolute",
  pl: 6,
  bottom: [3, 3, 0],
});

const baseStyleIcon = defineStyle({
  marginEnd: "0.5em",
  bg: "redAlert",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  borderRadius: "full",
  height: "m",
  width: "m",
  position: "absolute",
  bottom: [3, 3, 0],
});

const baseStyle = definePartsStyle({
  text: baseStyleText,
  icon: baseStyleIcon,
});

export const FormErrorMessage = defineMultiStyleConfig({
  baseStyle,
});
