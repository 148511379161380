import { LocaleAPI } from "../../locales";
import { CamelToSnakeCaseNested, Currency, Method, ResBuilder, api, isDate, isObject, transformResponse } from "./api";

export const camelToSnakeCase = <T extends string>(str: T) => {
  const snakeCase = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  return snakeCase;
};

export const camelKeysToSnake = <T extends unknown>(data: T): CamelToSnakeCaseNested<T> => {
  if (!isObject(data) || isDate(data)) {
    return data as CamelToSnakeCaseNested<T>;
  }

  if (Array.isArray(data)) {
    return data.map(datum => {
      return camelKeysToSnake(datum);
    }) as CamelToSnakeCaseNested<T>;
  }

  return Object.entries(data).reduce((result, [k, v]) => {
    let value;

    if (!isObject(v) || isDate(v)) {
      value = v;
    } else {
      value = camelKeysToSnake(v);
    }

    Object.assign(result, {
      [camelToSnakeCase(k)]: value,
    });

    return result;
  }, {}) as CamelToSnakeCaseNested<T>;
};

const ordersApi = api.injectEndpoints({
  endpoints: builder => ({
    createOrder: builder.mutation<Order, OrderReq>({
      query: order => ({
        url: "/users/orders",
        method: Method.Post,
        body: camelKeysToSnake(order),
      }),
      transformResponse: (res: ResBuilder<OrderRaw>) => transformResponse(res),
      invalidatesTags: ["Orders"],
    }),
  }),
  overrideExisting: false,
});

export const { useCreateOrderMutation } = ordersApi;

export type OrderReq = {
  billingDetailId: number;
  shippingMethodId: number;
  shippingAddressId: number;
  useBillingAsShippingAddress: boolean;
  storePolicyAttributes: {
    consentAccepted: boolean;
    language: LocaleAPI;
  };
  salesProducts: { id: number; quantity: number }[];
};

export type Order = {
  id: number;
  type: "order";
  uid: string;
  fullPriceSubunit: number;
  fullPriceCurrency: Currency;
  state: "pending" | "finished" | "unpaid";
  shippingMethodName: "ups_payable_in_advance" | "inpost_parcel_locker";
  userId: number;
  marketId: number;
  billingDetailId: number;
  shippingAddressId: number;
  itemsIds: string[];
  paymentsIds: string[];
};

export type OrderRaw = {
  id: string;
  type: "order";
  attributes: {
    uid: string;
    full_price_subunit: number;
    full_price_currency: string;
    state: "pending" | "finished" | "unpaid";
    shipping_method_name: "ups_payable_in_advance" | "inpost_parcel_locker";
  };
  relationships: {
    user: {
      data: {
        id: string;
        type: "user";
      };
    };
    market: {
      data: {
        id: string;
        type: "market";
      };
    };
    billing_detail: {
      data: {
        id: string;
        type: "billing_detail";
      };
    };
    shipping_address: {
      data: {
        id: string;
        type: "shipping_address";
      };
    };
    items: {
      data: {
        id: string;
        type: "orders_item";
      }[];
    };
    payments: {
      data: {
        id: string;
        type: "payment";
      }[];
    };
  };
};
