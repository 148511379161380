import colors from "./Colors/colors";
import radii from "./Radius/radius";
import shadows from "./Shadows/shadows";
import spacing from "./Spacing/spacing";
import transition from "./Translation/translation";
import typography from "./Typography/typography";
import zIndices from "./Z-index/z-index";
import breakpoints from "./breakpoints";
import sizes from "./sizes";

const foundations = {
  breakpoints,
  zIndices,
  radii,
  colors,
  ...typography,
  sizes,
  shadows,
  space: spacing,
  transition,
};

export default foundations;
