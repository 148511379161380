import { Method, ResBuilder, api, transformResponse } from "./api";

export const marketsApi = api.injectEndpoints({
  endpoints: builder => ({
    getMarkets: builder.query<Market[], void>({
      query: () => ({
        url: "markets",
        method: Method.Get,
      }),
      transformResponse: (res: ResBuilder<MarketRaw[]>) => transformResponse(res),
    }),
  }),
  overrideExisting: false,
});

export const { useGetMarketsQuery, useLazyGetMarketsQuery } = marketsApi;

export type Market = {
  id: number;
  type: "market";
  name: "poland" | "spain" | "france";
};

export type MarketRaw = {
  type: "market";
  id: string;
  attributes: {
    name: string;
  };
};
