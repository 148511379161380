import parse from "html-react-parser";
import { LocaleKey } from "locales";
import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { useGetLatestConsentQuery } from "store/api";
import { err } from "store/errors";
import { Box, Button, Flex, SpinnerWithLabel } from "ui";
import { parseLocaleToApi } from "utils/helpers";

const Terms: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { name } = useParams<{ name: string }>();

  const {
    data: consentDetail,
    error,
    isError,
    isLoading,
  } = useGetLatestConsentQuery(
    { language: parseLocaleToApi(i18n.resolvedLanguage as LocaleKey), name: name || "" },
    { refetchOnMountOrArgChange: true },
  );

  const openCookiebotDialog = () => {
    (window as any).Cookiebot.show();
  };

  if (isLoading) {
    return <SpinnerWithLabel label={t("label_action_info_loading")} />;
  }

  if (isError && err.hasSpecificStatus(error, 404)) {
    return <Navigate to={Paths.PageNotFound} />;
  }

  return (
    <Flex flexDir="column">
      <Flex id="terms" flexDir="column" mb="xl">
        {parse(consentDetail?.text || "")}
      </Flex>
      {name === "platform_privacy_policy" && (
        <Box py="l">
          <Button variant="link" onClick={openCookiebotDialog}>
            {t("label_action_request_cookie_preferences")}
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export default Terms;
