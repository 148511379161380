import React from "react";

import { createIcon } from "@chakra-ui/react";

export const EmailConfirmation2Illustration = createIcon({
  displayName: "EmailConfirmation2Illustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <g clipPath="url(#clip0_19921_83980)">
      <path
        d="M138.15 15.4502H11.8499C7.98939 15.4502 4.85986 18.5797 4.85986 22.4402V101.56C4.85986 105.421 7.98939 108.55 11.8499 108.55H138.15C142.01 108.55 145.14 105.421 145.14 101.56V22.4402C145.14 18.5797 142.01 15.4502 138.15 15.4502Z"
        stroke="#999999"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.26 108.55H59.73V134.54H90.26V108.55Z"
        stroke="#999999"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M39.8198 134.55H110.18" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M74.9998 92.5797C92.3581 92.5797 106.43 78.508 106.43 61.1497C106.43 43.7914 92.3581 29.7197 74.9998 29.7197C57.6415 29.7197 43.5698 43.7914 43.5698 61.1497C43.5698 78.508 57.6415 92.5797 74.9998 92.5797Z"
        stroke="#008A1E"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.31 52L70.96 71.35L62.21 62.6"
        stroke="#008A1E"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>,
    <defs>
      <clipPath id="clip0_19921_83980">
        <rect width="150" height="150" fill="white" />
      </clipPath>
    </defs>,
  ],
});
