import { Currency, Method, ResBuilder, SnakeToCamelCase, api, snakeToCamelCase, transformResponse } from "./api";
import { SubscriptionOffer } from "./subscriptions.api";

export const transformSalesProducts = (response: ResBuilder<SalesProductRaw[]>, variant?: Hardware["variant"]) => {
  const salesProductsNormalized = transformResponse<SalesProductNormalized[]>(response);

  const salesProducts = salesProductsNormalized.map(salesProductNormalized => {
    let mappedItems: { hardware?: Hardware[]; subscriptionOffer?: SubscriptionOffer[] } = {};

    salesProductNormalized.items.forEach(item => {
      const itemTypeCamelCase = snakeToCamelCase(item.subject.type) as SnakeToCamelCase<typeof item.subject.type>;
      if (Array.isArray(mappedItems[itemTypeCamelCase])) {
        // @ts-ignore
        mappedItems[itemTypeCamelCase]?.push(item.subject);
      } else {
        // @ts-ignore
        mappedItems[itemTypeCamelCase] = [item.subject];
      }
    });

    return { ...salesProductNormalized, items: { ...mappedItems } };
  });

  if (variant) {
    return salesProducts.filter(salesProduct => salesProduct.variant === variant);
  }

  return salesProducts;
};

export const salesProductsApi = api.injectEndpoints({
  endpoints: builder => ({
    getSalesProducts: builder.query<SalesProduct[], GetSalesProductReq>({
      query: ({ marketIds }) => ({
        url: "sales_products",
        method: Method.Get,
        params: {
          market_ids: marketIds,
        },
      }),
      transformResponse: (res: ResBuilder<SalesProductRaw[]>, _, { variant }) => transformSalesProducts(res, variant),
    }),
  }),
  overrideExisting: false,
});

export const { useGetSalesProductsQuery, useLazyGetSalesProductsQuery } = salesProductsApi;

export type Hardware = {
  id: number;
  type: "hardware";
  variant: "v4" | null;
  color: "stone_gray" | "cobalt_blue" | "ruby_red" | "pearl_white" | "black_onyx" | string | null;
};

export type SalesProduct = {
  id: number;
  type: "sales_product";
  externalId: string;
  description: string;
  priceSubunit: number;
  priceCurrency: Currency;
  maxQuantity: number;
  color: Hardware["color"];
  variant: Hardware["variant"];
  items: {
    subscriptionOffer?: SubscriptionOffer[];
    hardware?: Hardware[];
  };
};

export type GetSalesProductReq = {
  marketIds: number[];
  variant?: Hardware["variant"];
};

export type SalesProductRaw = {
  type: "sales_product";
  id: string;
  attributes: {
    external_id: string;
    description: string;
    price_subunit: number;
    price_currency: Currency;
    color: string | null;
    variant: string | null;
  };
  relationships: {
    items: {
      data: {
        id: string;
        type: "item";
      }[];
    };
    market: {
      data: {
        id: string;
        type: "market";
      };
    };
  };
};

type SalesProductNormalized = {
  id: number;
  type: "sales_product";
  externalId: string;
  description: string;
  priceSubunit: number;
  priceCurrency: Currency;
  maxQuantity: number;
  color: Hardware["color"];
  variant: Hardware["variant"];
  items: {
    id: number;
    type: "item";
    subject: Hardware | SubscriptionOffer;
  }[];
};
