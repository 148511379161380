import React from "react";

import { createIcon } from "@chakra-ui/react";

export const MollieLogo = createIcon({
  displayName: "MollieLogo",
  viewBox: "0 0 51 15",
  defaultProps: {
    width: "51px",
    height: "15px",
    fill: "transparent",
  },
  path: [
    <g clipPath="url(#clip0_19653_73522)">
      <path
        d="M21.571 5.39844C18.7701 5.39844 16.4976 7.66692 16.4976 10.4492C16.4976 13.2315 18.7762 15.5 21.571 15.5C24.3658 15.5 26.6445 13.2315 26.6445 10.4492C26.6445 7.66692 24.372 5.39844 21.571 5.39844ZM21.571 13.1096C20.1002 13.1096 18.9027 11.9175 18.9027 10.4533C18.9027 8.98901 20.1002 7.79689 21.571 7.79689C23.0419 7.79689 24.2394 8.98901 24.2394 10.4533C24.2394 11.9175 23.0419 13.1096 21.571 13.1096Z"
        fill="black"
      />
      <path
        d="M38.4478 3.64988C39.3209 3.64988 40.0308 2.94517 40.0308 2.07392C40.0308 1.20268 39.3209 0.5 38.4478 0.5C37.5747 0.5 36.8647 1.20471 36.8647 2.07595C36.8647 2.9472 37.5747 3.64988 38.4478 3.64988Z"
        fill="black"
      />
      <path
        d="M11.2118 5.4026C11.0792 5.39244 10.9528 5.38635 10.8222 5.38635C9.5982 5.38635 8.43744 5.88595 7.60512 6.76734C6.7728 5.89001 5.61612 5.38635 4.40436 5.38635C1.97676 5.38838 0 7.3502 0 9.76694V15.295H2.37252V9.83396C2.37252 8.83071 3.20076 7.90666 4.17588 7.80715C4.24524 7.80106 4.31256 7.79699 4.3758 7.79699C5.47332 7.79699 6.36888 8.68855 6.375 9.78115V15.2929H8.80056V9.8238C8.80056 8.82665 9.62268 7.9026 10.6039 7.80309C10.6733 7.79699 10.7406 7.79293 10.8038 7.79293C11.9014 7.79293 12.803 8.68042 12.8071 9.76694V15.295H15.2327V9.83396C15.2327 8.72713 14.8206 7.6589 14.078 6.83639C13.3375 6.0078 12.3196 5.49805 11.2118 5.4026Z"
        fill="black"
      />
      <path
        d="M30.3777 0.735596H27.9521V15.3051H30.3777V0.735596ZM35.0207 0.735596H32.5952V15.3051H35.0207V0.735596ZM39.6617 5.64015H37.2362V15.301H39.6617V5.64015Z"
        fill="black"
      />
      <path
        d="M51.0003 10.2238C51.0003 8.94233 50.4985 7.73599 49.5927 6.81601C48.6809 5.89805 47.4773 5.38831 46.1961 5.38831H46.1329C44.8049 5.40455 43.5482 5.92852 42.6098 6.86881C41.6714 7.8091 41.1431 9.05199 41.1288 10.3802C41.1125 11.7348 41.6347 13.0162 42.5996 13.987C43.5645 14.9578 44.8416 15.4939 46.2023 15.4939H46.2084C47.9914 15.4939 49.6621 14.5435 50.576 13.0162L50.6923 12.8213L48.689 11.8404L48.5891 12.0028C48.0831 12.8274 47.2141 13.3148 46.2492 13.3148C45.015 13.3148 43.9501 12.4963 43.6217 11.3306H51.0003V10.2238ZM46.1003 7.58164C47.208 7.58164 48.1994 8.30666 48.5483 9.33428H43.6584C44.0011 8.30666 44.9926 7.58164 46.1003 7.58164Z"
        fill="black"
      />
    </g>,
    <defs>
      <clipPath id="clip0_19653_73522">
        <rect width="51" height="15" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>,
  ],
});
