import React from "react";

import { Skeleton } from "@chakra-ui/react";

import { Box, BoxProps, Flex, FlexProps, Stack, StackProps } from "../../atoms";
import * as Illustrations from "../../atoms/Illustrations";

type Props = FlexProps & {
  children: JSX.Element[];
  illustrationName?: keyof typeof Illustrations;
  variant?: "horizontal" | "vertical";
  isBorder?: boolean;
};

export const InfoPanel = ({ children, variant = "horizontal", isBorder = false, ...props }: Props) => {
  const Illustration = children.find(({ type }) => type === InfoPanelIllustration);
  const Action = children.find(({ type }) => type === InfoPanelAction);
  const Content = children.find(({ type }) => type === InfoPanelContent);
  const direction = variant === "horizontal" ? "row" : "column";
  const borderProps = isBorder
    ? {
        border: ["none", "1px solid"],
        borderColor: [null, "grayLight"],
        borderRadius: "xl",
      }
    : {};

  return (
    <Flex direction="column" p="xl" w="full" {...borderProps} {...props}>
      <Stack spacing="xl" direction={["column", direction]}>
        {!!Illustration && (
          <Flex alignSelf="center" textAlign={["center", "initial"]}>
            {Illustration}
          </Flex>
        )}
        {!!Content && (
          <Box pt={[0, direction === "row" ? "l" : 0]} pb="xl">
            {Content}
          </Box>
        )}
      </Stack>
      {!!Action && Action}
    </Flex>
  );
};

type InfoPanelIllustrationProps = BoxProps & {
  color?: string;
  illustrationName?: keyof typeof Illustrations;
  children?: React.ReactNode;
};

export const InfoPanelIllustration = ({ color, illustrationName, children, ...props }: InfoPanelIllustrationProps) => {
  const IllustrationComponent = illustrationName ? Illustrations[illustrationName] : null;

  if (!IllustrationComponent && !children) return <Skeleton w={150} h={150} borderRadius="md" />;

  return (
    <Flex {...props}>
      {IllustrationComponent && <IllustrationComponent w={150} h={150} color={color} />}
      {children && children}
    </Flex>
  );
};

export const InfoPanelAction: React.FC<StackProps> = ({ children, ...props }) => {
  if (!children) return <Skeleton w="full" h={36} />;

  return (
    <Stack direction={["column", "row"]} spacing={["l", "m"]} pt={["l", "xl"]} justifyContent="end" {...props}>
      {children}
    </Stack>
  );
};

type InfoPanelContentProps = {
  children?: React.ReactNode;
};

export const InfoPanelContent: React.FC<InfoPanelContentProps> = ({ children }) => {
  if (!children) return <Skeleton w="full" h={60} />;

  return <Stack spacing="m">{children}</Stack>;
};
