import React from "react";

import { createIcon } from "@chakra-ui/react";

export const InfoIcon = createIcon({
  displayName: "InfoIcon",
  viewBox: "0 0 16 16",
  defaultProps: {
    fill: "transparent",
  },
  path: [
    <circle cx="8" cy="8" r="8" fill="white" />,
    <path
      d="M8.78749 6.0375V12.8H7.27499V6.0375H8.78749ZM7.17499 4.2625C7.17499 4.03333 7.24999 3.84375 7.39999 3.69375C7.55416 3.53958 7.76666 3.4625 8.03749 3.4625C8.30416 3.4625 8.51458 3.53958 8.66874 3.69375C8.82291 3.84375 8.89999 4.03333 8.89999 4.2625C8.89999 4.4875 8.82291 4.675 8.66874 4.825C8.51458 4.975 8.30416 5.05 8.03749 5.05C7.76666 5.05 7.55416 4.975 7.39999 4.825C7.24999 4.675 7.17499 4.4875 7.17499 4.2625Z"
      fill="#2D3139"
    />,
  ],
});
