import { selectCurrentUser } from "store/state";

import { useAppSelector } from "./useAppSelector";

export const useUser = () => {
  const user = useAppSelector(selectCurrentUser);
  if (!user) {
    throw new Error("You cannot use useUser before logging in.");
  }
  return user;
};
