import { Divider as ChakraDivider, ComponentWithAs, DividerProps as Props } from "@chakra-ui/react";

export interface DividerProps extends Props {
  /**
   * @default horizontal
   */
  orientation?: "horizontal" | "vertical";
  variant?: "black" | "dark" | "light" | "white" | "brand";
  size?: "small" | "medium";
}

/**
 * Dividers are used to visually separate content in a list or group.
 */
export const Divider = ChakraDivider as ComponentWithAs<"div", DividerProps>;
