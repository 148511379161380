import React from "react";

import { createIcon } from "@chakra-ui/react";

export const VascoSpaceLogo = createIcon({
  displayName: "VascoSpaceLogo",
  defaultProps: {
    width: "124px",
    height: "43px",
  },
  viewBox: "0 0 124 43",
  path: [
    <path
      fill="#010101"
      d="M35.183.03c1.304 0 2.523.779 3.064 1.967l8.186 18.264h-4.92L36.17 7.641c-.425-1.022-.759-1.978-.926-2.822h-.09c-.167.844-.496 1.805-.926 2.826l-5.37 12.621h-4.9l8.161-18.259A3.407 3.407 0 0 1 35.183.035V.03ZM11.25 20.676a3.394 3.394 0 0 1-3.064-1.967L0 .445h4.92l5.345 12.62c.424 1.022.758 1.978.925 2.822h.09c.168-.844.496-1.805.926-2.826L17.576.44h4.9l-8.161 18.259a3.407 3.407 0 0 1-3.064 1.972v.005ZM95.996 13.187c-.829 4.723-4.803 7.499-9.48 7.499-6.058 0-10.417-4.293-10.417-10.33C76.1 4.317 80.458.02 86.516.02c4.49 0 8.419 2.584 9.415 7.079h-4.394c-.86-2.098-2.65-3.292-5.011-3.292-3.626 0-6.088 2.66-6.088 6.538 0 3.879 2.462 6.538 6.088 6.538 2.518 0 4.43-1.33 5.162-3.711l4.313.01-.005.005ZM102.565 10.355c0-6.027 4.358-10.335 10.416-10.335 6.058 0 10.416 4.303 10.416 10.335 0 6.033-4.353 10.326-10.416 10.326-6.063 0-10.416-4.293-10.416-10.326Zm16.504 0c0-3.908-2.463-6.538-6.088-6.538-3.626 0-6.088 2.63-6.088 6.538 0 3.909 2.462 6.538 6.088 6.538 3.625 0 6.088-2.634 6.088-6.538ZM64.202 5.795c-.304-.774-1.613-1.998-4.334-1.998-2.381 0-3.828.9-3.828 2.109 0 1.122 1.23 1.911 3.09 2.275l3.403.602c3.888.693 6.356 2.478 6.533 5.76.187 3.453-2.746 6.188-8.293 6.188-6.042 0-8.646-2.72-9.385-6.366h4.445c.617 1.512 2.068 2.564 4.94 2.564 2.64 0 3.98-.885 3.98-2.24 0-.915-.779-1.72-2.655-2.078l-4.05-.749c-4.05-.748-6.164-2.821-6.326-5.814C51.555 3.024 54.377 0 59.894 0c4.94 0 8.14 2.392 8.752 5.79h-4.444v.005Z"
    />,
    <path
      fill="#1D1D1B"
      d="M0 37.175h.97c0 1.471 1.139 2.29 2.762 2.29h.136c1.487 0 2.29-.804 2.29-1.805 0-.9-.58-1.416-1.804-1.663L2.8 35.678C1.33 35.375.485 34.581.485 33.155c0-1.527 1.265-2.776 3.328-2.776 2.063 0 3.397 1.153 3.468 2.983h-.97c-.087-1.274-1.083-2.078-2.428-2.078h-.136c-1.345 0-2.29.804-2.29 1.856 0 .874.626 1.375 1.607 1.567l1.553.304c1.597.318 2.513 1.137 2.513 2.594 0 1.704-1.305 2.76-3.383 2.76C1.39 40.365 0 39.197 0 37.175ZM9.102 43v-9.708h.9v1.112c.414-.763 1.415-1.249 2.316-1.249 1.805 0 3.094 1.386 3.094 3.605 0 2.22-1.29 3.606-3.095 3.606-.9 0-1.9-.486-2.315-1.25V43h-.9Zm3.19-3.398c1.487 0 2.205-1.345 2.205-2.841 0-1.497-.723-2.842-2.205-2.842h-.07c-1.497 0-2.22 1.345-2.22 2.842 0 1.496.723 2.841 2.22 2.841h.07ZM16.818 38.313c0-1.527 1.32-1.998 2.386-2.079l2.19-.166v-.279c0-1.193-.667-1.87-1.734-1.87h-.071c-.89 0-1.638.445-1.735 1.32h-.9c.086-1.234 1.178-2.079 2.665-2.079 1.805 0 2.675 1.219 2.675 2.635v2.912c0 .415 0 1.042.07 1.527h-.834c-.055-.303-.07-.733-.07-1.112-.278.637-1.234 1.249-2.458 1.249-1.223 0-2.194-.875-2.194-2.053l.01-.005Zm2.427 1.29c.956 0 2.149-.845 2.149-1.872v-.93l-2.012.136c-.987.071-1.664.556-1.664 1.32s.597 1.345 1.385 1.345H19.245ZM24.038 36.76c0-2.037 1.138-3.605 3.19-3.605 1.498 0 2.57.9 2.706 2.357h-.9c-.152-.93-.804-1.593-1.734-1.593h-.137c-1.512 0-2.22 1.33-2.22 2.842 0 1.511.708 2.841 2.22 2.841h.137c.93 0 1.552-.708 1.734-1.592h.9c-.248 1.43-1.208 2.356-2.705 2.356-2.053 0-3.19-1.568-3.19-3.605ZM31.269 36.745c0-2.427 1.497-3.59 3.095-3.59 1.693 0 2.8 1.345 2.8 3.12v.556H32.17c.03 1.957 1.042 2.776 2.18 2.776h.085c.946 0 1.553-.43 1.816-1.36h.9c-.304 1.386-1.376 2.124-2.786 2.124-1.735 0-3.095-1.25-3.095-3.62v-.006Zm4.996-.677c-.04-1.234-.749-2.15-1.846-2.15h-.136c-.875 0-1.886.501-2.079 2.15h4.066-.005Z"
    />,
  ],
});
