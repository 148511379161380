import { Method, api } from "./api";
import { Market } from "./markets.api";

export const STORES_URLS = {
  development: {
    poland: {
      home: "https://speed-test-pl.vasco-technologies.com",
      cart: "https://speed-test-pl.vasco-technologies.com/koszyk?action=show",
      api: "https://speed-test-pl.vasco-technologies.com/api",
    },
    spain: {
      home: "https://speed-test-pl.vasco-technologies.com",
      cart: "https://speed-test-pl.vasco-technologies.com/koszyk?action=show",
      api: "https://speed-test-pl.vasco-technologies.com/api",
    },
    france: {
      home: "https://speed-test-pl.vasco-technologies.com",
      cart: "https://speed-test-pl.vasco-technologies.com/koszyk?action=show",
      api: "https://speed-test-pl.vasco-technologies.com/api",
    },
  },
  production: {
    poland: {
      home: "https://vasco-electronics.pl",
      cart: "https://vasco-electronics.pl/koszyk?action=show",
      api: "https://vasco-electronics.pl/api",
    },
    spain: {
      home: "https://traductor-de-voz.es",
      cart: "https://traductor-de-voz.es/carrito?action=show",
      api: "https://traductor-de-voz.es/api",
    },
    france: {
      home: "https://vasco-electronics.fr",
      cart: "https://vasco-electronics.fr/panier?action=show",
      api: "https://vasco-electronics.fr/api",
    },
  },
} as const;

export const getStoreUrls = (marketName: Market["name"]) => {
  return STORES_URLS[process.env.REACT_APP_ENV === "production" ? "production" : "development"][marketName];
};

const getPrestaShopApiUrl = (marketName: Market["name"]) => {
  if (marketName) {
    return getStoreUrls(marketName).api;
  }
};

const additionalParams = {
  ws_key: process.env.REACT_APP_PRESTA_API_KEY,
  output_format: "JSON",
};

export const prestashopApi = api.injectEndpoints({
  endpoints: builder => ({
    getCart: builder.query<any, any>({
      query: ({ id, marketName }: { id: number; marketName: Market["name"] }) => ({
        url: `${getPrestaShopApiUrl(marketName)}/getCart`,
        method: Method.Get,
        params: { id, ...additionalParams },
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetCartQuery } = prestashopApi;
