import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationCardIllustration = createIcon({
  displayName: "ConfirmationCardIllustration",
  viewBox: "0 0 150 150",
  defaultProps: { width: 150, height: 150, fill: "transparent" },
  path: [
    <path
      d="M11.0002 84.4705L4.05016 56.6805C3.61601 55.0495 3.84579 53.313 4.6892 51.8511C5.53261 50.3892 6.92092 49.321 8.55016 48.8805L11.0002 48.0005"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M77.8601 117.451L30.4901 130.761C28.8593 131.192 27.124 130.961 25.6628 130.118C24.2016 129.275 23.133 127.888 22.6901 126.261L20.6401 117.661"
      stroke="#A0A0A0"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M139.13 69.6904L146.07 93.2004C146.501 94.8313 146.27 96.5666 145.427 98.0278C144.584 99.489 143.198 100.558 141.57 101L139.08 101.71"
      stroke="#A0A0A0"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M72.8701 32.1705L119.63 19.1705C121.261 18.7391 122.996 18.9701 124.457 19.8131C125.919 20.6561 126.987 22.0427 127.43 23.6705L130 31.8305"
      stroke="#A0A0A0"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M131.78 32.2803H18.03C14.1696 32.2803 11.04 35.4098 11.04 39.2703V110.06C11.04 113.921 14.1696 117.05 18.03 117.05H131.78C135.641 117.05 138.77 113.921 138.77 110.06V39.2703C138.77 35.4098 135.641 32.2803 131.78 32.2803Z"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M22.4502 81.9907H33.3802" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M22.4502 92.2803H37.7202" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M22.4502 45.4204H37.7202" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M74.9998 106.43C92.3581 106.43 106.43 92.3586 106.43 75.0003C106.43 57.642 92.3581 43.5703 74.9998 43.5703C57.6415 43.5703 43.5698 57.642 43.5698 75.0003C43.5698 92.3586 57.6415 106.43 74.9998 106.43Z"
      stroke="#E40000"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M84.6701 65.3306L65.3301 84.6706"
      stroke="#E40000"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M65.3301 65.3306L84.6701 84.6706"
      stroke="#E40000"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M117.78 104.621C116.858 105.361 115.747 105.827 114.574 105.965C113.4 106.104 112.211 105.91 111.143 105.405C110.074 104.9 109.169 104.104 108.531 103.109C107.893 102.115 107.548 100.96 107.535 99.7786C107.522 98.5969 107.841 97.4353 108.457 96.4265C109.073 95.4178 109.96 94.6026 111.017 94.074C112.074 93.5454 113.258 93.3248 114.435 93.4373C115.611 93.5499 116.732 93.9911 117.67 94.7105"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M128 99.7107C128.002 100.78 127.732 101.833 127.216 102.769C126.7 103.706 125.954 104.496 125.048 105.065C124.143 105.634 123.108 105.964 122.04 106.024C120.972 106.083 119.907 105.87 118.944 105.404C117.981 104.939 117.152 104.236 116.535 103.363C115.918 102.489 115.534 101.473 115.417 100.41C115.301 99.3467 115.457 98.2715 115.87 97.285C116.283 96.2986 116.94 95.4334 117.78 94.7707C118.889 93.8835 120.269 93.4035 121.69 93.4107C122.518 93.4094 123.338 93.5714 124.104 93.8874C124.869 94.2034 125.565 94.6673 126.151 95.2524C126.737 95.8375 127.202 96.5325 127.519 97.2975C127.837 98.0625 128 98.8826 128 99.7107V99.7107Z"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
