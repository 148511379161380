import React from "react";

import { createIcon } from "@chakra-ui/react";

export const PayPalCardLogo = createIcon({
  displayName: "PayPalCardLogo",
  viewBox: "0 0 48 32",
  defaultProps: {
    width: 12,
    height: 8,
    fill: "transparent",
  },
  path: [
    <g clipPath="url(#clip0_2818_92417)">
      <path
        d="M18.6523 12.6973H16.3358C16.1773 12.6973 16.0425 12.8124 16.0178 12.9689L15.0809 18.9089C15.0623 19.0261 15.1531 19.1317 15.272 19.1317H16.3778C16.5364 19.1317 16.6712 19.0166 16.6959 18.8597L16.9486 17.2577C16.973 17.1008 17.1081 16.9857 17.2663 16.9857H17.9996C19.5255 16.9857 20.4061 16.2473 20.6361 14.7841C20.7397 14.1439 20.6405 13.6409 20.3407 13.2887C20.0115 12.9018 19.4276 12.6973 18.6523 12.6973ZM18.9195 14.8667C18.7928 15.6979 18.1578 15.6979 17.5437 15.6979H17.1941L17.4394 14.1456C17.4539 14.0518 17.5352 13.9827 17.6301 13.9827H17.7903C18.2086 13.9827 18.6032 13.9827 18.8071 14.2211C18.9287 14.3634 18.9659 14.5747 18.9195 14.8667Z"
        fill="#253B80"
      />

      <path
        d="M25.5763 14.84H24.467C24.3725 14.84 24.2909 14.9091 24.2763 15.0029L24.2272 15.3132L24.1497 15.2007C23.9095 14.8522 23.374 14.7357 22.8395 14.7357C21.6138 14.7357 20.5668 15.6641 20.3629 16.9664C20.2569 17.616 20.4076 18.2372 20.7761 18.6704C21.1142 19.0688 21.5978 19.2347 22.1733 19.2347C23.161 19.2347 23.7087 18.5997 23.7087 18.5997L23.6592 18.9079C23.6406 19.0258 23.7314 19.1314 23.8496 19.1314H24.8488C25.0076 19.1314 25.1418 19.0163 25.1668 18.8594L25.7663 15.0629C25.7853 14.946 25.6949 14.84 25.5763 14.84ZM24.0301 16.9989C23.9231 17.6326 23.4201 18.0581 22.7786 18.0581C22.4565 18.0581 22.199 17.9548 22.0338 17.759C21.8698 17.5646 21.8075 17.2878 21.8597 16.9796C21.9596 16.3513 22.471 15.912 23.1027 15.912C23.4177 15.912 23.6738 16.0167 23.8425 16.2141C24.0115 16.4136 24.0785 16.692 24.0301 16.9989Z"
        fill="#253B80"
      />

      <path
        d="M31.4842 14.84H30.3695C30.2631 14.84 30.1632 14.8928 30.1029 14.9812L28.5655 17.2458L27.9138 15.0696C27.8728 14.9334 27.7472 14.84 27.6049 14.84H26.5095C26.3764 14.84 26.284 14.97 26.3263 15.0954L27.5541 18.6985L26.3998 20.3281C26.309 20.4564 26.4005 20.6329 26.5573 20.6329H27.6706C27.7763 20.6329 27.8752 20.5814 27.9352 20.4947L31.6427 15.1431C31.7314 15.0151 31.6403 14.84 31.4842 14.84Z"
        fill="#253B80"
      />

      <path
        d="M35.1747 12.6973H32.858C32.6998 12.6973 32.565 12.8124 32.5403 12.9689L31.6034 18.9089C31.5848 19.0261 31.6755 19.1317 31.7937 19.1317H32.9826C33.093 19.1317 33.1875 19.0511 33.2048 18.9414L33.4707 17.2577C33.4951 17.1008 33.6302 16.9857 33.7884 16.9857H34.5214C36.0476 16.9857 36.9279 16.2473 37.1582 14.7841C37.2622 14.1439 37.1623 13.6409 36.8625 13.2887C36.5336 12.9018 35.95 12.6973 35.1747 12.6973ZM35.442 14.8667C35.3156 15.6979 34.6806 15.6979 34.0661 15.6979H33.7169L33.9625 14.1456C33.9771 14.0518 34.0577 13.9827 34.1529 13.9827H34.3131C34.731 13.9827 35.126 13.9827 35.3299 14.2211C35.4515 14.3634 35.4884 14.5747 35.442 14.8667Z"
        fill="#179BD7"
      />

      <path
        d="M42.0985 14.84H40.99C40.8948 14.84 40.8138 14.9091 40.7996 15.0029L40.7505 15.3132L40.6726 15.2007C40.4324 14.8522 39.8973 14.7357 39.3628 14.7357C38.137 14.7357 37.0904 15.6641 36.8865 16.9664C36.7808 17.616 36.9309 18.2372 37.2994 18.6704C37.6381 19.0688 38.1211 19.2347 38.6965 19.2347C39.6842 19.2347 40.2319 18.5997 40.2319 18.5997L40.1825 18.9079C40.1638 19.0258 40.2546 19.1314 40.3735 19.1314H41.3724C41.5305 19.1314 41.6653 19.0163 41.6901 18.8594L42.2899 15.0629C42.3082 14.946 42.2174 14.84 42.0985 14.84ZM40.5523 16.9989C40.446 17.6326 39.9423 18.0581 39.3008 18.0581C38.9794 18.0581 38.7213 17.9548 38.556 17.759C38.392 17.5646 38.3304 17.2878 38.3819 16.9796C38.4825 16.3513 38.9933 15.912 39.625 15.912C39.94 15.912 40.196 16.0167 40.3647 16.2141C40.5344 16.4136 40.6015 16.692 40.5523 16.9989Z"
        fill="#179BD7"
      />

      <path
        d="M43.4063 12.8602L42.4556 18.9088C42.4369 19.026 42.5277 19.1317 42.6459 19.1317H43.6018C43.7606 19.1317 43.8954 19.0165 43.9198 18.8597L44.8573 12.9201C44.876 12.8029 44.7852 12.6969 44.667 12.6969H43.5967C43.5022 12.6972 43.4209 12.7663 43.4063 12.8602Z"
        fill="#179BD7"
      />

      <path
        d="M5.46119 20.2861L5.63834 19.1609L5.24374 19.1518H3.3595L4.66895 10.849C4.67301 10.8239 4.68622 10.8006 4.70553 10.784C4.72484 10.7674 4.74956 10.7582 4.7753 10.7582H7.9524C9.00714 10.7582 9.73503 10.9777 10.1151 11.4109C10.2932 11.6142 10.4067 11.8265 10.4616 12.0602C10.5191 12.3055 10.5202 12.5984 10.4639 12.9558L10.4599 12.9819V13.2108L10.638 13.3118C10.7881 13.3914 10.9073 13.4825 10.9988 13.5868C11.1512 13.7606 11.2497 13.9814 11.2914 14.2432C11.3344 14.5125 11.3202 14.8329 11.2497 15.1957C11.1684 15.613 11.037 15.9764 10.8595 16.2738C10.6963 16.5478 10.4883 16.7751 10.2414 16.9512C10.0057 17.1185 9.72554 17.2455 9.40885 17.3268C9.10198 17.4068 8.75209 17.4471 8.36834 17.4471H8.12108C7.94427 17.4471 7.77255 17.5108 7.63774 17.6249C7.50259 17.7414 7.41317 17.9006 7.38574 18.0747L7.36711 18.176L7.05414 20.1591L7.03992 20.2319C7.03619 20.255 7.02976 20.2665 7.02027 20.2743C7.0118 20.2814 6.99961 20.2861 6.98776 20.2861H5.46119Z"
        fill="#253B80"
      />

      <path
        d="M10.8065 13.0082C10.797 13.0688 10.7862 13.1308 10.774 13.1945C10.355 15.3456 8.92156 16.0887 7.09083 16.0887H6.15871C5.93482 16.0887 5.74616 16.2513 5.71127 16.4722L5.23403 19.4989L5.09888 20.3568C5.07619 20.5018 5.18796 20.6325 5.33429 20.6325H6.98753C7.1833 20.6325 7.34961 20.4903 7.38043 20.2972L7.39669 20.2132L7.70796 18.2379L7.72795 18.1295C7.75843 17.9357 7.92508 17.7935 8.12085 17.7935H8.36811C9.96987 17.7935 11.2238 17.1431 11.5903 15.2613C11.7434 14.4751 11.6641 13.8187 11.259 13.357C11.1364 13.2178 10.9843 13.1023 10.8065 13.0082Z"
        fill="#179BD7"
      />

      <path
        d="M10.3681 12.8334C10.3041 12.8147 10.238 12.7978 10.1703 12.7826C10.1022 12.7677 10.0324 12.7545 9.96063 12.7429C9.70931 12.7023 9.43394 12.683 9.13892 12.683H6.64873C6.58742 12.683 6.52917 12.6969 6.477 12.7219C6.36218 12.7771 6.27683 12.8859 6.25617 13.019L5.72642 16.3742L5.71118 16.4721C5.74607 16.2513 5.93473 16.0887 6.15862 16.0887H7.09075C8.92147 16.0887 10.3549 15.3452 10.7739 13.1944C10.7864 13.1308 10.7969 13.0688 10.8064 13.0081C10.7004 12.9519 10.5856 12.9038 10.4619 12.8628C10.4314 12.8527 10.3999 12.8429 10.3681 12.8334Z"
        fill="#222D65"
      />

      <path
        d="M6.25635 13.019C6.27701 12.8859 6.36237 12.7772 6.47719 12.7223C6.52969 12.6973 6.58761 12.6834 6.64892 12.6834H9.13911C9.43413 12.6834 9.7095 12.7027 9.96082 12.7433C10.0326 12.7548 10.1024 12.7681 10.1705 12.783C10.2382 12.7982 10.3043 12.8151 10.3683 12.8338C10.4001 12.8433 10.4316 12.8531 10.4624 12.8629C10.5861 12.9039 10.7009 12.9523 10.8069 13.0082C10.9316 12.2133 10.8059 11.672 10.3761 11.1819C9.90222 10.6423 9.04698 10.4113 7.95261 10.4113H4.77551C4.55196 10.4113 4.36127 10.5739 4.32672 10.7951L3.00338 19.1832C2.9773 19.3492 3.10533 19.4989 3.27266 19.4989H5.23413L5.72661 16.3743L6.25635 13.019Z"
        fill="#253B80"
      />
    </g>,
    <rect x="0.5" y="0.5" width="47" height="31" rx="3.5" stroke="#8D95A5" />,
    <defs>
      <clipPath id="clip0_2818_92417">
        <rect width="42" height="11.1774" fill="white" transform="translate(3 10.4113)" />,
      </clipPath>
    </defs>,
  ],
});
