import { ChangeEvent } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Paths } from "paths";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Input, Link, Text } from "ui";
import * as yup from "yup";

export type SignUpFormState = {
  email: string;
  password: string;
  passwordConfirmation: string;
  acceptedPlatformPrivacyPolicy: boolean;
  acceptedPlatformRegulations: boolean;
};

type Props = { onSubmit: (formState: SignUpFormState) => void; isSubmitting: boolean };

const SignUpForm: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const customerEmail = params.get("customer_email");
  const validationSchema = yup
    .object({
      email: yup
        .string()
        .email(t("prompt_error_invalid_e-mail_2"))
        .max(255, t("prompt_error_length_max", { value: 255 }))
        .required(t("prompt_error_mandatory_e-mail")),
      password: yup
        .string()
        .required(t("prompt_error_mandatory_password"))
        .min(12, t("prompt_error_length_min", { value: 12 }))
        .matches(/[a-z]+/, t("prompt_error_lowercase_lack"))
        .matches(/[A-Z]+/, t("prompt_error_uppercase_lack"))
        .matches(/\d+/, t("prompt_error_number_lack")),
      passwordConfirmation: yup
        .string()
        .required(t("prompt_error_required_password_repeat"))
        .oneOf([yup.ref("password")], t(`prompt_error_passwords_dont_match`)),
      acceptedPlatformPrivacyPolicy: yup.bool().oneOf([true], t("prompt_error_mandatory_privacy_policy")),
      acceptedPlatformRegulations: yup.bool().oneOf([true], t("prompt_error_mandatory_store_policy")),
    })
    .required();

  const {
    register,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<SignUpFormState>({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: customerEmail || undefined,
    },
  });

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    register("password").onChange(event);
    if (touchedFields.passwordConfirmation) {
      trigger("passwordConfirmation");
    }
  };

  const onSubmit = () => {
    const formState = getValues();
    props.onSubmit(formState);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column">
        <FormControl isInvalid={errors.hasOwnProperty("email")}>
          <FormLabel htmlFor="email">{t("label_noun_e-mail")}</FormLabel>
          <Input
            id="email"
            type="email"
            placeholder={t("label_action_request_suggestion_e-mail")}
            isReadOnly={!!customerEmail}
            isDisabled={!!customerEmail}
            {...register("email")}
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.hasOwnProperty("password")}>
          <FormLabel htmlFor="password">{t("label_noun_password")}</FormLabel>
          <Input
            id="password"
            type="password"
            placeholder={t("label_action_request_suggestion_password")}
            {...register("password")}
            onChange={onChangePassword}
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.hasOwnProperty("passwordConfirmation")}>
          <FormLabel htmlFor="password-confirmation">{t("label_action_request_repeat_password")}</FormLabel>
          <Input
            id="password-confirmation"
            type="password"
            placeholder={t("label_action_request_suggestion_password")}
            {...register("passwordConfirmation")}
          />
          <FormErrorMessage>{errors.passwordConfirmation?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.hasOwnProperty("acceptedPlatformRegulations")}>
          <Checkbox id="platform-regulations" {...register("acceptedPlatformRegulations")}>
            <Text>
              {t("prompt_accepting_terms_and_conditions_pt1")}{" "}
              <Link href={Paths.PlatformRegulations} isExternal>
                {t("prompt_accepting_terms_and_conditions_pt2")}
              </Link>
            </Text>
          </Checkbox>
          <FormErrorMessage>{errors.acceptedPlatformRegulations?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.hasOwnProperty("acceptedPlatformPrivacyPolicy")}>
          <Checkbox id="privacy-policy" {...register("acceptedPlatformPrivacyPolicy")}>
            <Text>
              {t("prompt_accepting_store_privacy_policy_pt1")}{" "}
              <Link href={Paths.PlatformPrivacyPolicy} isExternal>
                {t("prompt_accepting_store_privacy_policy_pt2")}
              </Link>
            </Text>
          </Checkbox>
          <FormErrorMessage>{errors.acceptedPlatformPrivacyPolicy?.message}</FormErrorMessage>
        </FormControl>
        <Button type="submit" isLoading={props.isSubmitting} mt={6}>
          {t("label_action_request_sign_up")}
        </Button>
      </Flex>
    </form>
  );
};

export default SignUpForm;
