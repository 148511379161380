import React from "react";

import { createIcon } from "@chakra-ui/react";

export const HelpIcon = createIcon({
  displayName: "HelpIcon",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 6,
      h: 6,
    },
  },
  path: [
    <path
      d="M12.5332 13.3926H11.0391C11.043 13.041 11.0703 12.7383 11.1211 12.4844C11.1758 12.2266 11.2676 11.9941 11.3965 11.7871C11.5293 11.5801 11.7051 11.375 11.9238 11.1719C12.1074 11.0078 12.2676 10.8516 12.4043 10.7031C12.541 10.5547 12.6484 10.4023 12.7266 10.2461C12.8047 10.0859 12.8438 9.9082 12.8438 9.71289C12.8438 9.48633 12.8086 9.29883 12.7383 9.15039C12.6719 8.99805 12.5703 8.88281 12.4336 8.80469C12.3008 8.72656 12.1328 8.6875 11.9297 8.6875C11.7617 8.6875 11.6055 8.72461 11.4609 8.79883C11.3164 8.86914 11.1973 8.97852 11.1035 9.12695C11.0137 9.27539 10.9668 9.4707 10.9629 9.71289H9.26367C9.27539 9.17773 9.39844 8.73633 9.63281 8.38867C9.87109 8.03711 10.1895 7.77734 10.5879 7.60938C10.9863 7.4375 11.4336 7.35156 11.9297 7.35156C12.4766 7.35156 12.9453 7.44141 13.3359 7.62109C13.7266 7.79688 14.0254 8.05664 14.2324 8.40039C14.4395 8.74023 14.543 9.1543 14.543 9.64258C14.543 9.98242 14.4766 10.2852 14.3438 10.5508C14.2109 10.8125 14.0371 11.0566 13.8223 11.2832C13.6074 11.5098 13.3711 11.7441 13.1133 11.9863C12.8906 12.1855 12.7383 12.3945 12.6562 12.6133C12.5781 12.832 12.5371 13.0918 12.5332 13.3926ZM10.8633 15.2031C10.8633 14.9531 10.9492 14.7461 11.1211 14.582C11.293 14.4141 11.5234 14.3301 11.8125 14.3301C12.0977 14.3301 12.3262 14.4141 12.498 14.582C12.6738 14.7461 12.7617 14.9531 12.7617 15.2031C12.7617 15.4453 12.6738 15.6504 12.498 15.8184C12.3262 15.9863 12.0977 16.0703 11.8125 16.0703C11.5234 16.0703 11.293 15.9863 11.1211 15.8184C10.9492 15.6504 10.8633 15.4453 10.8633 15.2031Z"
      fill="currentColor"
    />,
    <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />,
  ],
});
