import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ArrowDown = createIcon({
  displayName: "ArrowDown",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 6,
      h: 6,
    },
  },
  path: [
    <path
      d="M12.71 18.17L21.71 9.17C21.8053 9.07687 21.8809 8.96564 21.9326 8.84286C21.9843 8.72008 22.0109 8.58821 22.0109 8.455C22.0109 8.32179 21.9843 8.18992 21.9326 8.06713C21.8809 7.94435 21.8053 7.83313 21.71 7.74C21.6171 7.64627 21.5065 7.57187 21.3846 7.52111C21.2627 7.47034 21.132 7.4442 21 7.4442C20.868 7.4442 20.7373 7.47034 20.6154 7.52111C20.4936 7.57187 20.383 7.64627 20.29 7.74L12 16L3.72002 7.75C3.62706 7.65627 3.51646 7.58187 3.3946 7.53111C3.27274 7.48034 3.14203 7.4542 3.01002 7.4542C2.87801 7.4542 2.7473 7.48034 2.62544 7.53111C2.50359 7.58187 2.39298 7.65627 2.30002 7.75C2.20477 7.84313 2.1291 7.95435 2.07743 8.07713C2.02576 8.19992 1.99915 8.33179 1.99915 8.465C1.99915 8.59821 2.02576 8.73008 2.07743 8.85286C2.1291 8.97564 2.20477 9.08687 2.30002 9.18L11.3 18.18C11.4887 18.3649 11.7429 18.4677 12.0071 18.4658C12.2713 18.4639 12.524 18.3576 12.71 18.17Z"
      fill="currentColor"
    />,
  ],
});
