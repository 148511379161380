import React from "react";

import { createIcon } from "@chakra-ui/react";

export const VascoLogo = createIcon({
  displayName: "VascoLogo",
  defaultProps: {
    width: "110px",
    height: "20px",
  },
  viewBox: "0 0 110 20",
  path: [
    <path
      d="M0 1.15256H4.36555L9.99315 14.3883H10.0429L15.645 1.15256H20.0348L12.7406 17.4331C12.2569 18.4924 11.1717 19.1905 10.0072 19.1915C8.84177 19.1898 7.75636 18.49 7.27378 17.4292L0 1.15256Z"
      fill="black"
    />,
    <path
      d="M31.3639 0.785187C32.5274 0.784985 33.6123 1.48096 34.0973 2.53855L41.394 18.8178H37.0068L31.4047 5.58333H31.3537L25.7235 18.8178H21.3567L28.6305 2.5411C29.1149 1.48274 30.1999 0.785754 31.3639 0.785187Z"
      fill="black"
    />,
    <path
      d="M85.5728 12.5101C84.8353 16.7206 81.2895 19.193 77.1199 19.193C71.7195 19.193 67.8337 15.3647 67.8337 9.98339C67.8337 4.60208 71.7195 0.772491 77.1199 0.772491C81.1229 0.772491 84.6241 3.07502 85.5116 7.08409H81.5927C80.827 5.21205 79.2319 4.14905 77.1263 4.14905C73.8965 4.14905 71.7003 6.52133 71.7003 9.97829C71.7003 13.4352 73.8965 15.8075 77.1263 15.8075C79.3697 15.8075 81.0733 14.6233 81.7279 12.4999L85.5728 12.5101Z"
      fill="black"
    />,
    <path
      d="M91.4302 9.98586C91.4302 4.61092 95.3172 0.774963 100.716 0.774963C106.116 0.774963 110 4.61092 110 9.98586C110 15.3608 106.118 19.1917 100.716 19.1917C95.3146 19.1917 91.4302 15.3634 91.4302 9.98586ZM106.142 9.98586C106.142 6.50211 103.946 4.15663 100.716 4.15663C97.4866 4.15663 95.2917 6.50211 95.2917 9.98586C95.2917 13.4696 97.4866 15.8151 100.716 15.8151C103.946 15.8151 106.142 13.4683 106.142 9.98586Z"
      fill="black"
    />,
    <path
      d="M57.1081 5.92029C56.8363 5.22992 55.6725 4.14141 53.2466 4.14141C51.1245 4.14141 49.8343 4.94407 49.8343 6.02238C49.8343 7.02284 50.9318 7.72725 52.5882 8.05011L55.6202 8.58862C59.0848 9.20498 61.2873 10.795 61.4456 13.7211C61.6128 16.8003 58.9993 19.239 54.0519 19.239C48.6667 19.239 46.3429 16.8144 45.687 13.5654H49.6493C50.198 14.9117 51.4945 15.8522 54.0519 15.8522C56.405 15.8522 57.5994 15.0636 57.5994 13.8551C57.5994 13.0397 56.9065 12.3238 55.2335 12.0022L51.6222 11.3348C48.0121 10.6674 46.126 8.81959 45.9818 6.14999C45.835 3.45614 48.349 0.761017 53.267 0.761017C57.6696 0.761017 60.5217 2.89466 61.0704 5.92029H57.1081Z"
      fill="black"
    />,
  ],
});
