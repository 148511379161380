import React from "react";

import { ButtonProps, chakra, useStyleConfig } from "@chakra-ui/react";

export interface StatusButtonProps extends ButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}
export const StatusButton: React.FC<StatusButtonProps> = ({ children, onClick, ...buttonProps }) => {
  const styles = useStyleConfig("StatusButton");

  return (
    <chakra.button __css={styles} onClick={onClick} {...buttonProps}>
      {children}
    </chakra.button>
  );
};
