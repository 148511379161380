import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { FetchBaseQueryMeta, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Paths } from "paths";
import { RootState } from "store";

import { Err, err } from "../errors";

export enum Method {
  Get = "GET",
  Post = "POST",
  Delete = "DELETE",
  Put = "PUT",
  Patch = "PATCH",
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("authorization", token);
    }
    headers.set("content-type", "application/json");
    headers.set("accept", "application/json");
    return headers;
  },
});

export const api = createApi({
  baseQuery: async (args, api, extraOptions) => {
    const queryResult = await baseQuery(args, api, extraOptions);
    const result: QueryReturnValue<unknown, Err, FetchBaseQueryMeta> = { ...queryResult, error: undefined };

    if (queryResult.error) {
      const error = err.parse(queryResult.error);
      const { token } = (api.getState() as RootState).auth;
      const isStatusUnauthorized = error.status === 401;
      const isSignOutRequest = typeof args !== "string" && args.url === "users/sign_out";

      if (!isSignOutRequest && token && isStatusUnauthorized) {
        window.location.href = `${Paths.SignOut}?session-expired=1`;
      }
      result.error = error;
    }

    return result;
  },
  tagTypes: [
    "Auth",
    "BillingAddress",
    "UserDevices",
    "Subscriptions",
    "SubscriptionOffers",
    "PairingToken",
    "PairedDevice",
    "ShippingAddress",
    "ShippingMethods",
    "Payments",
    "Orders",
  ],
  endpoints: () => ({}),
});

// all currency codes can be found at: https://www.six-group.com/en/products-services/financial-information/data-standards.html#scrollTo=currency-codes
export type Currency = "USD" | "PLN" | "EUR";

export type ModelType = "user" | "consents" | "consents_detail" | "billing_detail";

export type { ApiErrorRaw } from "../errors";

export type HardwareRaw = {
  id: string;
  type: "hardware";
  attributes: {
    name: string;
    details: {
      color?: string;
    };
  };
};

export type ItemRaw = {
  id: string;
  type: "item";
  relationships: {
    subject: {
      data: {
        id: string;
        type: "hardware" | "subscription_offer";
      };
    };
  };
};

export type ResBuilder<T extends {}, I = undefined> = I extends undefined
  ? {
      data: T;
    }
  : {
      data: T;
      included: I[];
    };

export const transformResponse = <T extends any>({ data, included }: TransformResponseProps): T => {
  const extractedIncluded = extractIncluded(included);
  const mappedData = mappingParse(data, extractedIncluded) as T;
  return mappedData;
};

export const extractIncluded = (data: ResDataProps[] = []): Record<string, Record<string, TObject>> => {
  return data.reduce((result, resource) => {
    const { id, type } = resource;
    const datum = extractData(resource);
    const camelizedType = snakeToCamelCase(type);

    if (result[camelizedType]) {
      result[camelizedType][id] = datum;
    } else {
      result[camelizedType] = {
        [id]: datum,
      };
    }

    return result;
  }, {} as Record<string, Record<string, TObject>>);
};

export const snakeToCamelCase = <T extends string>(str: T) => {
  return str.replace(/([-_][a-z])/gi, res => res.toUpperCase().replace("-", "").replace("_", ""));
};

export const isDate = (value: any): value is Date => {
  return value instanceof Date;
};

export const isObject = (data: any): data is object => {
  return typeof data === "object" && data !== null;
};

export const snakeKeysToCamel = <T extends unknown>(data: T): SnakeToCamelCaseNested<T> => {
  if (!isObject(data) || isDate(data)) {
    return data as SnakeToCamelCaseNested<T>;
  }

  if (Array.isArray(data)) {
    return data.map(datum => {
      return snakeKeysToCamel(datum);
    }) as SnakeToCamelCaseNested<T>;
  }

  return Object.entries(data).reduce((result, [k, v]) => {
    let value;

    if (!isObject(v) || isDate(v)) {
      value = v;
    } else {
      value = snakeKeysToCamel(v);
    }

    Object.assign(result, {
      [snakeToCamelCase(k)]: value,
    });

    return result;
  }, {}) as SnakeToCamelCaseNested<T>;
};

export const extractData = (data: ResDataProps): ResExtractDataReturnProps => {
  const { id, attributes, type, relationships } = data;

  const result = {
    id: checkStringContainOnlyDigits(id) ? parseInt(id) : id,
    type,
    ...attributes,
  };

  const extractedRelationships = extractRelationships(relationships);

  Object.assign(result, { relationships: extractedRelationships });
  // @ts-ignore
  return snakeKeysToCamel(result);
};

const extractRelationships = (data: ResRelationshipsProps = {}): TObject => {
  return Object.entries(data).reduce((result, [k, v]) => {
    if (!v?.data) {
      return result;
    }
    const camelizedKey = snakeToCamelCase(k);
    result[camelizedKey] = v.data;

    return result;
  }, {} as Record<string, string | string[] | object | object[]>);
};

const mappingParse = (
  data: ResDataProps | ResDataProps[],
  extractedIncluded: Record<string, Record<string, TObject>>,
): TObject | TObject[] => {
  if (Array.isArray(data)) {
    return data.map(resource => {
      const { relationships, ...datum } = extractData(resource);
      return {
        ...datum,
        ...mapRelation(
          relationships,
          extractedIncluded as Record<string, Record<string, TObject & ResExtractDataReturnProps>>,
        ),
      };
    });
  }

  const { relationships, ...datum } = extractData(data);
  return {
    ...datum,
    ...mapRelation(
      relationships,
      extractedIncluded as Record<string, Record<string, TObject & ResExtractDataReturnProps>>,
    ),
  };
};

export type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;

export type CamelToSnakeCaseNested<T> = T extends object
  ? {
      [K in keyof T as CamelToSnakeCase<K & string>]: CamelToSnakeCaseNested<T[K]>;
    }
  : T;

export type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S;

export type SnakeToCamelCaseNested<T> = T extends object
  ? {
      [K in keyof T as SnakeToCamelCase<K & string>]: SnakeToCamelCaseNested<T[K]>;
    }
  : T;

type TObject = Record<string, string | number | boolean | null | object | number[]>;
type TBasic = string | number | boolean;

interface ResResourceObjectProps {
  id: string;
  type: string;
}

interface ResRelationshipsProps {
  [key: string]:
    | {
        data: ResResourceObjectProps | ResResourceObjectProps[] | null;
      }
    | undefined;
}

interface ResAttributesProps {
  [key: string]: TBasic | TBasic[] | null | TObject | TObject[] | undefined;
}

interface ResExtractDataReturnProps {
  id: string;
  relationships: {
    [key: string]: ResResourceObjectProps | ResResourceObjectProps[];
  };
  [key: string]: string | TObject;
}

export interface ResDataProps extends ResResourceObjectProps {
  attributes?: ResAttributesProps;
  relationships?: ResRelationshipsProps;
}

interface TransformResponseProps {
  data: ResDataProps | ResDataProps[];
  included?: ResDataProps[];
}

export const checkStringContainOnlyDigits = (str: string | null): str is string => {
  if (!str) {
    return false;
  }

  return /^[0-9]+$/.test(str);
};

const extractResourceObjects = (data: ResResourceObjectProps | ResResourceObjectProps[]): string | string[] => {
  if (Array.isArray(data)) {
    return data.map(datum => datum.id);
  }

  return data.id;
};

const mapRelation = (
  data: ResExtractDataReturnProps["relationships"],
  extractedIncluded: Record<string, Record<string, TObject & ResExtractDataReturnProps>>,
): Record<string, TObject> => {
  return Object.entries(data).reduce((result, [k, v]) => {
    if (!v) {
      return result;
    }

    let relationData;
    if (Array.isArray(v)) {
      relationData = v.reduce((relationResult, datum) => {
        const { id, type } = datum;
        const camelizedType = snakeToCamelCase(type);
        const extractedIncludedData = extractedIncluded[camelizedType]?.[id];

        if (!extractedIncludedData) {
          const key = `${snakeToCamelCase(k)}Ids`;
          Object.assign(result, {
            [key]: extractResourceObjects(v),
          });
          return undefined;
        }
        const { relationships, ...relation } = extractedIncludedData;

        relationResult?.push({
          ...relation,
          ...mapRelation(relationships, extractedIncluded),
        });

        return relationResult;
      }, [] as Record<string, string | TObject>[] | undefined);
    } else {
      const { type } = v;
      const id = checkStringContainOnlyDigits(v.id) ? parseInt(v.id, 10) : v.id;
      const camelizedType = snakeToCamelCase(type);
      const extractedIncludedData = extractedIncluded[camelizedType]?.[id];

      if (!extractedIncludedData) {
        Object.assign(result, {
          [`${snakeToCamelCase(k)}Id`]: id,
        });

        return result;
      }

      const { relationships, ...relation } = extractedIncludedData;

      relationData = {
        ...relation,
        ...mapRelation(relationships, extractedIncluded),
      };
    }

    if (relationData !== undefined) {
      Object.assign(result, {
        [snakeToCamelCase(k)]: relationData,
      });
    }

    return result;
  }, {} as Record<string, TObject>);
};
