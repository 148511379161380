import React, { useMemo } from "react";

import { chakra } from "@chakra-ui/react";

import { Box, Text } from "../../atoms";

export interface MenuItemProps {
  onClick?: () => void;
  /**
   * If `true`, the menu item will be styled in its active state.
   * @default false
   */
  isActive?: boolean;
  /**
   * The content of the menu item
   */
  children: string;
}

export const SideMenuItem: React.FC<MenuItemProps> = ({ children, isActive = false, onClick }) => {
  const styleProps = useMemo(
    () => ({
      box: {
        backgroundColor: isActive ? "primary.300" : "grayLight",
      },
      text: { color: isActive ? "primary.300" : "black", _hover: { color: "primary.300" } },
    }),
    [isActive],
  );

  return (
    <chakra.li display="flex">
      <chakra.button display="flex" flex={1} alignItems="center" onClick={onClick}>
        <Box w={2} h={10} mr="l" borderRadius={8} {...styleProps.box} />
        <Text {...styleProps.text}>{children}</Text>
      </chakra.button>
    </chakra.li>
  );
};
