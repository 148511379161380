const transitionTimingFunction = {
  "ease-in": "cubic-bezier(0.4, 0, 1, 1)",
  "ease-out": "cubic-bezier(0, 0, 0.2, 1)",
  "ease-in-out": "cubic-bezier(0.4, 0, 0.2, 1)",
};

const transitionDuration = {
  "ultra-fast": "50ms",
  fast: "100ms",
  normal: "200ms",
  slow: "300ms",
  "ultra-slow": "600ms",
};

const transition = {
  easing: transitionTimingFunction,
  duration: transitionDuration,
};

export default transition;
