import { useEffect } from "react";

import { Paths } from "paths";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useRequiredSearchParams = (reqParams: string[] = []) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getParams = () => {
      return reqParams.map(param => params.get(param));
    };

    if (getParams().some(param => param === null)) {
      navigate(Paths.PageNotFound, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
