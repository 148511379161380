import { defineStyle, defineStyleConfig } from "@chakra-ui/system";

const baseStyle = defineStyle({
  fontSize: "1rem",
  lineHeight: "1.5rem",
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  textDecoration: "none",
  outline: "none",
  color: "primary.300",
  _hover: {
    color: "primary.500",
  },
});

export const Link = defineStyleConfig({
  baseStyle,
});
