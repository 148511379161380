import { useMemo, useState } from "react";

import { HStack } from "@chakra-ui/react";
import { LocaleKey } from "locales";
import { useTranslation } from "react-i18next";
import { BodyText, Button, Dropdown, DropdownItem, List, ListItem, PolishFlag, SpainFlag, TitleH1 } from "ui";
import { LINK_LANDING_PAGE, LINK_STORE_PAGE } from "utils/helpers";

type Locale = keyof typeof LINK_LANDING_PAGE;

type BuyDevicePromptProps = {
  title: string;
  description: string;
  listItems: string[];
};

const BuyDevicePrompt: React.FC<BuyDevicePromptProps> = ({ title, description, listItems }) => {
  const { t, i18n } = useTranslation();
  const [currentLocale, setCurrentLocale] = useState<Locale>("es-ES");
  const currentLang = i18n.language as LocaleKey;
  const languages = useMemo(
    () => [
      { value: "es-ES", label: t("label_noun_country_spain"), flag: <SpainFlag /> },
      { value: "pl-PL", label: t("label_noun_country_poland"), flag: <PolishFlag /> },
    ],
    [t],
  );
  const selectedLanguage = languages.find(language => language.value === currentLocale);

  const goToLandingPage = () => {
    const language = currentLang !== "en-US" ? currentLang : currentLocale;
    window.open(LINK_LANDING_PAGE[language], "_blank");
  };

  const onChange = (value: Locale) => setCurrentLocale(value);

  return (
    <>
      <TitleH1 color="violet.300" my="xs">
        {title}
      </TitleH1>
      <BodyText mt="xs">{description}</BodyText>
      <List mb="xs">
        {listItems.map((item: string, index: number) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </List>
      <HStack spacing="m" mt="l">
        {currentLang === "en-US" && (
          <Dropdown label={selectedLanguage?.label || ""} flag={selectedLanguage?.flag} isSecondary>
            {languages.map(language => (
              <DropdownItem key={language.value} onClick={() => onChange(language.value as Locale)}>
                {language.label}
              </DropdownItem>
            ))}
          </Dropdown>
        )}
        <Button onClick={goToLandingPage}>{t("label_action_request_go_to_the_store")}</Button>
      </HStack>
      <BodyText mt="xxl" mb="xs">
        {t("prompt_contact_support")}
      </BodyText>
      <List>
        <ListItem>{LINK_STORE_PAGE.phone[currentLang]}</ListItem>
        <ListItem>{LINK_STORE_PAGE.contact[currentLang]}</ListItem>
      </List>
    </>
  );
};

export default BuyDevicePrompt;
