import React from "react";

import { createIcon } from "@chakra-ui/react";

export const FailedIllustration = createIcon({
  displayName: "FailedIllustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <path
      d="M138.15 15.45H11.85a6.99 6.99 0 0 0-6.99 6.99v79.12a6.99 6.99 0 0 0 6.99 6.99h126.3a6.99 6.99 0 0 0 6.99-6.99V22.44a6.99 6.99 0 0 0-6.99-6.99ZM90.26 108.55H59.73v25.99h30.53v-25.99ZM39.82 134.55h70.36"
      stroke="#8D95A5"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M75 92.84c17.358 0 31.43-14.072 31.43-31.43S92.358 29.98 75 29.98 43.57 44.052 43.57 61.41 57.642 92.84 75 92.84ZM84.67 51.74 65.33 71.09M65.33 51.74l19.34 19.35"
      stroke="#C70046"
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
