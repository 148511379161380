import { Method, ResBuilder, api, transformResponse } from "./api";

const shippingMethodsApi = api.injectEndpoints({
  endpoints: builder => ({
    getShippingMethods: builder.query<ShippingMethod[], void>({
      query: () => ({
        url: "shipping_methods",
        method: Method.Get,
      }),
      transformResponse: (res: ResBuilder<ShippingMethodRaw[]>) => transformResponse(res),
      providesTags: ["ShippingMethods"],
    }),
    getShippingMethodsByMarket: builder.query<ShippingMethod[], { market_ids: number[] }>({
      query: payload => ({
        url: "shipping_methods",
        method: Method.Get,
        params: payload,
      }),
      transformResponse: (res: ResBuilder<ShippingMethodRaw[]>) => transformResponse(res),
      providesTags: ["ShippingMethods"],
    }),
    getShippingMethod: builder.query<ShippingMethod, { id: number }>({
      query: ({ id }) => ({
        url: `/shipping_methods/${id}`,
        method: Method.Get,
      }),
      transformResponse: (res: ResBuilder<ShippingMethodRaw>) => transformResponse(res),
      providesTags: ["ShippingMethods"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetShippingMethodsQuery, useGetShippingMethodsByMarketQuery, useGetShippingMethodQuery } =
  shippingMethodsApi;

export type ShippingMethod = {
  id: number;
  name: "ups_payable_in_advance" | "inpost_parcel_locker";
  externalId: string;
  priceSubunit?: number;
  priceCurrency?: string;
};

export type ShippingMethodRaw = {
  id: string;
  type: "shipping_method";
  attributes: {
    name: "ups_payable_in_advance" | "inpost_parcel_locker";
    external_id: string;
    price_subunit?: number;
    price_currency?: string;
  };
  relationships: {
    market: {
      data: {
        id: string;
        type: "market";
      };
    };
  };
};
