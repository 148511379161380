import React from "react";

import { createIcon } from "@chakra-ui/react";

export const SubscriptionEnd1Illustration = createIcon({
  displayName: "SubscriptionEnd1Illustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <path
      d="M68.0026 108.546C67.975 107.534 68.1653 106.528 68.5599 105.601C68.9544 104.673 69.5435 103.848 70.2848 103.183C71.9009 101.829 73.92 101.091 76.0027 101.091C78.0853 101.091 80.1045 101.829 81.7206 103.183C82.4393 103.865 83.0128 104.693 83.4051 105.615C83.7975 106.537 84 107.533 84 108.54C84 109.546 83.7975 110.542 83.4051 111.464C83.0128 112.386 82.4393 113.215 81.7206 113.897C80.1075 115.257 78.0872 116 76.0027 116C73.9181 116 71.8979 115.257 70.2848 113.897C69.5438 113.235 68.9548 112.411 68.5601 111.486C68.1655 110.56 67.9751 109.556 68.0026 108.546ZM81.9476 94.8188H70.2595L68.4818 51H83.7884L81.9476 94.8188Z"
      fill="#E40000"
    />,
    <path
      d="M144.525 117.505C150.1 126.533 145.935 134 135.264 134H15.736C5.06507 134 0.900155 126.571 6.47493 117.505L65.3529 21.7709C70.9406 12.743 80.0594 12.743 85.6471 21.7709L144.525 117.505Z"
      stroke="#A0A0A0"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
