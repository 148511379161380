import React from "react";

import { createIcon } from "@chakra-ui/react";

export const CbCardLogo = createIcon({
  displayName: "CbCardLogo",
  viewBox: "0 0 48 32",
  defaultProps: {
    width: 12,
    height: 8,
    fill: "transparent",
  },
  path: [
    <rect x="0.5" y="0.5" width="47" height="31" rx="3.5" fill="white" />,
    <path d="M40.4349 4.77747H7.56494V27.28H40.4349V4.77747Z" fill="#1BA559" />,
    <path d="M40.4349 4.77747H7.56494V27.28H40.4349V4.77747Z" fill="url(#paint0_linear_1848_80359)" />,
    <path d="M40.4349 4.77747H7.56494V27.28H40.4349V4.77747Z" fill="url(#paint1_linear_1848_80359)" />,
    <path
      d="M24.6501 15.92C24.4251 15.935 24.1876 15.965 23.9501 15.965H18.7701C18.6164 15.9447 18.4602 15.9541 18.3101 15.9925C18.2498 16.0244 18.1973 16.0691 18.1563 16.1236C18.1152 16.178 18.0866 16.2408 18.0726 16.3075C18.0726 16.37 18.2301 16.53 18.3226 16.5375C18.7351 16.5625 19.1501 16.5375 19.5726 16.5375H24.1776C24.3301 16.5375 24.4826 16.5525 24.6776 16.565C24.7324 17.358 24.5907 18.1523 24.2651 18.8775C23.3651 21.1275 21.5676 22.2425 19.2651 22.6275C17.7322 22.8976 16.1555 22.7676 14.6876 22.25C13.5502 21.8484 12.5623 21.1098 11.8552 20.1326C11.1482 19.1553 10.7557 17.9859 10.7301 16.78C10.6751 15.185 10.9251 13.6875 11.8851 12.37C12.8451 11.0525 14.2251 10.3025 15.8176 10C17.6101 9.65 19.3876 9.7275 21.0876 10.4575C23.2876 11.405 24.4176 13.1425 24.7051 15.475C24.7297 15.6272 24.7106 15.7832 24.6501 15.925V15.92Z"
      fill="white"
    />,
    <path
      d="M30.0601 16.565C31.3101 16.565 32.5601 16.5525 33.7901 16.565C34.2718 16.563 34.7525 16.6091 35.2251 16.7025C36.5576 16.9975 37.5076 18.41 37.3451 19.795C37.2865 20.4811 36.9758 21.1211 36.473 21.5915C35.9703 22.062 35.311 22.3295 34.6226 22.3425C31.7576 22.375 28.8926 22.3775 26.0276 22.41C25.5426 22.41 25.3226 22.3075 25.3401 21.7475C25.3826 20.2125 25.3801 18.675 25.3401 17.14C25.3401 16.64 25.5026 16.5375 25.9601 16.545C27.3226 16.5725 28.6876 16.545 30.0501 16.545L30.0601 16.565Z"
      fill="white"
    />,
    <path
      d="M25.3725 10.23C25.6425 10.21 25.8725 10.1825 26.0825 10.1825C28.6525 10.1825 31.225 10.1825 33.7975 10.2025C34.2951 10.1954 34.7918 10.2465 35.2775 10.355C35.9239 10.5277 36.4864 10.9276 36.862 11.4813C37.2376 12.035 37.4011 12.7055 37.3225 13.37C37.13 14.8425 36.025 15.885 34.5875 15.9C31.725 15.9325 28.86 15.935 25.995 15.9725C25.495 15.9725 25.34 15.8425 25.3525 15.335C25.3875 13.8175 25.37 12.2975 25.3725 10.78V10.23Z"
      fill="white"
    />,
    <rect x="0.5" y="0.5" width="47" height="31" rx="3.5" stroke="#8D95A5" />,
    <defs>
      <linearGradient
        id="paint0_linear_1848_80359"
        x1="23.9999"
        y1="4.77747"
        x2="23.9999"
        y2="27.2775"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#098EC3" />,
        <stop offset="1" stopColor="#098EC3" stopOpacity="0" />,
      </linearGradient>
      <linearGradient
        id="paint1_linear_1848_80359"
        x1="7.56494"
        y1="16.0275"
        x2="40.4349"
        y2="16.0275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#002A63" stopOpacity="0" />,
        <stop offset="1" stopColor="#002962" />,
      </linearGradient>
    </defs>,
  ],
});
