import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { authApi } from "store/api";

export type OrderPayload = {
  billingDetailId: number | null;
  shippingMethodId: number | null;
  shippingAddressId: number | null;
  useBillingAsShippingAddress: boolean | null;
  salesProducts: { id: number; quantity: number }[];
};

type CheckoutState = {
  order: OrderPayload;
  steps: {
    addresses: boolean;
    paymentMethod: boolean;
  };
};

export const initialCheckoutState: CheckoutState = {
  steps: {
    addresses: false,
    paymentMethod: false,
  },
  order: {
    shippingAddressId: null,
    billingDetailId: null,
    useBillingAsShippingAddress: null,
    shippingMethodId: null,
    salesProducts: [],
  },
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState: initialCheckoutState,
  reducers: {
    setOrderDetials: (state, { payload }: PayloadAction<Partial<OrderPayload>>) => {
      state.order = { ...state.order, ...payload };
    },
    setStepFinished: (state, { payload }: PayloadAction<"addresses" | "paymentMethod">) => {
      state.steps[payload] = true;
    },
    resetOrder: () => initialCheckoutState,
  },
  extraReducers: builder => {
    builder.addMatcher(authApi.endpoints.signOut.matchFulfilled, () => initialCheckoutState);
    builder.addMatcher(authApi.endpoints.signOut.matchRejected, () => initialCheckoutState);
  },
});

export const { setOrderDetials, resetOrder, setStepFinished } = checkoutSlice.actions;

export const selectOrder = (state: RootState) => state.checkout.order;
