import React from "react";

import {
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps as Props,
  chakra,
  useMultiStyleConfig,
} from "@chakra-ui/react";

import { ErrorIcon } from "../Icons";

export interface FormErrorMessageProps extends Props {
  /**
   * The content of the error
   */
  children?: Props["children"];
}

/**
 * FormErrorMessage is part of the FormControl, and it cannot be used outside of it. The message that appears when an error occurs. If you use FormErrorMessage and isInvalid in FormControl is false or undefined, FormErrorMessage will not be visible.
 */
export const FormErrorMessage = ({ children, ...restProps }: FormErrorMessageProps) => {
  const styles = useMultiStyleConfig("FormErrorMessage");

  return (
    <ChakraFormErrorMessage {...restProps}>
      <chakra.div __css={styles.icon}>
        <ErrorIcon />
      </chakra.div>
      <chakra.div __css={styles.text}>{children}</chakra.div>
    </ChakraFormErrorMessage>
  );
};
