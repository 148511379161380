import { defineStyle, defineStyleConfig } from "@chakra-ui/system";

const baseStyle = {};

export const variants = {
  header80: defineStyle({
    fontSize: "5rem",
    fontFamily: "heading",
    lineHeight: "6.5rem",
  }),
  headerSecondary80: defineStyle({
    fontSize: "5rem",
    lineHeight: "6.5rem",
  }),
  header48: defineStyle({
    fontSize: "3rem",
    fontFamily: "heading",
    lineHeight: "3.75rem",
  }),
  header32: defineStyle({
    fontSize: "2rem",
    fontFamily: "heading",
    lineHeight: "2.5rem",
  }),
  header24: defineStyle({
    fontSize: "1.5rem",
    fontFamily: "heading",
    lineHeight: "2.25rem",
  }),
  header20: defineStyle({
    fontSize: "1.25rem",
    fontFamily: "heading",
    lineHeight: "2rem",
  }),
  header16: defineStyle({
    fontSize: "1rem",
    fontFamily: "heading",
    lineHeight: "1.5rem",
  }),
  highlights48: defineStyle({
    fontSize: "3rem",
    lineHeight: "3.75rem",
    fontWeight: "bold",
  }),
  highlights40: defineStyle({
    fontSize: "2.5rem",
    lineHeight: "3rem",
    fontWeight: "bold",
  }),
  highlights32: defineStyle({
    fontSize: "2rem",
    lineHeight: "2.5rem",
    fontWeight: "bold",
  }),
  highlights28: defineStyle({
    fontSize: "1.75rem",
    lineHeight: "2.5rem",
    fontWeight: "bold",
  }),
  highlights24: defineStyle({
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
    fontWeight: "bold",
  }),
  highlights20: defineStyle({
    fontSize: "1.25rem",
    lineHeight: "2rem",
    letterSpacing: " 0.1rem",
    fontWeight: "bold",
  }),
  highlights18: defineStyle({
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    fontWeight: "bold",
  }),
  highlights16: defineStyle({
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: "bold",
  }),
  highlights12: defineStyle({
    fontSize: "0.75rem",
    lineHeight: "1rem",
    fontWeight: "bold",
  }),
  body48: defineStyle({
    fontSize: "3rem",
    lineHeight: "3.75rem",
  }),
  body40: defineStyle({
    fontSize: "2.5rem",
    lineHeight: "3rem",
  }),
  body28: defineStyle({
    fontSize: "1.75rem",
    lineHeight: "2.5rem",
  }),
  body24: defineStyle({
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
  }),
  body20: defineStyle({
    fontSize: "1.25rem",
    lineHeight: "2rem",
  }),
  body18: defineStyle({
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
  }),
  body16: defineStyle({
    fontSize: "1rem",
    lineHeight: "1.5rem",
  }),
  body12: defineStyle({
    fontSize: "0.75rem",
    lineHeight: "1rem",
  }),
  body10: defineStyle({
    fontSize: "0.625em",
    lineHeight: "0.875rem",
  }),
};

export const Text = defineStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: "body16",
  },
});
