import React from "react";

import { IconProps, createIcon } from "@chakra-ui/react";

const Triangle = createIcon({
  displayName: "Triangle",
  viewBox: "0 0 12 8",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 3,
      h: 2,
    },
  },
  path: [<path fill="currentColor" d="M6 8 .804 2h10.392L6 8Z" />],
});

type TriangleIconProps = IconProps & { css?: any };

export const TriangleDownIcon = (props: TriangleIconProps) => <Triangle {...props} />;

export const TriangleUpIcon = (props: TriangleIconProps) => <Triangle transform="rotate(0.5turn)" {...props} />;
