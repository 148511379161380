import React from "react";

import { TextProps as Props, SystemProps } from "@chakra-ui/react";

import { Text } from "../Text/Text";

export interface BodyTextProps extends Props {
  /**
   * The CSS `text-align` property
   * @type SystemProps["textAlign"]
   */
  align?: SystemProps["textAlign"];
  /**
   * The CSS `text-decoration` property
   * @type SystemProps["textDecoration"]
   */
  decoration?: SystemProps["textDecoration"];
  /**
   * The CSS `text-transform` property
   * @type SystemProps["textTransform"]
   */
  casing?: SystemProps["textTransform"];
  children: string;
  variant?: "body12" | "body16" | "highlights16" | "highlights18" | "body18" | "header24";
}

export const BodyText: React.FC<BodyTextProps> = ({ children, variant = "body16", ...props }) => {
  return (
    <Text color="black" variant={variant} {...props}>
      {children}
    </Text>
  );
};
