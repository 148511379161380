import React from "react";

import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BodyText, ErrorCart, Stack } from "ui";

const PageNotFound: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const action = {
    label: t("label_action_request_go_to_homepage"),
    onClick: () => navigate(Paths.Home),
  };

  return (
    <ErrorCart illustrationName="Error404Illustration" action={action}>
      <Stack direction="row" spacing="xs">
        <BodyText variant="header24" color="violet.300">
          {t("label_other_error_404_pt1")}
        </BodyText>
        <BodyText variant="header24">{t("label_action_info_error_404_pt2")}</BodyText>
      </Stack>
    </ErrorCart>
  );
};

export default PageNotFound;
