import React, { useEffect } from "react";

import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate, useNavigationType } from "react-router-dom";
import { useSendDeleteAccountInstructionMutation } from "store/api";
import { err } from "store/errors";
import {
  BodyText,
  Box,
  Button,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  SpinnerWithLabel,
  Stack,
  TitleH1,
} from "ui";

const SendDeleteAccountInstruction: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navType = useNavigationType();
  const [sendDeleteAccountInstruction, { isLoading }] = useSendDeleteAccountInstructionMutation();

  useEffect(() => {
    const onSendDeleteAccountInstruction = async () => {
      try {
        await sendDeleteAccountInstruction().unwrap();
      } catch (error) {
        err.handler(error);
        navigate(Paths.Profile);
      }
    };

    if (navType !== "REPLACE") {
      navigate(Paths.Profile);
    } else {
      onSendDeleteAccountInstruction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, navType]);

  const goToProfile = () => navigate(Paths.Profile, { replace: true });

  if (isLoading) {
    return <SpinnerWithLabel label={t("label_action_info_loading")} />;
  }

  return (
    <InfoPanel isBorder>
      <InfoPanelIllustration illustrationName="EmailConfirmationIllustration" />
      <InfoPanelContent>
        <Stack spacing="m">
          <TitleH1 color="violet.300">{t("label_action_request_check_e-mail")}</TitleH1>
          <BodyText>{t("prompt_e-mail_deletion_information")}</BodyText>
          <Box>
            <BodyText>{t("label_action_request_spam_alert_title")}</BodyText>
            <BodyText>{t("label_action_request_check_spam_and_other_folder")}</BodyText>
          </Box>
        </Stack>
      </InfoPanelContent>
      <InfoPanelAction>
        <Button onClick={goToProfile} variant="secondary">
          {t("label_action_request_go_to_profile")}
        </Button>
      </InfoPanelAction>
    </InfoPanel>
  );
};

export default SendDeleteAccountInstruction;
