import React from "react";

import { BodyText, Box, Button, ButtonProps, Flex, Stack, StackProps } from "../../atoms";
import { CardIllustrationProps, CreditCard } from "../../molecules";

type Props = CardIllustrationProps &
  StackProps & {
    action?: {
      label: string;
      onClick: ButtonProps["onClick"];
    };
  };

export const CreditCardDetails = ({
  numberLabel,
  number,
  holderLabel,
  holder,
  expDateLabel,
  expDate,
  logo,
  action,
  ...props
}: Props) => {
  return (
    <Stack spacing="l" direction={["column", "row"]} {...props}>
      <CreditCard
        numberLabel={numberLabel}
        number={number}
        holderLabel={holderLabel}
        holder={holder}
        expDateLabel={expDateLabel}
        expDate={expDate}
        logo={logo}
      />
      <Box px={[0, "m"]} alignSelf={["auto", "center"]}>
        <BodyText>{numberLabel}</BodyText>
        <BodyText variant="body18" pb="xs">
          {number}
        </BodyText>
        <BodyText>{holderLabel}</BodyText>
        <BodyText variant="body18" textTransform="uppercase">
          {holder}
        </BodyText>
      </Box>
      <Flex flex={1} justifyContent="flex-end" alignSelf={["auto", "center"]}>
        {action && (
          <Button onClick={action.onClick} variant="secondary" w={["full", "initial"]}>
            {action.label}
          </Button>
        )}
      </Flex>
    </Stack>
  );
};
