import React from "react";

import { Button, ButtonProps, Stack } from "../../atoms";

type CartFooterProps = {
  children: React.ReactNode;
  action?: {
    label: string;
    onClick: ButtonProps["onClick"];
  };
};

export const CartFooter = ({ action, children }: CartFooterProps) => {
  return (
    <Stack
      spacing="m"
      direction={["column", "row"]}
      justifyContent="space-between"
      backgroundColor="grayLight"
      pt={3}
      pb={4}
      px={4}
      borderBottomRadius="l"
    >
      <Stack spacing="m" direction={["column", "row"]}>
        {children}
      </Stack>
      {action && (
        <Button onClick={action.onClick} size="s" variant="black" width="min-content" placeSelf="center">
          {action.label}
        </Button>
      )}
    </Stack>
  );
};
