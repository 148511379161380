import { ChangeEvent } from "react";

import { useBreakpointValue } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequiredSearchParams } from "hooks";
import { Paths } from "paths";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "store/api";
import { err } from "store/errors";
import {
  BodyText,
  Box,
  Button,
  Card,
  Form,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  Input,
  Stack,
  TitleH1,
} from "ui";
import { camelKeysToSnake } from "utils/helpers";
import * as yup from "yup";

export type NewPasswordFormState = {
  password: string;
  passwordConfirmation: string;
};

const NewPassword: React.FC = () => {
  useRequiredSearchParams(["user_email", "reset_password_token"]);

  const { t } = useTranslation();
  let navigate = useNavigate();
  const [resetPassword, resetPasswordResult] = useResetPasswordMutation();
  const [params, setParams] = useSearchParams();
  const variant = useBreakpointValue({ sm: "vertical", md: "horizontal" });
  const {
    register,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm<NewPasswordFormState>({
    mode: "onTouched",
    resolver: yupResolver(
      yup
        .object({
          password: yup
            .string()
            .required(t("prompt_error_mandatory_password"))
            .min(12, t("prompt_error_length_min", { value: 12 }))
            .matches(/[a-z]+/, t("prompt_error_lowercase_lack"))
            .matches(/[A-Z]+/, t("prompt_error_uppercase_lack"))
            .matches(/\d+/, t("prompt_error_number_lack")),
          passwordConfirmation: yup
            .string()
            .required(t("prompt_error_required_password_repeat"))
            .oneOf([yup.ref("password")], t(`prompt_error_passwords_dont_match`)),
        })
        .required(),
    ),
  });

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    register("password").onChange(event);
    if (touchedFields.passwordConfirmation) {
      trigger("passwordConfirmation");
    }
  };

  const onNewPassword = async () => {
    try {
      const resetPasswordToken = params.get("reset_password_token");
      const passwordsPayload = camelKeysToSnake(getValues());

      if (resetPasswordToken) {
        const payload = {
          ...passwordsPayload,
          reset_password_token: resetPasswordToken,
        };
        await resetPassword(payload).unwrap();
        setParams({ confirm: "true" });
      }
    } catch (error) {
      err.handler(error);
    }
  };

  const goToSignIn = () => {
    navigate(Paths.SignIn);
  };

  if (params.get("confirm") && resetPasswordResult.isSuccess) {
    return (
      <InfoPanel isBorder>
        <InfoPanelIllustration illustrationName="ConfirmationCompleted2Illustration" color="greenAlert" />
        <InfoPanelContent>
          <TitleH1 color="greenAlert">{t("label_action_info_password_changed_description")}</TitleH1>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button w={["full", "initial"]} onClick={goToSignIn}>
            {t("label_action_request_go_to_sign_in")}
          </Button>
        </InfoPanelAction>
      </InfoPanel>
    );
  }

  const email = params.get("user_email");

  return (
    <Card py={[0, "xl"]} px={[0, "l", "xl"]}>
      <Form onSubmit={handleSubmit(onNewPassword)}>
        <Stack spacing="m">
          <BodyText variant="header24">{t("label_action_request_change_password")}</BodyText>
          <BodyText>{t("label_action_request_change_password_instruction", { email })}</BodyText>
          <Box>
            <FormControl variant={variant} isInvalid={errors.hasOwnProperty("password")}>
              <FormLabel htmlFor="password">{t("label_noun_password")}</FormLabel>
              <Input
                id="password"
                type="password"
                placeholder={t("label_action_request_suggestion_password")}
                {...register("password")}
                onChange={onChangePassword}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
            <FormControl variant={variant} isInvalid={errors.hasOwnProperty("passwordConfirmation")}>
              <FormLabel htmlFor="password-confirmation">{t("label_action_request_repeat_password")}</FormLabel>
              <Input
                id="password-confirmation"
                type="password"
                placeholder={t("label_action_request_suggestion_password")}
                {...register("passwordConfirmation")}
              />
              <FormErrorMessage>{errors.passwordConfirmation?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box mt={[0, 0, 16]} textAlign="right">
            <Button type="submit" isLoading={isSubmitting} w={["full", "auto"]}>
              {t("label_action_request_set_password")}
            </Button>
          </Box>
        </Stack>
      </Form>
    </Card>
  );
};

export default NewPassword;
