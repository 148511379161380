import React from "react";

import { useUser } from "hooks";
import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useChangePasswordMutation } from "store/api";
import { err } from "store/errors";
import {
  Box,
  Button,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  TitleH1,
  TitleH1Os,
} from "ui";
import { camelKeysToSnake } from "utils/helpers";

import ChangePasswordForm, { ChangePasswordFormState } from "./ChangePasswordForm";

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const { email } = useUser();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [changePassword, { isLoading, isSuccess }] = useChangePasswordMutation();

  const onChangePassword = async (formState: ChangePasswordFormState) => {
    try {
      const payload = camelKeysToSnake(formState);
      await changePassword(payload).unwrap();
      setParams({ confirm: "true" });
    } catch (error) {
      err.handler(error);
    }
  };

  const goToProfile = () => navigate(Paths.Profile);

  if (params.get("confirm") && isSuccess) {
    return (
      <InfoPanel>
        <InfoPanelIllustration illustrationName="ConfirmationCompleted2Illustration" color="greenAlert" />
        <InfoPanelContent>
          <TitleH1 color="greenAlert">{t("label_action_info_password_changed_description")}</TitleH1>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button onClick={goToProfile}>{t("label_action_request_go_to_profile")}</Button>
        </InfoPanelAction>
      </InfoPanel>
    );
  }

  return (
    <Box w="100%">
      <TitleH1Os subtitle={t("label_action_request_change_password_instruction", { email })}>
        {t("label_action_request_change_password")}
      </TitleH1Os>
      <ChangePasswordForm onSubmit={onChangePassword} isSubmitting={isLoading} />
    </Box>
  );
};

export default ChangePassword;
