import React from "react";

import { createIcon } from "@chakra-ui/react";

export const KebabIcon = createIcon({
  displayName: "KebabIcon",
  viewBox: "0 0 20 20",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: 5,
      h: 5,
    },
  },
  path: [
    <rect x="8.33337" y="2.5" width="3.33333" height="3.33333" rx="1.66667" fill="#2D3139" />,
    <rect x="8.33337" y="8.33334" width="3.33333" height="3.33333" rx="1.66667" fill="#2D3139" />,
    <rect x="8.33337" y="14.1667" width="3.33333" height="3.33333" rx="1.66667" fill="#2D3139" />,
  ],
});
