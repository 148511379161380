import React from "react";

import { ChakraProvider } from "@chakra-ui/provider";
import { createStandaloneToast } from "@chakra-ui/react";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import { ErrorBoundary, LEVEL_CRITICAL, Provider as RollbarProvider } from "@rollbar/react";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "store";
import { rollbar } from "store/errors";
import theme from "ui/theme";
import App from "views/App";
import ErrorPage from "views/ErrorPage";

import "./locales";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_GTM_ID_SECRET) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID_SECRET });
}

const Root: React.FC = () => {
  const { ToastContainer } = createStandaloneToast({ theme });

  return (
    <React.StrictMode>
      <RollbarProvider instance={rollbar}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <ChakraProvider theme={theme}>
                <ErrorBoundary level={LEVEL_CRITICAL} fallbackUI={ErrorPage}>
                  <App />
                </ErrorBoundary>
                <ToastContainer />
              </ChakraProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </RollbarProvider>
    </React.StrictMode>
  );
};

createRoot(document.getElementById("root")!).render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
