import React from "react";

import { FormLabel as ChakraFormLabel, FormLabelProps as Props } from "@chakra-ui/react";

export interface FormLabelProps extends Props {
  /**
   * The content of the label
   */
  children: Props["children"];
  /**
   * htmlFor is used to associate a label and a form field. htmlFor must have the same value as the id in input.
   */
  htmlFor?: string;
}

/**
 *  FormLabel is part of the FormControl, and should not be used outside of it. The label of a form section. The usage is similar to html label.
 */
export const FormLabel = ({ children, ...props }: FormLabelProps) => {
  return <ChakraFormLabel {...props}>{children}</ChakraFormLabel>;
};
