import React from "react";

import { createIcon } from "@chakra-ui/react";

export const EnglishUKFlag = createIcon({
  displayName: "EnglishUKFlag",
  viewBox: "0 0 40 40",
  defaultProps: {
    fill: "transparent",
    __css: {
      w: "20px",
      h: "20px",
    },
  },
  path: [
    <g clipPath="url(#a)">
      <path
        fill="#006"
        d="M39.422 15.203a19.514 19.514 0 0 0-1.508-4.101l-5.742 4.101h7.25ZM.688 25.203a20.06 20.06 0 0 0 1.539 3.977l5.562-3.977H.688ZM5.781 34.063a19.908 19.908 0 0 0 9.422 5.351V27.328l-9.422 6.735ZM25 39.367a19.964 19.964 0 0 0 9.406-5.5L25 27.148v12.22ZM37.898 28.938a19.71 19.71 0 0 0 1.422-3.735h-6.648l5.227 3.735ZM15.203.578a19.936 19.936 0 0 0-9.46 5.399l9.46 6.757V.578ZM34.438 6.156A19.937 19.937 0 0 0 25 .633v12.273l9.438-6.75ZM2.195 10.875a19.974 19.974 0 0 0-1.617 4.328h7.68l-6.063-4.328Z"
      />
      <path
        fill="#F0F0F0"
        d="m31.047 25.203 6.398 4.57c.157-.273.305-.554.446-.843l-5.227-3.735h-1.617v.008ZM9.93 15.203l-7.258-5.187c-.164.28-.32.57-.469.859l6.063 4.328H9.93ZM25 25.203v1.953l9.406 6.719a20.159 20.159 0 0 0 1.977-2.398l-8.781-6.274H25ZM15.203 15.203v-2.469l-9.46-6.757a20.382 20.382 0 0 0-1.985 2.351l9.617 6.867h1.828v.008ZM32.172 15.203l5.742-4.101c-.46-.93-1-1.829-1.594-2.672l-9.476 6.773h5.328ZM15.203 26.094 5.086 33.32c.227.25.46.5.695.742l9.422-6.734v-1.234ZM7.79 25.203 2.226 29.18c.468.906 1 1.765 1.593 2.586L13 25.21H7.79v-.008ZM25 14.063l10.078-7.196c-.21-.242-.422-.476-.64-.703L25 12.898v1.165Z"
      />
      <path
        fill="#C00"
        d="M25 15.203h1.844L36.32 8.43a20.3 20.3 0 0 0-1.242-1.57L25 14.061v1.141ZM13.375 15.203 3.758 8.336c-.39.539-.75 1.101-1.086 1.68l7.258 5.187h3.445ZM15.203 25.203H13l-9.18 6.555c.39.539.813 1.062 1.258 1.562l10.125-7.226v-.89Z"
      />
      <path
        fill="#F0F0F0"
        d="M15.203 15.203H17V.227c-.61.093-1.21.21-1.797.359v14.618ZM25 25.203h-2v14.57c.68-.101 1.344-.234 2-.406V25.203ZM17 25.203h-1.797V39.422c.586.148 1.188.266 1.797.36V25.202ZM23 15.203h2V.633a18.887 18.887 0 0 0-2-.406v14.976Z"
      />
      <path fill="#C00" d="m27.602 25.203 8.78 6.274c.384-.547.743-1.11 1.071-1.696l-6.398-4.57h-3.453v-.008Z" />
      <path
        fill="#F0F0F0"
        d="M32.172 15.203H23v2h16.805a21.023 21.023 0 0 0-.383-2h-7.25ZM17 17.203v-2H.578a18.83 18.83 0 0 0-.383 2H17ZM23 23.203v2h16.32c.172-.656.32-1.32.43-2H23ZM13 25.203h4v-2H.258c.11.68.25 1.344.43 2H13Z"
      />
      <path
        fill="#C00"
        d="M39.805 17.203H23V.227a19.936 19.936 0 0 0-6 0v16.976H.195A20.588 20.588 0 0 0 0 20c0 1.094.086 2.156.258 3.203H17v16.57a19.928 19.928 0 0 0 6 0v-16.57h16.742A20.6 20.6 0 0 0 40 20c0-.953-.07-1.883-.195-2.797Z"
      />
    </g>,
    <defs>
      <clipPath id="a">
        <rect width={40} height={40} fill="#fff" rx={20} />
      </clipPath>
    </defs>,
  ],
});
