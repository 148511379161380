import React from "react";

import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { BodyText, Flex, FlexProps, Footer, Link, TopBar } from "ui";

import ChangeLanguageWidget from "../ChangeLanguageWidget";

type Props = {
  children?: JSX.Element;
};

const BackgroundLayout: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const styleProps: Record<string, FlexProps> = {
    appWrapper: {
      flex: 1,
      flexDir: "column",
      alignItems: "center",
      minH: ["initial", "appHeight"],
    },
    content: {
      flex: 1,
      w: "full",
      justifyContent: ["flex-start", "center"],
      alignItems: ["flex-start", "center"],
      flexDirection: "column",
      px: ["m", "xl", "xxl", 120],
      mt: [115, 131],
      mb: "xxl",
      h: "full",
      alignSelf: "center",
      maxW: 1200,
    },
  };

  return (
    <Flex {...styleProps.appWrapper}>
      <TopBar isMenuMobile={false} logoLink={{ to: "/", as: RouterLink }}>
        <ChangeLanguageWidget />
      </TopBar>
      <Flex {...styleProps.content}>{children || <Outlet />}</Flex>
      <Footer>
        <Link href={Paths.PlatformRegulations} color="white" target="_blank">
          {t("label_noun_terms_of_use")}
        </Link>
        <BodyText color="white">|</BodyText>
        <Link href={Paths.PlatformPrivacyPolicy} color="white" target="_blank">
          {t("label_noun_privacy_policy")}
        </Link>
      </Footer>
    </Flex>
  );
};

export default BackgroundLayout;
