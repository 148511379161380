import React from "react";

import { createIcon } from "@chakra-ui/react";

export const DHLLogo = createIcon({
  displayName: "DHLLogo",
  viewBox: "0 0 60 60",
  defaultProps: {
    width: "3.75rem",
    height: "3.75rem",
    fill: "transparent",
  },
  path: [
    <rect width="60" height="60" fill="#FFCC00" />,
    <path d="M59.751 23.4775V36.6324H0.144165V23.4775H59.751Z" fill="#FFCC00" />,
    <path
      d="M10.6255 25.8392L8.72546 28.4314H19.101C19.6255 28.4314 19.6186 28.6275 19.3617 28.9775C19.1049 29.3275 18.6657 29.9441 18.401 30.302C18.2657 30.4833 18.0225 30.8147 18.8294 30.8147H23.0735C23.0735 30.8147 23.7598 29.8833 24.3313 29.1059C25.1157 28.0471 24.399 25.8431 21.6098 25.8431L10.6255 25.8392Z"
      fill="#D3072A"
    />,
    <path
      d="M7.95093 34.2686L11.7745 29.0725H16.5215C17.046 29.0725 17.0392 29.2686 16.7823 29.6196C16.5254 29.9706 16.0794 30.5784 15.8137 30.9373C15.6794 31.1186 15.4352 31.45 16.2421 31.45H22.6009C22.0725 32.1745 20.3568 34.2696 17.2764 34.2696L7.95093 34.2686Z"
      fill="#D3072A"
    />,
    <path
      d="M29.8578 31.449L27.7833 34.2686H22.3118C22.3118 34.2686 24.3853 31.449 24.3873 31.449H29.8578Z"
      fill="#D3072A"
    />,
    <path
      d="M38.2401 30.8108H24.8568L28.5166 25.8392H33.9872L31.8892 28.6902H34.3313L36.4294 25.8392H41.899L38.2401 30.8108Z"
      fill="#D3072A"
    />,
    <path
      d="M37.7706 31.449L35.6951 34.2686H30.2255C30.2255 34.2686 32.299 31.449 32.301 31.449H37.7706Z"
      fill="#D3072A"
    />,
    <path d="M0.144165 32.5618H8.20495L7.76377 33.1608H0.144165V32.5618Z" fill="#D3072A" />,
    <path d="M0.144165 31.449H9.02358L8.5824 32.048H0.144165V31.449Z" fill="#D3072A" />,
    <path d="M0.144165 33.6735H7.38632L6.94809 34.2686H0.144165V33.6735Z" fill="#D3072A" />,
    <path d="M59.7519 33.1608H51.7216L52.1618 32.5618H59.7519V33.1608Z" fill="#D3072A" />,
    <path d="M59.752 34.2686L50.9049 34.2696L51.3431 33.6735H59.752V34.2686Z" fill="#D3072A" />,
    <path d="M52.9804 31.449H59.7519V32.048H52.5402L52.9804 31.449Z" fill="#D3072A" />,
    <path
      d="M49.4746 25.8392L45.8157 30.8108H40.0197C40.0197 30.8108 43.6795 25.8392 43.6814 25.8392H49.4746Z"
      fill="#D3072A"
    />,
    <path
      d="M39.55 31.449C39.55 31.449 39.15 31.9961 38.9559 32.2588C38.2696 33.1873 38.8765 34.2686 41.1187 34.2686H49.902L51.9765 31.449H39.55Z"
      fill="#D3072A"
    />,
  ],
});
