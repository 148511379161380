import React from "react";

import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useChangeEmailMutation } from "store/api";
import { err } from "store/errors";
import {
  BodyText,
  Box,
  Button,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  Stack,
  TitleH1,
  TitleH1Os,
} from "ui";

import ChangeEmailForm, { ChangeEmailFormState } from "./ChangeEmailForm";

const ChangeEmail: React.FC = () => {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const [changeEmail, changeEmailResult] = useChangeEmailMutation();

  const goToProfile = () => navigate(Paths.Profile);

  const onChangeEmail = async (formState: ChangeEmailFormState) => {
    try {
      await changeEmail(formState).unwrap();
      setParams({ confirm: "true" });
    } catch (error) {
      err.handler(error);
    }
  };

  if (params.get("confirm") && changeEmailResult.isSuccess) {
    return (
      <InfoPanel>
        <InfoPanelIllustration illustrationName="EmailConfirmationIllustration" />
        <InfoPanelContent>
          <Stack spacing="m">
            <TitleH1 color="violet.300">{t("label_action_request_check_e-mail")}</TitleH1>
            <BodyText>{t("label_action_info_confirmation_e-mail_sent")}</BodyText>
            <Box>
              <BodyText>{t("label_action_request_spam_alert_title")}</BodyText>
              <BodyText>{t("label_action_request_check_spam_and_other_folder")}</BodyText>
            </Box>
          </Stack>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button variant="secondary" onClick={goToProfile}>
            {t("label_action_request_go_to_profile")}
          </Button>
        </InfoPanelAction>
      </InfoPanel>
    );
  }

  return (
    <Box w="100%">
      <TitleH1Os subtitle={t("prompt_change_e-mail_instruction")}>{t("label_action_request_change_e-mail")}</TitleH1Os>
      <ChangeEmailForm onSubmit={onChangeEmail} isSubmitting={changeEmailResult.isLoading} />
    </Box>
  );
};

export default ChangeEmail;
