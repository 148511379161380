import React from "react";

import { createIcon } from "@chakra-ui/react";

export const InPostLogo = createIcon({
  displayName: "InPostLogo",
  viewBox: "0 0 60 60",
  defaultProps: {
    width: "3.75rem",
    height: "3.75rem",
    fill: "transparent",
  },
  path: [
    <path d="M59.6467 7.09232H0.353271V42.849H59.6467V7.09232Z" fill="#FECA09" />,
    <path
      d="M31.3103 29.687H29.9598V25.8054C29.9598 24.9886 29.599 24.5474 28.9462 24.4967C28.5377 24.4639 27.8908 24.5623 27.4228 24.6666V29.687H26.0723V23.7066C26.0723 23.7066 27.7149 23.34 29.1996 23.3429C29.8405 23.3429 30.3533 23.5725 30.7379 23.9571C31.1224 24.3416 31.3132 24.8753 31.3132 25.5639V29.687H31.3103Z"
      fill="#3E3E3F"
    />,
    <path
      d="M54.0928 29.7287C53.8722 29.7853 53.6396 29.8122 53.4011 29.8122C52.6409 29.8122 52.1371 29.6422 51.8926 29.3054C51.6959 29.04 51.6005 28.5332 51.6005 27.7909V21.5691H52.9331V23.328H54.1166V24.4162H52.9331V27.7074C52.9331 28.0622 52.9778 28.2977 53.0702 28.411C53.1775 28.5571 53.4041 28.6286 53.7499 28.6286C53.8483 28.6286 53.9646 28.6197 54.0957 28.6048V29.7287H54.0928Z"
      fill="#3E3E3F"
    />,
    <path
      d="M12.3556 25.6355C12.3556 25.6355 10.7756 26.2466 8.82291 26.2466C6.87319 26.2466 5.29016 25.6355 5.29016 25.6355C5.29016 25.6355 6.87319 25.0243 8.82291 25.0243C10.7756 25.0243 12.3556 25.6355 12.3556 25.6355Z"
      fill="white"
    />,
    <path
      d="M17.2419 16.6441C17.2419 16.6441 15.8407 15.6991 14.7496 14.1161C13.6584 12.5301 13.2917 10.9023 13.2917 10.9023C13.2917 10.9023 14.6929 11.8474 15.784 13.4304C16.8752 15.0164 17.2419 16.6441 17.2419 16.6441Z"
      fill="white"
    />,
    <path
      d="M13.6644 20.5257C13.6644 20.5257 11.9741 20.3379 10.2539 19.4405C8.53078 18.5432 7.42773 17.2762 7.42773 17.2762C7.42773 17.2762 9.11808 17.464 10.8382 18.3613C12.5584 19.2557 13.6644 20.5257 13.6644 20.5257Z"
      fill="white"
    />,
    <path
      d="M17.072 28.5809C18.0737 28.572 19.2841 27.782 19.2841 27.782C19.2841 27.782 16.4787 26.9323 16.6576 24.4192C17.2091 20.2902 20.5541 17.0496 24.7367 16.5279C24.2925 16.4653 23.8394 16.4355 23.3773 16.4355C18.2019 16.4593 14.0252 20.5913 14.0491 25.6653C14.0729 30.7393 18.2854 34.8326 23.4608 34.8117C23.893 34.8087 24.3164 34.7759 24.7308 34.7193C21.0579 34.2602 18.0707 31.7231 16.9975 28.3663"
      fill="white"
    />,
    <path
      d="M17.2419 34.5941C17.2419 34.5941 15.8407 35.5391 14.7496 37.1251C13.6584 38.7111 13.2917 40.3389 13.2917 40.3389C13.2917 40.3389 14.6929 39.3938 15.784 37.8078C16.8752 36.2218 17.2419 34.5941 17.2419 34.5941Z"
      fill="white"
    />,
    <path
      d="M13.6644 30.7125C13.6644 30.7125 11.9741 30.9003 10.2539 31.7977C8.53078 32.695 7.42773 33.962 7.42773 33.962C7.42773 33.962 9.11808 33.7742 10.8382 32.8769C12.5584 31.9795 13.6644 30.7125 13.6644 30.7125Z"
      fill="white"
    />,
    <path d="M24.7367 21.5602H23.3594V29.681H24.7367V21.5602Z" fill="#3E3E3F" />,
    <path
      d="M38.5576 24.3357C38.5576 25.1674 38.3012 25.8352 37.7855 26.3391C37.2697 26.8429 36.5721 27.0963 35.6927 27.0963H34.0232V29.6631H32.6459V21.5393H35.5943C36.5483 21.5393 37.2817 21.7867 37.7915 22.2846C38.3042 22.7825 38.5576 23.4652 38.5576 24.3357ZM37.1654 24.3357C37.1654 23.3519 36.6824 22.86 35.7225 22.86H34.0232V25.7875H35.6957C36.1578 25.7875 36.5155 25.6593 36.7749 25.4059C37.0372 25.1525 37.1654 24.7948 37.1654 24.3357Z"
      fill="#3E3E3F"
    />,
    <path
      d="M44.9344 26.4643C44.9344 27.4391 44.6602 28.2262 44.1146 28.8254C43.5661 29.4216 42.8565 29.7198 41.986 29.7198C41.1155 29.7198 40.403 29.4216 39.8574 28.8254C39.3089 28.2292 39.0376 27.4421 39.0376 26.4643C39.0376 25.4894 39.3119 24.7024 39.8574 24.1091C40.403 23.5159 41.1155 23.2207 41.986 23.2207C42.8655 23.2207 43.578 23.5188 44.1206 24.1091C44.6632 24.7024 44.9344 25.4864 44.9344 26.4643ZM43.5392 26.4702C43.5392 25.8591 43.3961 25.3732 43.1129 25.0154C42.8297 24.6577 42.4511 24.4788 41.983 24.4788C41.515 24.4788 41.1364 24.6606 40.8532 25.0214C40.5699 25.3851 40.4268 25.8651 40.4268 26.4702C40.4268 27.0814 40.5699 27.5673 40.8532 27.9251C41.1364 28.2828 41.515 28.4617 41.983 28.4617C42.4511 28.4617 42.8297 28.2828 43.1129 27.9251C43.3961 27.5643 43.5392 27.0814 43.5392 26.4702Z"
      fill="#3E3E3F"
    />,
    <path
      d="M50.8015 27.5882C50.8015 28.2023 50.563 28.7061 50.086 29.1027C49.609 29.4992 48.98 29.6959 48.1989 29.6959C46.9796 29.6959 46.0375 29.3322 45.3727 28.6078L46.3982 27.7492C46.908 28.25 47.5042 28.5004 48.1869 28.5004C48.5656 28.5004 48.8667 28.4259 49.0873 28.2769C49.3079 28.1278 49.4212 27.94 49.4212 27.7134C49.4212 27.454 49.293 27.2781 49.0396 27.1798C48.8905 27.1231 48.497 27.0456 47.8531 26.9502C47.0809 26.8369 46.5294 26.667 46.1985 26.4434C45.8109 26.1691 45.6201 25.7577 45.6201 25.2092C45.6201 24.6517 45.8556 24.1866 46.3237 23.811C46.7917 23.4354 47.4178 23.2475 48.2019 23.2475C49.1469 23.2475 49.9548 23.5576 50.6226 24.1807L49.8207 25.0273C49.3347 24.601 48.7832 24.3864 48.1661 24.3864C47.3761 24.3864 46.9825 24.61 46.9825 25.0512C46.9825 25.3105 47.1644 25.4864 47.5251 25.5848C48.0349 25.6653 48.5417 25.7488 49.0426 25.8382C50.2112 26.0529 50.8015 26.6372 50.8015 27.5882Z"
      fill="#3E3E3F"
    />,
    <path d="M59.6467 42.849H0.353271V52.9077H59.6467V42.849Z" fill="#404042" />,
    <path
      d="M21.5707 48.3762L20.8135 49.1543V50.3736H19.925V45.6007H20.8135V48.0483L23.1716 45.6007H24.1673L22.1639 47.7352L24.2896 50.3736H23.2551L21.5707 48.3762Z"
      fill="white"
    />,
    <path
      d="M28.1622 46.7336V50.3736H27.3513V49.9086C27.08 50.2425 26.6477 50.4213 26.1797 50.4213C25.2466 50.4213 24.6116 49.9086 24.6116 48.8115V46.7306H25.4642V48.6952C25.4642 49.357 25.7772 49.6701 26.3168 49.6701C26.9101 49.6701 27.3126 49.3004 27.3126 48.573V46.7306H28.1622V46.7336Z"
      fill="white"
    />,
    <path
      d="M31.1971 46.6918V47.5027C31.1225 47.4878 31.0599 47.4818 31.0003 47.4818C30.3534 47.4818 29.942 47.8634 29.942 48.6058V50.3706H29.0894V46.7306H29.9002V47.2612C30.1477 46.8826 30.5889 46.6918 31.1971 46.6918Z"
      fill="white"
    />,
    <path
      d="M31.8767 45.6216C31.8767 45.3354 32.1092 45.1118 32.4223 45.1118C32.7353 45.1118 32.9678 45.3234 32.9678 45.6037C32.9678 45.9018 32.7442 46.1343 32.4223 46.1343C32.1063 46.1313 31.8767 45.9078 31.8767 45.6216ZM31.99 46.7336H32.8426V50.3736H31.99V46.7336Z"
      fill="white"
    />,
    <path
      d="M37.2161 48.8264H34.3601C34.4614 49.357 34.9056 49.6999 35.5406 49.6999C35.9491 49.6999 36.271 49.5717 36.5304 49.3034L36.9865 49.8281C36.6586 50.2156 36.1488 50.4213 35.5198 50.4213C34.3005 50.4213 33.5074 49.6373 33.5074 48.5521C33.5074 47.4669 34.3064 46.6918 35.3886 46.6918C36.4529 46.6918 37.228 47.4341 37.228 48.573C37.228 48.6415 37.222 48.7429 37.2161 48.8264ZM34.3511 48.2599H36.4171C36.3485 47.7353 35.9461 47.3745 35.3886 47.3745C34.8341 47.3745 34.4346 47.7293 34.3511 48.2599Z"
      fill="white"
    />,
    <path
      d="M39.9946 46.6918V47.5027C39.92 47.4878 39.8574 47.4818 39.7978 47.4818C39.1509 47.4818 38.7395 47.8634 38.7395 48.6058V50.3706H37.8868V46.7306H38.6977V47.2612C38.9422 46.8826 39.3864 46.6918 39.9946 46.6918Z"
      fill="white"
    />,
  ],
});
