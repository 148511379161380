import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationV4ErrorIllustration = createIcon({
  displayName: "ConfirmationV4ErrorIllustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <rect width="150" height="150" fill="white" />,
    <path
      d="M99.1 145.53H50.72C50.4158 145.531 50.1143 145.473 49.8329 145.357C49.5515 145.242 49.2957 145.072 49.0801 144.857C48.8646 144.642 48.6935 144.387 48.5768 144.106C48.4601 143.825 48.4 143.524 48.4 143.22V77L48 75V52.58L48.44 50.32V6.77999C48.44 6.4758 48.5001 6.17459 48.6168 5.89368C48.7335 5.61276 48.9045 5.35766 49.1201 5.14303C49.3357 4.9284 49.5915 4.75845 49.8729 4.64295C50.1543 4.52745 50.4558 4.46868 50.76 4.46999H99.1C99.4042 4.46868 99.7057 4.52745 99.9871 4.64295C100.268 4.75845 100.524 4.9284 100.74 5.14303C100.955 5.35766 101.126 5.61276 101.243 5.89368C101.36 6.17459 101.42 6.4758 101.42 6.77999V17.32L102 20V29.42L101.39 32.15V35.51L102.09 37.9L102 53L101.39 55.79L102 58.57V73.57L101.43 76.13V143.23C101.429 143.534 101.367 143.835 101.249 144.116C101.131 144.396 100.959 144.65 100.743 144.864C100.526 145.078 100.27 145.247 99.9878 145.361C99.7059 145.475 99.4042 145.533 99.1 145.53Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M48.47 9.46002H101.35" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M101.4 138.05H48.4199" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M94.4999 13.1H55.4699C53.2166 13.1 51.3899 14.9267 51.3899 17.18V130.28C51.3899 132.533 53.2166 134.36 55.4699 134.36H94.4999C96.7532 134.36 98.5799 132.533 98.5799 130.28V17.18C98.5799 14.9267 96.7532 13.1 94.4999 13.1Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M79.59 13.81L78.81 14.7H71.3L70.52 13.81"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M103 20.15H102.05V29.43H103V20.15Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M103.07 38.26H102.12V52.44H103.07V38.26Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M102.94 59.03H101.99V73.26H102.94V59.03Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M47.9099 52.8H46.9399V74.58H47.9099V52.8Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M51.3899 112.05C51.382 111.138 51.554 110.233 51.8961 109.387C52.2382 108.542 52.7437 107.772 53.3836 107.121C54.0236 106.471 54.7854 105.954 55.6255 105.598C56.4657 105.243 57.3677 105.057 58.2799 105.05H91.6799C92.5921 105.057 93.4941 105.243 94.3343 105.598C95.1744 105.954 95.9363 106.471 96.5762 107.121C97.2161 107.772 97.7216 108.542 98.0637 109.387C98.4058 110.233 98.5778 111.138 98.5699 112.05"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M63.9999 111.14C63.1336 111.14 62.2868 111.397 61.5665 111.878C60.8462 112.359 60.2848 113.044 59.9533 113.844C59.6218 114.644 59.535 115.525 59.704 116.375C59.873 117.224 60.2902 118.005 60.9028 118.617C61.5153 119.23 62.2957 119.647 63.1454 119.816C63.995 119.985 64.8757 119.898 65.676 119.567C66.4764 119.235 67.1604 118.674 67.6417 117.953C68.123 117.233 68.3799 116.386 68.3799 115.52C68.3799 114.358 67.9184 113.244 67.097 112.423C66.2756 111.601 65.1615 111.14 63.9999 111.14Z"
      fill="#999999"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M85.75 119.74C88.0807 119.74 89.97 117.851 89.97 115.52C89.97 113.189 88.0807 111.3 85.75 111.3C83.4194 111.3 81.53 113.189 81.53 115.52C81.53 117.851 83.4194 119.74 85.75 119.74Z"
      stroke="#999999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M68.97 126.47H58.97" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M90.75 126.47H80.75" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M74.5 77C84.165 77 92 69.165 92 59.5C92 49.835 84.165 42 74.5 42C64.835 42 57 49.835 57 59.5C57 69.165 64.835 77 74.5 77Z"
      stroke="#8F8BEF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M79.8841 54.1158L69.1157 64.8898"
      stroke="#8F8BEF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M69.1157 54.1158L79.8841 64.8898"
      stroke="#8F8BEF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
