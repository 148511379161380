import React from "react";

import { createIcon } from "@chakra-ui/react";

export const EmailConfirmationIllustration = createIcon({
  displayName: "EmailConfirmationIllustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <g clipPath="url(#clip0_1880_85490)">
      <rect width="150" height="150" fill="white" />,
      <path
        d="M105.64 49H126.92C131.918 49.0132 136.707 51.0045 140.241 54.5385C143.776 58.0726 145.767 62.8621 145.78 67.86V124.09C145.777 129.096 143.791 133.897 140.256 137.441C136.721 140.985 131.926 142.984 126.92 143H23.08C18.0777 142.984 13.2852 140.988 9.75086 137.448C6.21649 133.908 4.22788 129.112 4.21997 124.11V67.88C4.22789 62.8786 6.21682 58.084 9.75147 54.5456C13.2861 51.0072 18.0786 49.0132 23.08 49H46.08"
        stroke="#999999"
        strokeWidth="3.111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M125.83 65.98L76.3899 115.43L26.9399 65.98"
        stroke="#999999"
        strokeWidth="3.111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M109.47 105.08L130.39 126"
        stroke="#999999"
        strokeWidth="3.111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M40.5301 105.08L19.6101 126"
        stroke="#999999"
        strokeWidth="3.111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M61.4099 49.3L75.8699 71.0101"
        stroke="#8F8BEF"
        strokeWidth="5.185"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M75.8701 71.01L91.3601 49.88"
        stroke="#8F8BEF"
        strokeWidth="5.185"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M75.8701 7.03003V71.01"
        stroke="#8F8BEF"
        strokeWidth="5.185"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
    </g>,
    <defs>
      <clipPath id="clip0_1880_85490">
        <rect width="150" height="150" fill="white" />,
      </clipPath>
    </defs>,
  ],
});
