import React from "react";

import { createIcon } from "@chakra-ui/react";

export const SiCardLogo = createIcon({
  displayName: "SiCardLogo",
  viewBox: "0 0 48 32",
  defaultProps: {
    width: 12,
    height: 8,
    fill: "transparent",
  },
  path: [
    <rect x="0.428571" y="0.428571" width="47.1429" height="31.1429" rx="3.57143" fill="white" />,
    <g clipPath="url(#clip0_3011_91917)">
      <path
        d="M26.9024 11.1925C26.1074 11.155 25.7474 11.4325 25.7474 12.5125V14.5825C25.7474 17.815 18.4499 16.78 16.8974 14.86C16.5974 14.5 16.1474 14.6275 16.3349 15.0625C17.6399 17.935 21.9599 18.415 23.7524 18.415C26.2799 18.415 31.2524 17.4625 31.2524 14.665C31.2524 11.8675 27.8849 11.245 26.9024 11.1925Z"
        fill="#EC6E34"
      />

      <path
        d="M21.9299 5.66497C21.9299 6.35497 22.8374 6.91747 23.9624 6.91747C25.0874 6.91747 25.9949 6.35497 25.9949 5.66497C25.9949 4.97497 25.0874 4.40497 23.9999 4.40497C22.8374 4.40497 21.9299 4.96747 21.9299 5.66497Z"
        fill="#EC6F34"
      />

      <path
        d="M24.0675 8.28253C21.54 8.28253 16.5675 9.22753 16.5675 12.0325C16.5675 14.8375 19.935 15.46 20.9175 15.505C21.7125 15.5425 22.0725 15.265 22.0725 14.1925V12.1075C22.0725 8.87503 29.37 9.91753 30.9225 11.83C31.215 12.19 31.6725 12.07 31.485 11.6275C30.1725 8.75503 25.86 8.28253 24.0675 8.28253Z"
        fill="#EC6F34"
      />

      <path
        d="M12 20.29C12.5323 20.2754 13.0608 20.3832 13.545 20.605C13.5766 20.6159 13.6109 20.6159 13.6425 20.605C13.6571 20.5973 13.67 20.5867 13.6803 20.5738C13.6906 20.5609 13.6982 20.546 13.7025 20.53L13.965 19.6975C13.9729 19.6715 13.9713 19.6436 13.9602 19.6188C13.9492 19.594 13.9296 19.574 13.905 19.5625C13.3042 19.3043 12.6533 19.1838 12 19.21C11.4281 19.1747 10.8553 19.2616 10.3196 19.4649C9.7839 19.6682 9.29767 19.9833 8.89323 20.3892C8.4888 20.795 8.17544 21.2823 7.974 21.8187C7.77256 22.3551 7.68766 22.9282 7.72496 23.5C7.65386 24.4073 7.91185 25.3099 8.45174 26.0426C8.99162 26.7753 9.77728 27.2891 10.665 27.49C11.0233 27.5782 11.3909 27.6235 11.76 27.625C12.2183 27.6252 12.6755 27.58 13.125 27.49C13.3973 27.4393 13.662 27.3535 13.9125 27.235C13.9355 27.2238 13.954 27.205 13.9649 27.1818C13.9758 27.1586 13.9785 27.1324 13.9725 27.1075L13.77 26.29C13.7666 26.2736 13.7595 26.2583 13.749 26.2452C13.7386 26.2322 13.7252 26.2218 13.71 26.215C13.6947 26.2077 13.6781 26.2039 13.6612 26.2039C13.6443 26.2039 13.6277 26.2077 13.6125 26.215C13.0997 26.418 12.5512 26.5149 12 26.5C10.1625 26.5 9.06746 25.3525 9.06746 23.4325C9.06746 21.5125 10.185 20.29 12 20.29Z"
        fill="#010201"
      />

      <path
        d="M19.2376 25.99V23.8675C19.2376 22.765 18.8401 21.445 16.9876 21.445C16.3039 21.4394 15.631 21.6154 15.0376 21.955C15.0153 21.9687 14.9981 21.9893 14.9887 22.0136C14.9792 22.038 14.9779 22.0648 14.9851 22.09L15.2251 22.78C15.2297 22.7968 15.2382 22.8124 15.2499 22.8254C15.2617 22.8385 15.2763 22.8486 15.2926 22.855C15.3266 22.8664 15.3635 22.8664 15.3976 22.855C15.8262 22.5849 16.3235 22.4443 16.8301 22.45C16.9887 22.429 17.15 22.4449 17.3014 22.4962C17.4529 22.5476 17.5906 22.6332 17.7037 22.7463C17.8168 22.8594 17.9024 22.9971 17.9538 23.1486C18.0052 23.3001 18.021 23.4614 18.0001 23.62V23.62C15.7501 23.62 14.5651 24.4225 14.5651 25.87C14.5643 26.2188 14.6702 26.5595 14.8686 26.8463C15.067 27.1332 15.3484 27.3526 15.6751 27.475C15.9147 27.5655 16.1689 27.6112 16.4251 27.61C16.6806 27.6048 16.9338 27.5592 17.1751 27.475C17.5265 27.3637 17.844 27.165 18.0976 26.8975L18.1576 27.3775C18.1607 27.4022 18.172 27.4252 18.1896 27.4429C18.2073 27.4605 18.2303 27.4718 18.2551 27.475H19.2151C19.2423 27.4732 19.268 27.4616 19.2874 27.4422C19.3067 27.4229 19.3183 27.3972 19.3201 27.37C19.3208 27.3575 19.3208 27.3449 19.3201 27.3325C19.2598 26.8876 19.2323 26.4389 19.2376 25.99V25.99ZM18.0001 25.4425C18.0133 25.5445 18.0133 25.6479 18.0001 25.75C17.9016 26.0122 17.7236 26.2371 17.4909 26.3931C17.2582 26.5491 16.9826 26.6284 16.7026 26.62C16.584 26.6355 16.4634 26.6239 16.3499 26.5859C16.2365 26.5479 16.1332 26.4846 16.0479 26.4007C15.9626 26.3169 15.8975 26.2147 15.8576 26.1019C15.8177 25.9892 15.804 25.8688 15.8176 25.75C15.8176 25 16.5676 24.58 17.9551 24.58H18.0001V25.4425Z"
        fill="#010201"
      />

      <path
        d="M23.31 21.475C23.22 21.475 23.1375 21.475 23.0325 21.475C22.7215 21.4867 22.419 21.5802 22.1557 21.7461C21.8923 21.9121 21.6774 22.1445 21.5325 22.42V21.67C21.5306 21.6415 21.5179 21.6147 21.497 21.5952C21.4762 21.5757 21.4486 21.5649 21.42 21.565H20.43C20.4171 21.5589 20.403 21.5558 20.3888 21.5558C20.3745 21.5558 20.3604 21.5589 20.3475 21.565C20.341 21.5777 20.3375 21.5919 20.3375 21.6062C20.3375 21.6206 20.341 21.6347 20.3475 21.6475C20.3475 22.1275 20.3925 22.675 20.3925 23.41V27.3175C20.3941 27.3436 20.4046 27.3683 20.4224 27.3875C20.4402 27.4067 20.4641 27.419 20.49 27.4225H21.525C21.5523 27.4207 21.578 27.4091 21.5973 27.3898C21.6166 27.3704 21.6283 27.3447 21.63 27.3175V24.3175C21.6176 24.1652 21.6176 24.0122 21.63 23.86C21.6557 23.534 21.8008 23.229 22.0375 23.0035C22.2741 22.7779 22.5857 22.6475 22.9125 22.6375C23.0222 22.625 23.1329 22.625 23.2425 22.6375C23.2579 22.6443 23.2745 22.6478 23.2913 22.6478C23.3081 22.6478 23.3247 22.6443 23.34 22.6375C23.364 22.6136 23.3775 22.5813 23.3775 22.5475V21.5725C23.3786 21.5511 23.3725 21.5299 23.3603 21.5123C23.3481 21.4947 23.3304 21.4815 23.31 21.475V21.475Z"
        fill="#010201"
      />

      <path
        d="M27.225 21.565H25.8525V20.32C25.8539 20.3023 25.8504 20.2846 25.8425 20.2688C25.8346 20.2529 25.8225 20.2395 25.8075 20.23C25.7921 20.2232 25.7755 20.2197 25.7587 20.2197C25.7419 20.2197 25.7253 20.2232 25.71 20.23L24.69 20.5C24.6682 20.5077 24.6494 20.5219 24.6359 20.5407C24.6225 20.5595 24.6152 20.5819 24.615 20.605V21.55H23.865C23.8344 21.55 23.805 21.5616 23.7827 21.5825C23.7604 21.6034 23.7469 21.632 23.745 21.6625V22.45C23.7469 22.4805 23.7604 22.5092 23.7827 22.5301C23.805 22.551 23.8344 22.5626 23.865 22.5625H24.615V25.5625C24.5573 26.12 24.7097 26.679 25.0425 27.13C25.1822 27.2758 25.3508 27.3908 25.5375 27.4675C25.7634 27.5604 26.0058 27.6063 26.25 27.6025C26.5601 27.6051 26.8688 27.5595 27.165 27.4675H27.21C27.233 27.4594 27.2528 27.4441 27.2663 27.4238C27.2798 27.4035 27.2864 27.3794 27.285 27.355L27.2325 26.5675C27.2319 26.5512 27.2276 26.5353 27.2197 26.5209C27.2119 26.5066 27.2009 26.4943 27.1875 26.485C27.1723 26.4777 27.1556 26.4739 27.1387 26.4739C27.1219 26.4739 27.1052 26.4777 27.09 26.485C26.9046 26.5368 26.7123 26.5595 26.52 26.5525C26.0475 26.5525 25.8525 26.26 25.8525 25.5625V22.5775H27.225C27.2548 22.5775 27.2834 22.5657 27.3045 22.5446C27.3256 22.5235 27.3375 22.4949 27.3375 22.465V21.6775C27.3375 21.6477 27.3256 21.6191 27.3045 21.598C27.2834 21.5769 27.2548 21.565 27.225 21.565V21.565Z"
        fill="#010201"
      />

      <path
        d="M32.6325 25.99V23.8675C32.6325 22.765 32.235 21.445 30.3825 21.445C29.7013 21.4404 29.031 21.6163 28.44 21.955C28.4177 21.9687 28.4005 21.9893 28.391 22.0137C28.3816 22.038 28.3803 22.0648 28.3875 22.09L28.6275 22.78C28.6321 22.7969 28.6406 22.8124 28.6523 22.8255C28.6641 22.8385 28.6787 22.8486 28.695 22.855C28.7263 22.8671 28.7611 22.8671 28.7925 22.855C29.2242 22.5859 29.7237 22.4454 30.2325 22.45V22.45C30.3919 22.4298 30.5539 22.4467 30.7057 22.4995C30.8575 22.5523 30.9951 22.6394 31.1076 22.7542C31.2202 22.8689 31.3047 23.0081 31.3545 23.1609C31.4044 23.3137 31.4182 23.4759 31.395 23.635C29.145 23.635 27.96 24.4375 27.96 25.885C27.9592 26.2338 28.0651 26.5745 28.2635 26.8614C28.4619 27.1482 28.7433 27.3676 29.07 27.49C29.3096 27.5805 29.5638 27.6263 29.82 27.625C30.0755 27.6198 30.3287 27.5743 30.57 27.49C30.9273 27.3714 31.2478 27.1621 31.5 26.8825L31.56 27.3625C31.5631 27.3872 31.5744 27.4103 31.592 27.4279C31.6097 27.4456 31.6327 27.4568 31.6575 27.46H32.6175C32.6434 27.4565 32.6673 27.4442 32.6851 27.425C32.7029 27.4058 32.7134 27.3811 32.715 27.355V27.3175C32.6555 26.8775 32.6279 26.4339 32.6325 25.99V25.99ZM31.4025 25.4425C31.4145 25.5396 31.4145 25.6378 31.4025 25.735C31.304 25.9972 31.126 26.2221 30.8933 26.3781C30.6606 26.5341 30.385 26.6135 30.105 26.605C29.9901 26.6153 29.8743 26.6003 29.7659 26.561C29.6575 26.5217 29.559 26.4591 29.4774 26.3775C29.3959 26.2959 29.3332 26.1975 29.2939 26.089C29.2546 25.9806 29.2396 25.8648 29.25 25.75C29.25 25 30 24.58 31.3875 24.58H31.44L31.4025 25.4425Z"
        fill="#010201"
      />

      <path
        d="M36.2925 22.75C35.145 22.315 34.7925 22 34.7925 21.3325C34.7925 20.665 35.22 20.26 36.18 20.26C36.6836 20.2489 37.1809 20.3732 37.62 20.62C37.6352 20.6273 37.6519 20.6311 37.6688 20.6311C37.6857 20.6311 37.7023 20.6273 37.7175 20.62C37.7346 20.6148 37.7498 20.6051 37.7617 20.5919C37.7736 20.5787 37.7817 20.5624 37.785 20.545L38.055 19.75C38.0636 19.7249 38.063 19.6976 38.0534 19.6729C38.0438 19.6482 38.0258 19.6277 38.0025 19.615C37.4472 19.3285 36.8269 19.1915 36.2025 19.2175C34.62 19.2175 33.5175 20.1475 33.5175 21.4675C33.5175 22.525 34.2675 23.2975 35.6925 23.8225C36.7275 24.2125 37.1175 24.5725 37.1175 25.2775C37.1175 25.9825 36.525 26.5 35.565 26.5C34.9399 26.4992 34.3273 26.3253 33.795 25.9975C33.7798 25.9902 33.7632 25.9864 33.7463 25.9864C33.7294 25.9864 33.7127 25.9902 33.6975 25.9975C33.6811 26.0013 33.666 26.0096 33.6541 26.0215C33.6422 26.0335 33.6338 26.0485 33.63 26.065L33.3525 26.92C33.3458 26.9444 33.3464 26.9703 33.3544 26.9943C33.3625 27.0184 33.3775 27.0395 33.3975 27.055C33.7193 27.2415 34.0686 27.3756 34.4325 27.4525C34.7788 27.5375 35.1335 27.5828 35.49 27.5875C35.8393 27.5854 36.1869 27.5401 36.525 27.4525C37.046 27.3487 37.515 27.0679 37.8526 26.6579C38.1902 26.2478 38.3757 25.7336 38.3775 25.2025C38.3775 24.055 37.755 23.3275 36.2925 22.75Z"
        fill="#010201"
      />
      <path
        d="M40.1626 21.565H39.1201C39.0902 21.565 39.0616 21.5769 39.0405 21.598C39.0194 21.6191 39.0076 21.6477 39.0076 21.6775V27.355C39.0091 27.3811 39.0196 27.4059 39.0374 27.425C39.0552 27.4442 39.0791 27.4566 39.1051 27.46H40.1701C40.1973 27.4582 40.223 27.4466 40.2424 27.4273C40.2617 27.408 40.2733 27.3823 40.2751 27.355V21.6775C40.2751 21.6477 40.2632 21.6191 40.2421 21.598C40.221 21.5769 40.1924 21.565 40.1626 21.565V21.565Z"
        fill="#010100"
      />

      <path
        d="M40.1626 19.21H39.1201C39.0579 19.21 39.0076 19.2604 39.0076 19.3225V20.335C39.0076 20.3972 39.0579 20.4475 39.1201 20.4475H40.1626C40.2247 20.4475 40.2751 20.3972 40.2751 20.335V19.3225C40.2751 19.2604 40.2247 19.21 40.1626 19.21Z"
        fill="#010100"
      />
    </g>,
    <rect
      x="0.428571"
      y="0.428571"
      width="47.1429"
      height="31.1429"
      rx="3.57143"
      stroke="#8D95A5"
      strokeWidth="0.857143"
    />,
    <defs>
      <clipPath id="clip0_3011_91917">
        <rect width="36" height="24" fill="white" transform="translate(6 4)" />,
      </clipPath>
    </defs>,
  ],
});
