import React from "react";

import { Button, ButtonProps, Flex, Text } from "../../atoms";

type AddressInformationProps = {
  title?: string;
  children: React.ReactNode;
  action?: {
    label: string;
    onClick: ButtonProps["onClick"];
  };
};

export const AddressInformation: React.FC<AddressInformationProps> = ({ title, action, children }) => {
  return (
    <Flex
      flexDirection={["column", "row"]}
      justifyContent="space-between"
      alignItems={["flex-start", "center"]}
      w="full"
      pr={["m", 0]}
      pl={["m", action ? "m" : 0]}
    >
      <Flex flexDirection="column" py="m">
        {title && (
          <Text variant="highlights16" mb="m">
            {title}
          </Text>
        )}
        {children}
      </Flex>
      {action && (
        <Button onClick={action.onClick} variant="secondary" w={["full", "initial"]} mb={["s", 0]} mt={["xs", 0]}>
          {action.label}
        </Button>
      )}
    </Flex>
  );
};
