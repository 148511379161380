import strings from "locales/strings.json";
import { SalesProduct } from "store/api";

import { formatPrice, getDeviceImageByColor, transformSubunitToPrice } from "./common";

const getName = (variant: string | null) => {
  if (variant && strings.hasOwnProperty(variant)) {
    return strings[variant as keyof typeof strings];
  }
  return "Vasco Translator";
};

const getColorName = (color: string | null) => {
  if (color && strings.hasOwnProperty(color)) {
    return strings[color as keyof typeof strings];
  }
  return null;
};

const getFullPriceAmount = (salesProducts: SalesProduct[], withSubunit = false) => {
  const fullPriceWithSubunit = salesProducts.reduce<number>((prev, current) => prev + current.priceSubunit, 0);

  if (!withSubunit) {
    return transformSubunitToPrice(fullPriceWithSubunit);
  }

  return fullPriceWithSubunit;
};

export const salesProducts = {
  getName,
  getImage: getDeviceImageByColor,
  getColorName,
  formatPrice,
  getFullPriceAmount,
};
