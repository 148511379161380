import TagManager from "react-gtm-module";
import { store } from "store";
import { Order } from "store/api";
import { CartItemWithQuantity, cartSelectors, selectCartItemsWithQty } from "store/state";
import { utils } from "utils";
import { transformSubunitToPrice } from "utils/common";

const addGeneralPayload = ({ cartItems, productId }: { cartItems?: CartItemWithQuantity[]; productId?: number }) => {
  const { user } = store.getState().auth;
  const marketName = store.getState().app.market?.name || "poland";
  const items = cartItems || cartSelectors.selectAll(store.getState());

  return {
    is_logged: !!user?.id,
    user_id: user?.id || "",
    ecomm_totalvalue: utils.salesProducts.getFullPriceAmount(items),
    ecomm_prodid: productId || items.map(cartItem => cartItem.id).join(", "),
    ecomm_pagetype: "cart_subscription",
    currency_code: utils.markets.getCurrency(marketName),
  };
};

const addItemsPayload = ({ cartItems }: { cartItems: CartItemWithQuantity[] }) =>
  cartItems.map((cartItem, index) => ({
    index,
    item_id: cartItem.id,
    item_name: utils.salesProducts.getName(cartItem.variant),
    item_brand: "Vasco-subscription",
    item_variant: utils.salesProducts.getColorName(cartItem.color),
    item_category: "translator-subscription",
    item_list_id: "translators-subscription",
    items_list_name: "translator-subscription",
    price: transformSubunitToPrice(cartItem.priceSubunit),
    quantity: cartItem.quantity,
  }));

const addEcommerceCheckoutPayload = () => {
  const marketName = store.getState().app.market?.name || "poland";
  const cartItems = cartSelectors.selectAll(store.getState());
  const cartItemsWithQty = selectCartItemsWithQty(store.getState());

  return {
    currency: utils.markets.getCurrency(marketName),
    value: utils.salesProducts.getFullPriceAmount(cartItems),
    items: addItemsPayload({ cartItems: cartItemsWithQty }),
  };
};

const addEcommerceOrderPayload = ({ order, cartItems }: { order: Order; cartItems: CartItemWithQuantity[] }) => ({
  transaction_id: order.uid,
  affiliation: "Vasco Electronics-subscription",
  currency: order.fullPriceCurrency,
  value: transformSubunitToPrice(order.fullPriceSubunit),
  shipping: 0,
  payment_type: "subscription_card",
  items: addItemsPayload({ cartItems }),
});

const addPurchaseSuccessDataLayer = ({ order, cartItems }: { order: Order; cartItems: CartItemWithQuantity[] }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "purchase",
      ...addGeneralPayload({ cartItems }),
      ecommerce: addEcommerceOrderPayload({ order, cartItems }),
    },
  });
};

const addPurchaseFailedDataLayer = ({ order, cartItems }: { order: Order; cartItems: CartItemWithQuantity[] }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "failed_transaction",
      ...addGeneralPayload({ cartItems }),
      ecommerce: addEcommerceOrderPayload({ order, cartItems }),
    },
  });
};

const addRegistrationSuccessDataLayer = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "registration_success",
      ...addGeneralPayload({}),
    },
  });
};

const addLoginSuccessDataLayer = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "login_success",
      ...addGeneralPayload({}),
    },
  });
};

const addToCartDataLayer = ({ ecommPagetype, productId }: { ecommPagetype?: string | null; productId?: number }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "add_to_cart",
      ...addGeneralPayload({ productId }),
      ecomm_pagetype: ecommPagetype || "cart_subscription",
      ecommerce: addEcommerceCheckoutPayload(),
    },
  });
};

const addRemoveFormCartDataLayer = ({ productId }: { productId: number }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "remove_from_cart",
      ...addGeneralPayload({ productId }),
      ecommerce: addEcommerceCheckoutPayload(),
    },
  });
};

const addBeginCheckoutCartDataLayer = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "begin_checkout",
      ...addGeneralPayload({}),
      ecommerce: addEcommerceCheckoutPayload(),
    },
  });
};

const addCheckoutStepInitDataLayer = (step: number) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "checkout_progress",
      checkout_step: step * 10,
      ...addGeneralPayload({}),
      ecommerce: addEcommerceCheckoutPayload(),
    },
  });
};

const addCreditCardDataLayer = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "add_payment_info",
      ...addGeneralPayload({}),
      ecommerce: addEcommerceCheckoutPayload(),
    },
  });
};

const addBillingAddressDataLayer = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "add_shipping_info",
      ...addGeneralPayload({}),
      ecommerce: addEcommerceCheckoutPayload(),
    },
  });
};

const addCheckoutCardSuccessDataLayer = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "add_payment_info",
      ...addGeneralPayload({}),
      ecommerce: addEcommerceCheckoutPayload(),
    },
  });
};

const addCheckoutShippingInfoDataLayer = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "add_shipping_info",
      ...addGeneralPayload({}),
      ecommerce: addEcommerceCheckoutPayload(),
    },
  });
};

export const gtm = {
  addPurchaseSuccessDataLayer,
  addPurchaseFailedDataLayer,
  addRegistrationSuccessDataLayer,
  addLoginSuccessDataLayer,
  addCheckoutStepInitDataLayer,
  addCheckoutCardSuccessDataLayer,
  addCheckoutShippingInfoDataLayer,
  addRemoveFormCartDataLayer,
  addBeginCheckoutCartDataLayer,
  addCreditCardDataLayer,
  addBillingAddressDataLayer,
  addToCartDataLayer,
};
