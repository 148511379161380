import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationUnpaired1 = createIcon({
  displayName: "ConfirmationUnpaired1",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <rect width="150" height="150" fill="white" />,
    <path
      d="M89.2 50.58H135.57C137.427 50.58 139.207 51.3175 140.52 52.6302C141.833 53.943 142.57 55.7234 142.57 57.58V116.42C142.57 118.276 141.833 120.057 140.52 121.37C139.207 122.682 137.427 123.42 135.57 123.42H39.83C37.9735 123.42 36.193 122.682 34.8803 121.37C33.5675 120.057 32.83 118.276 32.83 116.42V92.12"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M56.08 50.58H65.46" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M99.64 123.4H75.76V143.73H99.64V123.4Z"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M65.48 143.73H109.92" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M36.08 6.27002H14.43C10.5695 6.27002 7.44 9.39955 7.44 13.26V72.1C7.44 75.9605 10.5695 79.09 14.43 79.09H36.08C39.9405 79.09 43.07 75.9605 43.07 72.1V13.26C43.07 9.39955 39.9405 6.27002 36.08 6.27002Z"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M31.15 20.23L20.67 27.49" stroke="#E40000" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M20.67 27.49L31.14 34.86" stroke="#E40000" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M32.65 27.54C40.543 27.5242 48.3444 29.2305 55.51 32.54"
      stroke="#E40000"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M101.15 99.37L93.89 109.84"
      stroke="#E40000"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M93.89 109.84L86.52 99.37"
      stroke="#E40000"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M93.84 97.87C93.8571 89.9769 92.1507 82.1752 88.84 75.01"
      stroke="#E40000"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M85.57 41.65L66.22 60.99" stroke="#E40000" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M66.22 41.65L85.57 60.99" stroke="#E40000" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />,
  ],
});
