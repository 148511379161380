import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationPairing1Illustration = createIcon({
  displayName: "ConfirmationPairing1Illustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <rect width="150" height="150" fill="white" />,
    <path
      d="M137.31 16.73H11.01C7.14955 16.73 4.02002 19.8595 4.02002 23.72V102.84C4.02002 106.7 7.14955 109.83 11.01 109.83H137.31C141.17 109.83 144.3 106.7 144.3 102.84V23.72C144.3 19.8595 141.17 16.73 137.31 16.73Z"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M89.4199 109.83H58.8899V135.82H89.4199V109.83Z"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M38.98 135.82H109.33" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M86.68 72.38H82.8V76.26H86.68V72.38Z" fill="#8F8BEF" />,
    <path
      d="M91.1101 67.95H78.3701V80.69H91.1101V67.95Z"
      stroke="#8F8BEF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M66.0199 51.91H62.1399V55.79H66.0199V51.91Z" fill="#8F8BEF" />,
    <path
      d="M70.45 47.48H57.71V60.22H70.45V47.48Z"
      stroke="#8F8BEF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M86.68 51.91H82.8V55.79H86.68V51.91Z" fill="#8F8BEF" />,
    <path
      d="M91.1101 47.48H78.3701V60.22H91.1101V47.48Z"
      stroke="#8F8BEF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M66.0199 72.38H62.1399V76.26H66.0199V72.38Z" fill="#8F8BEF" />,
    <path
      d="M70.45 67.95H57.71V80.69H70.45V67.95Z"
      stroke="#8F8BEF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M68.67 35.73H45.49V58.08" stroke="#8F8BEF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M80.6699 92.44H103.33V70.08"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M46.0601 69.83V93.01H68.4101"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M102.76 57.83V35.16H80.4099"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
