import { StyleFunctionProps } from "@chakra-ui/system";
import { extendTheme } from "@chakra-ui/theme-utils";

import getCookiebotStyles from "./overrides/cookiebot";
import getTermsStyles from "./overrides/terms";

const styles = extendTheme({
  global: (props: StyleFunctionProps) => {
    const cookiebotStyles = getCookiebotStyles(props);
    const termsStyles = getTermsStyles(props);

    return {
      ":root": {
        "--app-height": "100%",
      },
      body: {
        fontFamily: "body",
        bg: "white",
        transitionProperty: "background-color",
        transitionDuration: "normal",
        lineHeight: "base",
        color: "black",
      },
      "#root": {
        minH: "var(--app-height)",
        display: "flex",
        flexDirection: "column",
      },
      "*, *::before, &::after": {
        wordWrap: "break-word",
      },

      ...cookiebotStyles,
      ...termsStyles,
    };
  },
});

export default styles;
