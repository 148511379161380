import React from "react";

import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Device, Subscription, useGetSubscriptionsQuery, useGetUserDevicesQuery } from "store/api";
import {
  BodyText,
  Button,
  Flex,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  ItemCart,
  TitleH1Os,
} from "ui";
import { DeviceListItem } from "views/commons/DeviceListItem";

export type FinalizationState = {
  newDeviceId: number;
};

const Finalization: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation() as any;
  const state = location.state as FinalizationState;
  const { data: pairedDevices, isSuccess, isLoading } = useGetUserDevicesQuery();
  const { data: subscriptions } = useGetSubscriptionsQuery();
  const navigate = useNavigate();
  const goToMyDevices = React.useCallback(() => navigate(Paths.MyDevices), [navigate]);
  const goToPairingDevice = () => navigate(Paths.PairDevice);
  const newlyPairedDevice = pairedDevices?.find((device: Device) => device.id === state?.newDeviceId);

  if (isSuccess && !newlyPairedDevice) {
    return (
      <>
        <TitleH1Os>{t("label_action_info_finalization")}</TitleH1Os>
        <InfoPanel>
          <InfoPanelIllustration illustrationName="ConfirmationPaired2Illustration" />
          <InfoPanelContent>
            <BodyText>{t("prompt_device_not_registered")}</BodyText>
          </InfoPanelContent>
          <InfoPanelAction>
            <Button onClick={goToMyDevices} variant="secondary">
              {t("label_action_info_resignation")}
            </Button>
            <Button onClick={goToPairingDevice}>{t("label_action_request_register_again")}</Button>
          </InfoPanelAction>
        </InfoPanel>
      </>
    );
  }

  const subscription = subscriptions?.find(
    (subscription: Subscription) => newlyPairedDevice?.subscriptionId === subscription.id,
  );

  return (
    <>
      <TitleH1Os>{t("label_action_info_finalization")}</TitleH1Os>
      <InfoPanel>
        <InfoPanelIllustration illustrationName="ConfirmationSuccessIllustration" />
        <InfoPanelContent>
          <BodyText>{t("label_action_info_successfully_registered")}</BodyText>
        </InfoPanelContent>
      </InfoPanel>
      <TitleH1Os>{t("label_noun_registered_devices")}</TitleH1Os>
      {isLoading && (
        <ItemCart>
          <ItemCart.Header />
          <ItemCart.Image />
          <ItemCart.Description />
        </ItemCart>
      )}
      {newlyPairedDevice && (
        <DeviceListItem device={newlyPairedDevice} subscription={subscription}>
          {!newlyPairedDevice.subscriptionId && (
            <Button onClick={goToPairingDevice} variant="secondary" width={["full", "auto"]}>
              {t("label_action_request_assign")}
            </Button>
          )}
        </DeviceListItem>
      )}
      <Flex justifyContent="flex-end" mt="xl" flexDirection={["column", "row"]}>
        <Button onClick={goToMyDevices}>{t("label_action_request_go_to_my_devices")}</Button>
      </Flex>
    </>
  );
};

export default Finalization;
