import { useEffect, useState } from "react";

import { useAppSelector, useRequiredSearchParams } from "hooks";
import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { store } from "store";
import { api, useConfirmEmailMutation, useSignOutMutation } from "store/api";
import { err } from "store/errors";
import { cartSelectors, selectToken } from "store/state";
import {
  BodyText,
  Button,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  PageLoader,
  TitleH1,
} from "ui";

const ConfirmEmail: React.FC = () => {
  useRequiredSearchParams(["confirmation_token"]);
  const [params] = useSearchParams();
  const [signOut] = useSignOutMutation();
  const [confirmEmail, confirmEmailResult] = useConfirmEmailMutation();
  const [messageType, setMessageType] = useState<"success" | "expired" | "alreadyConfirmed">("expired");

  const navigate = useNavigate();
  const token = useAppSelector(selectToken);
  const { t } = useTranslation();

  useEffect(() => {
    const signOutUser = async () => {
      try {
        if (token) {
          await signOut().unwrap();
          store.dispatch(api.util.resetApiState());
        }
      } catch (error) {
        err.handler(error, [], true);
      }
    };

    const redirectToIfCartExists = (path: Paths) => {
      if (cartSelectors.selectTotal(store.getState()) > 0) {
        navigate(path, { replace: true });
      }
    };

    const onConfirmEmail = async () => {
      const confirmationToken = params.get("confirmation_token");
      if (confirmationToken) {
        try {
          await signOutUser();
          await confirmEmail({ confirmation_token: confirmationToken }).unwrap();

          redirectToIfCartExists(Paths.CheckoutEmailConfirmed);
          setMessageType("success");
        } catch (error) {
          if (err.hasSpecificCode(error, "error_confirmation_token_already_confirmed")) {
            redirectToIfCartExists(Paths.CheckoutEmailUsedLink);
            setMessageType("alreadyConfirmed");
          } else {
            redirectToIfCartExists(Paths.CheckoutEmailExpiredLink);
          }
        }
      }
    };

    onConfirmEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToSignIn = () => {
    navigate(Paths.SignIn);
  };

  const goToSignUp = () => {
    navigate(Paths.SignUp);
  };

  if (confirmEmailResult.isLoading) return <PageLoader>{t("label_action_info_loading")}</PageLoader>;

  const infoCardProps = {
    success: {
      title: t("label_action_info_e-mail_confirmed"),
      content: confirmEmailResult.data?.reconfirmation
        ? t("prompt_e-mail_confirmed_instruction")
        : t("prompt_e-mail_confirmed_description"),
      action: {
        title: t("label_action_request_go_to_sign_in"),
        onClick: goToSignIn,
      },
    },
    expired: {
      title: t("label_action_info_expired_link"),
      content: t("prompt_expired_link_description"),
      action: {
        title: t("label_action_request_go_to_sign_up"),
        onClick: goToSignUp,
      },
    },
    alreadyConfirmed: {
      title: t("label_action_info_used_link"),
      action: {
        title: t("label_action_request_go_to_homepage"),
        onClick: goToSignIn,
      },
    },
  }[messageType];

  const illustrationName = confirmEmailResult.isSuccess
    ? "EmailConfirmation2Illustration"
    : "ConfirmationEmailExpired2Illustration";

  const color = confirmEmailResult.isSuccess ? "greenAlert" : "redAlert";

  return (
    <InfoPanel isBorder>
      <InfoPanelIllustration illustrationName={illustrationName} color={color} />
      <InfoPanelContent>
        <TitleH1 color={color}>{infoCardProps.title}</TitleH1>
        {infoCardProps.content && <BodyText>{infoCardProps.content}</BodyText>}
      </InfoPanelContent>
      <InfoPanelAction>
        <Button onClick={infoCardProps.action.onClick}>{infoCardProps.action.title}</Button>
      </InfoPanelAction>
    </InfoPanel>
  );
};

export default ConfirmEmail;
