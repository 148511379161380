import React from "react";

import { createIcon } from "@chakra-ui/react";

export const Sezzle2CardLogo = createIcon({
  displayName: "Sezzle2CardLogo",
  viewBox: "0 0 48 32",
  defaultProps: {
    width: 12,
    height: 8,
    fill: "transparent",
  },
  path: [
    <path
      d="M15.9355 20.1439C18.1115 22.3199 21.6315 22.3199 23.7915 20.1439L23.9995 19.9359C25.0875 18.8479 22.9115 13.1519 23.9995 12.0799L15.9355 20.1439Z"
      fill="url(#paint0_linear_4346_22305)"
    />,
    <path
      d="M24.2073 11.8561L23.9993 12.0641C22.9113 13.1521 25.0873 18.848 23.9993 19.92L32.0632 11.8561C30.9753 10.7681 29.5513 10.2241 28.1273 10.2241C26.7193 10.2241 25.2953 10.7681 24.2073 11.8561Z"
      fill="url(#paint1_linear_4346_22305)"
    />,
    <path
      d="M15.9358 12.272C13.7598 14.448 13.7598 17.968 15.9358 20.128L24.2078 11.856C26.3838 9.67999 26.3838 6.16 24.2078 4L15.9358 12.272Z"
      fill="url(#paint2_linear_4346_22305)"
    />,
    <path
      d="M23.792 20.144C21.616 22.32 21.616 25.84 23.792 28L32.064 19.728C34.24 17.552 34.24 14.032 32.064 11.872L23.792 20.144Z"
      fill="url(#paint3_linear_4346_22305)"
    />,
    <rect x="0.5" y="0.5" width="47" height="31" rx="3.5" stroke="#8D95A5" />,
    <defs>
      <linearGradient
        id="paint0_linear_4346_22305"
        x1="23.5452"
        y1="20.3703"
        x2="19.6439"
        y2="15.721"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CE5DCB" />,
        <stop offset="0.2095" stopColor="#C558CC" />,
        <stop offset="0.5525" stopColor="#AC4ACF" />,
        <stop offset="0.9845" stopColor="#8534D4" />,
        <stop offset="1" stopColor="#8333D4" />,
      </linearGradient>
      <linearGradient
        id="paint1_linear_4346_22305"
        x1="23.686"
        y1="15.0791"
        x2="32.0685"
        y2="15.0791"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0237" stopColor="#FF5667" />,
        <stop offset="0.6592" stopColor="#FC8B82" />,
        <stop offset="1" stopColor="#FBA28E" />,
      </linearGradient>
      <linearGradient
        id="paint2_linear_4346_22305"
        x1="14.3009"
        y1="12.0661"
        x2="25.8373"
        y2="12.0661"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00B874" />,
        <stop offset="0.5126" stopColor="#29D3A2" />,
        <stop offset="0.6817" stopColor="#53DFB6" />,
        <stop offset="1" stopColor="#9FF4D9" />,
      </linearGradient>
      <linearGradient
        id="paint3_linear_4346_22305"
        x1="22.1624"
        y1="19.9274"
        x2="33.6988"
        y2="19.9274"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCD77E" />,
        <stop offset="0.5241" stopColor="#FEA500" />,
        <stop offset="1" stopColor="#FF5B00" />,
      </linearGradient>
    </defs>,
  ],
});
