const spacing = {
  0: "0",
  xxs: "0.25rem",
  xs: "0.5rem" /** 8 */,
  s: "0.75rem",
  m: "1rem" /** 16 */,
  l: "1.5rem" /** 24 */,
  xl: "2rem" /** 32 */,
  xxl: "2.5rem" /** 40 */,
  xxxl: "3rem",
} as const;

export default spacing;
