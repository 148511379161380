import { checkboxAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleControl = defineStyle({
  transitionProperty: "box-shadow",
  transitionDuration: "normal",
  border: "1px solid",
  borderRadius: "s",
  borderColor: "primary.300",
  color: "white",
  _checked: {
    bg: "primary.300",
    borderColor: "primary.300",

    _hover: {
      bg: "primary.500",
      borderColor: "primary.500",
    },

    _disabled: {
      borderColor: "grayDark",
      bg: "grayDark",
      color: "white",
    },
  },
  _indeterminate: {
    bg: "primary.300",
    borderColor: "primary.300",
  },
  _active: {
    borderColor: "primary.500",
  },
  _readOnly: {
    boxShadow: "none !important",
    userSelect: "all",
    _active: {
      borderColor: "primary.300",
    },
  },
  _disabled: {
    cursor: "not-allowed",
    bg: "white",
    borderColor: "grayDark",
  },
  _focus: {
    boxShadow: "outline",
    borderColor: "transparent",
    zIndex: 1,
  },
});

const baseStyleLabel = defineStyle({
  userSelect: "none",
  ml: 4,
  _disabled: { color: "grayDark" },
});

const baseStyleIcon = defineStyle({
  transitionProperty: "transform",
  transitionDuration: "normal",
});

const baseStyleContainer = defineStyle({
  "+ .message-wrapper": {
    mb: 1,
  },
  "&[data-invalid] + div": {
    ml: 10,
  },
});

const baseStyle = definePartsStyle(() => ({
  icon: baseStyleIcon,
  control: baseStyleControl,
  label: baseStyleLabel,
  container: baseStyleContainer,
}));

const sizes = {
  md: definePartsStyle({
    control: { w: "l", h: "l" },
    label: { fontSize: "1rem" },
    icon: { fontSize: "1rem" },
  }),
};

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: "md",
  },
});
