import React from "react";

import { Flex, FlexProps } from "../Flex/Flex";

export const Card: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex
      bgColor="white"
      border={["none", "1px solid"]}
      borderColor={[null, "grayLight"]}
      borderRadius="xl"
      direction={["column", "row"]}
      w="full"
      {...props}
    >
      {children}
    </Flex>
  );
};
