import { Skeleton } from "@chakra-ui/react";
import { BodyText, Stack } from "ui";

const NO_DATA_SYMBOL = "—";

type InfoListItemProps = {
  title: string;
  content: string | null | undefined;
  isLoading?: boolean;
};

const InfoListItem = ({ title, content, isLoading }: InfoListItemProps) => {
  return (
    <Stack display="flex" direction={["column", "row"]} spacing={[2, 8]} w="full">
      <BodyText flex={1}>{title}</BodyText>
      {isLoading ? (
        <Skeleton w="full" h={4} flex={1} />
      ) : (
        <BodyText variant="highlights16" flex={1}>
          {content || NO_DATA_SYMBOL}
        </BodyText>
      )}
    </Stack>
  );
};

export default InfoListItem;
