import { Method, ResBuilder, api, transformResponse } from "./api";

export const transformValidPayment = (data: ResBuilder<MandateRaw[]>) => {
  const payments = transformResponse<Mandate[]>(data);
  return payments?.find((mandate: { status: string }) => mandate.status === "valid");
};

const paymentsApi = api.injectEndpoints({
  endpoints: builder => ({
    getPayments: builder.query<Mandate[], void>({
      query: () => ({
        url: "/users/payment_methods",
        method: Method.Get,
      }),
      transformResponse: (res: ResBuilder<MandateRaw[]>) => transformResponse(res),
      providesTags: ["Payments"],
    }),
    getValidPayment: builder.query<Mandate | undefined, void>({
      query: () => ({
        url: "/users/payment_methods",
        method: Method.Get,
      }),
      transformResponse: transformValidPayment,
      providesTags: ["Payments"],
    }),
    addPayment: builder.mutation<Mandate, AddMandateReq>({
      query: ({ redirectUrl, cardToken }) => ({
        url: "/users/payment_methods",
        method: Method.Post,
        body: {
          card_token: cardToken,
          redirect_url: redirectUrl,
        },
      }),
      transformResponse: (res: ResBuilder<MandateRaw>) => transformResponse(res),
      invalidatesTags: ["Payments"],
    }),
    deletePayment: builder.mutation<void, DeleteMandateReq>({
      query: ({ id }) => ({
        url: `/users/payment_methods/${id}`,
        method: Method.Delete,
      }),
      invalidatesTags: ["Payments"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPaymentsQuery,
  useGetValidPaymentQuery,
  useLazyGetPaymentsQuery,
  useAddPaymentMutation,
  useDeletePaymentMutation,
} = paymentsApi;

export enum MandateStatus {
  PENDING = "pending",
  VALID = "valid",
  INVALID = "invalid",
}

export type Mandate = {
  id: string;
  status: "valid" | "pending" | "invalid";
  method: "creditcard";
  holder: string;
  number: string;
  label:
    | "American Express"
    | "Carta Si"
    | "Carte Bleue"
    | "Dankort"
    | "Diners Club"
    | "Discover"
    | "JCB"
    | "Laser"
    | "Maestro"
    | "Mastercard"
    | "Unionpay"
    | "Visa"
    | null;
  expiryDate: string;
  mandateId?: string;
  checkoutUrl?: string;
};

export type AddMandateReq = {
  redirectUrl: string;
  cardToken: string;
};

export type DeleteMandateReq = {
  id: string;
};

export type MandateRaw = {
  type: "mandate";
  id: string;
  attributes: {
    status: MandateStatus;
    method: "creditcard";
    holder: string;
    number: string;
    label:
      | "American Express"
      | "Carta Si"
      | "Carte Bleue"
      | "Dankort"
      | "Diners Club"
      | "Discover"
      | "JCB"
      | "Laser"
      | "Maestro"
      | "Mastercard"
      | "Unionpay"
      | "Visa"
      | null;
    expiry_date: string;
    mandate_id?: string;
    checkout_url?: string;
  };
};
