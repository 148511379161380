import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationSuccessIllustration = createIcon({
  displayName: "ConfirmationSuccessIllustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <rect width="150" height="150" fill="white" />,
    <path
      d="M73.0899 106C73.0899 115.65 66.0899 124.25 55.3199 129.81C53.9299 130.53 51.1999 131.72 49.6899 132.33"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M79.8201 106C79.8201 117.26 89.2701 127.08 103.3 132.33"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M106.81 35.53C106.81 74.3 93.2201 105.73 76.4501 105.73C59.6801 105.73 46.1001 74.3 46.1001 35.53H106.81Z"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M94.94 47.64C93.29 69.58 87.24 88 79 97.93"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M57.21 89.86C47.21 89.86 29.21 71.2 29.21 48.18H46.83"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M106.35 48.18H123.71C123.71 71.18 105.65 89.86 95.71 89.86"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M103.22 144.47H49.6899V132.31H50.5099H103.22V144.47Z"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M75 4.96997V25.52" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M129.52 75H140.03" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M9.96997 75H20.71" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M110.45 110.45L124.52 124.52"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M15.48 15.48L30.81 30.81" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M119.57 30.43L134.52 15.48"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M25.48 124.52L39.4 110.6" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,
  ],
});
