import React from "react";

import { HStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from "@chakra-ui/react";

import { ArrowDown, ArrowUp, BodyText, Box } from "../../atoms";
import colors from "../../foundations/Colors/colors";

type Props = {
  label: string;
  children: React.ReactNode;
};

export const TopbarDropdown: React.FC<Props> = ({ children, label }) => {
  return (
    <Box zIndex="dropdown">
      <Menu gutter={0} placement="bottom-end">
        {({ isOpen }) => {
          return (
            <>
              <MenuButton
                pl={2}
                pr={1}
                py={2}
                role="group"
                backgroundColor={isOpen ? "grayLight" : "white"}
                _focusVisible={{
                  boxShadow: `0 0 0 2px ${colors.keyboardBlueAlert}`,
                }}
              >
                <HStack>
                  <BodyText textTransform="uppercase" variant="body16" color="black">
                    {label}
                  </BodyText>
                  {isOpen ? <ArrowUp /> : <ArrowDown />}
                </HStack>
              </MenuButton>
              <MenuList backgroundColor="white">
                {React.Children.map(children, item => (
                  <>
                    {item}
                    <MenuDivider borderColor="grayLight" />
                  </>
                ))}
              </MenuList>
            </>
          );
        }}
      </Menu>
    </Box>
  );
};

type TopbarDropdownItemProps = {
  onClick?: () => void;
  children: string;
};

export const TopbarDropdownItem: React.FC<TopbarDropdownItemProps> = ({ children, onClick }) => (
  <MenuItem
    onClick={onClick}
    padding={4}
    _focusVisible={{
      boxShadow: `0 0 0 2px ${colors.keyboardBlueAlert}`,
    }}
  >
    <BodyText variant="body16">{children}</BodyText>
  </MenuItem>
);
