import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Box, Flex, HamburgerButton, Link, Stack, VascoLogo, VascoSpaceLogo } from "../../atoms";
import { VascoSpaceSpainLogo, VascoSpainLogo } from "../../atoms/Logos";
import { useBreakpoints } from "../../hooks";

type Props = {
  isMenuMobile?: boolean;
  logoLink: {
    as?: any;
    to: string;
  };
  children?: React.ReactNode;
  isOpenMenu?: boolean;
  onToggleMenu?: () => void;
  maxW?: number;
};

export const TopBar = ({
  isMenuMobile = true,
  logoLink,
  isOpenMenu = false,
  onToggleMenu = () => {},
  children,
  maxW = 1200,
}: Props) => {
  const { isDesktop } = useBreakpoints();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();

  const logoComponent = useMemo(() => {
    const isAuthPath = pathname.startsWith("/auth");
    const isLangSpain = i18n.languages[0] === "es-ES";

    if (isAuthPath) {
      if (isLangSpain) return <VascoSpaceSpainLogo />;
      else return <VascoSpaceLogo />;
    } else {
      if (isLangSpain) return <VascoSpainLogo />;
      else return <VascoLogo />;
    }
  }, [i18n.languages, pathname]);

  return (
    <Flex
      w="full"
      zIndex="sticky"
      backgroundColor="white"
      top={0}
      left={0}
      alignItems="center"
      justifyContent="space-between"
      px={["m", "xl", "xxl", 120]}
      pos="fixed"
      maxW={maxW}
      right={0}
      m="0 auto"
    >
      <Stack spacing={[4, 4, 0]} direction="row" h="menu" alignItems="center">
        {!isDesktop && isMenuMobile && <HamburgerButton isOpen={isOpenMenu} onClick={onToggleMenu} />}
        <Link as={logoLink.as} to={logoLink.to}>
          {logoComponent}
        </Link>
      </Stack>
      <Box>{children}</Box>
    </Flex>
  );
};
