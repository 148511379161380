import React, { useMemo } from "react";

import { Collapse, useBoolean, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  Device,
  Mandate,
  Subscription,
  SubscriptionStatus as SubscriptionStatusType,
  useActivateSubscriptionMutation,
  useCancelSubscriptionMutation,
} from "store/api";
import { err, infoToast } from "store/errors";
import { Button, CloseIcon, IconButton, ItemCart } from "ui";
import { ProductHelpToolbar } from "views/commons/ProductHelpToolbar";
import { SubscriptionStatus } from "views/commons/SubscriptionStatus";

import { PromptCancelSubscription } from "./PromptCancelSubscription";
import { SubscriptionInfo } from "./SubscriptionInfo";

type SubscriptionListItemProps = {
  subscription: Subscription;
  device?: Device;
  mandate?: Mandate;
};

export const SubscriptionListItem: React.FC<SubscriptionListItemProps> = ({ subscription, mandate, device }) => {
  const { t } = useTranslation();
  const [isShowSubscriptionDetails, { on: showSubscriptionInfo, off: hideSubscriptionInfo }] = useBoolean(false);
  const [activateSubscription, activateSubscriptionState] = useActivateSubscriptionMutation();
  const [cancelSubscription, cancelSubscriptionState] = useCancelSubscriptionMutation();

  const {
    isOpen: isCancelSubscriptionPromptOpen,
    onOpen: showCancelSubscriptionPrompt,
    onClose: closeCancelSubscriptionPrompt,
  } = useDisclosure();

  const onCancelSubscription = async () => {
    await cancelSubscription({ id: subscription.id }).unwrap();
    closeCancelSubscriptionPrompt();
    infoToast({
      title: t("label_action_info_subscription_cancelled"),
      description: t("prompt_subscription_will_expire", { number: subscription.uid }),
    });
  };

  const onActivate = async () => {
    try {
      await activateSubscription({ id: subscription.id }).unwrap();
    } catch (error) {
      err.handler(error);
    }
  };

  const menuOptions = useMemo(() => {
    const isSubscriptionActive = subscription.status === SubscriptionStatusType.Active;

    const options = [
      {
        key: "more",
        onClick: showSubscriptionInfo,
        label: t("label_noun_more_info"),
      },
    ];

    if (isSubscriptionActive) {
      options.push({
        key: "cancel",
        onClick: showCancelSubscriptionPrompt,
        label: t("label_action_request_cancel_subscription"),
      });
    }

    return options;
  }, [subscription.status, showSubscriptionInfo, t, showCancelSubscriptionPrompt]);

  const isSubscriptionCanBeActivated = useMemo(
    () => [SubscriptionStatusType.Expiring, SubscriptionStatusType.Inactive].includes(subscription.status),
    [subscription.status],
  );

  if (isCancelSubscriptionPromptOpen) {
    return (
      <PromptCancelSubscription
        onClose={closeCancelSubscriptionPrompt}
        onSubmit={onCancelSubscription}
        isSubmitting={cancelSubscriptionState.isLoading}
      />
    );
  }

  return (
    <ItemCart>
      <ItemCart.Header
        title={t("label_noun_subscription_no", { number: subscription.uid })}
        menuItems={!isShowSubscriptionDetails ? menuOptions : undefined}
        extra={
          isShowSubscriptionDetails && (
            <IconButton onClick={hideSubscriptionInfo}>
              <CloseIcon />
            </IconButton>
          )
        }
      />
      <ItemCart.Description>
        <SubscriptionStatus subscription={subscription} />
        {isSubscriptionCanBeActivated && (
          <Button
            onClick={onActivate}
            isLoading={activateSubscriptionState.isLoading}
            mt={2}
            w={["full", "min-content"]}
          >
            {t("label_action_request_activate")}
          </Button>
        )}
      </ItemCart.Description>
      <ItemCart.Content hasContent={isShowSubscriptionDetails} paddingTop={0}>
        <Collapse in={isShowSubscriptionDetails} animateOpacity transitionEnd={{ enter: { overflow: "inherit" } }}>
          <SubscriptionInfo
            subscription={subscription}
            device={device}
            mandate={mandate}
            isActive={isShowSubscriptionDetails}
          />
        </Collapse>
      </ItemCart.Content>
      <ItemCart.Footer>
        <ProductHelpToolbar />
      </ItemCart.Footer>
    </ItemCart>
  );
};
