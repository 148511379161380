import React from "react";

import { createIcon } from "@chakra-ui/react";

export const VascoSpaceSpainLogo = createIcon({
  displayName: "VascoSpaceSpainLogo",
  defaultProps: {
    width: "118px",
    height: "32px",
  },
  viewBox: "0 0 118 32",
  path: [
    <path
      d="M54.5882 0C56.6274 0 58.5098 1.17647 59.3725 3.05882L72.0784 31.3725H64.4705L56.1568 11.7647C55.5294 10.1961 54.9804 8.70587 54.7451 7.37254H54.5882C54.3529 8.70587 53.8039 10.1961 53.1764 11.7647L44.8627 31.3725H37.2549L49.8823 3.05882C50.6666 1.17647 52.549 0 54.5882 0Z"
      fill="#010101"
    />,
    <path
      d="M17.4117 31.9999C15.3725 31.9999 13.4902 30.8235 12.6274 28.9411L0 0.627441H7.60783L15.9216 20.2353C16.549 21.8039 17.098 23.2941 17.3333 24.6274H17.4902C17.7255 23.2941 18.2745 21.8039 18.9019 20.2353L27.2157 0.627441H34.8235L22.1961 28.9411C21.3333 30.7451 19.451 31.9999 17.4117 31.9999Z"
      fill="#010101"
    />,
    <path
      d="M82.2742 6.35293H83.2154C83.2154 7.7647 84.3134 8.47058 85.8036 8.47058H85.9605C87.3723 8.47058 88.0781 7.68626 88.0781 6.82352C88.0781 5.96077 87.5291 5.49019 86.4311 5.25489L85.0193 4.94117C83.6076 4.62745 82.8232 3.92157 82.8232 2.58823C82.8232 1.17647 83.9997 0 85.9605 0C87.9213 0 89.0977 1.09804 89.1762 2.82352H88.235C88.1566 1.64706 87.2154 0.862743 85.9605 0.862743H85.8036C84.5487 0.862743 83.686 1.64706 83.686 2.58823C83.686 3.37255 84.235 3.84313 85.1762 4.07842L86.588 4.39215C88.0781 4.70588 88.9409 5.49019 88.9409 6.82352C88.9409 8.39214 87.686 9.41175 85.8036 9.41175C83.6076 9.33332 82.2742 8.23528 82.2742 6.35293Z"
      fill="#1D1D1B"
    />,
    <path
      d="M90.823 11.8433V2.74524H91.6857V3.76485C92.0779 3.05897 93.019 2.58838 93.8818 2.58838C95.5288 2.58838 96.7837 3.84328 96.7837 5.96092C96.7837 8.00013 95.6073 9.33347 93.8818 9.33347C93.019 9.33347 92.0779 8.86288 91.6857 8.157V11.7648H90.823V11.8433ZM93.8034 8.62758C95.2151 8.62758 95.8426 7.37268 95.8426 5.96092C95.8426 4.54916 95.1367 3.29426 93.8034 3.29426H93.7249C92.3132 3.29426 91.6857 4.54916 91.6857 5.96092C91.6073 7.37268 92.3132 8.62758 93.8034 8.62758Z"
      fill="#1D1D1B"
    />,
    <path
      d="M97.9608 7.45106C97.9608 6.03929 99.2156 5.5687 100.157 5.49027L102.196 5.33341V5.09812C102.196 4.00008 101.569 3.37263 100.549 3.37263H100.471C99.6078 3.37263 98.9804 3.76479 98.8235 4.62753H97.9608C98.0392 3.45106 99.0588 2.66675 100.471 2.66675C102.118 2.66675 102.98 3.84322 102.98 5.09812V7.84321C102.98 8.23537 102.98 8.78439 103.059 9.25498H102.274C102.196 8.94125 102.196 8.54909 102.196 8.23537C101.961 8.86282 101.02 9.41184 99.9215 9.41184C98.9019 9.33341 97.9608 8.54909 97.9608 7.45106ZM100.235 8.62752C101.098 8.62752 102.274 7.84321 102.274 6.90204V6.03929L100.392 6.19616C99.4509 6.27459 98.8235 6.74517 98.8235 7.45106C98.8235 8.15694 99.3725 8.70595 100.078 8.70595L100.235 8.62752Z"
      fill="#1D1D1B"
    />,
    <path
      d="M104.705 5.96092C104.705 4.07857 105.803 2.58838 107.686 2.58838C109.097 2.58838 110.117 3.45112 110.195 4.78445H109.333C109.176 3.92171 108.548 3.29426 107.686 3.29426C106.274 3.29426 105.646 4.54916 105.646 5.96092C105.646 7.37268 106.274 8.62758 107.686 8.62758H107.843C108.705 8.62758 109.254 8.00013 109.49 7.13739H110.352C110.117 8.47072 109.254 9.33347 107.843 9.33347C105.803 9.33347 104.705 7.9217 104.705 5.96092Z"
      fill="#1D1D1B"
    />,
    <path
      d="M111.451 5.96092C111.451 3.68641 112.863 2.58838 114.353 2.58838C115.922 2.58838 116.941 3.84328 116.941 5.49033V6.03935H112.314C112.314 7.84327 113.255 8.62758 114.353 8.62758H114.431C115.294 8.62758 115.843 8.23543 116.157 7.37269H117.02C116.706 8.62759 115.765 9.33347 114.431 9.33347C112.784 9.33347 111.451 8.157 111.451 5.96092ZM116.157 5.33347C116.157 4.157 115.451 3.29426 114.431 3.29426H114.275C113.49 3.29426 112.549 3.76485 112.314 5.33347H116.157Z"
      fill="#1D1D1B"
    />,
  ],
});
