import strings from "locales/strings.json";

import { getDeviceImageByColor } from "./common";

const DEVICE_COLORS = {
  black_onyx: "#272829",
  stone_gray: "#494B4E",
  cobalt_blue: "#27415C",
  ruby_red: "#9B1E19",
  pearl_white: "#F0F0EF",
} as const;

const getName = (productId?: string) => {
  switch (productId) {
    case "com.vasco.translator.v4.sub":
      return strings.v4;
    default:
      return "Vasco Translator";
  }
};

export const devices = {
  getImage: getDeviceImageByColor,
  getName,
  DEVICE_COLORS,
};
