import { useBoolean, useBreakpointValue } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequiredSearchParams } from "hooks";
import { Paths } from "paths";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { store } from "store";
import { api, useDeleteAccountMutation } from "store/api";
import { err } from "store/errors";
import { setCredentials } from "store/state";
import {
  BodyText,
  Button,
  Form,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  Input,
  Stack,
  TitleH1,
} from "ui";
import * as yup from "yup";

export type DeleteAccountFormState = {
  password: string;
};

const DeleteAccount: React.FC = () => {
  const { t } = useTranslation();
  useRequiredSearchParams(["destroy_token"]);
  let navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [isSuccess, success] = useBoolean();
  const [deleteAccount, deleteAccountResult] = useDeleteAccountMutation();
  const variant = useBreakpointValue({ sm: "vertical", md: "horizontal" });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<DeleteAccountFormState>({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object({ password: yup.string().required(t("prompt_error_mandatory_password")) }).required(),
    ),
  });

  const onDeleteAccount = async () => {
    try {
      const { password } = getValues();
      const destroy_token = params.get("destroy_token");
      if (destroy_token) {
        await deleteAccount({ destroy_token, password }).unwrap();
        store.dispatch(api.util.resetApiState());
        store.dispatch(setCredentials({}));
        setParams({ confirm: "true" });
        success.on();
      }
    } catch (error) {
      const skipNotification = err.hasSpecificCode(error, "error_destroy_token_invalid");
      err.handler(
        error,
        [
          {
            code: "error_password_invalid",
            title: t("label_noun_error_incorrect_password_pt1"),
            description: t("label_action_request_error_incorrect_password_pt2"),
          },
        ],
        skipNotification,
      );
    }
  };

  const goToSignUp = () => {
    navigate(Paths.SignUp, { replace: true });
  };

  const goToProfile = () => {
    navigate(Paths.Profile, { replace: true });
  };

  const goToHomepage = () => {
    navigate(Paths.Home, { replace: true });
  };

  if (isSuccess) {
    return (
      <InfoPanel isBorder>
        <InfoPanelIllustration illustrationName="EmailConfirmation2Illustration" color="greenAlert" />
        <InfoPanelContent>
          <Stack spacing="m">
            <TitleH1 color="greenAlert">{t("label_action_info_account_deleted")}</TitleH1>
            <BodyText>{t("prompt_account_deleted_information")}</BodyText>
          </Stack>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button onClick={goToSignUp}>{t("label_action_request_go_to_sign_up")}</Button>
        </InfoPanelAction>
      </InfoPanel>
    );
  }

  if (err.hasSpecificCode(deleteAccountResult.error, "error_destroy_token_invalid")) {
    return (
      <InfoPanel isBorder>
        <InfoPanelIllustration illustrationName="ConfirmationEmailExpired2Illustration" />
        <InfoPanelContent>
          <Stack spacing="m">
            <TitleH1 color="redAlert">{t("prompt_wrong_link")}</TitleH1>
            <BodyText>{t("prompt_wrong_link_instruction")}</BodyText>
          </Stack>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button onClick={goToHomepage}>{t("label_action_request_go_to_homepage")}</Button>
        </InfoPanelAction>
      </InfoPanel>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onDeleteAccount)}>
      <InfoPanel isBorder>
        <InfoPanelIllustration illustrationName="SubscriptionEnd2Illustration" />
        <InfoPanelContent>
          <Stack spacing="m">
            <TitleH1 color="redAlert">{t("label_action_request_deletion_confirmation")}</TitleH1>
            <BodyText>{t("label_action_request_deletion_confirmation_instruction")}</BodyText>
            <FormControl variant={variant} isInvalid={errors.hasOwnProperty("password")}>
              <FormLabel htmlFor="password">{t("label_noun_password")}</FormLabel>
              <Input
                id="password"
                type="password"
                placeholder={t("label_action_request_suggestion_password")}
                {...register("password")}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button variant="secondary" onClick={goToProfile}>
            {t("label_action_request_cancel")}
          </Button>
          <Button type="submit" isLoading={isSubmitting}>
            {t("label_action_request_delete_account")}
          </Button>
        </InfoPanelAction>
      </InfoPanel>
    </Form>
  );
};

export default DeleteAccount;
