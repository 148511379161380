import React from "react";

import { createIcon } from "@chakra-ui/react";

export const ConfirmationPairing2Illustration = createIcon({
  displayName: "ConfirmationPairing2Illustration",
  viewBox: "0 0 150 150",
  defaultProps: {
    width: 150,
    height: 150,
    fill: "transparent",
  },
  path: [
    <rect width="150" height="150" fill="white" />,
    <path d="M87.0399 83.75H83.1599V87.63H87.0399V83.75Z" fill="#8F8BEF" />,
    <path
      d="M91.46 79.32H78.72V92.06H91.46V79.32Z"
      stroke="#8F8BEF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M66.37 63.28H62.49V67.16H66.37V63.28Z" fill="#8F8BEF" />,
    <path
      d="M70.8001 58.85H58.0601V71.59H70.8001V58.85Z"
      stroke="#8F8BEF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M87.0399 63.28H83.1599V67.16H87.0399V63.28Z" fill="#8F8BEF" />,
    <path
      d="M91.46 58.85H78.72V71.59H91.46V58.85Z"
      stroke="#8F8BEF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M66.37 83.75H62.49V87.63H66.37V83.75Z" fill="#8F8BEF" />,
    <path
      d="M70.8001 79.32H58.0601V92.06H70.8001V79.32Z"
      stroke="#8F8BEF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M64.8501 103.81H68.6901" stroke="#8F8BEF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M87.2 47.1H81.02" stroke="#8F8BEF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M103.69 69.45V68.27" stroke="#8F8BEF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M69.0301 47.1H45.8401V69.45"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M81.03 103.81H103.69V81.45"
      stroke="#8F8BEF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M93.09 35L79.91 7.32998C78.7976 5.07655 76.8377 3.35569 74.4593 2.54415C72.0809 1.73261 69.4778 1.89652 67.22 2.99998L24.32 24C22.0754 25.1162 20.3623 27.0735 19.5533 29.4462C18.7443 31.8189 18.9048 34.415 20 36.67L35.09 67.93L58.85 116.48L71.59 142.12C72.7008 144.375 74.6594 146.099 77.0373 146.914C79.4153 147.729 82.0193 147.569 84.28 146.47L127.18 125.47C129.437 124.361 131.162 122.403 131.978 120.024C132.793 117.645 132.632 115.04 131.53 112.78L114.81 78.4L93.09 35Z"
      stroke="#999999"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
