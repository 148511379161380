import React from "react";

import {
  Stack as ChakraStack,
  ComponentWithAs,
  StackProps as Props,
  StackDirection,
  SystemProps,
} from "@chakra-ui/react";

export interface StackProps extends Props {
  /**
   * Shorthand for `alignItems` style prop
   * @type SystemProps["alignItems"]
   */
  align?: SystemProps["alignItems"];
  /**
   * Shorthand for `justifyContent` style prop
   * @type SystemProps["justifyContent"]
   */
  justify?: SystemProps["justifyContent"];
  /**
   * Shorthand for `flexWrap` style prop
   * @type SystemProps["flexWrap"]
   */
  wrap?: SystemProps["flexWrap"];
  /**
   * The space between each stack item
   * @type SystemProps["margin"]
   */
  spacing?: SystemProps["margin"];
  /**
   * The direction to stack the items.
   * @default "column"
   */
  direction?: StackDirection;
  /**
   * If `true`, each stack item will show a divider
   * @type React.ReactElement
   */
  divider?: React.ReactElement;
  /**
   * If `true`, the children will be wrapped in a `Box` with
   * `display: inline-block`, and the `Box` will take the spacing props
   */
  shouldWrapChildren?: boolean;
  /**
   * If `true` the items will be stacked horizontally.
   */
  isInline?: boolean;
}

/**
 * Stack is a layout component that makes it easy to stack elements together and apply a space between them. It uses a modified version of the CSS lobotomized owl selector to add spacing between its children.
 */
export const Stack = ChakraStack as ComponentWithAs<"div", StackProps>;
