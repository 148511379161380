import React from "react";

import { Collapse, useDisclosure } from "@chakra-ui/react";
import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Device, Subscription, useDisconnectSubscriptionMutation } from "store/api";
import { errorToast, infoToast } from "store/errors";
import {
  BodyText,
  Button,
  CloseIcon,
  DividedList,
  IconButton,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  ItemCart,
  Stack,
  TitleH1,
} from "ui";
import { utils } from "utils";
import { SubscriptionStatus } from "views/commons/SubscriptionStatus";

import InfoListItem from "./InfoListItem";
import { ProductHelpToolbar } from "./ProductHelpToolbar";

type DeviceListItemProps = {
  device: Device;
  subscription?: Subscription;
  children: React.ReactNode;
};

export const DeviceListItem: React.FC<DeviceListItemProps> = ({ device, subscription, children }) => {
  const { t } = useTranslation();
  const [showDeviceInfo, setShowDeviceInfo] = React.useState(false);
  const navigate = useNavigate();
  const {
    isOpen: isUnregisterPromptOpen,
    onOpen: showUnregisterPrompt,
    onClose: closeUnregisterPrompt,
  } = useDisclosure();
  const [disconnectDevice, { isLoading }] = useDisconnectSubscriptionMutation();

  const goToPairingDevice = () => navigate(Paths.PairDevice);
  const displayDeviceInfo = () => setShowDeviceInfo(true);
  const hideDeviceInfo = () => setShowDeviceInfo(false);

  const onUnregister = async () => {
    try {
      await disconnectDevice({ id: device.id }).unwrap();
      closeUnregisterPrompt();
      infoToast({
        title: t("label_action_info_unregistered_device"),
        description: t("prompt_subscription_still_active"),
      });
    } catch {
      errorToast();
    }
  };

  const overflowMenuItems = React.useMemo(() => {
    let items = [
      {
        label: t("label_noun_device_info"),
        onClick: displayDeviceInfo,
      },
    ];
    if (device.subscriptionId) {
      items = [
        ...items,
        {
          label: t("label_action_request_unregister"),
          onClick: showUnregisterPrompt,
        },
      ];
    }

    return items;
  }, [device.subscriptionId, showUnregisterPrompt, t]);

  if (isUnregisterPromptOpen) {
    return (
      <InfoPanel isBorder borderColor="grayLight" border="2px solid">
        <InfoPanelIllustration illustrationName="ConfirmationUnpaired1" />
        <InfoPanelContent>
          <Stack spacing="m">
            <TitleH1 color="redAlert">{t("prompt_unregister_confirmation")}</TitleH1>
            <BodyText>{t("prompt_unregister_info")}</BodyText>
          </Stack>
        </InfoPanelContent>

        <InfoPanelAction>
          <Button onClick={closeUnregisterPrompt} variant="secondary" mt={["m", 0]} order={[2, 1]}>
            {t("label_action_request_cancel")}
          </Button>
          <Button onClick={onUnregister} isLoading={isLoading} order={[1, 2]}>
            {t("label_action_request_unregister_device")}
          </Button>
        </InfoPanelAction>
      </InfoPanel>
    );
  }

  return (
    <ItemCart>
      <ItemCart.Header
        title={utils.devices.getName(device.externalProductId)}
        menuItems={!showDeviceInfo ? overflowMenuItems : undefined}
        extra={
          showDeviceInfo && (
            <IconButton onClick={hideDeviceInfo}>
              <CloseIcon />
            </IconButton>
          )
        }
      />
      <ItemCart.Image
        src={utils.devices.getImage(device.active && "black_onyx")}
        alt={utils.devices.getName(device.externalProductId)}
        w={[124, 180]}
        h={[124, 180]}
      />
      <ItemCart.Description>
        {device.active ? (
          <>
            <SubscriptionStatus subscription={subscription} />
            {children}
          </>
        ) : (
          <>
            <BodyText mb={5} color="redAlert">
              {t("label_noun_device_disconnected").toUpperCase()}
            </BodyText>
            <Button onClick={goToPairingDevice} w="fit-content">
              {t("label_action_request_reconnect_device")}
            </Button>
          </>
        )}
      </ItemCart.Description>
      <ItemCart.Content hasContent={showDeviceInfo}>
        <Collapse in={showDeviceInfo} animateOpacity transitionEnd={{ enter: { overflow: "inherit" } }}>
          <Stack spacing={4}>
            <BodyText color="violet.300" mb={4}>
              {t("label_noun_device_info").toUpperCase()}
            </BodyText>
            <DividedList dividerColor="grayLight">
              <InfoListItem title={`${t("label_noun_device_id")}:`} content={device.externalDeviceId} />
              <InfoListItem title={`${t("label_noun_product_id")}:`} content={device.externalProductId} />
              <InfoListItem title={`${t("label_noun_subscription_ID")}:`} content={subscription?.uid} />
            </DividedList>
          </Stack>
        </Collapse>
      </ItemCart.Content>
      <ItemCart.Footer>
        <ProductHelpToolbar />
      </ItemCart.Footer>
    </ItemCart>
  );
};
