import React from "react";

import { createIcon } from "@chakra-ui/react";

export const DeleteIcon = createIcon({
  displayName: "DeleteIcon",
  viewBox: "0 0 24 24",
  defaultProps: {
    __css: {
      w: 6,
      h: 6,
    },
  },
  path: [
    <g clipPath="url(#clip0_2150_89784)">
      <path
        d="M10.4 3.19902H13.7V3.79902H14.9V3.09902C14.9 2.49902 14.4 1.99902 13.8 1.99902H10.4C9.69997 1.99902 9.19997 2.49902 9.19997 3.09902V3.69902H10.4V3.19902Z"
        fill="#2D3139"
      />
      <path
        d="M19.7 6.59905L19.3 5.39905C19.2 5.09905 18.9 4.89905 18.6 4.89905H5.39997C5.09997 4.89905 4.79997 5.09905 4.69997 5.39905L4.29997 6.59905C4.19997 6.79905 4.29997 7.09905 4.49997 7.19905C4.59997 7.29905 4.69997 7.29905 4.79997 7.29905H19.3C19.4 7.29905 19.5 7.29905 19.6 7.19905C19.7 7.09905 19.8 6.79905 19.7 6.59905Z"
        fill="#2D3139"
      />
      <path
        d="M17.7 8.76599H6.30005C6.00005 8.76599 5.80005 8.96599 5.80005 9.26599L6.80005 20.766C6.90005 21.366 7.4001 21.866 8.1001 21.866H16.1001C16.8001 21.866 17.2999 21.366 17.3999 20.766L18.3999 9.26599C18.3999 8.86599 18.1 8.76599 17.7 8.76599ZM9.1001 20.766C8.7001 20.766 8.5 20.566 8.5 20.166L7.8999 10.766C7.8999 10.466 8.1 10.166 8.5 10.166C8.8 10.166 9.1001 10.366 9.1001 10.766L9.69995 20.166C9.69995 20.466 9.4001 20.766 9.1001 20.766ZM12.6001 20.166C12.6001 20.466 12.3 20.766 12 20.766C11.7 20.766 11.3999 20.466 11.3999 20.166V10.766C11.3999 10.466 11.7 10.166 12 10.166C12.3 10.166 12.6001 10.466 12.6001 10.766V20.166ZM16.2 10.766L15.6001 20.166C15.6001 20.466 15.3 20.766 15 20.766C14.7 20.766 14.3999 20.466 14.3999 20.166L15 10.766C15 10.466 15.3 10.166 15.7 10.166C16 10.166 16.2 10.466 16.2 10.766Z"
        fill="#2D3139"
      />
    </g>,
    <defs>
      <clipPath id="clip0_2150_89784">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>,
  ],
});
