import { selectAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleFunction, PartsStyleObject, SystemStyleFunction, SystemStyleObject } from "@chakra-ui/system";
import { mergeWith } from "@chakra-ui/utils";

import { Input } from "../../atoms/Input/inputStyles";
import colors from "../../foundations/Colors/colors";
import zIndices from "../../foundations/Z-index/z-index";

const baseStyleField: SystemStyleFunction = () => {
  return {
    ...Input?.baseStyle?.field,
    appearance: "none",
    "> option, > optgroup": {
      bg: "white",
    },
  };
};

const baseStyleIcon: SystemStyleObject = {
  width: 0,
  height: 0,
  zIndex: zIndices.docked,
  borderLeft: "5px solid transparent",
  borderRight: "5px solid transparent",
  borderTop: `5px solid ${colors.violet[300]}`,
  insetEnd: 3,
  position: "relative",
  _disabled: {
    display: "none",
  },
};

const baseStyle: PartsStyleFunction<typeof parts> = props => ({
  field: baseStyleField(props),
  icon: baseStyleIcon,
});

const iconSpacing = { paddingInlineEnd: "2rem" };

const sizes: Record<string, PartsStyleObject<typeof parts>> = mergeWith({}, Input.sizes, {
  md: {
    field: iconSpacing,
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants: Input.variants,
  defaultProps: Input.defaultProps,
};
