import { useEffect } from "react";

import { useUserConfirmationSocket } from "hooks";
import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { User, transformResponse, useSignUpMutation } from "store/api";
import { err } from "store/errors";
import {
  BodyText,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  InfoPanel,
  InfoPanelAction,
  InfoPanelContent,
  InfoPanelIllustration,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  TextDivider,
  TitleH1,
} from "ui";
import { useBreakpoints } from "ui/hooks";
import { camelKeysToSnake, getCurrentLocale } from "utils/helpers";

import SignUpForm, { SignUpFormState } from "./SignUpForm";

const SignUp: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();
  const [params, setParams] = useSearchParams();
  const [signUp, signUpResult] = useSignUpMutation();
  const lastMessage = useUserConfirmationSocket(signUpResult.data?.token || "");
  const customerEmail = params.get("customer_email");

  const onSignUp = async (formState: SignUpFormState) => {
    try {
      const signUpPayload = {
        language: getCurrentLocale(),
        ...camelKeysToSnake(formState),
      };
      await signUp(signUpPayload).unwrap();
      setParams({ confirm: "true" });
    } catch (error) {
      err.handler(error, [
        {
          code: "error_email_not_unique",
          title: t("prompt_error_used_e-mail_pt1"),
          description: t("label_action_request_error_used_e-mail_pt2"),
        },
      ]);
    }
  };

  useEffect(() => {
    const onConfirmation = async () => {
      try {
        const message = JSON.parse(lastMessage.message);
        const identifier = JSON.parse(lastMessage.identifier);
        const user = transformResponse(message) as User;
        if (identifier.channel === "Users::ConfirmationChannel" && user.emailConfirmed) {
          setParams({ confirmed: "true" });
        }
      } catch (error) {
        err.handler(error);
      }
    };

    if (params.get("confirm") === "true" && lastMessage && lastMessage.identifier && lastMessage.message) {
      onConfirmation();
    }
  }, [lastMessage, params, setParams]);

  const goToSignIn = () => {
    navigate(Paths.SignIn);
  };

  if (params.get("confirm") && signUpResult.isSuccess) {
    return (
      <InfoPanel isBorder>
        <InfoPanelIllustration illustrationName="EmailConfirmationIllustration" />
        <InfoPanelContent>
          <TitleH1 color="violet.300">{t("label_action_request_check_e-mail")}</TitleH1>
          <BodyText>{t("label_action_info_e-mail_sent_registration_information")}</BodyText>
          <Box>
            <BodyText>{t("label_action_request_spam_alert_title")}</BodyText>
            <BodyText>{t("label_action_request_check_spam_and_other_folder")}</BodyText>
          </Box>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button variant="secondary" w={["full", "initial"]} onClick={goToSignIn}>
            {t("label_action_request_go_to_sign_in")}
          </Button>
        </InfoPanelAction>
      </InfoPanel>
    );
  }

  if (params.get("confirmed") && signUpResult.isSuccess) {
    return (
      <InfoPanel isBorder>
        <InfoPanelIllustration illustrationName="ConfirmationCompleted2Illustration" color="greenAlert" />
        <InfoPanelContent>
          <TitleH1 color="greenAlert">{t("label_action_info_e-mail_confirmed")}</TitleH1>
          <BodyText>{t("prompt_e-mail_confirmed_description")}</BodyText>
        </InfoPanelContent>
        <InfoPanelAction>
          <Button w={["full", "initial"]} onClick={goToSignIn}>
            {t("label_action_request_go_to_sign_in")}
          </Button>
        </InfoPanelAction>
      </InfoPanel>
    );
  }

  return (
    <Card direction={["column", "row"]} py={[0, "xl"]} px={[0, "l", 16]}>
      <Stack direction="column" flex={1} spacing="m">
        <BodyText variant="header24" mt={[0, "m"]}>
          {t("label_noun_user_registration_title")}
        </BodyText>
        <BodyText>
          {customerEmail
            ? t("prompt_set_password_and_complete_account_creation")
            : t("label_action_request_user_registration_instructions")}
        </BodyText>
        <SignUpForm onSubmit={onSignUp} isSubmitting={signUpResult.isLoading} />
      </Stack>
      <Box mt={["xl", 0]} mb={[12, 0]}>
        {isMobile ? (
          <TextDivider label={t("label_other_divider").toUpperCase()} />
        ) : (
          <Divider variant="light" orientation="vertical" mx={[0, "l", 16]} />
        )}
      </Box>
      <Flex direction="column" flex={1} justifyContent="center">
        {!isMobile && (
          <>
            <TitleH1 color="violet.300" mt="xxl" mb="xl">
              {customerEmail ? t("prompt_quickly_set_up_your_account") : t("prompt_question_already_have_account")}
            </TitleH1>
            <List>
              {customerEmail ? (
                <ListItem>{t("prompt_set_up_your_vasco_space_account_quickly_and_easily")}</ListItem>
              ) : (
                <>
                  <ListItem>{t("prompt_sign_in_description")}</ListItem>
                  <ListItem>
                    <Text>
                      {t("prompt_forgot_password_description_pt1")}
                      <Link as={RouterLink} to={Paths.ResetPassword}>
                        {` ${t("prompt_forgot_password_description_pt2")}`}
                      </Link>
                      .
                    </Text>
                  </ListItem>
                </>
              )}
            </List>
          </>
        )}
        {!customerEmail && (
          <Button variant="tertiary" onClick={goToSignIn} w="full" mt={[0, "xl"]}>
            {t("label_action_request_sign_in")}
          </Button>
        )}
      </Flex>
    </Card>
  );
};

export default SignUp;
